import { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import { ISave, IUndo } from '../../../../styles/icons'
import it from 'date-fns/locale/it'
import * as Constants from '../../../../config/Constants'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from 'react-bootstrap/Modal'
import * as Utility from '../../../shared/Utility'
import * as actions from '../../../../actions'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { ProvinceField } from './ProvinceFieldB5'
import CodiceFiscale from 'codice-fiscale-js'
import { PersonTypeField } from './PersonTypeField'
import { Formik, Field, Form } from 'formik'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import * as Yup from 'yup'

registerLocale('it', it)

export const ModalCreateDelegate = (props) => {
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.delegatePerson?.id,
      personType: props.delegatePerson?.personType || Constants.PF,
      coraRelationship: {
        id: props.relationship?.id ? props.relationship?.id : null
      },
      startDate: props.delegatePerson?.startDate
        ? new Date(props.delegatePerson.startDate)
        : props.relationship?.startDate
          ? new Date(props.relationship.startDate)
          : null,
      endDate: props.delegatePerson?.endDate ? new Date(props.delegatePerson.endDate) : null,
      companyName: props.delegatePerson?.companyName || '',
      role: props.delegatePerson?.role ? props.delegatePerson.role : { id: 5 },
      fiscalCode: props.delegatePerson?.fiscalCode ? props.delegatePerson.fiscalCode : '',
      lastName: props.delegatePerson?.lastName ? props.delegatePerson.lastName : '',
      firstName: props.delegatePerson?.firstName ? props.delegatePerson.firstName : '',
      gender: props.delegatePerson?.gender || Constants.genderList[0].id,
      birthDate: props.delegatePerson?.birthDate ? new Date(props.delegatePerson.birthDate) : null,
      location: props.delegatePerson?.location ? props.delegatePerson.location : '',
      province: props.delegatePerson?.province ? props.delegatePerson.province : '',
      errorFiscalCode: false
    })
  }
  const [displayPf, setDisplayPf] = useState(true)
  const [displayPnf, setDisplayPnf] = useState(false)

  useEffect(() => {
    setNewInitialValues()
  }, [props])

  const handleInputAutocompile = (location, setValues, values) => {
    const form = Object.assign({}, values)
    if (!location || location === '') {
      form.location = location
      setValues(form)
    }
    if (form.province?.toUpperCase() === 'EE') {
      form.location = location?.toUpperCase() || ''
      form.province = form.province?.toUpperCase() || ''
      return
    }
    actions.getLocation(location?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.province = loc.province
          form.location = location?.toUpperCase() || ''
          setValues(form)
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    form.location = location?.toUpperCase() || ''
    setValues(form)
  }

  const handleChangeValue = (val, name, setValues, values, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (val && val !== '' && trim) val = val.trim()
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handlerRole = (e, setValues, values) => {
    const form = Object.assign({}, values)
    form.role = {
      id: e.target.value.trim(),
      code: e.target.getAttribute('data-cod'),
      description: e.target[e.target.value].text
    }
    setValues(form)
  }

  const handleFiscalCode = (val, setValues, values) => {
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    const form = Object.assign({}, values)
    form.fiscalCode = val
    actions.getBaseRegistryPersonByFiscalCode(val).then(
      (baseRegistry) => {
        if (baseRegistry !== undefined) {
          if (baseRegistry.personType === Constants.PF) {
            form.companyName = null
            form.lastName = form.lastName || baseRegistry?.lastName || ''
            form.firstName = form.firstName || baseRegistry?.firstName || ''
            form.birthDate = form.birthDate || baseRegistry?.birthDate || ''
            form.gender = baseRegistry?.gender || Constants.genderList[0].id
            if (baseRegistry?.location) {
              form.location = form.location || baseRegistry?.location?.location || ''
              form.province = form.province || baseRegistry?.location?.province || ''
            } else {
              const cf = new CodiceFiscale(val).toJSON()
              form.location = form.location || cf?.birthplace?.toUpperCase() || ''
              form.province = form.province || cf?.birthplaceProvincia || ''
            }
          } else {
            form.companyName = form.companyName || baseRegistry?.companyName || ''
            form.location = form.location || baseRegistry?.location?.location || ''
            form.province = form.province || baseRegistry?.location?.province || ''
            form.firstName = null
            form.lastName = null
            form.gender = null
          }
          form.errorFiscalCode = false
          setValues(form)
        } else {
          if (form.personType === Constants.PF) {
            try {
              const cf = new CodiceFiscale(val).toJSON()
              if (cf) {
                getFiscalCodeData(cf, form, setValues)
              }
              form.errorFiscalCode = false
            } catch (e) {
              form.errorFiscalCode = true
            }
            setValues(form)
          }
        }
      },
      () => {
        if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              getFiscalCodeData(cf, form, setValues)
            }
            form.errorFiscalCode = false
          } catch (e) {
            form.errorFiscalCode = true
          }
          setValues(form)
        }
      }
    )
  }

  const getFiscalCodeData = (cf, form, setValues) => {
    form.birthDate = form.birthDate || cf?.birthday ? new Date(cf?.birthday) : null
    form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
    if (cf.birthplaceProvincia !== 'EE') {
      form.location = form.location || cf?.birthplace.toUpperCase()
    }
    form.province = form.province || cf?.birthplaceProvincia || ''
    setValues(form)
  }

  const handleFormSubmit = async (values, validateForm) => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      setShowErrorAlert(false)
      props.handlerSubmitDelegate({ ...values, errorFiscalCode: undefined })
      handlerClose()
    }
  }

  const handlerClose = () => {
    props.onHide()
  }

  const handlePersonTypeClick = (val, setValues, values) => {
    const form = Object.assign({}, values)
    if (val === Constants.PF) {
      form.personType = val
      form.gender = 'FEMALE'
      form.companyName = null
      form.location = null
      setDisplayPf(true)
      setDisplayPnf(false)
    } else if (val === Constants.PNF) {
      form.personType = val
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
      form.location = null
      setDisplayPf(false)
      setDisplayPnf(true)
    }
    setValues(form)
  }

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required('Selezionare il tipo di persona'),
    startDate: Yup.date()
      .required('Campo obbligatorio')
      .test(
        'is-after-startDate',
        'Data di inizio deve essere uguale o successiva a data inizio rapporto',
        function (value) {
          return value >= new Date(props.relationship.startDate)
        }
      ),
    endDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'), 'La data fine non può essere minore della data inizio'),
    role: Yup.object().shape({
      id: Yup.string()
        .required('Campo obbligatorio')
        .test(
          'role-error',
          'Una persona fisica non può avere un titolare effettivo',
          function (value) {
            const { personType } = this.options.context
            return !(value == 5 && personType === Constants.PF)
          }
        )
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().nullable(),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione sociale'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede legale'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'gender',
      errorLabel: 'Sesso'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'role.id',
      errorLabel: 'Ruolo'
    },
    {
      errorKey: 'startDate',
      errorLabel: 'Data inizio'
    },
    {
      errorKey: 'endDate',
      errorLabel: 'Data fine'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = 'Luogo di nascita'
    else formErrorsTabMapping[4].errorLabel = 'Sede legale (Comune)'
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="xl"
      centered
      onHide={handlerClose}
      bsPrefix="modal-create modal">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          FAMIGLIA (Anagrafiche collegate al rapporto)
          <h4>
            {props.relationship.companyName !== null && props.relationship.companyName !== ''
              ? props.relationship.companyName
              : props.relationship.lastName + ' ' + props.relationship.firstName}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ values, errors, setValues, validateForm }) => (
            <Form>
              {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
              {showErrorAlert &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div>
                    <ErrorListAlert
                      errors={getErrors(errors, values.personType)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <div>
                <div className="row mb-3">
                  <div className="col-2 pe-2">
                    <Field name="startDate">
                      {({ field }) => (
                        <DateField
                          {...field}
                          label={'Data Inizio'}
                          date={values.startDate}
                          onChange={(date) =>
                            handleChangeValue(date, 'startDate', setValues, values)
                          }
                          error={errors.startDate}
                          touched={touched}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-2 pe-2">
                    <Field name="endDate">
                      {({ field }) => (
                        <DateField
                          {...field}
                          label={'Data Fine'}
                          date={values.endDate}
                          onChange={(date) => handleChangeValue(date, 'endDate', setValues, values)}
                          error={errors.endDate}
                          touched={touched}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-6 pe-0">
                    <Field name="role">
                      {({ field }) => (
                        <BwmSelect
                          {...field}
                          options={props.roles}
                          name="role"
                          label="Ruolo"
                          className="form-control"
                          value={values.role?.id}
                          error={errors.role}
                          touched={touched}
                          onChange={(e) => handlerRole(e, setValues, values)}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-row p-3 pb-2 pt-0">
                  <div className="w-15">
                    <Field name="personType">
                      {({ field }) => (
                        <PersonTypeField
                          {...field}
                          key={props.index}
                          id={props.index}
                          personType={values.personType}
                          error={errors.personType}
                          touched={touched}
                          onClick={(e) => handlePersonTypeClick(e.target.value, setValues, values)}
                        />
                      )}
                    </Field>
                  </div>
                  <div
                    id="infoPnf"
                    className={'col-md-10 col-lg-10' + (!displayPnf ? ' d-none' : '')}>
                    <div className="row">
                      <div className="col-4 pe-2 mb-2">
                        <Field name="fiscalCode">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="fiscalCode"
                              label="Codice Fiscale"
                              className="form-control"
                              value={values.fiscalCode}
                              error={errors.fiscalCode || errors.errorFiscalCode}
                              touched={touched}
                              maxLength="16"
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'fiscalCode',
                                  setValues,
                                  values,
                                  true,
                                  true
                                )
                              }
                              onBlur={(e) => handleFiscalCode(e.target.value, setValues, values)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-8 mb-2 pe-4">
                        <Field name="companyName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="companyName"
                              label="Denominazione"
                              className="form-control"
                              value={values.companyName}
                              error={errors.companyName}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'companyName',
                                  setValues,
                                  values,
                                  true,
                                  true
                                )
                              }
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-5 col-lg-5 pe-2">
                        <Field name="location">
                          {({ field }) => (
                            <AutoCompileCustom
                              {...field}
                              label="Sede Legale (Comune) "
                              id="location"
                              value={values.location}
                              error={errors.location}
                              touched={touched}
                              handleInputAutocompile={(e) =>
                                handleInputAutocompile(e, setValues, values)
                              }
                              filtro={props.relationshipLocations}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <Field name="province">
                          {({ field }) => (
                            <ProvinceField
                              {...field}
                              placeholder="Prov. EE per Estero"
                              label="EE per Estero"
                              province={values.province}
                              error={errors.province}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'province',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div
                    id="infoPf"
                    className={'col-md-10 col-lg-10' + (!displayPf ? ' d-none' : '')}>
                    <div className="form-row col-md-12 col-lg-12 mb-2">
                      <div className="col-4 pe-2">
                        <Field name="fiscalCode">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="fiscalCode"
                              label="Codice Fiscale"
                              className="form-control"
                              value={values.fiscalCode}
                              error={errors.fiscalCode || errors.errorFiscalCode}
                              touched={touched}
                              maxLength="16"
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'fiscalCode',
                                  setValues,
                                  values,
                                  true,
                                  true
                                )
                              }
                              onBlur={(e) => handleFiscalCode(e.target.value, setValues, values)}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="col-4 pe-2">
                        <Field name="lastName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="lastName"
                              label="Cognome"
                              className="form-control"
                              value={values.lastName}
                              error={errors.lastName}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'lastName',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              maxLength="26"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-4 pe-2">
                        <Field name="firstName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="firstName"
                              label="Nome"
                              className="form-control"
                              value={values.firstName}
                              error={errors.firstName}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'firstName',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              maxLength="25"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="form-row col-md-12 col-lg-12">
                      <div className="col-2 pe-2">
                        <Field name="gender">
                          {({ field }) => (
                            <BwmSelect
                              {...field}
                              options={Constants.genderList}
                              name="gender"
                              label="Sesso"
                              className="form-control"
                              onChange={(e) =>
                                handleChangeValue(e.target.value, 'gender', setValues, values)
                              }
                              value={values.gender}
                              error={errors.gender}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-2 pe-2">
                        <Field name="birthDate">
                          {({ field }) => (
                            <DateField
                              {...field}
                              name={'birthDate'}
                              label={'Data di Nascita'}
                              date={values.birthDate}
                              error={errors.birthDate}
                              touched={touched}
                              onChange={(date) =>
                                handleChangeValue(date, 'birthDate', setValues, values)
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className={'col-4 pe-2'}>
                        <Field name="location">
                          {({ field }) => (
                            <AutoCompileCustom
                              {...field}
                              label="Comune di Nascita"
                              id="location"
                              handleInputAutocompile={(e) =>
                                handleInputAutocompile(e, setValues, values)
                              }
                              filtro={props.relationshipLocations}
                              value={values.location}
                              error={errors.location}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-3">
                        <Field name="province">
                          {({ field }) => (
                            <ProvinceField
                              {...field}
                              placeholder="Prov. EE per Estero"
                              label="EE per Estero"
                              province={values.province}
                              error={errors.province}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'province',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row mt-4 d-flex justify-content-between">
                  <button className="btn btn-empty px-4 btn-sm" onClick={handlerClose}>
                    <IUndo className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
                    &nbsp; ANNULLA
                  </button>
                  <button
                    onClick={() => handleFormSubmit(values, validateForm)}
                    className="btn btn-new-rel px-5">
                    <ISave className={'padding-bottom-4px svg'} width="16" fill={'#FFFFFF'} />
                    &nbsp; CONFERMA
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalCreateDelegate
