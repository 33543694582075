import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from 'react-bootstrap/Modal'
import { CoraSubjectForm } from './NewCoraSubjectForm'
import axiosService from '../../../../services/axios-service'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import * as Constants from '../../../../config/Constants'
import { isNotEmpty } from '../../../shared/Utility'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'

export class CoraSubjectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: this.props.hideButton,
      defaultEmail: ''
    }
  }

  getCurrentUser = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get(`/api/common/users/current`).then((res) => {
      if (isNotEmpty(res)) {
        this.setState({
          defaultEmail: res.data.email
        })
      }
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.setState({ fiscalCode: '' })
    this.setState({ companyName: '' })
    this.setState({ location: '' })
    this.setState({ province: '' })
    if (this.props.closeDialog) this.props.closeDialog()
  }

  openModal = () => {
    const subjectSummary = this.props.subjectSummary
    if (subjectSummary && subjectSummary?.available > 0) {
      this.getCurrentUser()
      this.setState({ showModal: true })
    } else {
      PopupSubjectLicense({
        total: subjectSummary?.total,
        used: subjectSummary?.used,
        available: subjectSummary?.available,
        name: Constants.APPLICATION_CORA
      })
    }
  }

  render() {
    let disabled = this.props.licenseExpired
    return (
      <>
        {!this.props.hideButton && (
          <button
            type="button"
            className="btn btn-new-rel px-3 btn-sm"
            onClick={this.openModal}
            disabled={disabled}>
            <IPlus
              className={'padding-right-4px'}
              width="14"
              fill={` ${disabled ? '#128186' : '#FFFFFF'}`}
            />
            &nbsp; Nuovo soggetto
          </button>
        )}
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="width-90 p-2"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>
                  {this.props.id
                    ? this.state.subject?.id
                      ? `Modifica Soggetto ${this.state.subject.companyName}`
                      : 'Caricamento...'
                    : 'Nuovo soggetto'}
                </h2>
              </Modal.Title>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CoraSubjectForm
              defaultEmail={this.state.defaultEmail}
              subjectId={this.props.id}
              locations={this.props.locations}
              key="coraSubjectNewModal"
              licenseExpired={this.props.licenseExpired}
              modal={true}
              addOrUpdateCoraSubject={this.props.addOrUpdateCoraSubject}
              setSubject={(subject) => this.setState({ subject })}
              closeModal={this.closeModal}
            />
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
