import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from './components/shared/Header'
import * as actions from './actions'
import './App.css'
import { Provider } from 'react-redux'
import { ProtectedRoute } from './components/shared/auth/ProtectedRoute'
import Footer from './components/shared/Footer'
import ReactGA from 'react-ga'
import { ApplicationPaths } from './services/ApplicationPaths'
const store = require('./reducers').init()

class App extends Component {
  constructor(props) {
    super()
  }

  getCurrentUser = () => {
    store.dispatch(actions.getAccount())
  }

  componentDidMount() {
    this.getCurrentUser()

    store.subscribe(() => {
      if (store.getState().auth?.user?.email) {
        ReactGA.initialize('UA-148800686-1')
        ReactGA.pageview(window.location.pathname)
        ReactGA.set({
          userId: store.getState().auth.user.email
        })
      }
    })
  }

  logout() {
    store.dispatch(actions.logout())
  }

  renderUnprotectedPaths() {
    return ApplicationPaths.unprotected.map((p) => {
      return <Route key={p} exact path={p.path} component={p.component} />
    })
  }

  renderProtectedPaths() {
    return ApplicationPaths.protected.map((p) => {
      return <ProtectedRoute key={p} exact path={p.path} component={p.component} />
    })
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Header logout={this.logout} />
            <div className="mx-2 page-body">
              <Switch>
                {this.renderUnprotectedPaths()}

                {this.renderProtectedPaths()}
              </Switch>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
