import { GET_CRS_RELATIONSHIPS_SUCCESS } from '../../actions/types'

const INITIAL_STATE = {
  crsRelationships: {
    data: [],
    errors: []
  }
}

export const crsRelationshipReducer = (state = INITIAL_STATE.crsRelationships, action) => {
  switch (action.type) {
    case GET_CRS_RELATIONSHIPS_SUCCESS:
      return { ...state, data: action.crsRelationships }
    default:
      return state
  }
}
