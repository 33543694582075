import { useEffect, useState } from 'react'
import './DropdownMultipleSelect.css'
import { SelectableElement } from './SelectableElement'

export function DropdownMultipleSelect({
  options = [{ id: null, description: '' }],
  onSelectionChange = (selectedOptions) => {},
  value = []
}) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])
  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const handleConfirmClick = () => {
    onSelectionChange(selectedOptions)
    setShowDropdown(false)
  }

  return (
    <>
      <button
        title={
          selectedOptions?.length > 0
            ? selectedOptions.map((o) => o.description).join(', ')
            : undefined
        }
        className="dropdown-toggle"
        onClick={() => setShowDropdown(!showDropdown)}>
        {selectedOptions?.length > 0
          ? `${selectedOptions.length} ${selectedOptions.length === 1 ? 'Elemento selezionato' : 'Elementi selezionati'}`
          : 'Scegli opzioni'}
      </button>
      {showDropdown && (
        <div className="dropdownMultipleSelect">
          <div className="dropdownMultipleSelect-content">
            {options.map((option, index) => (
              <SelectableElement
                key={`dropdown-multiple-select-${option.id}-${Math.random() * 10}`}
                value={option}
                defaultChecked={selectedOptions.includes(option)}
                onChange={() => handleOptionClick(option)}
                label={option.description}
              />
            ))}
          </div>
          <div className="dropdownMultipleSelect-footer">
            <button
              className="btn btn-primary btn-new-rel mb-1 me-1 mt-2"
              onClick={handleConfirmClick}>
              APPLICA
            </button>
          </div>
        </div>
      )}
    </>
  )
}
