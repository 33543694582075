import { BLUE_LANGUAGE_CHANGE } from '../../actions/types'

const detectLanguage = () => {
  if (/^en\b/.test(navigator.language)) {
    return 'en'
  }
  return 'it'
}

const INITIAL_STATE = {
  language: detectLanguage()
}

export const blueLanguageReducer = (state = INITIAL_STATE, action) => {
  if (action.type === BLUE_LANGUAGE_CHANGE) {
    return { language: action.language }
  }
  return state
}
