import { OverlayTrigger, Tooltip } from 'react-bootstrap'

//v. documentazione: https://react-bootstrap-v4.netlify.app/components/overlays/#tooltips
/**
 * Componente wrapper per visualizzare tooltip
 * se props.text è vuoto, il toltip viene nascosto
 * @param {*} props
 * @returns
 */
export function StarTooltip({ text, className, placement, children }) {
  return (
    <>
      {text ? (
        <OverlayTrigger
          placement={placement || 'top'}
          overlay={
            <Tooltip aria-label={text} className={className}>
              {text}
            </Tooltip>
          }>
          {children}
        </OverlayTrigger>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
