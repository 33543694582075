import React, { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

export function FormBenvenuto() {
  const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  return <React.Fragment>{console.log(useQuery().getAll('asd'))}</React.Fragment>
}
