import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'

export function SubjectCredentialsSection({
  sectionTitleStyle,
  pinFiscoOnline,
  pinRevoca,
  progressivoSede,
  numeroRichiestaRegistrazione,
  setPinFiscoOnline,
  setPinRevoca,
  setProgressivoSede,
  setNumeroRichiestaRegistrazione
}) {
  return (
    <>
      <div className={sectionTitleStyle}>CREDENZIALI DEL SOGGETTO OBBLIGATO </div>
      <Row>
        <Col lg={5}>
          <FloatLabelTextBox
            value={pinFiscoOnline}
            label={'PIN Fisconline/Entratel'}
            onChange={(e) => setPinFiscoOnline(e.target.value)}
          />
        </Col>
        <Col lg={5}>
          <FloatLabelTextBox
            value={pinRevoca}
            label={'PIN DI REVOCA ambiente di sicurezza attuale'}
            onChange={(e) => setPinRevoca(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <FloatLabelTextBox
            value={progressivoSede}
            label={'Progressivo sede'}
            onChange={(e) => setProgressivoSede(e.target.value)}
          />
        </Col>
        <Col lg={6}>
          <FloatLabelTextBox
            value={numeroRichiestaRegistrazione}
            label={'Numero  richiesta di registrazione al servizio Fisconline'}
            onChange={(e) => setNumeroRichiestaRegistrazione(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
