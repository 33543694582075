import { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import Tabs from '../../application-cora/relationships/Tabs'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import * as actions from '../../../../actions'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import { ISave, ITextFile, IUndo } from '../../../../styles/icons'
import { DateField } from '../../../shared/form/DateFieldB5'
import * as Constants from '../../../../config/Constants'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { ReactComponent as IInfo } from '../../../../styles/images/svg/info-circle.svg'
import '../../../../styles/comurei-form.css'
import CodiceFiscale from 'codice-fiscale-js'
import { PopupError } from '../../../shared/PopupError'
import * as Utility from '../../../shared/Utility'
import { Row } from 'react-bootstrap'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'
import { ProvinceField } from '../../application-cora/relationships/ProvinceFieldB5'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

const ComureiForm = (props) => {
  const [subjectSummary, setSubjectSummary] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [tabError, setTabError] = useState({
    subject: true,
    responsible: true,
    pec: true,
    canc: true
  })
  const [activeTab, setActiveTab] = useState('tab-sub')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const setTab = (tab) => {
    setActiveTab(tab)
  }

  const retrieveSubject = (id) => {
    if (id) {
      actions.getComureiSubjectDetail(id).then((response) => {
        setNewInitialValues(response.data)
      })
    } else {
      setNewInitialValues()
    }
  }

  const setNewInitialValues = (data) => {
    setInitialValues({
      id: data?.id || null,
      fiscalCode: data?.fiscalCode || '',
      companyName: data?.companyName || '',
      location: data?.location || '',
      province: data?.province || '',
      registryReason: data && data.registryReason !== null ? data.registryReason : true,
      monitoringReason: data?.monitoringReason || false,
      factaReason: data?.factaReason || false,
      birthDate: data?.birthDate ? new Date(data?.birthDate) : null,
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      centralizedStructure: data?.centralizedStructure || false,
      gender:
        data?.gender ||
        ((data?.personType || Constants.PNF) === Constants.PF ? Constants.genderList[0].id : null),
      personType: data?.personType || Constants.PNF,
      comureiCommunicationType: {
        id: data?.comureiCommunicationType.id.toString() || '1'
      },
      comureiSubjectType: {
        id: data?.comureiSubjectType?.id || '',
        code: data?.comureiSubjectType?.code || ''
      },
      comureiSubjectResponsible: {
        id: data?.comureiSubjectResponsible?.id || null,
        fiscalCode: data?.comureiSubjectResponsible?.fiscalCode || '',
        firstName: data?.comureiSubjectResponsible?.firstName || '',
        lastName: data?.comureiSubjectResponsible?.lastName || '',
        gender: data?.comureiSubjectResponsible?.gender || 'FEMALE',
        birthDate: data?.comureiSubjectResponsible?.birthDate
          ? new Date(data?.comureiSubjectResponsible?.birthDate)
          : null,
        location: data?.comureiSubjectResponsible?.location || '',
        province: data?.comureiSubjectResponsible?.province || '',
        email: data?.comureiSubjectResponsible?.email || props.user?.login || '',
        phone: data?.comureiSubjectResponsible?.phone || ''
      },
      comureiSubjectPec: {
        id: data?.comureiSubjectPec?.id || null,
        pecEmail: data?.comureiSubjectPec?.pecEmail || '',
        pecManager: data?.comureiSubjectPec?.pecManager || '',
        effectiveDate: data?.comureiSubjectPec?.effectiveDate
          ? new Date(data.comureiSubjectPec.effectiveDate)
          : '',
        endDate: data?.comureiSubjectPec?.endDate ? new Date(data.comureiSubjectPec.endDate) : '',
        reiEmail: data?.comureiSubjectPec?.reiEmail || ''
      },
      comureiSubjectCancellation: {
        id: data?.comureiSubjectCancellation?.id || null,
        cancellationDate: data?.comureiSubjectCancellation?.cancellationDate
          ? new Date(data.comureiSubjectCancellation.cancellationDate)
          : null,
        address: data?.comureiSubjectCancellation?.address || '',
        incorporatingFiscalCodeOne:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '',
        incorporatingFiscalCodeTwo:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '',
        incorporatingFiscalCodeThree:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '',
        location: data?.comureiSubjectCancellation?.location || '',
        province: data?.comureiSubjectCancellation?.province || '',
        comureiSubjectCancellationType: {
          id:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1',
          code:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1'
        }
      },
      comureiTransmission: {
        id: data?.comureiTransmission?.id || null,
        fiscalCode: data?.comureiTransmission?.fiscalCode || '',
        comureiTransmissionType: {
          id: data?.comureiTransmission?.comureiTransmissionType.id.toString() || '1'
        }
      },
      errorFiscalCode: false,
      errorComureiSubjectResponsibleFiscalCode: false
    })
  }

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth) // Aggiorna la larghezza ogni volta che la finestra viene ridimensionata
    }

    // Aggiungi l'evento resize per aggiornare la larghezza
    window.addEventListener('resize', handleResize)

    // Pulisci l'evento quando il componente viene smontato
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const setTabWithError = (tabDict) => {
    const tabList = Object.entries(tabDict)
      .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
      .map(([key]) => key) // Mappa solo le chiavi

    document.querySelectorAll('.tab-list-item').forEach((item) => {
      item.firstChild.classList.remove('text-danger')
    })
    tabList.forEach((tabId) => {
      // Add 'border-danger' to the element with id 'test'
      document.getElementById(tabId).firstChild.classList.add('text-danger')
    })
  }

  const validationSchema = Yup.object().shape({
    // VALIDATION SUBJECT
    personType: Yup.string().required('Selezionare il tipo di persona'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    atLeastOne: Yup.boolean().test('at-least-one', 'Selezionare almeno una tipologia', function () {
      const { monitoringReason, registryReason, factaReason } = this.parent
      return monitoringReason || registryReason || factaReason
    }),
    notMonitoringReasonSelectable: Yup.boolean().test(
      'notMonitoringReasonSelectable',
      'non può essere selezionato se il Tipo Soggetto è con codice 05',
      function () {
        const { comureiSubjectType, monitoringReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '5' && monitoringReason)
      }
    ),
    notRegistryReasonSelectable: Yup.boolean().test(
      'notRegistryReasonSelectable',
      'Il Tipo Soggetto 29 non può essere iscritto alla sezione INDAGINI BANCARIE. In caso di Holding da iscrivere per la comunicazione Anagrafe Rapporti scegliere il tipo 05',
      function () {
        const { comureiSubjectType, registryReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '29' && registryReason)
      }
    ),
    comureiSubjectType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio'),
      code: Yup.string()
    }),
    // VALIDATION RESPONSABLE
    comureiSubjectResponsible: Yup.object().shape({
      id: Yup.string().nullable(),
      fiscalCode: Yup.string().required('Campo obbligatorio'),
      firstName: Yup.string().required('Campo obbligatorio'),
      lastName: Yup.string().required('Campo obbligatorio'),
      gender: Yup.string().required('Campo obbligatorio'),
      birthDate: Yup.date().required('Campo obbligatorio'),
      location: Yup.string().required('Campo obbligatorio'),
      province: Yup.string().required('Campo obbligatorio'),
      email: Yup.string().email('Formato email non valido').required('Campo obbligatorio'),
      phone: Yup.string().required('Campo obbligatorio')
    }),
    // VALIDATION EMAIL
    comureiSubjectPec: Yup.object().shape({
      id: Yup.string().nullable(),
      pecEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id !== '2' && comureiCommunicationType?.id !== '3',
        then: (schema) => schema.email('Formato email non valido').required('Campo obbligatorio'),
        otherwise: (schema) => schema.email('Formato email non valido')
      }),
      reiEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id === '2' || comureiCommunicationType?.id === '3',
        then: (schema) => schema.email('Formato email non valido').required('Campo obbligatorio'),
        otherwise: (schema) => schema.email('Formato email non valido')
      }),
      pecManager: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      effectiveDate: Yup.date().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      endDate: Yup.date()
        .nullable()
        .when(['effectiveDate', '$comureiCommunicationType'], (data, schema) => {
          return schema.test({
            test: (endDate) => {
              return !(
                data[1]?.id !== '2' &&
                endDate &&
                data?.[0] &&
                new Date(endDate) < new Date(data[0])
              )
            },
            message: 'La data non può essere minore della Data DECORRENZA'
          })
        })
    }),

    // VALIDATION CANCELLATION
    comureiSubjectCancellation: Yup.object().shape({
      id: Yup.string().nullable(),
      cancellationDate: Yup.date()
        .nullable()
        .when('$comureiCommunicationType', {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        }),
      address: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      location: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      province: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      comureiSubjectCancellationType: Yup.object().shape({
        id: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        }),
        code: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        })
      }),
      incorporatingFiscalCodeOne: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            if (
              comureiCommunicationType?.id === '2' &&
              comureiSubjectCancellation?.comureiSubjectCancellationType?.id === '1'
            )
              return true
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      ),
      incorporatingFiscalCodeTwo: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      ),
      incorporatingFiscalCodeThree: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      )
    }),

    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    ),
    errorComureiSubjectResponsibleFiscalCode: Yup.boolean().test(
      'errorComureiSubjectResponsibleFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = {
    'tab-sub': [
      {
        errorKey: 'comureiSubjectType.id',
        errorLabel: 'Tipo soggetto'
      },
      {
        errorKey: 'atLeastOne',
        errorLabel: 'Motivi della comunicazione'
      },
      {
        errorKey: 'notMonitoringReasonSelectable',
        errorLabel: 'Monitoraggio fiscale'
      },
      {
        errorKey: 'notRegistryReasonSelectable',
        errorLabel: 'Indagini Finanziarie'
      },
      {
        errorKey: 'errorFiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'fiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'companyName',
        errorLabel: 'Ragione Sociale'
      },
      {
        errorKey: 'location',
        errorLabel: 'Sede legale'
      },
      {
        errorKey: 'province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'firstName',
        errorLabel: 'Nome'
      },
      {
        errorKey: 'lastName',
        errorLabel: 'Cognome'
      },
      {
        errorKey: 'birthDate',
        errorLabel: 'Data di nascita'
      },
      {
        errorKey: 'gender',
        errorLabel: 'Sesso'
      }
    ],
    'tab-resp': [
      {
        errorKey: 'errorComureiSubjectResponsibleFiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'comureiSubjectResponsible.fiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'comureiSubjectResponsible.firstName',
        errorLabel: 'Nome'
      },
      {
        errorKey: 'comureiSubjectResponsible.lastName',
        errorLabel: 'Cognome'
      },
      {
        errorKey: 'comureiSubjectResponsible.gender',
        errorLabel: 'Sesso'
      },
      {
        errorKey: 'comureiSubjectResponsible.birthDate',
        errorLabel: 'Data di nascita'
      },
      {
        errorKey: 'comureiSubjectResponsible.location',
        errorLabel: 'Comune di nascita'
      },
      {
        errorKey: 'comureiSubjectResponsible.province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'comureiSubjectResponsible.email',
        errorLabel: 'Email'
      },
      {
        errorKey: 'comureiSubjectResponsible.phone',
        errorLabel: 'Telofono'
      }
    ],
    'tab-pec': [
      {
        errorKey: 'comureiSubjectPec.pecEmail',
        errorLabel: 'Posta Elettronica'
      },
      {
        errorKey: 'comureiSubjectPec.pecManager',
        errorLabel: 'Gestore PEC'
      },
      {
        errorKey: 'comureiSubjectPec.effectiveDate',
        errorLabel: 'Data DECORRENZA'
      },
      {
        errorKey: 'comureiSubjectPec.endDate',
        errorLabel: 'Data CESSAZIONE'
      },
      {
        errorKey: 'comureiSubjectPec.pecEmailAddress',
        errorLabel: 'Indirizzo PEC ultima pecEmailAddress'
      }
    ],
    'tab-delete': [
      {
        errorKey: 'comureiSubjectCancellation.cancellationDate',
        errorLabel: 'Data CANCELLAZIONE'
      },
      {
        errorKey: 'comureiSubjectCancellation.address',
        errorLabel: 'Indirizzo'
      },
      {
        errorKey: 'comureiSubjectCancellation.province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeOne',
        errorLabel: 'Codice Fiscale operatore incorporante 1'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
        errorLabel: 'Codice Fiscale operatore incorporante 2'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeThree',
        errorLabel: 'Codice Fiscale operatore incorporante 3'
      }
    ],
    'tab-transmission': []
  }

  const formTabsWithErrors = {
    'tab-sub': false,
    'tab-resp': false,
    'tab-pec': false,
    'tab-delete': false,
    'tab-transmission': false
  }

  const getActivePropsAndTab = (errors) => {
    var tabsWithErrors = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping)
    setTabWithError(tabsWithErrors)

    var activeErrorsLabels = undefined
    if (activeTab) {
      activeErrorsLabels = formErrorsTabMapping[activeTab]
    }

    if (activeErrorsLabels) {
      errors = Utility.extractErrors(errors, activeErrorsLabels)
    }

    return errors
  }

  // Funzione per cercare i tab con errori
  const findTabsWithErrors = (errors, formTabsWithErrors, formErrorsTabMapping) => {
    const updatedTabsWithErrors = { ...formTabsWithErrors } // Clone the initial state

    // Helper function to check recursively if a field exists in errors (including nested objects)
    const checkFieldInErrors = (errors, fieldKey) => {
      const keys = fieldKey.split('.') // Handle nested keys, e.g., comureiSubjectResponsible.fiscalCode
      let current = errors

      // Traverse through nested objects
      for (let key of keys) {
        if (current[key]) {
          current = current[key] // Dive deeper if the key exists
        } else {
          return false // If any key is not found, return false
        }
      }
      return true // If all keys are found, return true
    }

    // Iterate over each tab in the mapping
    for (let tab in formErrorsTabMapping) {
      const fields = formErrorsTabMapping[tab]

      // Check if at least one field in this tab has an error
      const hasError = fields.some(({ errorKey }) => checkFieldInErrors(errors, errorKey))

      // Update the tab status based on whether it has an error
      updatedTabsWithErrors[tab] = hasError
    }

    return updatedTabsWithErrors
  }

  useEffect(() => {
    if (!tabError.subject) {
      if (!document.getElementById('tab-sub').classList.contains('active')) {
        document.getElementById('tab-sub').click()
      }
    } else if (!tabError.responsible) {
      if (!document.getElementById('tab-resp').classList.contains('active')) {
        document.getElementById('tab-resp').click()
      }
    } else if (!tabError.pec) {
      if (!document.getElementById('tab-pec').classList.contains('active')) {
        document.getElementById('tab-pec').click()
      }
    } else if (!tabError.canc) {
      if (!document.getElementById('tab-delete').classList.contains('active')) {
        document.getElementById('tab-delete').click()
      }
    }
  }, [tabError])

  useEffect(() => {
    checkRemainingSubjects()
  }, [])

  const checkIncorporatingFiscalCodeOne = (
    comureiCommunicationType,
    comureiSubjectCancellation = {}
  ) => {
    const cancellationReason = comureiSubjectCancellation?.comureiSubjectCancellationType?.id
    if (comureiCommunicationType?.id?.toString() === '2') {
      if (cancellationReason === '5') {
        return (
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeTwo) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeTwo &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree)
        )
      }
    }
    return false
  }

  const checkRemainingSubjects = () => {
    CheckRemainingSubjects(Constants.APPLICATION_COMUREI, false, (subjectSummary) => {
      setSubjectSummary(subjectSummary)
    })
  }

  const addComureiSubject = (newSubject, sendComm) => {
    actions.addComureiSubject(newSubject).then(
      (response) => {
        if (sendComm) {
          createCommunication(response)
          props.getSubjects()
        } else {
          PopupConfirm({
            text: 'DATI SALVATI, SI DESIDERA CREARE LA COMUNICAZIONE?',
            confirm: 'CREA COMUNICAZIONE',
            cancel: 'CHIUDI',
            titleColor: titleColors.SUCCESS,
            title: 'Soggetto salvato correttamente',
            handleClickConfirm: () => createCommunication(response)
          })
        }

        props.getSubjects()
      },
      (errors) => {
        if (errors?.status === 511) {
          PopupSubjectLicense({
            total: subjectSummary.total,
            used: subjectSummary.used,
            link: Constants.COMUREI_BUY_LINK
          })
        } else {
          PopupError({
            text: Constants.APPLICATION_GENERIC_ERROR
          })
        }
      }
    )

    if (initialValues?.id === null) {
      props.closeModal()
    }
  }

  const createCommunication = (response) => {
    actions.createComureiCommunication(response).then(
      (communications) => {
        props.openCreateCommunicationModal(response, communications)
      },
      (errors) => {
        if (errors?.status === 511) {
          PopupSubjectLicense({
            total: subjectSummary.total,
            used: subjectSummary.used,
            link: Constants.COMUREI_BUY_LINK
          })
        } else if (errors?.data?.title) {
          PopupError({
            text: errors?.data?.title
          })
        } else {
          PopupError({
            text: 'Errore nella generazione della comunicazione'
          })
        }
      }
    )
  }

  const handlerChangeSubjectType = (e, setValues, values) => {
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setValues({
      ...values,
      comureiSubjectType: { id: parseInt(e.target.value), code: cod }
    })
  }

  const handleChangeTextField = (value, field, setValues, values, trim) => {
    let val = value.toUpperCase()
    if (trim) val = val.trim()
    const form = Object.assign({}, values)
    Utility.updateObjProp(form, val, field)
    setValues(form)
  }

  const handleFiscalCode = (val, setValues, values) => {
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    const form = Object.assign({}, values)
    actions
      .getBaseRegistryByFiscalCode(val)
      .then((baseRegistry) => {
        if (baseRegistry) {
          if (values.personType === Constants.PNF) {
            form.companyName = baseRegistry?.companyName || values.companyName || ''
            form.location = baseRegistry?.location?.location || values.location || ''
            form.province = baseRegistry?.location?.province || values.province || ''
            form.firstName = null
            form.lastName = null
            form.birthDate = null
            form.gender = null
          } else if (values.personType === Constants.PF) {
            form.companyName = null
            form.location = baseRegistry?.location?.location || values.location || ''
            form.province = baseRegistry?.location?.province || values.province || ''
            form.firstName = baseRegistry?.firstName || ''
            form.lastName = baseRegistry?.lastName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender || ''
          }
          form.errorFiscalCode = false
          setValues(form)
        } else if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              const form = Object.assign({}, values)
              form.birthDate = form.birthDate || cf?.birthday ? new Date(cf?.birthday) : null
              form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
              if (cf.birthplaceProvincia !== 'EE') {
                form.location = form.location || cf?.birthplace.toUpperCase()
              }
              form.province = form.province || cf?.birthplaceProvincia || ''
              setValues(form)
            }
            form.errorFiscalCode = false
            setValues(form)
          } catch (e) {
            form.errorFiscalCode = true
            setValues(form)
          }
        }
      })
      .catch(() => {
        form.errorFiscalCode = true
        setValues(form)
      })
  }

  const handleChangeValue = (val, field, setValues, values) => {
    const form = Object.assign({}, values)
    Utility.updateObjProp(form, val, field)
    setValues(form)
  }

  const handlerResponsibleFiscalCode = (val, setValues, values) => {
    const form = Object.assign({}, values)
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    actions
      .getBaseRegistryPersonByFiscalCode(val)
      .then((baseRegistry) => {
        if (baseRegistry !== undefined && baseRegistry?.personType === Constants.PF) {
          const form = Object.assign({}, values)
          form.comureiSubjectResponsible.fiscalCode = val
          form.comureiSubjectResponsible.lastName = baseRegistry?.lastName || ''
          form.comureiSubjectResponsible.firstName = baseRegistry?.firstName || ''
          form.comureiSubjectResponsible.location = baseRegistry?.location?.location || ''
          form.comureiSubjectResponsible.province = baseRegistry?.location?.province || ''
          form.comureiSubjectResponsible.birthDate = baseRegistry?.birthDate
            ? new Date(baseRegistry?.birthDate)
            : null
          form.comureiSubjectResponsible.gender = baseRegistry?.gender
          form.errorComureiSubjectResponsibleFiscalCode = false
          setValues(form)
        } else if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              const form = Object.assign({}, values)
              form.comureiSubjectResponsible.location =
                cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace : ''
              form.comureiSubjectResponsible.province = cf?.birthplaceProvincia || ''
              form.comureiSubjectResponsible.birthDate = cf?.birthday
                ? new Date(cf?.birthday)
                : null
              form.comureiSubjectResponsible.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
            }
            form.errorComureiSubjectResponsibleFiscalCode = false
            setValues(form)
          } catch (e) {
            form.errorComureiSubjectResponsibleFiscalCode = true
            setValues(form)
          }
        }
      })
      .catch(() => {
        form.errorComureiSubjectResponsibleFiscalCode = true
        setValues(form)
      })
  }

  const handleInputAutocompile = (newLocation, objectKey, setValues, values) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = newLocation
          if (loc) form[objectKey].province = loc.province
        } else {
          form.location = newLocation
          if (loc) form.province = loc.province
        }
        setValues(form)
      },
      (errors) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = ''
          form[objectKey].province = ''
        } else {
          form.location = ''
          form.province = ''
        }
        setValues(form)
      }
    )
  }

  const handlerChangeResponsiblePhoneNumber = (e, setValues, values) => {
    if (!Number(e.target.value) && e.target.value !== '') return
    handleChangeTextField(
      e.target.value,
      'comureiSubjectResponsible.phone',
      setValues,
      values,
      true
    )
  }

  const handleFormSubmit = async (values, validateForm, sendComm) => {
    const errors = await validateForm(values) // Validazione dei valori
    setTouched(true)
    if (Object.keys(errors).length > 0) {
      setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      updateAll(values, sendComm) // Esegui l'aggiornamento se non ci sono errori
      var tabsWithErrors = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping)
      setTabWithError(tabsWithErrors)
      setShowErrorAlert(false) // Nascondi l'alert
    }
  }

  const updateAll = (values, sendComm) => {
    addComureiSubject(
      {
        ...values,
        id: values?.id,
        errorFiscalCode: undefined,
        errorComureiSubjectResponsibleFiscalCode: undefined
      },
      sendComm
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ values, errors, setValues, validateForm }) => (
          <Form>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert &&
              errors &&
              typeof errors === 'object' &&
              Object.keys(errors).length > 0 && (
                <div>
                  <ErrorListAlert
                    errors={getActivePropsAndTab(errors, activeTab)}
                    hide={() => setShowErrorAlert(false)}
                  />
                </div>
              )}
            <div className="row-detail comurei d-flex">
              <div className="tab-content col-md-12 col-lg-12">
                <Tabs setTab={setTab}>
                  <div id="tab-sub" label="Soggetto">
                    <div className="row">
                      <div className="col-md-8 col-lg-7 subDetailComurei pe-5">
                        {/* <h6>Dettaglio Soggetto che Comunica</h6> */}
                        {/* TIPO PERSONA */}
                        <div className="row mb-3">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 mb-3 ps-0">
                              <div className="input-group">
                                <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
                                  <label
                                    className="form-check-label d-flex align-items-center"
                                    htmlFor={`pType1${initialValues?.id}`}
                                    onClick={() =>
                                      handleChangeValue(
                                        Constants.PNF,
                                        'personType',
                                        setValues,
                                        values
                                      )
                                    }>
                                    <input
                                      className="form-check-input pe-1 me-2 mt-0"
                                      style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                      type="radio"
                                      checked={values.personType === Constants.PNF}
                                      onChange={() => {}}
                                    />
                                    Persona giuridica
                                  </label>
                                </div>
                                <div className="inputRadio" style={{ fontSize: '15px' }}>
                                  <label
                                    className="form-check-label d-flex align-items-center"
                                    htmlFor={`pType2${initialValues?.id}`}
                                    onClick={() =>
                                      handleChangeValue(
                                        Constants.PF,
                                        'personType',
                                        setValues,
                                        values
                                      )
                                    }>
                                    <input
                                      className="form-check-input pe-1 me-2 mt-0"
                                      style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                      type="radio"
                                      checked={values.personType === Constants.PF}
                                      onChange={() => {}}
                                    />
                                    Persona fisica
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* TIPO SOGGETTO */}
                          <div className="col-md-12 col-lg-12 mt-1 mb-3">
                            <Field name="personType">
                              {({ field }) => (
                                <BwmSelect
                                  {...field}
                                  options={props.subjectTypes || []}
                                  name="comureiSubjectType"
                                  label="Tipo Soggetto"
                                  className="form-control"
                                  onChange={(e) => handlerChangeSubjectType(e, setValues, values)}
                                  value={values.comureiSubjectType?.id}
                                  showCode={true}
                                  error={errors.comureiSubjectType}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>

                          {/* CODICE FISCALE */}
                          <div className="col-md-12 col-lg-3 mb-3 pe-2">
                            <Field name="fiscalCode">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="fiscalCode"
                                  label="Codice Fiscale"
                                  className="form-control"
                                  value={values.fiscalCode}
                                  onChange={(e) =>
                                    handleChangeTextField(
                                      e.target.value,
                                      'fiscalCode',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.fiscalCode || errors.errorFiscalCode}
                                  touched={touched}
                                  maxLength="16"
                                  onBlur={(e) => {
                                    handleFiscalCode(e.target.value, setValues, values)
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          {values.personType === Constants.PNF && (
                            <div className="col-12 col-lg-9">
                              {/* RAGIONE SOCIALE */}
                              <Field name="companyName">
                                {({ field }) => (
                                  <BwmInput
                                    {...field}
                                    name="companyName"
                                    label="Ragione Sociale"
                                    className="form-control font-weight-bold font-size-big"
                                    value={values.companyName}
                                    onChange={(e) =>
                                      handleChangeTextField(
                                        e.target.value,
                                        'companyName',
                                        setValues,
                                        values
                                      )
                                    }
                                    error={errors.companyName}
                                    touched={touched}
                                    maxLength="60"
                                  />
                                )}
                              </Field>
                            </div>
                          )}
                          {values.personType === Constants.PF && (
                            <>
                              {/* NOME */}
                              <div className="col-md-12 col-lg-3 pe-2">
                                <Field name="firstName">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      name="firstName"
                                      label="Nome"
                                      className="form-control font-weight-bold font-size-big"
                                      value={values.firstName}
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'firstName',
                                          setValues,
                                          values
                                        )
                                      }
                                      error={errors.firstName}
                                      touched={touched}
                                      maxLength="60"
                                    />
                                  )}
                                </Field>
                              </div>
                              {/* COGNOME */}
                              <div className="col-md-12 col-lg-3 pe-2">
                                <Field name="lastName">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      name="lastName"
                                      label="Cognome"
                                      className="form-control font-weight-bold font-size-big"
                                      value={values.lastName}
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'lastName',
                                          setValues,
                                          values
                                        )
                                      }
                                      error={errors.lastName}
                                      touched={touched}
                                      maxLength="60"
                                    />
                                  )}
                                </Field>
                              </div>

                              {/* SESSO */}
                              <div className="col-md-12 col-lg-1  hide-error-icon">
                                <Field name="gender">
                                  {({ field }) => (
                                    <BwmSelect
                                      {...field}
                                      options={Constants.genderList || []}
                                      name="gender"
                                      label="Sesso"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'gender',
                                          setValues,
                                          values
                                        )
                                      }
                                      value={values.gender}
                                      error={errors.gender}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </div>
                              {/* DATA DI NASCITA */}
                              <div className="col-md-12 col-lg-2 ps-2">
                                <Field name="birthDate">
                                  {({ field, form }) => (
                                    <DateField
                                      {...field}
                                      label="Data di nascita"
                                      date={values.birthDate}
                                      onChange={(value) => form.setFieldValue(field.name, value)}
                                      error={errors.birthDate}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </div>
                            </>
                          )}

                          <div className={`col-12 col-lg-4 pe-2`}>
                            {/* LOCALITÀ */}
                            <Field name="location">
                              {({ field }) => (
                                <AutoCompileCustom
                                  {...field}
                                  label={
                                    values.personType === Constants.PNF
                                      ? 'Sede Legale (Località)'
                                      : 'Comune di nascita'
                                  }
                                  id="location"
                                  handleInputAutocompile={(e) =>
                                    handleInputAutocompile(e, undefined, setValues, values)
                                  }
                                  filtro={props.locations}
                                  value={values.location}
                                  error={errors.location}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-md-3 col-lg-3">
                            {/* PROVINCIA */}
                            <Field name="province">
                              {({ field }) => (
                                <ProvinceField
                                  {...field}
                                  placeholder="Prov. EE per Estero"
                                  label="EE per Estero"
                                  province={values.province}
                                  onChange={(e) =>
                                    handleChangeTextField(
                                      e.target.value,
                                      'province',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.province}
                                  touched={touched}
                                  maxLength="2"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="row col-lg-4 col-lg-5" style={{ height: '180px' }}>
                        {/* Sezione Tipo Comunicazione (a sinistra) */}
                        <div className="col-md-6 pe-5">
                          {/* <h6>Dettaglio Comunicazione</h6> */}
                          <div className="form-check">
                            <label>Tipo Comunicazione</label>
                            <div className="input-group mt-2 d-flex flex-column">
                              <div className="col-12 p-0 inputRadio">
                                <Field type="radio" name="comureiCommunicationType.id" value="2">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`ct2${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`ct2${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiCommunicationType?.id === '2'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Cancellazione dal/dai Registro/i"
                                        />
                                        <span
                                          className={
                                            values.comureiCommunicationType?.id === '2'
                                              ? 'fw-bold'
                                              : ''
                                          }>
                                          Cancellazione dal/dai Registro/i
                                        </span>
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0 inputRadio">
                                <Field type="radio" name="comureiCommunicationType.id" value="1">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`ct1${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`ct1${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiCommunicationType?.id === '1'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Prima iscrizione"
                                        />
                                        <span
                                          className={
                                            values.comureiCommunicationType?.id === '1'
                                              ? 'fw-bold'
                                              : ''
                                          }>
                                          Prima iscrizione
                                        </span>
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0 inputRadio">
                                <Field type="radio" name="comureiCommunicationType.id" value="3">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`ct3${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`ct3${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiCommunicationType?.id === '3'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Variazione di dati già comunicati"
                                        />
                                        <span
                                          className={
                                            values.comureiCommunicationType?.id === '3'
                                              ? 'fw-bold'
                                              : ''
                                          }>
                                          Variazione di dati già comunicati
                                        </span>
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0 inputRadio">
                                <Field type="radio" name="comureiCommunicationType.id" value="4">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`ct4${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`ct4${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiCommunicationType?.id === '4'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Reiscrizione al/ai Registri"
                                        />
                                        <span
                                          className={
                                            values.comureiCommunicationType?.id === '4'
                                              ? 'fw-bold'
                                              : ''
                                          }>
                                          Reiscrizione al/ai Registri
                                        </span>
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Sezione Motivi della Comunicazione (a destra) */}
                        <div className="col-md-6">
                          <label
                            className="mb-3"
                            style={{ color: '#128186', fontWeight: 'bold', marginRight: '10px' }}>
                            Motivi della Comunicazione (sezioni REI)
                          </label>
                          <div className="form-check col-12 p-0">
                            <Field name="registryReason">
                              {({ field, form }) => (
                                <BwmCheckbox
                                  {...field}
                                  name={`registryReason_${initialValues?.id}`}
                                  label={
                                    <span
                                      className={field.value ? 'fw-bold' : ''}
                                      style={{ fontSize: '13px' }}>
                                      Indagini Finanziarie (Anagrafe Rapporti)
                                    </span>
                                  }
                                  className="form-control"
                                  checked={field.value}
                                  error={errors.registryReason}
                                  touched={touched}
                                  onChange={(e) => {
                                    handleChangeValue(
                                      e.target.checked,
                                      'registryReason',
                                      setValues,
                                      values
                                    )
                                    field.onChange(e)
                                  }}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="form-check col-12 p-0">
                            <Field name="monitoringReason">
                              {({ field }) => (
                                <BwmCheckbox
                                  {...field}
                                  name={`monitoringReason_${initialValues?.id}`}
                                  label={
                                    <span
                                      className={field.value ? 'fw-bold' : ''}
                                      style={{ fontSize: '13px' }}>
                                      Monitoraggio fiscale
                                    </span>
                                  }
                                  className="form-control"
                                  checked={field.value}
                                  error={errors.monitoringReason}
                                  touched={touched}
                                  onChange={(e) => {
                                    handleChangeValue(
                                      e.target.checked,
                                      'monitoringReason',
                                      setValues,
                                      values
                                    )
                                  }}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="form-check col-12 p-0">
                            {/* <label>Sezione FACTA/CRS</label> */}
                            <Field name="factaReason">
                              {({ field }) => (
                                <BwmCheckbox
                                  {...field}
                                  name={`factaReason_${initialValues?.id}`}
                                  label={
                                    <span
                                      className={field.value ? 'fw-bold' : ''}
                                      style={{ fontSize: '13px' }}>
                                      (FACTA/CRS)
                                    </span>
                                  }
                                  className="form-control"
                                  checked={field.value}
                                  error={errors.factaReason}
                                  touched={touched}
                                  onChange={(e) => {
                                    handleChangeValue(
                                      e.target.checked,
                                      'factaReason',
                                      setValues,
                                      values
                                    )
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </div>

                        <div className="form-check col-12 p-0 d-flex align-items-start">
                          <label
                            style={{
                              color: '#128186',
                              fontWeight: 'bold',
                              marginRight: '10px',
                              minWidth: '140px',
                              paddingBottom: '20px',
                              fontSize: '15px'
                            }}>
                            Struttura accentrata
                          </label>
                          <Field name="centralizedStructure">
                            {({ field }) => (
                              <div className="d-flex align-items-center">
                                <BwmCheckbox
                                  {...field}
                                  name={`centralizedStructure_${initialValues?.id}`}
                                  label="Sì"
                                  className="form-control"
                                  labelStyle={{ minWidth: '50px' }}
                                  checked={field.value}
                                  error={errors.centralizedStructure}
                                  touched={touched}
                                  onChange={(e) => {
                                    handleChangeValue(
                                      e.target.checked,
                                      'centralizedStructure',
                                      setValues,
                                      values
                                    )
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                          {values.centralizedStructure && (
                            <span className="text-danger ms-1 me-1 fw-bold">
                              per le strutture accentrate non sarà possibile caricare l'impegno alla
                              trasmissione di un intermediario
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-resp" className="d-none" label="Responsabile">
                    <div className="form-row">
                      <div className="col-md-12 col-lg-2 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.fiscalCode">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Codice Fiscale"
                              className="form-control"
                              value={values.comureiSubjectResponsible.fiscalCode}
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.fiscalCode',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              onBlur={(e) =>
                                handlerResponsibleFiscalCode(e.target.value, setValues, values)
                              }
                              touched={touched}
                              error={
                                errors.comureiSubjectResponsible?.fiscalCode ||
                                errors.errorComureiSubjectResponsibleFiscalCode
                              }
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-3 col-lg-2 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.lastName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Cognome"
                              className="form-control"
                              value={values.comureiSubjectResponsible.lastName}
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.lastName',
                                  setValues,
                                  values
                                )
                              }
                              error={errors.comureiSubjectResponsible?.lastName}
                              touched={errors.comureiSubjectResponsible?.lastName}
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-3 col-lg-2 mt-2 mb-3 pe-2">
                        <Field name="comureiSubjectResponsible.firstName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Nome"
                              className="form-control"
                              value={values.comureiSubjectResponsible.firstName}
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.firstName',
                                  setValues,
                                  values
                                )
                              }
                              error={errors.comureiSubjectResponsible?.firstName}
                              touched={errors.comureiSubjectResponsible?.firstName}
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-1 col-lg-1 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.gender">
                          {({ field }) => (
                            <BwmSelect
                              {...field}
                              options={Constants.genderList || []}
                              name="responsibleGender"
                              label="Sesso"
                              className="form-control"
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.gender',
                                  setValues,
                                  values
                                )
                              }
                              value={values.comureiSubjectResponsible?.gender}
                              error={errors.comureiSubjectResponsible?.gender}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="w-50 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.birthDate">
                          {({ field, form }) => (
                            <DateField
                              {...field}
                              label="Data di nascita"
                              date={errors.comureiSubjectResponsible?.birthDate}
                              onChange={(value) => form.setFieldValue(field.name, value)}
                              error={errors.comureiSubjectResponsible?.birthDate}
                              touched={errors.comureiSubjectResponsible?.birthDate}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-3 col-lg-2 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.location">
                          {({ field }) => (
                            <AutoCompileCustom
                              {...field}
                              label="Comune di nascita"
                              handleInputAutocompile={(location) =>
                                handleInputAutocompile(
                                  location,
                                  'comureiSubjectResponsible',
                                  setValues,
                                  values
                                )
                              }
                              filtro={props.locations}
                              value={values.comureiSubjectResponsible?.location}
                              error={errors.comureiSubjectResponsible?.location}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-2 col-lg-2 pe-2 mb-3 mt-2">
                        <Field name="comureiSubjectResponsible.province">
                          {({ field }) => (
                            <ProvinceField
                              {...field}
                              placeholder="Prov. EE per Estero"
                              province={values.comureiSubjectResponsible?.province}
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.province',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              error={errors.comureiSubjectResponsible?.province}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="form-row"></div>
                    <div className="form-row">
                      <div className="col-md-6 col-lg-4 me-2 mb-3">
                        <Field name="comureiSubjectResponsible.email">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Indirizzo Email (indirizzo personale del responsabile)"
                              className="form-control"
                              value={values.comureiSubjectResponsible?.email}
                              onChange={(e) =>
                                handleChangeTextField(
                                  e.target.value,
                                  'comureiSubjectResponsible.email',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              error={errors.comureiSubjectResponsible?.email}
                              touched={touched}
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-2 col-lg-2 mb-3">
                        <Field name="comureiSubjectResponsible.phone">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Telefono"
                              className="form-control"
                              value={values.comureiSubjectResponsible.phone}
                              onChange={(e) =>
                                handlerChangeResponsiblePhoneNumber(e, setValues, values)
                              }
                              error={errors.comureiSubjectResponsible?.phone}
                              touched={touched}
                              maxLength="10"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div id="tab-pec" className="d-none" label="Posta Elettronica">
                    <div className="form-row">
                      <div className="col-md-7 col-lg-7">
                        <div className="form-row mb-3">
                          <div
                            className={`col-md-12 col-lg-10 ${!(values.comureiCommunicationType?.id?.toString() === '1' || values.comureiCommunicationType?.id?.toString() === '4') ? 'hidden' : ''}`}>
                            <Field name="comureiSubjectPec.pecEmail">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label={`${values.comureiCommunicationType?.id === '4' ? 'INDIRIZZO PEC DA REISCRIVERE AL REI' : 'INDIRIZZO PEC DA SCRIVERE AL REI'} `}
                                  className="form-control"
                                  value={values.comureiSubjectPec.pecEmail}
                                  onChange={(e) =>
                                    handleChangeTextField(
                                      e.target.value,
                                      'comureiSubjectPec.pecEmail',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.comureiSubjectPec?.pecEmail}
                                  touched={touched}
                                  maxLength="100"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        {values.comureiCommunicationType?.id?.toString() !== '2' && (
                          <div className="form-row mb-3">
                            <div
                              className={`col-md-12 col-lg-4 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                              <Field name="comureiSubjectPec.pecManager">
                                {({ field }) => (
                                  <BwmInput
                                    {...field}
                                    label="Gestore PEC"
                                    className="form-control"
                                    value={values.comureiSubjectPec?.pecManager}
                                    onChange={(e) =>
                                      handleChangeTextField(
                                        e.target.value,
                                        'comureiSubjectPec.pecManager',
                                        setValues,
                                        values
                                      )
                                    }
                                    error={errors.comureiSubjectPec?.pecManager}
                                    touched={touched}
                                    maxLength="100"
                                  />
                                )}
                              </Field>
                            </div>
                            {values.comureiCommunicationType?.id?.toString() !== '2' && (
                              <>
                                <div
                                  className={`col-md-5 col-lg-3 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                                  <Field name="comureiSubjectPec.effectiveDate">
                                    {({ field, form }) => (
                                      <DateField
                                        {...field}
                                        label={'Data DECORRENZA'}
                                        date={values.comureiSubjectPec?.effectiveDate}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        error={errors.comureiSubjectPec?.effectiveDate}
                                        touched={touched}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div
                                  className={`col-md-5 col-lg-3 me-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                                  <Field name="comureiSubjectPec.endDate">
                                    {({ field, form }) => (
                                      <DateField
                                        {...field}
                                        label={'Data CESSAZIONE'}
                                        date={values.comureiSubjectPec?.endDate}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        error={errors.comureiSubjectPec?.endDate}
                                        touched={touched}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={`form-row ${values.comureiCommunicationType?.id?.toString() === '2' || values.comureiCommunicationType?.id?.toString() === '3' ? '' : 'hidden'}`}>
                          <div
                            className={
                              values.comureiCommunicationType?.id?.toString() === '2'
                                ? 'col-10 pe-2'
                                : 'col-5 pe-2'
                            }>
                            <Field name="comureiSubjectPec.reiEmail">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label={` ${values.comureiCommunicationType?.id?.toString() === '2' ? 'Indirizzo di posta elettronica già comunicata al REI DA CANCELLARE' : 'Indirizzo PEC già comunicato DA VARIARE'}`}
                                  className="form-control"
                                  value={values.comureiSubjectPec?.reiEmail}
                                  onChange={(e) =>
                                    handleChangeTextField(
                                      e.target.value,
                                      'comureiSubjectPec.reiEmail',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.comureiSubjectPec?.reiEmail}
                                  touched={touched}
                                  maxLength="255"
                                />
                              )}
                            </Field>
                          </div>
                          {values.comureiCommunicationType?.id?.toString() === '3' && (
                            <div className={'col-5'}>
                              <Field name="comureiSubjectPec.pecEmailAddress">
                                {({ field }) => (
                                  <BwmInput
                                    {...field}
                                    label="Indirizzo PEC da variare"
                                    className="form-control"
                                    value={values.comureiSubjectPec?.pecEmailAddress}
                                    onChange={(e) =>
                                      handleChangeTextField(
                                        e.target.value,
                                        'comureiSubjectPec.pecEmailAddress',
                                        setValues,
                                        values,
                                        true
                                      )
                                    }
                                    error={errors.comureiSubjectPec?.pecEmailAddress}
                                    touched={touched}
                                    maxLength="255"
                                  />
                                )}
                              </Field>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-5 col-lg-5  infoPostaEle mb-3">
                        <div className="infoPoint-title ms-0 mb-3">
                          <IInfo fill="#b8b8b8" width="20" /> Informazioni
                        </div>
                        <div className="infoPoint">
                          <strong className="pe-1">Indirizzo EMAIL PEC:</strong> lo stesso da
                          indicare nella RICHIESTA DI ACCREDITAMENTO al SID
                        </div>
                        <div className="infoPoint">
                          <strong className="pe-1">Gestore PEC:</strong> Il gestore del servizio PEC
                          (Infocamere, Aruba, PEC.it etc.)
                        </div>
                        <div className="infoPoint">
                          <strong className="pe-1">Data DECORRENZA:</strong> è l'inizio del
                          contratto con il gestore del servizio PEC
                        </div>
                        <div className="infoPoint">
                          <strong className="pe-1">Data CESSAZIONE:</strong> non obbligatoria. Se
                          indicata in caso di rinnovo <strong>NON</strong> deve essere fatta una
                          nuova comunicazione
                        </div>
                        <div className="infoPoint">
                          <strong className="pe-1">La Data VARIAZIONE</strong> non è più prevista
                          quindi <strong>NON</strong> va indicata
                        </div>
                      </div>
                    </div>
                  </div>
                  {values.comureiCommunicationType?.id?.toString() === '2' ? (
                    <div id="tab-delete" className="d-none" label="Cancellazione">
                      <Row className="align-items-center">
                        <div className="col-2 mb-3">
                          <Field name="comureiSubjectCancellation.cancellationDate">
                            {({ field, form }) => (
                              <DateField
                                {...field}
                                label={'Data CANCELLAZIONE'}
                                date={values.comureiSubjectCancellation.cancellationDate}
                                onChange={(value) => form.setFieldValue(field.name, value)}
                                error={errors.comureiSubjectCancellation?.cancellationDate}
                                touched={touched}
                              />
                            )}
                          </Field>
                        </div>
                      </Row>
                      <div className="form-row mt-3">
                        <div className="col-lg-5 p-0">
                          <div className="form-check">
                            <label>Motivo della CANCELLAZIONE</label>
                            <div className="input-group mt-2">
                              <div className="col-12 p-0 inputRadio mb-3">
                                <Field
                                  type="radio"
                                  name="comureiSubjectCancellation.comureiSubjectCancellationType.id"
                                  value="1">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`cr1${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`cr1${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiSubjectCancellation
                                              .comureiSubjectCancellationType?.id === '1'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Cessazione  con confluenza dell'attività finanziaria in un solo operatore finanziario"
                                        />{' '}
                                        Cessazione con confluenza dell'attività finanziaria in un
                                        solo operatore finanziario
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0 inputRadio mb-3">
                                <Field
                                  type="radio"
                                  name="comureiSubjectCancellation.comureiSubjectCancellationType.id"
                                  value="2">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`cr2${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`cr2${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiSubjectCancellation
                                              .comureiSubjectCancellationType?.id === '2'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt=""
                                        />{' '}
                                        Perdita dei requisiti soggettivi
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0  inputRadio mb-3">
                                <Field
                                  type="radio"
                                  name="comureiSubjectCancellation.comureiSubjectCancellationType.id"
                                  value="3">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`cr3${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`cr3${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiSubjectCancellation
                                              .comureiSubjectCancellationType?.id === '3'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Cessazione senza confluenza in altro soggetto"
                                        />{' '}
                                        Cessazione senza confluenza in altro soggetto
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0  inputRadio mb-3">
                                <Field
                                  type="radio"
                                  name="comureiSubjectCancellation.comureiSubjectCancellationType.id"
                                  value="4">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`cr4${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`cr4${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiSubjectCancellation
                                              .comureiSubjectCancellationType?.id === '4'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Cessazione con confluenza in altro soggetto non operatore finanziario"
                                        />{' '}
                                        Cessazione con confluenza in altro soggetto non operatore
                                        finanziario
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-12 p-0  inputRadio mb-3">
                                <Field
                                  type="radio"
                                  name="comureiSubjectCancellation.comureiSubjectCancellationType.id"
                                  value="5">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`cr5${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`cr5${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiSubjectCancellation
                                              .comureiSubjectCancellationType?.id === '5'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Cessazione  con confluenza dell'attività finanziaria in più operatori finanziari"
                                        />{' '}
                                        Cessazione con confluenza dell'attività finanziaria in più
                                        operatori finanziari
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 p-0">
                          <h6 className="mb-3">
                            <strong>Disponibilità Contabilità Ordinaria</strong>
                          </h6>
                          {(values.comureiSubjectCancellation?.comureiSubjectCancellationType
                            ?.id === '2' ||
                            values.comureiSubjectCancellation?.comureiSubjectCancellationType
                              ?.id === '3' ||
                            values.comureiSubjectCancellation?.comureiSubjectCancellationType
                              ?.id === '4') && (
                            <div className="row">
                              <div className="col pe-2">
                                <Field name="comureiSubjectCancellation.address">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      label="Indirizzo"
                                      className="form-control"
                                      value={values.comureiSubjectCancellation?.address}
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.address',
                                          setValues,
                                          values
                                        )
                                      }
                                      error={errors.comureiSubjectCancellation?.address}
                                      touched={touched}
                                      maxLength="100"
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className="col pe-2">
                                <Field name="comureiSubjectCancellation.location">
                                  {({ field }) => (
                                    <AutoCompileCustom
                                      {...field}
                                      label="Comune Sede Legale"
                                      handleInputAutocompile={(e) =>
                                        handleInputAutocompile(
                                          e,
                                          'comureiSubjectCancellation',
                                          setValues,
                                          values
                                        )
                                      }
                                      filtro={props.locations}
                                      value={values.comureiSubjectCancellation?.location}
                                      error={errors.comureiSubjectCancellation?.location}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className="col-md-2 col-lg-2 pe-2">
                                <Field name="comureiSubjectCancellation.location">
                                  {({ field }) => (
                                    <ProvinceField
                                      {...field}
                                      province={values.comureiSubjectCancellation?.province}
                                      placeholder="Prov. EE per Estero"
                                      label="EE per Estero"
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.province',
                                          setValues,
                                          values,
                                          true
                                        )
                                      }
                                      error={errors.comureiSubjectCancellation?.province}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          )}
                          {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                            '1' && (
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <Field name="comureiSubjectCancellation.location">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      label="Codice Fiscale operatore incorporante 1"
                                      className="form-control"
                                      value={
                                        values.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeOne
                                      }
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                          setValues,
                                          values,
                                          true
                                        )
                                      }
                                      error={
                                        errors.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeOne
                                      }
                                      touched={touched}
                                      maxLength="100"
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          )}
                          {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                            '5' && (
                            <div className="row">
                              <div className="col pe-2">
                                <Field name="comureiSubjectCancellation.incorporatingFiscalCodeOne">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      label="Codice Fiscale operatore incorporante 1"
                                      className="form-control"
                                      value={
                                        values.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeOne
                                      }
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                          setValues,
                                          values,
                                          true
                                        )
                                      }
                                      error={
                                        errors.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeOne
                                      }
                                      touched={touched}
                                      maxLength="100"
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className="col pe-2">
                                <Field name="comureiSubjectCancellation.incorporatingFiscalCodeTwo">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      name="incorporatingFiscalCodeTwo"
                                      label="Codice Fiscale operatore incorporante 2"
                                      className="form-control"
                                      value={
                                        values.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeTwo
                                      }
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
                                          setValues,
                                          values,
                                          true
                                        )
                                      }
                                      error={
                                        errors.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeTwo
                                      }
                                      touched={touched}
                                      maxLength="100"
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className="col">
                                <Field name="comureiSubjectCancellation.incorporatingFiscalCodeThree">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      name="incorporatingFiscalCodeThree"
                                      label="Codice Fiscale operatore incorporante 3"
                                      className="form-control"
                                      value={
                                        values.comureiSubjectCancellation
                                          .incorporatingFiscalCodeThree
                                      }
                                      onChange={(e) =>
                                        handleChangeTextField(
                                          e.target.value,
                                          'comureiSubjectCancellation.incorporatingFiscalCodeThree',
                                          setValues,
                                          values,
                                          true
                                        )
                                      }
                                      error={
                                        errors.comureiSubjectCancellation
                                          ?.incorporatingFiscalCodeThree
                                      }
                                      touched={touched}
                                      maxLength="100"
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div id="tab-delete" className="d-none" label="Cancellazione" disabled></div>
                  )}
                  {values.centralizedStructure ? (
                    <div
                      id="tab-transmission"
                      className="d-none"
                      label="Impegno alla trasmissione"></div>
                  ) : (
                    <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione">
                      <div className="form-row mb-3">
                        <div className="col-md-4 col-lg-4">
                          <Field name="comureiTransmission.fiscalCode">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Codice Fiscale"
                                className="form-control"
                                value={values.comureiTransmission?.fiscalCode}
                                onChange={(e) =>
                                  handleChangeTextField(
                                    e.target.value,
                                    'comureiTransmission.fiscalCode',
                                    setValues,
                                    values,
                                    true
                                  )
                                }
                                error={errors.comureiTransmission?.fiscalCode}
                                touched={touched}
                                maxLength="100"
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-md-7 col-lg-7 commDetailComurei offset-1">
                          <div className="form-check">
                            <label>Impegno alla trasmissione telematica</label>
                            <div className="input-group mt-2">
                              <div className="inputRadio me-5">
                                <Field
                                  type="radio"
                                  name="comureiTransmission.comureiTransmissionType.id"
                                  value="1">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`tt1${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`tt1${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiTransmission?.comureiTransmissionType
                                              ?.id === '1'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Da CONTRIBUENTE"
                                        />
                                        Da CONTRIBUENTE
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="inputRadio">
                                <Field
                                  type="radio"
                                  name="comureiTransmission.comureiTransmissionType.id"
                                  value="2">
                                  {({ field }) => (
                                    <>
                                      <input
                                        hidden={true}
                                        className="form-check-input"
                                        type="radio"
                                        {...field}
                                        id={`tt2${initialValues?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`tt2${initialValues?.id}`}>
                                        <img
                                          width="20"
                                          className="me-2"
                                          src={
                                            values.comureiTransmission?.comureiTransmissionType
                                              ?.id === '2'
                                              ? radioSel
                                              : radioUnsel
                                          }
                                          alt="Da chi effettua l'INVIO"
                                        />
                                        Da chi effettua l'INVIO
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-9 col-lg-9 me-4 mt-3">
                          <div className="infoPostaEle panelTrasm">
                            <div className="infoPoint-title ms-0 mb-3">
                              <IInfo fill="#b8b8b8" width="20" /> Informazioni
                            </div>
                            <div className="infoPoint">
                              Compilare solo nel caso di comunicazione spedita tramite Entratel di
                              un intermediario (commercialista) diverso dal soggetto obbligato.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Tabs>
              </div>
            </div>
            <div className="form-row pt-3 row align-item-center">
              <div
                className={
                  props.paginationComponent
                    ? 'col-8 d-flex justify-content-start'
                    : 'd-flex justify-content-between'
                }>
                {/* Bottone Annulla */}
                <button
                  className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
                  onClick={props.closeModal}>
                  <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
                  &nbsp; ANNULLA
                </button>

                {/* Bottone Crea Comunicazione */}
                <div className="d-flex">
                  <button
                    className="btn btn-outline-primary btn-empty px-5 ml-2 me-4"
                    onClick={() => handleFormSubmit(values, validateForm, true)}>
                    <ITextFile
                      className={'padding-right-4px svg'}
                      width="20"
                      height="18"
                      fill={'#128186'}
                    />
                    &nbsp; CREA COMUNICAZIONE
                  </button>

                  {/* Bottone Conferma */}
                  <button
                    type="submit"
                    className="btn btn-primary btn-new-rel px-5 ml-2"
                    onClick={() => handleFormSubmit(values, validateForm, false)}>
                    <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
                    &nbsp; CONFERMA
                  </button>
                </div>
              </div>

              {/* Vista sulla destra */}
              {props.paginationComponent && (
                <div className="col-4 d-flex justify-content-end ">
                  {windowWidth >= 1780 && props.paginationComponent}
                </div>
              )}
            </div>
            {props.paginationComponent && windowWidth < 1780 && (
              <div className="pb-3">{props.paginationComponent}</div>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default reduxForm({
  form: 'ComureiForm'
})(ComureiForm)
