import { useState } from 'react'
import * as actions from '../../../../actions'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import ShowManyMofalTogheter from './showManyMofalTogheter'
import GenerateNameFileWhenIsend from './GenerateNameFileWhenIsend'
import { handleUploadReceiptCrsCora } from '../../../shared/UploadReceipt'

export const UploadReceiptModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [receiptFile, setReceiptFile] = useState([])
  const [errorReceipt, setErrorReceipt] = useState({ isValid: true, msg: '' })
  const [loading, setLoading] = useState(false)
  const [communication, setCommunication] = useState([])

  const closeModal = () => {
    setShowModal(false)
    setReceiptFile([])
  }

  const openModal = () => {
    setShowModal(true)
  }

  const setCertificateFile = () => {}

  /**
   * Funzione che cambia lo state e che mi da la possibilita di cambiare lo stato anche da altre funzioni
   * @param {*} val valore dell'oggetto che dovra' essere cambiato
   */
  const setReceiptFileData = ({ receiptFile, errorReceipt }) => {
    setReceiptFile(receiptFile)
    setErrorReceipt(errorReceipt)
  }

  /**
   * controlla estensione e nome file
   * @param {*} files
   * @returns
   */
  const checkFileName = (files) => {
    for (let x = 0; x < files.length; x++) {
      let file = files[x]
      let name = file.name
      let checkIfNameIsCorrect =
        name.slice(0, 7) === 'ATPECRQ' &&
        (name.split('.').pop().toLowerCase() === 'p7m' ||
          name.split('.').pop().toLowerCase() === 'run')
      if (!checkIfNameIsCorrect) {
        return false
      }
      return true
    }
  }

  const handleSendReceipt = () => {
    setShowModal(false)
    setLoading(true)
    let length_file = receiptFile.length
    const formData = new FormData()
    for (var x = 0; x < length_file; x++) {
      let file = receiptFile[x]
      formData.append('receiptFiles', file)
    }
    if (communication.length !== 0) {
      //NOTE: questo id serve a controllare che la lista delle ricevute sia vuota in partenza quando faccio la chiamta, cosi si se si rifa la richiesta e ci sono ricevute nuove si riparte da zero
      setCommunication([])
    }
    actions
      .createCrsReceipt(formData)
      .then((communication) => {
        setLoading(false)
        setCommunication(communication)
        setReceiptFile([])
      })
      .catch(() => {
        setLoading(false)
        setReceiptFile([])
        PopupError({ text: 'Errore durante la verifica della ricevuta' })
      })
  }

  // TODO: Not used
  // const writeClearFile = (clearTxt) => {
  //   let lines = clearTxt.split('\r\n')
  //   return lines.map((line, index) => {
  //     return (
  //       <Row key={index} className="alert-header">
  //         {line}
  //       </Row>
  //     )
  //   })
  // }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        className="btn btn-primary  btn-new-rel btn-sm"
        data-tip={'Caricare file RICEVUTA DI TRASMISSIONE (ATPECRQ) o ESITI (ATPECXX)'}
        onClick={openModal}
        disabled={props.disabled}>
        <ITextFile className={'padding-right-4px svg'} width="16" fill={` #FFFFFF`} />
        &nbsp; ELABORA RICEVUTE
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>ELABORA RICEVUTE</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="image-upload text-start">
              <label htmlFor={`receiptFile`}>
                <div className="certificateFiles">
                  CARICA FILE RICEVUTO
                  <IUpload width="25" fill="#000" />
                </div>
              </label>
              <input
                id={`receiptFile`}
                type="file"
                name={`receiptFile`}
                multiple
                ref={(ref) => setCertificateFile(ref)}
                onChange={(e) => {
                  const data = handleUploadReceiptCrsCora(e, checkFileName)
                  setReceiptFileData(data)
                }}
              />
              <div className="certificatiLoaded text-start">
                {!errorReceipt.isValid ? (
                  <div className="certificatiLoaded-error">{errorReceipt.msg}</div>
                ) : (
                  <GenerateNameFileWhenIsend files={receiptFile} />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorReceipt.isValid || !receiptFile}
              onClick={handleSendReceipt}
              type="button"
              className="btn btn-primary  btn-new-rel px-5">
              CONFERMA
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ShowManyMofalTogheter communication={communication} />
    </>
  )
}
