import React from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import Modal from 'react-bootstrap/Modal'
import { ArcoRegistryPrintForm } from './ArcoRegistryPrintForm'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import { formatDateForDisplay } from '../../../shared/Utility'

registerLocale('it', it)

export function ArcoRegistryPrintModal(props) {
  const arcoSubject = useSelector((state) => state.arcoSubject.data)

  const getSubjectName = () => {
    return arcoSubject?.companyName
  }

  const getSurnameOrDenomination = (item) => {
    if (item?.personType) {
      return (
        Constants.PF === item.personType ? item.lastName + ' ' + item.firstName : item.denomination
      ).toUpperCase()
    }
  }

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>
              <strong>{'< ' + getSubjectName()}</strong>
            </h3>
            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;<span>{'Operazione: '}</span>
              <strong>{props?.currentOperation?.description}</strong>{' '}
              {'DEL ' + formatDateForDisplay(props?.currentOperation?.createDate)}
            </h3>
            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;<span>{'Soggetto: '}</span>
              <strong>{getSurnameOrDenomination(props.selectedPrintItem)}</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ArcoRegistryPrintForm
              onHide={props.onHide}
              key={'printModal-' + props.selectedPrintItem?.id}
              arcoRegistriesOperations={props?.currentOperation}
              selectedPrintItem={props.selectedPrintItem}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
