import { useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import { ModalRelationship } from './ModalRelationship'
import { ReactComponent as IPlus } from '../../../../../styles/images/svgs/regular/plus.svg'

registerLocale('it', it)

const ButtonRelationshipModal = (props) => {
  const [modalShow, setModalShow] = useState(false)

  const refreshList = () => {
    setModalShow(false)
  }

  const openModal = (val) => {
    ;[].forEach.call(document.querySelectorAll('.row-table'), function (el) {
      if (el.classList[2] === 'bg-blue') el.click()
    })
    setModalShow(val)
  }
  return (
    <>
      <button type="button" className="btn btn-new-rel px-5 btn-sm" onClick={() => openModal(true)}>
        <IPlus className={'padding-right-4px'} width="14" fill={`#128186`} />
        &nbsp; NUOVO RAPPORTO
      </button>
      <ModalRelationship
        show={modalShow}
        onHide={refreshList}
        subject={props.subject}
        subjectId={props.subjectId}
        relationshipLocations={props.relationshipLocations}
        relationshipProvinces={props.relationshipProvinces}
        currencies={props.currencies}
        roles={props.roles}
        communicationTypes={props.communicationTypes}
        onClone={props.onClone}
        getRelationships={props.getRelationships}
      />
    </>
  )
}

export default ButtonRelationshipModal
