import { ReactComponent as IInfo } from '../../styles/images/svg/info-circle.svg'

/**
 *
 * @param {*} simplePointsArr array composto da oggetti di tipo {title:"",text:""}
 * @returns
 */
export function InfoBox({ simplePointsArr = [], additionalClasses }) {
  const renderSimpleInfoPoints = (arr) => {
    if (arr) {
      return arr.map((el, index) => {
        return (
          <div key={'infobox-' + index} className="infoPoint">
            <strong>{el.title ? `${el.title}: ` : ``}</strong>
            {`  ${el.text}`}
          </div>
        )
      })
    }
  }

  return (
    <div className={`infoPostaEle ${additionalClasses ? additionalClasses : ''}`}>
      <div className={`infoPoint-title`}>
        <IInfo fill="#808080" width="20" /> Informazioni
      </div>
      {renderSimpleInfoPoints(simplePointsArr)}
    </div>
  )
}
