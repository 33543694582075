import { Component } from 'react'
import PropTypes from 'prop-types'
import Tab from './Tab'

export class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTab: this.props.children[0].props.label
    }
  }

  isPromise = (obj) => {
    return obj && typeof obj === 'object' && typeof obj.then === 'function'
  }

  //TODO: evitare utilizzo di funzioni specifiche
  onClickTabItem = (tab, idTab) => {
    if (this.props.children[1].props.handleFormSubmitHolder !== undefined) {
      this.props.children[1].props.handleFormSubmitHolder(tab)
    } else if (this.props.children[1].props.handleCreateRelationship !== undefined) {
      if (this.props.children[1].props.handleCreateRelationship) {
        const result = this.props.children[1].props.handleCreateRelationship()
        if (this.isPromise(result)) {
          result.then((res) => {
            if (res) this.setState({ activeTab: tab })
          })
        } else {
          this.setState({ activeTab: tab })
        }
      }
    } else {
      this.setState({ activeTab: tab })
    }
    if (this.props.setTab) {
      this.props.setTab(idTab)
    }
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this
    return (
      <div className="tabs">
        <ul className="tab-list nav nav-tabs">
          {children.map((child, index) => {
            const { label, icon, id, disabled } = child.props

            return (
              <Tab
                {...this.props}
                activeTab={activeTab}
                key={label}
                last={index === children.length - 1}
                label={label}
                onClick={onClickTabItem}
                icon={icon}
                idTab={id}
                disabled={disabled}
              />
            )
          })}
        </ul>
        <div className={`tab-content ${this.props.className || 'pt-4'}`}>
          {/* className="tab-content tab-rel mt-2 */}
          {children.map((child, index) => {
            if (child.props.id === 'tab-rel-crs') {
              return (
                <div key={index} className={child.props.label !== activeTab ? 'hide' : 'show'}>
                  {child.props.children}
                </div>
              )
            } else {
              if (child.props.label !== activeTab) return undefined
              return child.props.children
            }
          })}
        </div>
      </div>
    )
  }
}

export default Tabs
