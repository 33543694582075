import { TableUtils, fieldTypes } from './TableUtils'
import NumberFormat from 'react-number-format'
import { Cell } from '@table-library/react-table-library'
import { CURRENCYFORMAT } from '../../../config/Constants'
import { Form } from 'react-bootstrap'
import { format, isValid } from 'date-fns'
import { formatDateForDisplay, isValidDate } from '../Utility'

function TableInputField({
  key,
  type = fieldTypes.TEXT,
  value = '',
  onChange = (value) => {},
  options = [{ id: null, description: '' }]
} = {}) {
  if ([fieldTypes.TEXT].includes(type)) {
    return (
      <input
        key={key}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className={'cell-input'}
      />
    )
  } else if (fieldTypes.DATE === type) {
    return (
      <input
        key={key}
        type={fieldTypes.DATE}
        onChange={(e) =>
          onChange(isValidDate(new Date(e.target.value)) ? new Date(e.target.value) : null)
        }
        value={
          value !== null && isValid(new Date(value)) ? format(new Date(value), 'yyyy-MM-dd') : null
        }
        className={'cell-input'}
        max={'2500-01-01'}
      />
    )
  } else if ([fieldTypes.NUMBER, fieldTypes.CURRENCY].includes(type)) {
    return (
      <NumberFormat
        key={key}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={0}
        fixedDecimalScale={true}
        className={'cell-input'}
        inputMode="numeric"
        value={value}
        onValueChange={(value) => onChange(value.floatValue)}
      />
    )
  } else if (fieldTypes.SELECTION === type) {
    return (
      <Form.Control
        key={key}
        onChange={(e) => onChange(e.target.value)}
        as="select"
        value={value}
        className={'cell-input'}>
        <option value={null}></option>
        {options.map((o, index) => {
          return (
            <option key={o.description + '-' + index} value={o.id}>{`${o.description}`}</option>
          )
        })}
      </Form.Control>
    )
  }

  return <>{value}</>
}

function formatCellValue({ type, fieldValue } = {}) {
  if ([fieldTypes.NUMBER, fieldTypes.CURRENCY].includes(type)) {
    return fieldValue ? CURRENCYFORMAT.format(fieldValue) : ''
  }
  if (fieldTypes.DATE === type) {
    return formatDateForDisplay(fieldValue)
  } else {
    return fieldValue || ''
  }
}

function TableCell({ cellData, key, hide, type, header, pinRight, pinLeft } = {}) {
  const textValue = formatCellValue({ type: type, fieldValue: cellData?.fieldValue })
  const component = cellData?.component()
  if (hide) {
    return <Cell hide={true} />
  } else if (component && textValue) {
    return (
      <Cell
        key={key}
        title={textValue}
        hide={hide}
        className={`table-row ${cellData.additionalClass}`}
        pinRight={pinRight}
        pinLeft={pinLeft}>
        {component}
      </Cell>
    )
  } else if (component) {
    return (
      <Cell
        key={key}
        title={textValue}
        hide={hide}
        className={`table-row ${cellData.additionalClass}`}
        pinRight={pinRight}
        pinLeft={pinLeft}>
        {component}
      </Cell>
    )
  } else if (textValue) {
    return (
      <Cell
        key={key}
        title={textValue}
        hide={hide}
        className={`${[fieldTypes.NUMBER, fieldTypes.CURRENCY].includes(type) && 'text-end'} 
                                ${type === fieldTypes.DATE && 'text-center'} 
                                table-row 
                                ${cellData.additionalClass}`}
        onClick={cellData.onClick}
        pinRight={pinRight}
        pinLeft={pinLeft}>
        {textValue}
      </Cell>
    )
  } else {
    return (
      <Cell
        key={key}
        title={textValue}
        hide={hide}
        className={`table-row ${cellData.additionalClass}`}
        onClick={cellData.onClick}
        pinRight={pinRight}
        pinLeft={pinLeft}
      />
    )
  }
}

export function StarCell({ rowId, data, index, headerColums, hiddenColumns, columnIndex }) {
  if ('id' !== data[0]) {
    const cellData = data[1]
    if (cellData) {
      const key = `cell-${rowId}-${index}`
      const header = headerColums.find((h) => h.fieldName === cellData.fieldName)
      const type = header.type
      const pinRight = header.pinRight
      const pinLeft = header.pinLeft
      const hide = TableUtils.isHiddenColumn(hiddenColumns, header)

      if (cellData?.editable) {
        return (
          <Cell
            key={key}
            hide={hide}
            className={`table-row ${cellData.additionalClass}`}
            onClick={cellData.onClick}
            pinRight={pinRight}
            pinLeft={pinLeft}>
            {TableInputField({
              key: key + '-input-field',
              value: cellData.fieldValue,
              type: type,
              onChange: (inputValue) => cellData.onEdit(rowId, cellData.fieldName, inputValue),
              options: header.options
            })}
          </Cell>
        )
      } else {
        return TableCell({
          cellData,
          key,
          hide: hide,
          type: type,
          header,
          pinRight,
          pinLeft
        })
      }
    }
  }
  return null
}
