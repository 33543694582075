import { COMUREI_COMMUNICATIONS_HISTORY_SUCCESS } from '../../actions/types'

const INITIAL_STATE = {
  comureiCommunications: {
    data: [],
    errors: []
  }
}

export const comureiCommunicationsReducer = (
  state = INITIAL_STATE.comureiCommunications,
  action
) => {
  switch (action.type) {
    case COMUREI_COMMUNICATIONS_HISTORY_SUCCESS:
      return { ...state, data: action.comureiCommunications }
    default:
      return state
  }
}
