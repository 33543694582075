import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IPlus } from '../../../styles/images/svgs/regular/plus.svg'
import BlueSubjectForm from './NewBlueSubjectForm'
import getText from './labels'
import { useSelector } from 'react-redux'

export default function BlueSubjectModal(props) {
  const { openModal, showModal, closeModal, licenseExpired } = props
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-new-rel"
        onClick={openModal}
        disabled={licenseExpired}>
        <IPlus className={'padding-right-4px'} width="14" fill={` ${'#128186'}`} />
        &nbsp; {labels.SUBJECTNEW}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {props.id
                ? `Modifica Soggetto ${props.subject.denomination}`
                : labels.SUBJECTNEW.toLowerCase()
                    .split(' ')
                    .map((word) => {
                      return word[0].toUpperCase() + word.substring(1)
                    })
                    .join(' ')}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueSubjectForm key="newBlueSubjectForm" {...props} handleCloseModal={closeModal} />
        </Modal.Body>
      </Modal>
    </>
  )
}
