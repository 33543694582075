import * as actions from '../../../../actions'
import { useState } from 'react'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'
import { downloadFile } from '../../../shared/Utility'
import { downloadRelationshipsCsvTemplate } from '../relationships/utils/CoraTemplateUtils'
import { ImportModal } from '../../../shared/popups/ImportModal'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { Col, Row } from 'react-bootstrap'

export function YearlyBalanceImportModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataFile, setDataFile] = useState([])
  const [charSeparator, setCharSeparator] = useState(';')

  const closeModal = () => {
    setShowModal(false)
    setDataFile('')
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handleSendDataFile = (dataFiles) => {
    let formData = new FormData()
    formData.append('subjectId', props.subjectId)
    formData.append('separator', charSeparator)
    if (dataFiles?.length > 0) {
      for (let i = 0; i < dataFiles.length; i++) {
        const file = dataFiles[i]
        formData.append('file', file)
      }
    } else {
      return
    }
    setLoading(true)
    setShowModal(false)
    actions
      .importCoraYearlyBalances(formData)
      .then(
        (res) => {
          PopupSuccess({ dataList: [getImportReport(res)] })
          props.getYearlyBalance()
        },
        (errors) => {
          if (errors?.title) {
            PopupError({ text: errors.title })
          } else {
            PopupError({ text: "Errore durante l'importazione dei Saldi Annuali." })
          }
        }
      )
      .then(() => {
        setDataFile('')
        setLoading(false)
      })
  }

  const getImportReport = (importResultDTO) => {
    if (importResultDTO.lineMessages.length > 0) {
      //compongo un file di testo con i messaggi
      const file = importResultDTO.lineMessages
        .map((el) => {
          return `Riga ${el.line}: ${el.message}`
        })
        .join('\r\n')

      return (
        <>
          <div className="text-center">File elaborato CORRETTAMENTE</div>
          <div className="text-center">Righe elaborate: {importResultDTO.elaborated}</div>
          <div className="text-center">Righe NON elaborate: {importResultDTO.rejected}</div>
          <div className={'mt-4 text-center'}>
            Sono presenti errori nel file. Consulta il file LOG
          </div>
          <div className="text-center">
            <button
              className={'btn btn-primary btn-new-rel mt-3 text-center'}
              onClick={() => {
                downloadFile(file, 'risultati_import_saldi.log')
              }}>
              Controlla il file LOG
            </button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="text-center">File elaborato CORRETTAMENTE</div>
          <div className="text-center">Righe elaborate: {importResultDTO.elaborated}</div>
          <div className="text-center">Righe NON elaborate: {importResultDTO.rejected}</div>
        </>
      )
    }
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        data-tip={'Importa i saldi annuali nella tua lista'}
        className={`btn btn-outline-primary btn-empty px-4 btn-sm`}
        onClick={openModal}>
        <IImport className={'padding-right-4px'} width="20" fill={`#128186`} />
        &nbsp; Importa Saldi
      </button>
      {showModal && (
        <ImportModal
          show={showModal}
          onHide={() => closeModal()}
          onSend={(dataFiles) => handleSendDataFile(dataFiles)}
          allowedFileExtensions={['csv']}
          buttonLoad={() => (
            <>
              <Row>
                <Col>
                  <UploadFileLabel forLabel={'dataFile'} label={'CARICA FILE'} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="pt-1 pb-1">
                  <label className="form-group label me-1">Separatore di dati:</label>
                  <input
                    style={{ maxWidth: '20px', textAlign: 'center' }}
                    maxLength="1"
                    type="text"
                    value={charSeparator}
                    onChange={(e) => setCharSeparator(e.target.value)}></input>
                </Col>
              </Row>
            </>
          )}
          title={'IMPORTA SALDI ANNUALI'}
          extraButtons={(_, index) => [
            <button
              key={index}
              onClick={() => downloadRelationshipsCsvTemplate()}
              className="btn btn-empty ms-2 px-4 btn-sm">
              <IExport className={'padding-right-4px svg'} width="16" fill={'#128186'} />
              &nbsp; Scarica template csv
            </button>
          ]}
        />
      )}
    </>
  )
}
