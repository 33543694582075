import React, { useEffect, useState } from 'react'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'
import { searchCoraSubjects } from '../../../../actions'
import imgShowBlu from '../../../../styles/images/show_blu.png'
import { isNotEmpty } from '../../../shared/Utility'
import { useHistory } from 'react-router-dom'
import { PopupError } from '../../../shared/PopupError'
import * as Constants from '../../../../config/Constants'

export function CoraSubjectList(props) {
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const history = useHistory()

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    fiscalCode: null,
    denomination: null,
    location: null,
    province: null,
    sidNumber: null,
    sidExpiration: null,
    sidEmail: null
  })

  const tableUtils = TableUtils
  const tableName = 'cora-subject'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      maxWidth: '190px',
      displayedName: 'Codice Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      orderingName: 'completeName',
      maxWidth: '460px',
      displayedName: 'Ragione Sociale / Nominativo'
    }),
    tableUtils.composeHeader({
      fieldName: 'location',
      displayedName: 'Sede Legale'
    }),
    tableUtils.composeHeader({
      fieldName: 'province',
      displayedName: 'Prov.',
      maxWidth: '100px'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidNumber',
      maxWidth: '180px',
      displayedName: 'Num. SID'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidExpiration',
      type: fieldTypes.DATE,
      maxWidth: '160px',
      displayedName: 'Scad. Certificati'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidEmail',
      maxWidth: '350px',
      displayedName: 'Email da StarSoluzioni'
    }),
    tableUtils.composeHeader({
      fieldName: 'actions',
      sortable: false,
      searchable: false,
      maxWidth: '100px',
      additionalClass: 'justify-content-center',
      displayedName: 'Dettaglio'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.completeName
          }),
          location: tableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.location
          }),
          province: tableUtils.composeCell({
            fieldName: 'province',
            fieldValue: c.province
          }),
          sidNumber: tableUtils.composeCell({
            fieldName: 'sidNumber',
            fieldValue: c.sidNumber
          }),
          sidExpiration: tableUtils.composeCell({
            fieldName: 'sidExpiration',
            fieldValue: c.sidExpiration
          }),
          sidEmail: tableUtils.composeCell({
            fieldName: 'sidEmail',
            fieldValue: c.sidEmail
          }),
          actions: tableUtils.composeCell({
            fieldName: 'actions',
            additionalClass: 'd-flex justify-content-center p-0',
            component: () => {
              return (
                <div className="d-flex justify-content-center p-0">
                  <button
                    className="btn btn-outline-primary btn-table"
                    onClick={() => props.openDetail(c.id)}>
                    <img src={imgShowBlu} alt={'Mostra dettagli'} />
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  const onClickRow = (subject) => {
    const couldSendPec = subject?.coraSubjectSid?.couldSendPec
    const isDelayedCommunicationDateSet = isNotEmpty(props.delayedCommunicationDate)
    const cannotSendPec = couldSendPec !== true && isDelayedCommunicationDateSet === true
    const disableLink = cannotSendPec || props.licenseExpired
    if (cannotSendPec) {
      const name =
        subject.personType === Constants.PNF
          ? subject.companyName
          : subject.lastName + ' ' + subject.firstName
      PopupError({
        text: `Parametri pec non configurati per il soggetto</br>${name}</br> Completare la configurazione o eliminare data di invio differito per continuare.`
      })
      return
    }
    const path = disableLink
      ? '#'
      : {
          pathname: `/app/cora/relationship/${subject.id}`,
          state: { licenseExpired: props.licenseExpired }
        }
    history.push(path)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
  }, [props.subjects])

  const renderTable = () => {
    return (
      <StarTable
        headerColums={header}
        rows={rows}
        isLoading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchCoraSubjects(searchObject)}
        onExecutedSearch={(content) => props.setSubjects(content)}
        usePagination={true}
        withCheckBoxes={true}
        onClickRow={onClickRow}
        onClickCheck={props.checkSubject}
        actionsComponent={props.actionsComponent}
      />
    )
  }

  return <>{renderTable()}</>
}
