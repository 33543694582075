import React from 'react'
import NumberFormat from 'react-number-format'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import PaymentList from './PaymentList'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'

class PaymentForm extends React.Component {
  constructor(props) {
    super(props)
    this.paymentListRefData = React.createRef()
    this.state = {
      yearRel: this.props.subject?.referenceYear || new Date().getFullYear() - 1,
      amountRel: this.props.relationship?.balance || 0.0,
      currencyRel: this.props.relationship?.currency || {
        id: '58',
        isoCode: 'EUR',
        description: ''
      },
      closed: this.props.relationship?.closed || false,
      sleepy: this.props.relationship?.sleepy || false,
      notDocumented: this.props.relationship?.notDocumented || false,
      errorAmount: { isValid: true, msg: '' }
    }
    console.log('amountRel: ' + this.state.amountRel)
  }

  handlerAmountRel = (val) => {
    if (isEmpty(val)) val = 0
    this.setState({
      amountRel: val.floatValue,
      errorAmount: { isValid: true, msg: '' }
    })
  }

  isNotEmpty = (val) => {
    return isNotEmpty(val)
  }

  handleInputAutocompileCurrency = (newCurrency, id, suggestionIndex) => {
    this.setState({
      currencyRel: {
        id: '',
        isoCode: newCurrency,
        description: ''
      }
    })

    let currencyFilter = this.props.currencies.filter((currency) => {
      return currency.name === newCurrency
    })

    if (currencyFilter.length === 1) {
      this.setState({
        currencyRel: {
          id: currencyFilter[0].id,
          isoCode: currencyFilter[0].name.split(' - ')[0],
          description: currencyFilter[0].name.split(' - ')[1]
        }
      })
    }
    if (currencyFilter.length === 0) {
      this.setState({
        currencyRel: {
          id: '',
          isoCode: newCurrency,
          description: ''
        }
      })
    }
  }

  handlerChangeClosed = (e) => {
    this.setState({ closed: e.target.checked })
  }

  handlerChangeSleepy = (e) => {
    this.setState({ sleepy: e.target.checked })
  }

  handlerChangeNotDocumented = (e) => {
    this.setState({ notDocumented: e.target.checked })
  }

  savePayments = () => {
    this.paymentListRefData.current.addCrsPayment()
    this.paymentListRefData.current.getCrsPaymentList()
  }

  refreshPayments = () => {
    this.paymentListRefData.current.getCrsPaymentList()
  }

  getData() {
    if (this.handleValidation()) {
      return {
        yearRel: this.state.yearRel,
        amountRel: this.state.amountRel,
        currencyRel: this.state.currencyRel,
        closed: this.state.closed,
        sleepy: this.state.sleepy,
        notDocumented: this.state.notDocumented
      }
    }
    return {}
  }

  handleValidation = () => {
    let isValid = true
    if (isEmpty(this.state.amountRel)) {
      this.setState({
        errorAmount: { isValid: false, msg: 'Il saldo deve essere valorizzato' }
      })
      isValid = false
    }
    return isValid
  }

  updateCrsRelationship = () => {
    if (this.handleValidation()) {
      let relationship = this.props.relationship
      let amountData = this.getData()
      relationship.balance = amountData.amountRel?.floatValue
      relationship.currency = amountData.currencyRel
      relationship.closed = amountData.closed
      relationship.sleepy = amountData.sleepy
      relationship.notDocumented = amountData.notDocumented

      actions.addCrsRelationship(relationship).then(
        (response) => {},
        (errors) => {
          PopupError({ ...this.props, text: errors })
        }
      )
    }
  }

  render() {
    return (
      <div className="row ms-4 me-4 mt-2">
        <div className="col-5">
          <div className="payment_relationship">
            <h5>Anno di riferimento (dettaglio soggetto) SALDO RAPPORTO</h5>

            <div className="d-flex align-items-start">
              <div className="col-2 form-group">
                <div className="input-group">
                  <input
                    name="yearRel"
                    className="form-control text-start me-1"
                    value={this.state.yearRel}
                    maxLength="4"
                    disabled="disabled"
                    style={{
                      fontSize: '0.875em',
                      padding: '0.375rem 0.5rem'
                    }}
                  />
                </div>
              </div>

              <div className="col-4 form-group mb-2">
                <div className="input-group">
                  <NumberFormat
                    name="amountRel_"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    className="form-control me-1 text-start p-2"
                    inputMode="numeric"
                    value={this.state.amountRel}
                    onValueChange={(val) => this.handlerAmountRel(val)}
                  />
                </div>
                <div className="text-danger">{this.state.errorAmount.msg || ''}</div>
              </div>

              <div className="col-2 form-group">
                <div className="input-group">
                  <AutoCompileCustom
                    id="currencyRel_"
                    handleInputAutocompile={(e) => this.handleInputAutocompileCurrency(e)}
                    filtro={this.props.currencies}
                    value={this.state.currencyRel.isoCode || ''}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row form-check mt-2 col-auto ms-2">
              <div className="me-3">
                <BwmCheckbox
                  name="closed_"
                  label="Rapporto CHIUSO"
                  value={this.state.closed}
                  onClick={this.handlerChangeClosed}
                />
              </div>
              <div className="me-3">
                <BwmCheckbox
                  name="sleepy_"
                  label="Rapporto DORMIENTE"
                  value={this.state.sleepy}
                  onClick={this.handlerChangeSleepy}
                />
              </div>
              <div className="me-3">
                <BwmCheckbox
                  name="notDocumented_"
                  label="Rapporto NON DOCUMENTATO"
                  value={this.state.notDocumented}
                  onClick={this.handlerChangeNotDocumented}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-7 mb-2">
          <PaymentList
            ref={this.paymentListRefData}
            {...this.props}
            relationship={this.props.relationship}
          />
        </div>
      </div>
    )
  }
}

export default PaymentForm
