export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ADMIN_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS = 'GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'

export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'

export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const GET_CURRENCY_TYPES_SUCCESS = 'GET_CURRENCY_TYPES_SUCCESS'

export const GET_LOCATION_BY_CITY_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_BY_CITY_FAILURE = 'GET_LOCATION_FAILURE'

export const APPLICATION_SUCCESS = 'APPLICATION_SUCCESS'
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS'

//CORA
export const RELATIONSHIP_SUCCESS = 'RELATIONSHIP_SUCCESS'
export const RELATIONSHIP_TYPE_SUCCESS = 'RELATIONSHIP_TYPE_SUCCESS'
export const RANKED_RELATIONSHIP_TYPE_SUCCESS = 'RANKED_RELATIONSHIP_TYPE_SUCCESS'

export const SUBJECTS_BY_PERSONTYPE_SUCCESS = 'SUBJECTS_BY_PERSONTYPE_SUCCESS'
export const NEW_CORA_SUBJECT_SUCCESS = 'NEW_CORA_SUBJECT_SUCCESS'
export const UPDATE_CORA_SUBJECT_SUCCESS = 'UPDATE_CORA_SUBJECT_SUCCESS'
export const GET_CORA_SUBJECT_SUCCESS = 'GET_CORA_SUBJECT_SUCCESS'
export const DELETE_CORA_SUBJECT_SUCCESS = 'DELETE_CORA_SUBJECT_SUCCESS'
export const DELETE_CORA_SUBJECT_ERROR = 'DELETE_CORA_SUBJECT_ERROR'

export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'

export const GET_DELEGATE_BY_RELATIONSHIP_SUCCESS = 'GET_DELEGATE_BY_RELATIONSHIP_SUCCESS'
export const GET_BALANCES_BY_RELATIONSHIP_SUCCESS = 'GET_BALANCES_BY_RELATIONSHIP_SUCCESS'

export const GET_ALL_COMMUNICATION_TYPES_SUCCESS = 'GET_ALL_COMMUNICATION_TYPES_SUCCESS'
export const COMMUNICATIONS_TO_SEND_SUCCESS = 'COMMUNICATIONS_TO_SEND_SUCCESS'
export const COMMUNICATIONS_HISTORY_SUCCESS = 'COMMUNICATIONS_HISTORY_SUCCESS'
export const COMMUNICATIONS_BALANCE_SUCCESS = 'COMMUNICATIONS_BALANCE_SUCCESS'
export const COMMUNICATIONS_LAST_ERROR_SUCCESS = 'COMMUNICATIONS_LAST_ERROR_SUCCESS'

export const DELAYED_COMMUNICATION_DATE = 'DELAYED_COMMUNICATION_DATE'

// COMUREI
export const COMUREI_GET_SUBJECT_TYPES_SUCCESS = 'COMUREI_GET_SUBJECT_TYPES_SUCCESS'
export const COMUREI_GET_COMMUNICATION_TYPES_SUCCESS = 'COMUREI_GET_COMMUNICATION_TYPES_SUCCESS'
export const COMUREI_GET_SUBJECTS_SUCCESS = 'COMUREI_GET_SUBJECTS_SUCCESS'
export const COMUREI_COMMUNICATIONS_HISTORY_SUCCESS = 'COMUREI_COMMUNICATIONS_HISTORY_SUCCESS'

// CRS
export const CRS_GET_SUBJECTS_SUCCESS = 'CRS_GET_SUBJECTS_SUCCESS'
export const CRS_GET_SUBJECT_SUCCESS = 'CRS_GET_SUBJECT_SUCCESS'
export const GET_CRS_RELATIONSHIPS_SUCCESS = 'GET_CRS_RELATIONSHIPS_SUCCESS'

// CORPRO
export const CORPRO_GET_SUBJECT_TYPES_SUCCESS = 'CORPRO_GET_SUBJECT_TYPES_SUCCESS'
export const CORPRO_GET_SUBJECTS_SUCCESS = 'CORPRO_GET_SUBJECTS_SUCCESS'
export const CORPRO_COMMUNICATIONS_HISTORY_SUCCESS = 'CORPRO_COMMUNICATIONS_HISTORY_SUCCESS'
export const GET_ALL_TRANSMISSION_TYPES_SUCCESS = 'GET_ALL_TRANSMISSION_TYPES_SUCCESS'
export const GET_ALL_SUPPLIER_TYPES_SUCCESS = 'GET_ALL_SUPPLIER_TYPES_SUCCESS'

// ARCO
export const ARCO_GET_SUBJECTS_SUCCESS = 'ARCO_GET_SUBJECTS_SUCCESS'
export const ARCO_GET_SUBJECT_SUCCESS = 'ARCO_GET_SUBJECT_SUCCESS'
export const ARCO_GET_SUBJECT_TYPES_SUCCESS = 'ARCO_GET_SUBJECT_TYPES_SUCCESS'
export const GET_ALL_COLLABORATORS_SUCCESS = 'GET_ALL_COLLABORATORS_SUCCESS'
export const GET_ALL_PROFESSIONISTS_SUCCESS = 'GET_ALL_PROFESSIONISTS_SUCCESS'
export const GET_ALL_ARCO_DOMAIN_SUCCESS = 'GET_ALL_ARCO_DOMAIN_SUCCESS'
export const GET_ALL_RISK_VALUE_SUCCESS = 'GET_ALL_RISK_VALUE_SUCCESS'
export const GET_ARCO_TABLE_DOMAIN_SUCCESS = 'GET_ARCO_TABLE_DOMAIN_SUCCESS'
export const GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS = 'GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS'
export const ARCO_GET_REGISTRIES_SUCCESS = 'ARCO_GET_REGISTRIES_SUCCESS'
export const GET_ARCO_OPERATIONS_SUCCESS = 'GET_ARCO_OPERATIONS_SUCCESS'
export const CLEAR_ARCO_OPERATIONS = 'CLEAR_ARCO_OPERATIONS'
export const ADD_SIGNATORIES = 'ADD_SIGNATORIES'
export const REMOVE_SIGNATORY = 'REMOVE_SIGNATORY'
export const ADD_HOLDERS = 'ADD_HOLDERS'
export const REMOVE_HOLDER = 'REMOVE_HOLDER'
export const GET_ARCO_CONTRACTS_SUCCESS = 'GET_ARCO_CONTRACTS_SUCCESS'
export const GET_ARCO_CASUAL_OPERATIONS_SUCCESS = 'GET_ARCO_CASUAL_OPERATIONS_SUCCESS'
export const GET_ARCO_REGISTRY_DOCUMENT_SUCCESS = 'GET_ARCO_REGISTRY_DOCUMENT_SUCCESS'
export const ARCO_CACHE_SEARCH = 'ARCO_CACHE_SEARCH'
export const ARCO_CLEAR_SEARCH = 'ARCO_CLEAR_SEARCH'
export const ARCO_REGISTRY_CACHE_SEARCH = 'ARCO_REGISTRY_CACHE_SEARCH'

//BLUE
export const BLUE_GET_SUBJECTS_SUCCESS = 'BLUE_GET_SUBJECTS_SUCCESS'
export const BLUE_GET_SUBJECT_SUCCESS = 'BLUE_GET_SUBJECT_SUCCESS'
export const BLUE_LANGUAGE_CHANGE = 'BLUE_LANGUAGE_CHANGE'
export const BLUE_GET_EVENTS_SUCCESS = 'BLUE_GET_EVENTS_SUCCESS'
