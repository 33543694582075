import 'react-confirm-alert/src/react-confirm-alert.css'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { useState } from 'react'
import '../../../../styles/alertComm.css'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import axiosService from '../../../../services/axios-service'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

import { CommunicationModal } from './CommunicationModal'
import * as Utils from '../CommunicationDownloadUtils'

export function CreateCommunicationModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sentCommunications, setSentCommunications] = useState([])

  const closeModal = () => {
    setShowModal(false)
    document.getElementById('history').click()
  }

  const openModal = () => {
    setShowModal(true)
  }

  const onClickConfirm = () => {
    if (props.onClickCreateCommunicationBefore) {
      props.onClickCreateCommunicationBefore().then(() => {
        confirmCommunications()
      })
    } else {
      confirmCommunications()
    }
  }

  const confirmCommunications = () => {
    const { subjectId, delayedCommunicationDate } = props
    setLoading(true)
    if (props.type === 'MONTHLY') {
      actions.createMonthlyCommunications(subjectId, delayedCommunicationDate).then(
        (sentCommunications) => {
          if (sentCommunications?.length > 0) {
            setLoading(false)
            setSentCommunications(sentCommunications)
            openModal()
          } else {
            setLoading(false)
            PopupError({
              ...props,
              text: "Errore nell'elaborazione della comunicazione, errore non gestito"
            })
          }
        },
        (errors) => {
          setLoading(false)
          PopupError({ ...props, text: errors })
        }
      )
    } else {
      const axiosInstance = axiosService.getInstance()
      axiosInstance({
        data: props.excludedRelationships,
        url: `/api/cora/custom/cora-communications/create/yearly/${subjectId}/${props.selectedYear}`,
        method: 'POST'
      })
        .then((response) => {
          if (response) {
            let sentCommunications = response.data
            if (sentCommunications?.length > 0) {
              setLoading(false)
              setSentCommunications(sentCommunications)
              openModal()
            } else {
              setLoading(false)
              PopupError({
                ...props,
                text: "Errore nell'elaborazione della comunicazione, errore non gestito"
              })
            }
          }
        })
        .catch((errors) => {
          setLoading(false)
          PopupError({ ...props, text: "Errore nell'elaborazione della comunicazione" })
        })
    }
  }

  return (
    <>
      {loading && <PageSpinner />}
      <div className={'text-end'}>
        <button
          onClick={(e) => onClickConfirm()}
          className="btn btn-primary btn-new-rel px-5"
          disabled={props.isDisabled}>
          <ITextFile
            className={'padding-right-4px svg'}
            width="16"
            fill={` ${props.isDisabled ? '#FFFFFF' : '#FFFFFF'}`}
          />
          &nbsp; CREA COMUNICAZIONE
        </button>
      </div>
      <CommunicationModal
        showModal={showModal}
        closeModal={closeModal}
        communications={sentCommunications}
        coraSubject={props.subject}
        downloadTxt={Utils.downloadTxt}
        downloadPecTz={Utils.downloadPecTz}
        downloadDgn={Utils.downloadDgn}
        downloadPdf={Utils.downloadPdf}
        downloadErrorReport={Utils.downloadErrorReport}
        delayedCommunicationDate={props.delayedCommunicationDate}
      />
    </>
  )
}
