import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'

export function PasswordSection({
  sectionTitleStyle,
  password,
  setPassword,
  passwordRevoca,
  setPasswordRevoca
}) {
  const [errorPassword, setErrorPassword] = useState('')
  const [errorPasswordRevoca, setErrorPasswordRevoca] = useState('')
  const passwordRegex = /([a-zA-Z]+)(\d+)/

  const validatePassword = (value = '') => {
    let error = ''
    if (value.length < 8 || value.length > 20) {
      error = 'La password deve essere lunga tra gli 8 ed i 20 caratteri'
    } else if (!value.match(passwordRegex)) {
      error = 'La password deve essere composta da almeno un numero ed una lettera'
    }
    setErrorPassword(error)
  }

  const validatePasswordRevoca = (value = '') => {
    let error = ''
    if (value.length < 15 || value.length > 20) {
      error = 'La password deve essere lunga tra i 15 ed i 20 caratteri'
    }
    setErrorPasswordRevoca(error)
  }

  return (
    <>
      <div className={sectionTitleStyle}>NUOVE CREDENZIALI CERTIFICATI ***</div>
      <Row>
        <Col lg={5}>
          <FloatLabelTextBox
            value={password}
            label={'Password per uso Certificati'}
            onChange={(e) => setPassword(e.target.value)}
            error={errorPassword}
            onBlur={(e) => validatePassword(e.target.value)}
          />
        </Col>
        <Col lg={5}>
          <FloatLabelTextBox
            value={passwordRevoca}
            label={'PIN DI REVOCA a scelta'}
            onChange={(e) => setPasswordRevoca(e.target.value.toUpperCase())}
            error={errorPasswordRevoca}
            onBlur={(e) => validatePasswordRevoca(e.target.value)}
          />
        </Col>
      </Row>
    </>
  )
}
