import { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('it', it)

export function ProvinceField(props) {
  const [error, setError] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    if (props.error !== undefined && props.error !== error) {
      setError(props.error)
    }
  }, [props.error])

  return (
    <>
      <div className="form-group">
        <div className="input-group col-6 ps-0">
          <input
            type={'text'}
            className={'form-control'}
            name={props.name}
            onFocus={props.onFocus}
            onChange={props.onChange}
            value={props.province}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        </div>
        {!error.isValid ? <div className="text-danger">{error.msg}</div> : ''}
      </div>
    </>
  )
}
