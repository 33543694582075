import React, { useEffect, useState } from 'react'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { ReactComponent as IRotateRight } from '../../../../styles/images/svgs/solid/redo.svg'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { ReactComponent as IZipFile } from '../../../../styles/images/svg/file-archive.svg'
import { ReactComponent as IEnvelope } from '../../../../styles/images/svgs/solid/envelope.svg'

import { ElaborateCommunicationModal } from './NewElaborateCommunicationModal'
import { computeIsError, computeStatusText, isReceipt } from './CommunicationRow'
import { formatDateForDisplay, isEmpty } from '../../../shared/Utility'

export function CommunicationHistoryList(props) {
  const {
    communications,
    subjectId,
    renderDeleteButton,
    openEmailModal,
    downloadReceiptTxt,
    downloadTxt,
    downloadPecTz,
    downloadPdf,
    downloadDgn,
    downloadErrorReport,
    reelaborateCommunicationTxt,
    SearchBar,
    hasRowsToShow
  } = props
  const [allChecked, setAllChecked] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)
  const [checkedCommunications, setCheckedCommunications] = useState(new Map())

  const filterReceipts = () => {
    return communications.filter((c) => isReceipt(c) === false)
  }

  //rivaluto la flag del bottone di eliminazione, e la flag di check delle righe
  useEffect(() => {
    if (checkedCommunications.size > 0) {
      setDisableDeleteButton(false)
    } else {
      setDisableDeleteButton(true)
    }
    setAllChecked(
      checkedCommunications.size === filterReceipts().length && checkedCommunications.size > 0
    )
  }, [checkedCommunications])

  //resetto la lista di comunicazioni selezionate al ricaricamento della lista
  useEffect(() => {
    setCheckedCommunications(new Map())
  }, [communications])

  const handleAllChecked = (e) => {
    let checkedComms = new Map()
    if (e.target.checked) {
      filterReceipts().forEach((element) => {
        //le comunicazioni con ricevuta non sono eliminabili
        checkedComms.set(element.id, element)
      })
    }
    setCheckedCommunications(checkedComms)
  }

  const handleCheckChange = (e, communication) => {
    let checkedComms = new Map(checkedCommunications)
    if (e.target.checked) {
      checkedComms.set(communication.id, communication)
    } else {
      checkedComms.delete(communication.id)
    }
    setCheckedCommunications(checkedComms)
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className="col w-5 text-center div-td">
          <input
            type="checkbox"
            checked={allChecked}
            onChange={(e) => {
              handleAllChecked(e)
            }}
          />
        </div>
        <div className="col w-10 div-td">DATA CREAZIONE</div>
        <div className="col w-5 div-td">PERIODO</div>
        <div className="col w-15 div-td">COMUNICAZIONE</div>
        <div className="col w-10 div-td">TIPOLOGIA DI INVIO</div>
        <div className="col w-50 div-td">STATO</div>
        <div className="col w-10 div-td">SCARICA</div>
      </>
    )
  }

  const renderRows = (communications = []) => {
    if (!communications?.length > 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey q-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">Nessun Storico comunicazioni trovato</h3>
        </div>
      )
    }
    return communications.map((communication, index) => {
      let status = computeStatusText(communication)
      let receipt = isReceipt(communication)
      let deletable = receipt || isEmpty(communication?.pecSentDate)
      let created =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
      let isError = computeIsError(communication.communicationStatus)
      let canDownloadPecat =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED ||
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR

      const isChecked = checkedCommunications.has(communication.id)

      return (
        <div key={`key-comm-history-${communication.id}`}>
          <div
            className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-hover`}
            id={`history-row-${communication.id}`}>
            <div className="col w-5 text-center div-td">
              {deletable === true && (
                <input
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  id={`history-row-check-${communication.id}`}
                  name="communicationCheck"
                  value={communication.id}
                  checked={isChecked}
                  onChange={(e) => handleCheckChange(e, communication)}
                />
              )}
            </div>
            <div className="col w-10 div-td text-truncate">
              {formatDateForDisplay(communication?.creationDate)}
            </div>
            <div className="col w-5 div-td text-truncate">{communication?.referencePeriod}</div>
            <div className="col w-15 div-td text-truncate">
              {communication.communicationType?.code} -{' '}
              {communication?.communicationType?.description}
            </div>
            <div className="col w-10 div-td text-truncate">
              {communication?.dispatchType === 'ORDINARY' ? 'Ordinario' : 'Straordinario'}
            </div>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title={status}
              className={`col w-50 div-td text-truncate ${isError && 'color-red'}`}>
              {status}
            </div>
            <div className="col w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver">
              {(created || receipt) && (
                <span
                  data-tip={'Invia COMUNICAZIONE'}
                  onClick={() => openEmailModal(communication)}>
                  <IEnvelope width="20" fill="#128186" />
                </span>
              )}
              {receipt && (
                <span
                  data-tip={'Scarica RICEVUTA'}
                  onClick={() => downloadReceiptTxt(communication)}>
                  <ITextFile width="16" />
                </span>
              )}
              <span
                data-tip={'Scarica COMUNICAZIONE TXT'}
                onClick={() => downloadTxt(communication)}>
                <ITextFile width="16" fill="#128186" />
              </span>
              {canDownloadPecat && (
                <div data-tip={'Scarica COPPIA FILE PECAT'}>
                  <IZipFile
                    width="16"
                    fill="#128186"
                    onClick={() => downloadPecTz(communication)}
                  />
                </div>
              )}
              {communication.communicationStatus !== CommunicationStatus.ERROR_SID &&
                !communication.elaboratedTxt && (
                  <span
                    data-tip={'Scarica STAMPA COMUNICAZIONE PDF'}
                    onClick={() => downloadPdf(communication)}>
                    <IPdfFile width="16" fill="#128186" />
                  </span>
                )}
              <span
                data-tip={'Rielabora FILE TXT'}
                onClick={() => reelaborateCommunicationTxt(communication)}>
                <IRotateRight width="20" fill="#128186" />
              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderReelaborateCommunicationButton = () => {
    return (
      <ElaborateCommunicationModal
        subjectId={subjectId}
        downloadTxt={(communication) => downloadTxt(communication)}
        downloadPecTz={(communication) => downloadPecTz(communication)}
        downloadDgn={(communication) => downloadDgn(communication)}
        downloadPdf={(communication) => downloadPdf(communication)}
        downloadErrorReport={(communication) => downloadErrorReport(communication)}
      />
    )
  }
  return (
    <>
      {hasRowsToShow ? (
        <>
          <div className="row header-btn-history">
            <div className={'col d-flex align-items-center'}>
              STORICO COMUNICAZIONI &nbsp; &nbsp; &nbsp;
              {renderDeleteButton(disableDeleteButton, checkedCommunications)}
            </div>
            <div className={'col-7'}>{SearchBar()}</div>
            <div className="col-2 d-flex justify-content-end">
              {renderReelaborateCommunicationButton()}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col d-flex justify-content-center">
              {renderReelaborateCommunicationButton()}
            </div>
          </div>
        </>
      )}
      <div className="star-table mt-2">
        <div className="text-start header-table">{renderRowHeader()}</div>
        {renderRows(communications)}
      </div>
    </>
  )
}
