import React from 'react'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import NumberFormat from 'react-number-format'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'

class PaymentRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: this.props.payment?.id || null,
      progressive: this.props.payment?.progressive || '',
      paymentType: this.props.payment?.paymentType?.id || '',
      paymentTypeCode: this.props.payment?.paymentType?.code || '',
      amount: isNotEmpty(this.props.payment?.amount) ? this.props.payment?.amount : '',
      currency: this.props.payment?.currency || {
        id: '58',
        isoCode: 'EUR',
        description: ''
      },
      errorPaymentType: '',
      errorAmount: '',
      errorCurrency: ''
    }
  }

  handlerChangePaymentType = (e) => {
    let code = e.target[e.target.selectedIndex].dataset.cod
    this.setState({
      paymentType: e.target.value,
      paymentTypeCode: code,
      errorPaymentType: ''
    })
  }

  resetFields = () => {
    this.setState({
      id: null,
      progressive: '',
      paymentType: '',
      paymentTypeCode: '',
      amount: '',
      currency: {
        id: '58',
        isoCode: 'EUR',
        description: ''
      }
    })
  }

  handlerAmount = (val) => {
    if (isEmpty(val)) val = 0
    this.setState({
      amount: val.floatValue,
      errorCurrency: ''
    })
  }

  // Usare la funzione freccia per mantenere il contesto di `this`
  handleInputAutocompileCurrency = (newCurrency, id, suggestionIndex) => {
    this.setState({
      currency: {
        id: '',
        isoCode: newCurrency,
        description: ''
      }
    })

    let currencyFilter = this.props.currencies.filter((currency) => {
      return currency.name === newCurrency
    })
    if (currencyFilter.length === 1) {
      this.setState({
        currency: {
          id: currencyFilter[0].id,
          isoCode: currencyFilter[0].name.split(' - ')[0],
          description: currencyFilter[0].name.split(' - ')[1]
        }
      })
    }
    if (currencyFilter.length === 0) {
      this.setState({
        currency: {
          id: '',
          isoCode: newCurrency,
          description: ''
        }
      })
    }
  }

  getData() {
    let payment = {
      id: this.state.id || null,
      progressive: this.state.progressive,
      paymentType: { id: this.state.paymentType },
      amount: this.state.amount,
      currency: this.state.currency
    }
    return payment
  }

  handleValidation = () => {
    let isValid = true
    if (isEmpty(this.state.paymentType)) {
      this.setState({
        errorPaymentType: "E' obbligatorio selezionare un valore"
      })
      isValid = false
    }
    if (isEmpty(this.state.amount)) {
      this.setState({
        errorAmount: "E' obbligatorio inserire un valore"
      })
      isValid = false
    }
    if (isEmpty(this.state.currency)) {
      this.setState({
        errorCurrency: "E' obbligatorio selezionare un valore"
      })
      isValid = false
    }

    return isValid
  }

  isNotEmpty = (val) => {
    return isNotEmpty(val)
  }

  addCrsPayment = () => {
    let relationshipId = this.props.relationship?.id || ''
    let payment = this.getData()
    payment.crsRelationship = { id: relationshipId }
    if (this.handleValidation()) {
      actions.addCrsPayment(payment, relationshipId).then(
        (response) => {
          //this.props.handlerShowPersonForm();
        },
        (errors) => {
          PopupError({ ...this.props, text: errors })
        }
      )
    }
  }

  render() {
    return (
      <div className="text-start row-table-input crs d-flex">
        <div className="col w-5 div-td text-center">
          {this.state.progressive !== '' && (
            <input
              type="checkbox"
              id={`checkbox_${this.state.id || ''}`}
              checked={this.props.checkbox}
              onClick={() => this.props.onClickCheckBox()}
            />
          )}
        </div>
        <div className="col w-5 div-td text-truncate">
          <input
            name={`progressive_${this.state.id || ''}`}
            className="form-control text-end"
            value={this.state.progressive}
            maxLength="16"
            disabled="disabled"
          />
        </div>
        <div className="col w-40 div-td text-truncate">
          <BwmSelect
            options={this.props.relationshipPaymentTypes}
            name={`holderType_${this.state.id || ''}`}
            className="form-control"
            onChange={this.handlerChangePaymentType}
            value={this.state.paymentType}
            showCode={true}
            error={this.state.errorPaymentType}
          />
        </div>
        <div className="col w-25 div-td text-truncate text-start">
          <NumberFormat
            name={`amount_${this.state.id || ''}`}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={0}
            fixedDecimalScale={true}
            className="form-control p-2 text-start"
            inputmode="numeric"
            value={this.state.amount}
            onValueChange={(val) => this.handlerAmount(val)}
          />
        </div>
        <div className="col w-10 div-td">
          <AutoCompileCustom
            name={`currency_${this.state.id || ''}`}
            handleInputAutocompile={this.handleInputAutocompileCurrency} // Passaggio diretto della funzione
            filtro={this.props.currencies}
            value={this.state.currency.isoCode || ''}
            error={this.state.errorCurrency}
          />
        </div>
      </div>
    )
  }
}

export default PaymentRow
