import RowSpinner from './Spinner'
import '../../../styles/spinner.css'

export function PageSpinner(props) {
  return (
    <div className="resultLoader">
      <div className="loader">
        <RowSpinner />
        <div className="testo">{props?.text || 'Stiamo elaborando la tua richiesta...'}</div>
      </div>
    </div>
  )
}
