export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-bar"></div>
      <div className="d-flex" style={{ height: 40 }}>
        <div className="col-2">
          <a
            rel="noopener noreferrer"
            className="nav-item nav-link ps-4 pl-2 px-2 py-2 font-weight-bold text-primary"
            href="https://lnx.starinfostudio.it/wp-content/files_mf/Assistenza-TVQS.exe">
            Team Viewer
          </a>
        </div>
        <div className="col-2">
          <a
            rel="noopener noreferrer"
            className="nav-item nav-link px-2 py-2 font-weight-bold text-primary"
            href="https://lnx.starinfostudio.it/downloads/exe/SupremoP.exe">
            Supremo
          </a>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <a
            rel="noopener noreferrer"
            className="nav-item nav-link font-weight-bold text-primary px-2 py-2"
            target={'_blank'}
            href="https://www.starsoluzioni.it/dati-societari/">
            Dati Societari
          </a>
        </div>
        <div className="col-2">
          <a
            rel="noopener noreferrer"
            className="nav-item nav-link font-weight-bold text-primary px-2 py-2"
            target={'_blank'}
            href="https://www.starsoluzioni.it/privacy/">
            Privacy & Cookie Policy
          </a>
        </div>
      </div>
    </footer>
  )
}
