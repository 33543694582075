import { ReactComponent as IUndo } from '../../../../../styles/images/svgs/regular/times-circle.svg'

export function CloseButton(props) {
  return (
    <button
      className="btn btn-outline-primary btn-empty px-4 margin-left-5px btn-sm"
      onClick={props.onClick}>
      <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
      &nbsp; CHIUDI
    </button>
  )
}
