import React from 'react'
import { CoraSubjectList } from './NewCoraSubjectList'
import { CoraSubjectModal } from './NewCoraSubjectModal'
import { connect } from 'react-redux'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { PopupDelete } from '../../../shared/PopupDelete'
import '../../../../styles/subject.css'
import { UploadReceiptModal } from '../receipts/NewUploadReceiptModal'
import { ImportDataModal } from '../import/NewImportDataModal'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { CoraSubjectsExportModal } from './CoraSubjectsExportModal'
import * as Constants from '../../../../config/Constants'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { ReactComponent as ICalendar } from '../../../../styles/images/svg/calendar-alt-regular.svg'
import { ReactComponent as IQuestion } from '../../../../styles/images/svgs/regular/question-circle.svg'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'

import { DelayedCommunicationModal } from '../communications/DelayedCommunicationModal'
import { DELAYED_COMMUNICATION_DATE } from '../../../../actions/types'
import { formatDateForDisplay } from '../../../shared/Utility'
import { Modal, Row } from 'react-bootstrap'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'

export class CoraSubject extends React.Component {
  constructor() {
    super()
    this.state = {
      id: null,
      updating: false,
      subjects: [],
      updatedSubject: '',
      checkedSubjects: [],
      locations: [],
      baseRegistry: null,
      location: null,
      isInBaseReg: false,
      fiscalCodeIns: '',
      companyNameIns: '',
      locationIns: '',
      provinciaIns: '',
      errors: '',
      subjectSummary: null,
      isLicenseValid: false,
      disableNewSubjectButtons: false,
      searchString: '',
      isLoading: false,
      licenseExpiration: undefined,
      showSubjectImportModal: false
    }
  }

  componentDidMount() {
    this.getSubjectsByPersonType()
    this.getAllLocations()
    if (this.props?.auth?.user?.customer?.customerApplications?.length > 0) {
      this.checkLicense()
    }
  }

  componentDidUpdate(prev, prevState) {
    if (
      prev.auth?.user?.customer?.customerApplications !==
      this.props?.auth?.user?.customer?.customerApplications
    ) {
      this.checkLicense()
    }

    if (prevState.subjectSummary != this.state.subjectSummary) {
      if (this.state.subjectSummary?.available < 0) {
        PopupSubjectLicense({
          total: this.state.subjectSummary.total,
          used: this.state.subjectSummary.used,
          name: Constants.APPLICATION_CORA,
          available: this.state.subjectSummary.available
        })
        this.setState({ isLicenseValid: false })
      }
    }
  }

  setSubjects = (subjects) => {
    this.setState({ subjects })
  }

  getSubjectsByPersonType = () => {
    actions.searchCoraSubjects().then((res) => {
      this.setSubjects(res?.content || res?.data)
    })
  }

  checkLicense = () => {
    const isLicenseValid = this.isApplicationLicenseValid()

    this.getSubjectSummary(false)

    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_CORA
    )
    const customerApplication = this.props?.auth?.user?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application.id
    )
    this.setState({
      isLicenseValid: isLicenseValid,
      customerApplication: customerApplication,
      licenseExpiration: customerApplication?.expiration
    })
  }

  getSubjectSummary = (shouldDisplayError) => {
    CheckRemainingSubjects(Constants.APPLICATION_CORA, shouldDisplayError, (subjectSummary) => {
      this.setState({
        subjectSummary: subjectSummary,
        disableNewSubjectButtons: subjectSummary?.available < 1
      })
    })
  }

  isApplicationLicenseValid() {
    return IsLicenseValid(
      this.props?.auth?.user?.customer?.customerApplications,
      Constants.APPLICATION_CORA
    )
  }

  getAllLocations() {
    this.props.dispatch(actions.getAllLocations())
  }

  addOrUpdateCoraSubject = (newSubject, previousFiscalCode) => {
    newSubject = this.trimFields(newSubject)
    const promise = newSubject.id
      ? actions.updateCoraSubject(newSubject)
      : actions.addCoraSubject(newSubject)
    promise
      .then(
        () => {
          if (previousFiscalCode !== newSubject.fiscalCode) {
            this.verifyCoraSubjectOnComurei(newSubject.fiscalCode)
          } else {
            PopupSuccess({ text: 'Soggetto salvato correttamente' })
          }

          this.getSubjectsByPersonType()
          this.getSubjectSummary(true)
        },
        (errors) => {
          if (errors.status === 511) {
            PopupSubjectLicense({
              total: this.state.subjectSummary.total,
              used: this.state.subjectSummary.used,
              available: this.state.subjectSummary?.available,
              name: Constants.APPLICATION_CORA
            })
          } else {
            PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
          }
        }
      )
      .then(() => {
        this.getSubjectsByPersonType()
        this.getSubjectSummary(true)
      })
  }

  verifyCoraSubjectOnComurei = (fiscalCode, completeName) => {
    actions.verifyCoraSubjectOnComurei(fiscalCode).then(
      (res) => {
        if (res === false) {
          ComureiSubjectNotPresentAlert({ fiscalCode: fiscalCode, denomination: completeName })
        } else {
          PopupSuccess({ text: 'Soggetto salvato correttamente' })
        }
      },
      (errors) => {
        console.log(errors)
        PopupSuccess({ text: 'Soggetto salvato correttamente' })
      }
    )
  }

  updateCoraSubject = (subject, previousFiscalCode = '') => {
    subject = this.trimFields(subject)
    actions
      .updateCoraSubject(subject)
      .then(
        () => {
          if (previousFiscalCode !== subject.fiscalCode) {
            this.verifyCoraSubjectOnComurei(subject.fiscalCode)
          } else {
            PopupSuccess({ text: 'Soggetto salvato correttamente' })
          }
        },
        (errors) => {
          PopupError({ text: errors?.title || Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        this.getSubjectsByPersonType()
      })
  }

  openPopupDelete = () => {
    PopupDelete({
      operazione: 'Soggetti Selezionati',
      handleClickConfirm: this.deleteCoraSubjects,
      text: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    })
  }

  deleteCoraSubjects = () => {
    this.setState({ isLoading: true })
    let promises = []
    this.state.checkedSubjects.map((subject) => {
      promises.push(actions.deleteCoraSubject(subject))
    })
    Promise.all(promises)
      .then(
        () => {
          PopupSuccess()
        },
        (errors) => {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        this.setState({ isLoading: false })
        this.getSubjectsByPersonType()
        this.getSubjectSummary(false)
        this.checkSubject([])
      })
  }

  checkSubject = (list = []) => {
    this.setState({ checkedSubjects: list })
  }

  trimFields(subject) {
    if (subject.companyName) {
      //TODO: trimmare lato BE
      subject.companyName = subject.companyName.trim()
    } else if (subject.firstName && subject.lastName) {
      subject.firstName = subject.firstName.trim()
      subject.lastName = subject.lastName.trim()
    }
    return subject
  }

  onChangeScheduledDate(date) {
    this.props.dispatch({
      type: DELAYED_COMMUNICATION_DATE,
      delayedCommunicationDate: date
    })
    this.setState({ showDelayedCommunicationModal: false })
  }

  render() {
    return (
      <>
        {this.state.isLoading && <PageSpinner />}
        <div className="table-custom">
          <div className="tabs single">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-8 ps-0 d-flex align-items-center">
                  <SubjectTotalAndCallToAction
                    total={this.state.subjectSummary?.total}
                    remaining={this.state.subjectSummary?.available}
                    used={this.state.subjectSummary?.used}
                    licenseType={this.state.customerApplication?.licenseType}
                    customerApplication={this.state.customerApplication}
                  />
                </div>

                <div className="d-flex col-4 text pe-0 align-items-center justify-content-end">
                  <div className="col d-flex justify-content-end">
                    <CoraSubjectModal
                      subjects={this.state.subjects}
                      licenseExpired={!this.state.isLicenseValid}
                      subjectSummary={this.state.subjectSummary}
                      addOrUpdateCoraSubject={this.addOrUpdateCoraSubject}
                      locations={this.props.locations}
                      closeDialog={() => this.setState({ id: null })}
                    />
                  </div>
                </div>
              </div>

              <CoraSubjectList
                subjects={this.state.subjects}
                updateCoraSubject={this.updateCoraSubject}
                checkSubject={this.checkSubject}
                reloadSubjects={this.getSubjectsByPersonType}
                licenseExpired={!this.state.isLicenseValid}
                setSubjects={this.setSubjects}
                onRowClick={() => {
                  //TODO: richiamare nelle celle
                  if (this.state.subjectSummary?.available < 0) {
                    PopupSubjectLicense({
                      total: this.state.subjectSummary?.total,
                      used: this.state.subjectSummary.used,
                      name: Constants.APPLICATION_CORA
                    })
                  }
                }}
                openDetail={(id) => this.setState({ id })}
                actionsComponent={
                  <div className="col-12 ps-0">
                    <div className="btn-group" role="group" aria-label="action buttons">
                      <button
                        type="button"
                        disabled={Object.keys(this.state.checkedSubjects).length === 0}
                        className={`btn btn-empty btn-outline-primary btn-sm ${Object.keys(this.state.checkedSubjects).length === 0 ? 'disabled' : ''}`}
                        onClick={() => this.openPopupDelete()}>
                        <IDelete
                          width="16"
                          fill={` ${Object.keys(this.state.checkedSubjects).length === 0 ? '#FFFFFF' : '#128186'}`}
                        />
                        &nbsp; Elimina
                      </button>
                      <CoraSubjectsExportModal
                        disabled={Object.keys(this.state.checkedSubjects).length === 0}
                        checkedSubjects={this.state.checkedSubjects}
                      />
                      <button
                        type="button"
                        data-tip={'Importa soggetti alla tua lista'}
                        className={`btn btn-empty btn-sm`}
                        disabled={this.state.disableNewSubjectButtons || !this.state.isLicenseValid}
                        onClick={() => {
                          this.setState({ showSubjectImportModal: true })
                        }}>
                        <IImport
                          width="18"
                          height="20"
                          fill={` ${this.state.disableNewSubjectButtons || !this.state.isLicenseValid ? '#FFFFFF' : '#128186'}`}
                        />
                        &nbsp; Importa
                      </button>
                      {this.state.showSubjectImportModal && (
                        <ImportDataModal
                          show={this.state.showSubjectImportModal}
                          onSuccess={() => {
                            this.checkLicense()
                            this.getSubjectsByPersonType()
                            this.setState({ showSubjectImportModal: false })
                          }}
                          onClose={() => {
                            this.setState({ showSubjectImportModal: false })
                          }}
                        />
                      )}
                      <UploadReceiptModal
                        disabled={!this.state.isLicenseValid}
                        refreshSubjects={this.getSubjectsByPersonType}
                      />
                      {this.props.delayedCommunicationDate ? (
                        <>
                          <button
                            type="button"
                            className={`btn btn-danger btn-sm`}
                            onClick={() => {
                              this.onChangeScheduledDate(null)
                            }}>
                            <IDelete fill={`#FFFFFF`} width="16" />
                            &nbsp;INVIO DIFFERITO IMPOSTATO AL{' '}
                            {formatDateForDisplay(this.props.delayedCommunicationDate)}
                          </button>
                          <button
                            type="button"
                            className={`btn  btn-empty btn-sm`}
                            onClick={() => {
                              this.setState({ showDelayedCommunicationHint: true })
                            }}>
                            <IQuestion fill={`#128186`} width="16" />
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className={`btn  btn-empty btn-sm`}
                          onClick={() => {
                            this.setState({ showDelayedCommunicationModal: true })
                          }}>
                          <ICalendar fill={`#128186`} width="20" height="17" />
                          &nbsp; INVIO DIFFERITO
                        </button>
                      )}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {this.state.id && (
          <CoraSubjectModal
            id={this.state.id}
            hideButton={true}
            subjects={this.state.subjects}
            licenseExpired={!this.state.isLicenseValid}
            subjectSummary={this.state.subjectSummary}
            addOrUpdateCoraSubject={this.addOrUpdateCoraSubject}
            locations={this.props.locations}
            closeDialog={() => this.setState({ id: null })}
          />
        )}
        {this.state.showDelayedCommunicationModal && (
          <DelayedCommunicationModal
            show={this.state.showDelayedCommunicationModal}
            onHide={() => this.setState({ showDelayedCommunicationModal: false })}
            onConfirm={(date) => {
              this.onChangeScheduledDate(date)
            }}
            date={this.props.delayedCommunicationDate}
            licenseExpiration={this.state.licenseExpiration}
          />
        )}
        {this.state.showDelayedCommunicationHint && (
          <Modal
            centered
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            show={this.state.showDelayedCommunicationHint}
            onHide={() => {
              this.setState({ showDelayedCommunicationHint: false })
            }}>
            <div className="bar"></div>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="container">
                <p>
                  INVIO DIFFERITO impostato per la data:{' '}
                  <strong>{formatDateForDisplay(this.props.delayedCommunicationDate)}</strong>
                </p>
                <p>Alla chiusura della sessione (o premendo F5) l'impostazione viene eliminata</p>
                <p>Le eventuali comunicazioni create con l'impostazione attiva rimangono valide</p>
                <p>Per annullare questi invii va eliminata la riga dallo STORICO comunicazioni</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row className={'justify-content-center'}>
                <button
                  type="button"
                  className={`btn  btn-empty px-4 btn-sm`}
                  onClick={() => {
                    this.setState({ showDelayedCommunicationHint: false })
                  }}>
                  OK, CHIUDI
                </button>
              </Row>
            </Modal.Footer>
          </Modal>
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  let customLocations = state.locations?.data.map((location, index) => {
    return { name: location.location, key: index }
  })
  return {
    auth: state.auth,
    subjects: state.subjects.data,
    locations: customLocations,
    delayedCommunicationDate: state.delayedCommunicationDate
  }
}

export default connect(mapStateToProps)(CoraSubject)
