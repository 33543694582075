import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axiosService from '../../../../services/axios-service'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { CrsForm } from './NewCrsForm'
import { APPLICATION_CRS } from '../../../../config/Constants'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import '../../../../styles/dac7NewStyles.css'

export const CrsSubjectModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [newSubjectCode, setNewSubjectCode] = useState()

  const closeModal = () => {
    setShowModal(false)
    setShowModal(false)
    if (props.closeModal) props.closeModal()
  }

  const openModal = () => {
    const subjectSummary = props.subjectSummary
    if (subjectSummary && subjectSummary?.available > 0) {
      const axiosInstance = axiosService.getInstance()
      axiosInstance.get('/api/crs/custom/crs-subjects/lastProgressive').then((res) => {
        let newCode = parseInt(res.data) + 1
        setNewSubjectCode(newCode)
        setShowModal(true)
      })
    } else {
      PopupSubjectLicense({
        total: subjectSummary?.total,
        used: subjectSummary?.used,
        available: subjectSummary?.available,
        name: APPLICATION_CRS
      })
    }
  }

  return (
    <>
      {!props.hideButton && (
        <button
          type="button"
          className="btn btn-primary  btn-new-rel  btn-sm"
          disabled={props.licenseExpired}
          onClick={openModal}>
          <IPlus
            className={'padding-right-4px'}
            width="14"
            fill={` ${props.licenseExpired ? '#128186' : '#FFFFFF'}`}
          />
          &nbsp; Nuovo soggetto
        </button>
      )}
      <Modal
        backdrop="static"
        id={'subject-modal'}
        show={!props.hideButton ? showModal : props.showModal}
        onHide={closeModal}
        dialogClassName="modal-subject"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {props.id
                ? props.subject?.id
                  ? `Modifica Soggetto ${props.subject.companyName}`
                  : 'Caricamento...'
                : 'Nuovo soggetto'}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CrsForm
            subjectId={props.id}
            locations={props.locations}
            countries={props.countries}
            showModal={showModal}
            licenseExpired={props.licenseExpired}
            newSubjectCode={newSubjectCode}
            getSubjects={props.getSubjects}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
