import { GET_COUNTRIES_SUCCESS } from '../../actions/types'

const INITIAL_STATE = {
  countries: {
    data: [],
    errors: []
  }
}

export const countriesReducer = (state = INITIAL_STATE.countries, action) => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS:
      return { ...state, data: action.countries }
    default:
      return state
  }
}
