import { PopupConfirm, titleColors } from '../PopupConfirm'

export default function ComureiSubjectNotPresentAlert({ denomination, fiscalCode }) {
  return PopupConfirm({
    titleColor: titleColors.SUCCESS,
    title: 'Soggetto salvato correttamente',
    showCancelButton: false,
    component: () => (
      <>
        <div>
          Il soggetto {denomination} <br />
          codice fiscale {fiscalCode}
          <br></br>
          <strong className="text-danger">NON RISULTA</strong>
          <br></br>
          nel NOSTRO programma <strong className="text-danger">ComuREI</strong> (Registro
          Elettronico Indirizzi)
          <br></br>
          se la mail pec è stata iscritta con un altro programma considerate nullo questo avviso
          <br />
          <br />
          se invece la mail pec NON è stata ancora inscritta al REI
          <br />
          vi invitiamo a fare l'iscrizione PRIMA (almeno 24 ore) di inviare le comunicazioni CORA o
          CRS, pena lo scarto delle stesse.
          <br />
          <br />
          Noi NON possiamo interrogare il REI: notiamo solo l'assenza del codice fiscale nel nostro
          programma.
          <br></br>
          Trovate maggiori dettagli in{' '}
          <a
            target="_blank"
            href="https://www.starsoluzioni.it/cosa-bisogna-fare-per-essere-in-regola/">
            questa pagina
          </a>
        </div>
      </>
    )
  })
}
