import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { ReactComponent as ITextFileSolid } from '../../../../styles/images/svg/file-alt-solid.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import * as Utils from '../CommunicationDownloadUtils'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { formatDateForDisplay } from '../../../shared/Utility'

export function ConsistencyHistoryList(props) {
  const dispatch = useDispatch()

  const communicationsHistory = useSelector((state) => state.communicationsHistory.data)

  useEffect(() => {
    getHistory(props.subjectId)
    getSubject(props.subjectId)
  }, [])

  const getHistory = (subjectId) => {
    dispatch(actions.getConsistencyReceiptHistoryBySubjectId(subjectId))
  }

  const getSubject = (subjectId) => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className="col w-10 div-td">DATA CREAZIONE</div>
        <div className="col w-25 div-td">NOME FILE</div>
        <div className="col w-75 div-td">STATO</div>
        <div className="col w-10 div-td">SCARICA</div>
      </>
    )
  }

  const isATPEC03or04 = (communication) => {
    return (
      communication?.txtFilename.startsWith('ATPEC03') ||
      communication?.txtFilename.startsWith('ATPEC04')
    )
  }

  const computeStatusText = (communication) => {
    let status = ''
    if (communication?.communicationStatus === CommunicationStatus.ERROR_SID) {
      status = 'Attenzione!! ' + communication?.communicationError + '.'
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
      if (isATPEC03or04(communication)) {
        status = 'Esito Elaborato: comunicazione ACCOLTA'
      } else {
        status = 'Fotografia Elaborata: nessuna discordanza tra gli archivi'
      }
    } else if (
      communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
    ) {
      if (isATPEC03or04(communication)) {
        status = 'Esito Elaborato: comunicazione ACCOLTA CON ERRORI'
      } else {
        status = 'Fotografia Elaborata: presenti discordanze tra gli archivi'
      }
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
      if (communication.communicationError) {
        status = communication.communicationError
      } else {
        if (isATPEC03or04(communication)) {
          status = 'Esito Elaborato: comunicazione SCARTATA'
        } else {
          status = 'Fotografia non processata'
        }
      }
    }
    return status
  }

  const renderCommunicationRow = (communication, index) => {
    let isReceipt = isATPEC03or04(communication)
    let status = computeStatusText(communication)
    return (
      <>
        <div
          className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-hover`}>
          <div className="col w-10 div-td text-truncate">
            {formatDateForDisplay(communication.creationDate)}
          </div>
          <div className="col w-25 div-td text-truncate">{communication?.txtFilename}</div>
          <div className="col w-75 div-td text-truncate">{status}</div>
          <div className="col w-10 div-td text-truncate d-flex justify-content-around align-items-center pe-4 ps-4 containerPopOver">
            {isReceipt ? (
              <>
                <button
                  data-tip={'Scarica file esito in chiaro'}
                  onClick={() => Utils.downloadReceiptTxt(communication)}>
                  <ITextFile width="20" />
                </button>
                <button
                  data-tip={'Scarica file esito originale'}
                  onClick={() => Utils.downloadOriginalReceipt(communication)}>
                  <ITextFileSolid width="20" fill="#128186" />
                </button>
              </>
            ) : (
              <>
                <button
                  data-tip={'Scarica fotografia in chiaro'}
                  onClick={() => Utils.downloadConsistencyReceiptTxt(communication)}>
                  <ITextFile width="20" />
                </button>
                <button
                  data-tip={'Scarica file ricevuto se presente (P7M)'}
                  onClick={() => Utils.downloadConsistencyFile(communication)}>
                  <ITextFileSolid width="20" fill="#128186" />
                </button>
                <button
                  data-tip={'Scarica file elaborato da SID (RUN)'}
                  onClick={() => Utils.downloadOriginalReceipt(communication)}>
                  <ITextFileSolid width="20" fill="#128186" />
                </button>
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  const renderRows = (communications) => {
    if (!communications.length > 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey q-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">Nessuna Fotografia/Esiti trovata</h3>
        </div>
      )
    }
    return communications.map((communication, index) => {
      return renderCommunicationRow(communication, index)
    })
  }

  return (
    <>
      <div className="row header-btn-v2 mb-2 align-items-left">
        STORICO FOTOGRAFIE DI CONSISTENZA/ESITI
      </div>
      <div className="star-table">
        <div className="text-start header-table">{renderRowHeader()}</div>
        {renderRows(communicationsHistory)}
      </div>
    </>
  )
}
