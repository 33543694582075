import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

/**
 * Permette di costruire un dropdown con determinati elementi in cima alla lista
 * @param {*} props
 * @param props.list lista di oggetti {id, code, description}
 * @param props.rankedElements come la lista di oggetti, verranno inseriti in cima
 * @param props.onChange callback su selezione, con parametro evento
 * @param props.error oggetto errore {isValid, msg}
 * @param selectedValue item selezionato, per id
 * @returns
 */
export function RankedDropdownList(props) {
  const list = props.list || []
  const rankedElements = props.rankedElements || []
  const label = props.label || ''
  const error = props.error
  const selectedValue = props.selectedValue || null
  const disabled = props.disabled

  useEffect(() => {
    setRenderOptions(getRenderOptions())
  }, [props.rankedElements])

  const getRenderOptions = () => {
    const completeList = [...new Set([...rankedElements, ...list])]
    let selectedIndex = completeList.findIndex((el) => el.id === selectedValue)
    selectedIndex = selectedIndex != -1 ? selectedIndex : 0
    return completeList.map((el, index) => {
      if (rankedElements.length && index === rankedElements.length - 1) {
        return (
          <>
            <option
              selected={index === selectedIndex}
              value={el.id}>{`${el.code && `${el.code} - `}${el.description}`}</option>
            <option value={null} disabled>
              ________________________________________________________________________
            </option>
          </>
        )
      } else {
        return (
          <option
            key={`ranked-option-${index}`}
            selected={index === selectedIndex}
            value={el.id}>{`${el.code} - ${el.description}`}</option>
        )
      }
    })
  }

  const [renderOptions, setRenderOptions] = useState(getRenderOptions())

  const hasError = props.touched && props.error !== undefined ? 'has-error' : ''

  return (
    <div className={`input-wrapper w-100 ${hasError}`}>
      <div className="input-group">
        <FloatingLabel id="floatingLabel" label={label}>
          <Form.Control
            disabled={disabled}
            className={`form-control bg-white custom-select-arrow ${hasError}`}
            onChange={(e) => props.onChange(e)}
            as="select">
            <option value={null}></option>
            {renderOptions}
          </Form.Control>
        </FloatingLabel>
      </div>
      {error && !error.isValid && <div className="text-danger">{error.msg}</div>}
    </div>
  )
}
