import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import ComureiForm from './NewComureiForm'
import { IPlus } from '../../../../styles/icons'
import { getComureiSubjectDetail } from '../../../../actions'
import Loader from '../../../Loader'

export function ComureiSubjectModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [subject, setSubject] = useState(false)

  useEffect(() => {
    if (props.id) {
      getComureiSubjectDetail(props.id).then((response) => {
        setSubject(response.data)
      })
    }
  }, [])

  const closeModal = () => {
    if (props.closeModal) props.closeModal()
    setShowModal(false)
  }

  const openModal = async () => {
    setShowModal(true)
  }

  return (
    <>
      {!props.hideButton && (
        <button type="button" className="btn btn-primary btn-new-rel px-3" onClick={openModal}>
          <IPlus className={'padding-right-4px'} width="14" fill={'#FFFFFF'} />
          &nbsp;
          <span>Nuovo soggetto</span>
        </button>
      )}
      <Modal
        dialogClassName="comurei-registry-modal"
        backdrop="static"
        show={props.hideButton ? props.showModal : showModal}
        onHide={closeModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {props.id
                ? subject
                  ? `Modifica Soggetto ${subject.companyName}`
                  : 'Caricamento...'
                : 'Nuovo soggetto'}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 pt-0">
          {((props.id && subject) || !props.id) && (
            <ComureiForm
              locations={props.locations}
              subjectId={props.id}
              openCreateCommunicationModal={props.openCreateCommunicationModal}
              subjectTypes={props.subjectTypes}
              closeModal={closeModal}
              getSubjects={props.getSubjects}
            />
          )}
          {props.id && !subject && <Loader />}
        </Modal.Body>
      </Modal>
    </>
  )
}
