import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions'
import * as Constants from '../../config/Constants'
import { Col, Row } from 'react-bootstrap'
import { PopupError } from '../shared/PopupError'
import { PopupSuccess } from '../shared/PopupSuccess'

import { TableUtils, baseSearchObject, fieldTypes } from '../shared/tables/TableUtils'
import { StarTable } from '../shared/tables/StarTable'

import { BtnFill } from '../shared/BtnFill'

import { Link } from 'react-router-dom'
import { AdminNewApplicationModal } from './AdminNewApplicationModal'
import authService from '../../services/auth-service'
import { IPhoneAlt } from '../../styles/icons'
import { BwmInput } from '../shared/form/BwmInput'
import { useEffect, useState } from 'react'

const tableName = 'admin-customer-applications'
const startelPath = '/admin/star-tel'

export function AdminCustomers() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [editedIds, setEditedIds] = useState([])
  const [sidNumber, setSidNumber] = useState('')
  const [subjectDenomination, setSubjectDenomination] = useState('')
  const [rows, setRows] = useState([])
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    denomination: '',
    email: '',
    applicationId: [],
    maxSubjects: '',
    licenseType: [],
    expiration: '',
    lastModified: '',
    pageSize: 100,
    sortField: 'lastModified',
    sidNumber: '',
    subjectDenomination: '',
    version: '3'
  })

  const [customerApplications, setCustomerApplications] = useState([])
  const _customerApplications = useSelector((state) => state.customerApplications.data)

  useEffect(() => {
    dispatch(actions.getAccount())
  }, [])

  useEffect(() => {
    setCustomerApplications(_customerApplications)
  }, _customerApplications)

  const header = [
    TableUtils.composeHeader({
      fieldName: 'denomination',
      displayedName: 'Ragione Sociale'
    }),
    TableUtils.composeHeader({
      fieldName: 'email',
      displayedName: 'Email'
    }),
    TableUtils.composeHeader({
      fieldName: 'applicationId',
      displayedName: 'Nome App',
      type: fieldTypes.SELECTION,
      options: Constants.APPLICATIONS,
      minWidth: '9rem',
      maxWidth: '9rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'maxSubjects',
      displayedName: 'Num. Soggetti',
      type: fieldTypes.NUMBER,
      minWidth: '8rem',
      maxWidth: '8rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'licenseType',
      displayedName: 'Tipo Licenza',
      type: fieldTypes.SELECTION,
      options: Constants.LICENSE_TYPES,
      minWidth: '10rem',
      maxWidth: '10rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'expiration',
      displayedName: 'Scadenza',
      type: fieldTypes.DATE,
      minWidth: '10rem',
      maxWidth: '10rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'lastModified',
      displayedName: 'Ultimo Ordine',
      type: fieldTypes.DATE,
      minWidth: '9rem',
      maxWidth: '9rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'save',
      displayedName: 'Modifica',
      sortable: false,
      searchable: false,
      minWidth: '9rem',
      maxWidth: '9rem'
    })
  ]

  const prepareRows = (customerApplications = []) => {
    return customerApplications.map((app) => {
      const application = Constants.APPLICATIONS.find((a) => a.id === app.applicationId)
      return TableUtils.composeRow({
        id: app.id,
        cellData: {
          denomination: TableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: app.denomination
          }),
          email: TableUtils.composeCell({
            fieldName: 'email',
            fieldValue: app.email,
            component: () => (
              <div className=" text-truncate d-flex align-items-center">
                <AdminNewApplicationModal
                  onSave={(customerApplication) => onSave(customerApplication)}
                  key={'admin-new-application' + app.id}
                  customer={app.customer}
                />
                &nbsp;
                <Link
                  onClick={() => {
                    authService.setHeader(Constants.FAKE_USER, app.email)
                  }}
                  to={'/dashboard'}>
                  <button type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">
                    accedi
                  </button>
                </Link>
                &nbsp;
                <span className="text-truncate" style={{ maxWidth: '75%' }}>
                  {app.email}
                </span>
                &nbsp;
                <Link
                  to={{
                    pathname: '/app/cora/admin',
                    state: { loginParam: app.email }
                  }}
                  rel="noopener noreferrer"
                  className="ms-auto">
                  <button type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">
                    C
                  </button>
                </Link>
                &nbsp;
                <Link
                  to={{
                    pathname: '/app/facciamo-noi/admin',
                    state: { loginParam: app.email }
                  }}
                  rel="noopener noreferrer">
                  <button type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm ">
                    FNT
                  </button>
                </Link>
                &nbsp;
                <Link
                  to={`${startelPath}?login=${app.email}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <IPhoneAlt fill={'#128186'} className={'padding-bottom-4px svg'} width="15" />
                </Link>
              </div>
            )
          }),
          applicationId: TableUtils.composeCell({
            fieldName: 'applicationId',
            fieldValue: application ? application.description : ''
          }),
          maxSubjects: TableUtils.composeCell({
            fieldName: 'maxSubjects',
            fieldValue: app.maxSubjects,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          licenseType: TableUtils.composeCell({
            fieldName: 'licenseType',
            fieldValue: app.licenseType,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          expiration: TableUtils.composeCell({
            fieldName: 'expiration',
            fieldValue: app.expiration,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerApplications, rowId, fieldName, value }),
                app.id
              )
          }),
          lastModified: TableUtils.composeCell({
            fieldName: 'lastModified',
            fieldValue: app.lastModified
          }),
          save: TableUtils.composeCell({
            fieldName: 'save',
            component: () => {
              return (
                <div className="w-100 d-flex justify-content-center">
                  <button
                    disabled={!editedIds.includes(app.id)}
                    className="btn btn-new-rel btn-sm "
                    onClick={() => {
                      onUpdate(app)
                    }}>
                    Salva
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  const onEdit = (newList = [], id) => {
    setCustomerApplications(newList)
    setRows(prepareRows(newList))
    setEditedIds([...editedIds, id])
  }

  const getCustomerApplications = (searchObject) => {
    actions.searchAdminCustomerApplications(searchObject).then((res) => {
      setCustomerApplications(res.content)
      setRows(prepareRows(res.content))
    })
  }

  const onUpdate = (customerApplication) => {
    actions.updateCustomerApplication(customerApplication.id, customerApplication).then(
      () => {
        getCustomerApplications(searchObject)
        const ids = editedIds.filter((id) => id !== customerApplication.id)
        setEditedIds(ids)
      },
      (err) => {
        PopupError({ text: err?.title || Constants.APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const onSave = (customerApplication) => {
    setLoading(true)
    actions
      .addCustomerApplication(customerApplication)
      .then(
        () => {
          PopupSuccess({ text: 'Licenza inserita' })
          getCustomerApplications(searchObject)
        },
        (err) => {
          PopupError({
            text: err?.title || Constants.APPLICATION_GENERIC_ERROR
          })
        }
      )
      .then(() => setLoading(false))
  }

  const renderTable = () => {
    return (
      <StarTable
        headerColums={header}
        rows={rows}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={(newSearchObject) => searchObject(newSearchObject)}
        searchCallBack={actions.searchAdminCustomerApplications}
        onExecutedSearch={(content) => {
          setCustomerApplications(content)
          setRows(prepareRows(content))
        }}
      />
    )
  }

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col lg={4} className={' d-flex align-items-center'}>
          <span className="me-3">
            <BwmInput
              label="Cerca tramite nome soggetto"
              value={subjectDenomination}
              onChange={(e) => {
                setSubjectDenomination(e.target.value)
              }}
            />
          </span>
          <BwmInput
            label="Cerca tramite numero SID"
            value={sidNumber}
            onChange={(e) => {
              setSidNumber(e.target.value)
            }}
          />
          <BtnFill
            classCustom={'mt-2 ms-1'}
            text={'CERCA'}
            handlerOnClick={() => {
              setSearchObject({
                ...searchObject,
                sidNumber,
                subjectDenomination
              })
              getCustomerApplications({
                ...searchObject,
                sidNumber,
                subjectDenomination
              })
            }}
          />
        </Col>
        <Col lg={5} className={'ms-auto d-flex align-items-center justify-content-end'}>
          <Link to={'/app/facciamo-noi/admin'}>
            <BtnFill text={'FACCIAMO NOI'} />
          </Link>
          <Link className="ms-2 me-2" to={'/app/cora/admin'}>
            <BtnFill text={'CORA'} />
          </Link>
          <Link to={startelPath}>
            <BtnFill text={'STAR-TEL'} />
          </Link>
        </Col>
      </Row>
      {renderTable()}
    </>
  )
}
