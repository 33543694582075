import React, { useEffect, useState } from 'react'
import { StarTable } from '../../../shared/tables/StarTable'
import { searchArcoSubjects } from '../../../../actions'
import { baseSearchObject, TableUtils } from '../../../shared/tables/TableUtils'
import { useHistory } from 'react-router-dom'

export function ArcoSubjectList(props) {
  const history = useHistory()
  const [subjects, setSubjects] = useState([])
  const [show] = useState(false)
  const [indexShow] = useState(0)
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    fiscalCode: null,
    companyName: null,
    subject: null,
    year: null
  })

  const tableUtils = TableUtils
  const tableName = 'subject-list-all'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: 'Codice fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'companyName',
      displayedName: 'Soggetto'
    }),
    tableUtils.composeHeader({
      fieldName: 'arcoSubjectType',
      displayedName: 'Tipologia'
    }),
    tableUtils.composeHeader({
      fieldName: 'location',
      displayedName: 'Località'
    })
  ]

  const [rows, setRows] = useState([])

  const showDetail = (index) => {
    if (index !== this.state.indexShow) {
      this.setState({
        show: true,
        indexShow: index
      })
    } else {
      this.setState({
        show: !this.state.show,
        indexShow: index
      })
    }
  }

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          companyName: tableUtils.composeCell({
            fieldName: 'companyName',
            fieldValue: c.companyName
          }),
          arcoSubjectType: tableUtils.composeCell({
            fieldName: 'arcoSubjectType',
            fieldValue: c.arcoSubjectType
          }),
          location: tableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.location
          })
        }
      })
    })
  }

  const onClickRow = (row) => {
    const path = `/app/arco/registrations/${row.id}`
    history.push(path)
  }

  useEffect(() => {
    setSubjects(subjects)
  }, [searchObject])

  useEffect(() => {
    setRows(prepareRows(subjects))
  }, [subjects])

  const renderTable = () => {
    return (
      <StarTable
        headerColums={header}
        rows={rows}
        isLoading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchArcoSubjects(searchObject)}
        onExecutedSearch={(content) => setSubjects(content)}
        actionsComponent={props.actionsComponent}
        onClickRow={onClickRow}
        onClickCheck={props.checkSubject}
        withCheckBoxes
        usePagination={true}
      />
    )
  }

  return <React.Fragment>{renderTable()}</React.Fragment>
}
