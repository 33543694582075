export function PageSelector(props) {
  const pageNumber = props.pageNumber || 0
  const totalPages = props.totalPages || 0
  const onClickBack = props.onClickBack
  const onClickForward = props.onClickForward

  return (
    <div
      className="d-flex justify-content-end align-items-center"
      style={{ maxWidth: '145px', marginLeft: '10px' }}>
      <button
        disabled={pageNumber <= 0}
        type="button"
        className={`p-0 btn btn-change-page ${pageNumber <= 0 && 'disabled'} `}
        onClick={() => onClickBack()}>
        <i className="thx-nav-arrow-left q-icon" />
      </button>
      <input
        type="number"
        placeholder={`${pageNumber + 1} / ${totalPages}`}
        className="star-table-page-selector"
      />
      <button
        disabled={totalPages === pageNumber + 1}
        type="button"
        className={`p-0 btn btn-change-page ${totalPages === pageNumber + 1 && 'disabled'}`}
        onClick={() => onClickForward()}>
        <i className="thx-nav-arrow-right q-icon" />
      </button>
    </div>
  )
}
