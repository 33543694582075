import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import starMulticolor from '../../styles/images/star_multicolor.png'
import menuOpen from '../../styles/images/menu_open.png'
import arrowL from '../../styles/images/arrow_l.png'
import AppBox from './AppBox'
import { CustomerBox } from './CustomerBox'
import crsNegative from '../../styles/images/app/negative/Crs.png'
import coraNegative from '../../styles/images/app/negative/Cora.png'
import comureiNegative from '../../styles/images/app/negative/Comurei.png'
import corproNegative from '../../styles/images/app/negative/Corpro.png'
import arcoNegative from '../../styles/images/app/negative/Arco.png'
import blueNegative from '../../styles/images/app/negative/Blue.png'
import facciamoNoiLogo from '../../styles/images/app/negative/FacciamoNoi.png'
import { Overlay } from 'react-bootstrap'
import { getCurrentApplicationName } from './Utility'
import authService from '../../services/auth-service'
import { FAKE_USER } from '../../config/Constants'

//regole header:
//root + /login: non mostrare
//dashboard: mostra logo, assistenza, box utente
//app senza auth: come sopra + logo app
//app con auth: come sopra + menu a tendina("appBox")
class Header extends React.Component {
  constructor(props) {
    const queryParameters = new URLSearchParams(window.location.search)
    const selectedLogin = queryParameters.get('asUser')
    if (selectedLogin) {
      authService.setHeader(FAKE_USER, selectedLogin)
    }
    super(props)
    this.state = {
      showHeader: false,
      isAdmin: false,
      isLoggedIn: false,
      hasAppBox: false,
      fakeUser: undefined,
      showAppBox: false,
      showCustomerBox: false
    }

    this.customerBoxButtonRef = React.createRef(null)
    this.appBoxButtonRef = React.createRef(null)
  }

  updateState() {
    this.setState({
      showHeader: this.props.location.pathname !== '/login' && this.props.location.pathname !== '/',
      isAdmin: this.props.auth?.isAdmin,
      isLoggedIn: this.props.auth?.isAuth,
      hasAppBox: ['cora', 'comurei', 'crs', 'corpro', 'arco', 'blue'].find((e) =>
        this.props.location.pathname.includes(e)
      ),
      fakeUser: authService.getHeader(FAKE_USER)
    })
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevprops) {
    if (prevprops !== this.props) {
      this.updateState()
    }
  }

  handleClickMenuApp = (e) => {
    if (this.props.location.pathname !== '/dashboard')
      this.setState({ showAppBox: !this.state.showAppBox })
  }

  handleClickMenuCustomer = (e) => {
    this.setState({ showCustomerBox: !this.state.showCustomerBox })
  }

  logout = (e) => {
    this.setState({ showCustomerBox: false })
    this.props.logout()
  }

  renderBrand(title) {
    switch (getCurrentApplicationName()) {
      case 'cora':
        return this.renderNavbar(coraNegative, 'CORA', title)
      case 'comurei':
        return this.renderNavbar(comureiNegative, 'COMUNEI', title)
      case 'crs':
        return this.renderNavbar(crsNegative, 'CRS', title)
      case 'corpro':
        return this.renderNavbar(corproNegative, 'CORPRO', title)
      case 'arco':
        return this.renderNavbar(arcoNegative, 'ARCO', title)
      case 'blue':
        return this.renderNavbar(blueNegative, 'BLUE', title)
      case 'facciamo-noi':
        return this.renderNavbar(facciamoNoiLogo, 'FACCIAMONOI', title)
      default:
        return (
          <ul className="navbar-nav">
            <li className="nav-item ">
              {this.state.hasAppBox && (
                <div className="m-auto">
                  <img src={menuOpen} alt={`${title}`} width="28" height="18" />
                </div>
              )}
              <Link className="navbar-brand logo-dashboard" to="/">
                <img src={starMulticolor} alt={`${title}`} width="181" />
              </Link>
            </li>
          </ul>
        )
    }
  }

  renderNavbar(logo, altText, title) {
    return (
      <ul className="navbar-nav">
        <li className="nav-item ">
          <Link className="navbar-brand logo-app" to="/">
            <img src={starMulticolor} alt={`${title}`} width="181" />
          </Link>
          <div className="m-auto">
            <div className="vertical-line me-3 "></div>
          </div>
          <div className="m-auto">
            <img src={logo} alt={altText} width="160" />
          </div>
          {this.state.hasAppBox && (
            <>
              <div
                className="m-auto margin-left-2-percent"
                role="button"
                onClick={() => this.handleClickMenuApp()}>
                <img
                  src={menuOpen}
                  alt={`${title}`}
                  width="28"
                  height="18"
                  ref={this.state.appBoxButtonRef}
                />
              </div>
            </>
          )}
          <Overlay
            target={this.appBoxButtonRef.current}
            show={this.state.showAppBox}
            placement={'bottom'}>
            {({ ...props }) => {
              return (
                <div className="app-box-overlay" {...props}>
                  <AppBox mouseLeave={this.handleClickMenuApp} />
                </div>
              )
            }}
          </Overlay>
        </li>
      </ul>
    )
  }

  renderOwnerSection(user) {
    if (this.state.isLoggedIn) {
      const displayName = user.firstName
        ? `${user.firstName} ${user.lastName || ''}`
        : user.customer.companyName
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link nav-item clickable"
              target="_blank"
              rel="noreferrer noopener"
              href={`https://www.starsoluzioni.it/assistenza-remota/`}>
              <button type="button" className="btn btn-light rounded-pill px-4 btn-xs">
                ASSISTENZA
              </button>
            </a>
          </li>
          <li className="nav-item">
            <div className="dropdown-customer">
              <a
                className="nav-link nav-item clickable"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => this.handleClickMenuCustomer()}
                ref={this.customerBoxButtonRef}>
                <span className="notranslate ">{displayName}</span>
                <img src={arrowL} width={10} height={18} className="rotate-down" />
              </a>
            </div>

            <Overlay
              target={this.customerBoxButtonRef.current}
              show={this.state.showCustomerBox}
              placement={'bottom'}>
              {({ ...props }) => {
                return (
                  <div className="customer-box-overlay" {...props}>
                    <CustomerBox logout={this.logout} mouseLeave={this.handleClickMenuCustomer} />
                  </div>
                )
              }}
            </Overlay>
          </li>
        </ul>
      )
    }
  }

  render() {
    if (this.state.showHeader) {
      const { auth } = this.props

      return (
        <nav id="header" className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <div id="navbarContent">
              {this.renderBrand('StarSoluzioni')}
              {this.state.isAdmin &&
                !this.state.fakeUser &&
                !window.location.pathname.endsWith('admin') && (
                  <Link id="admin" className="nav-link active text-white" to="/admin">
                    <button type="button" className="btn btn-light rounded-pill px-4 ">
                      DASHBOARD ADMIN
                    </button>{' '}
                  </Link>
                )}
              {this.state.fakeUser && (
                <>
                  <span>
                    <strong className="pulse text-light px-4" style={{ fontSize: '2rem' }}>
                      LOGGATO COME {this.state.fakeUser}
                    </strong>
                  </span>
                  <Link onClick={() => authService.setHeader(FAKE_USER, '')} to={'/admin'}>
                    <button type="button" className="btn btn-light rounded-pill px-4 ">
                      ESCI
                    </button>
                  </Link>
                </>
              )}
              {!this.state.fakeUser && (
                <ul className="navbar-nav ">
                  <li className="nav-item">{this.renderOwnerSection(auth.user)}</li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      )
    } else {
      return ''
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(Header))
