import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import { ApplicationList } from './ApplicationList'
import { PopupError } from '../shared/PopupError'
import { PopupSuccess } from '../shared/PopupSuccess'

import '../../styles/dashboard.css'
import authService from '../../services/auth-service'
import { FAKE_USER } from '../../config/Constants'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.getApplication()
  }

  getApplication = () => {
    this.props.dispatch(actions.getApplicationsByCustomer())
    this.props.dispatch(actions.getAccount())
  }

  activateDemo(applicationName) {
    actions.activateDemoForApplication(applicationName).then(
      (res) => {
        if (res) {
          PopupSuccess({ text: 'Demo attivata' })
        } else {
          PopupError({ ...this.props, text: "Errore nell'attivazione della demo" })
        }
      },
      (errors) => {
        PopupError({ ...this.props, text: "Errore nell'attivazione della demo " + errors })
      }
    )
    this.props.dispatch(actions.getApplicationsByCustomer())
  }

  render() {
    let { applications } = this.props
    applications = applications.filter((app) => app?.application?.enabled)
    const applicationsOther = applications.filter(
      (app) => app?.application?.category !== 'ALTRE_COMUNICAZIONI'
    )
    const applicationsOtherCommunications = applications.filter(
      (app) => app?.application?.category === 'ALTRE_COMUNICAZIONI'
    )
    return (
      <div className="dashboard">
        <ApplicationList getApplication={this.getApplication} applications={applicationsOther} />
        <ApplicationList
          getApplication={this.getApplication}
          applications={applicationsOtherCommunications}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    applications: state.applications.data
  }
}

export default connect(mapStateToProps)(Dashboard)
