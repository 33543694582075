import { useState, useEffect } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import * as Constants from '../../../../config/Constants'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'

registerLocale('it', it)

export function PersonTypeField(props) {
  const [personType, setPersonType] = useState(props.personType)
  const [error, setError] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    setPersonType(props.personType)
  }, [props.personType])

  useEffect(() => {
    if (props.error !== undefined) {
      setError(props.error)
    }
  }, [props.error])

  return (
    <>
      <div className="form-check mb-3">
        <label>Tipo</label>
        <div className="input-group mt-2 d-flex flex-column">
          <div className="inputRadio mt-1 mb-4 ">
            <input
              hidden={true}
              className="form-check-input"
              type="radio"
              name={`personType${props.id}`}
              id={`personType1${props.id}`}
              value={Constants.PNF}
              onClick={props.onClick}
              disabled={props.readOnly}
            />
            <label className="form-check-label" htmlFor={`personType1${props.id}`}>
              <img
                alt={''}
                width="20"
                className="me-3"
                src={personType === Constants.PNF ? radioSel : radioUnsel}
              />
              Persona giuridica
            </label>
          </div>
          <div className="inputRadio">
            <input
              hidden={true}
              className="form-check-input"
              type="radio"
              name={`personType${props.id}`}
              id={`personType2${props.id}`}
              value={Constants.PF}
              onClick={props.onClick}
              disabled={props.readOnly}
            />
            <label className="form-check-label" htmlFor={`personType2${props.id}`}>
              <img
                alt={''}
                width="20"
                className="me-3"
                src={personType === Constants.PF ? radioSel : radioUnsel}
              />
              Persona Fisica
            </label>
          </div>
        </div>
        {!error.isValid && <div className="text-danger">{error.msg}</div>}
      </div>
    </>
  )
}
