import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as ICheck } from '../../../styles/images/svg/check.svg'
import { ReactComponent as ITrash } from '../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as ISearch } from '../../../styles/images/svg/search-solid.svg'
import { BwmInput } from '../../shared/form/BwmInputB5'
import { DateField } from '../../shared/form/DateFieldB5'
import { ReactComponent as ITextFile } from '../../../styles/images/svg/file-alt.svg'
import Modal from 'react-bootstrap/Modal'
import BlueHelpModal from './BlueHelpModal'
import { PopupError } from '../../shared/PopupError'
import moment from 'moment'
import BlueFileImportModal from './BlueFileImportModal'
import getText from './labels'

export default function BlueSearchForm(props) {
  const [isGenericFormDisabled, setGenericFormDisabled] = useState(false)
  const [isSpecificFormDisabled, setSpecificFormDisabled] = useState(false)
  const [genericSearchValue, setGenericSearchValue] = useState('')
  const [fullName, setFullName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthDate, setBirthDate] = useState(null)
  const [birthLocation, setBirthLocation] = useState('')
  const [passportNumber, setPassportNumber] = useState('')
  const [citizenship, setCitizenShip] = useState('')
  const [precision, setPrecision] = useState('3')
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)
  const emptyEntity = {
    generic: '',
    wholeName: '',
    firstName: '',
    lastName: '',
    birthDate: null,
    birthLocation: '',
    passportNumber: '',
    citizenCountry: ''
  }
  const [formData, setFormData] = useState({
    subject: props.subject?.denomination,
    precision: '3',
    language: lang,
    entities: []
  })
  const [searchEntity, setSearchEntity] = useState(emptyEntity)
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showMassiveSearchModal, setShowMassiveSearchModal] = useState(false)

  const handleModalClick = () => {
    setShowHelpModal(true)
  }

  const closeModal = () => {
    setShowHelpModal(false)
    setShowMassiveSearchModal(false)
  }

  useEffect(() => {
    if (genericSearchValue) {
      setSpecificFormDisabled(true)
    } else {
      setSpecificFormDisabled(false)
    }
    if (
      fullName ||
      firstName ||
      lastName ||
      birthDate ||
      birthLocation ||
      passportNumber ||
      citizenship
    ) {
      setGenericFormDisabled(true)
    } else if (
      !fullName &&
      !firstName &&
      !lastName &&
      !birthDate &&
      !birthLocation &&
      !passportNumber &&
      !citizenship
    ) {
      setGenericFormDisabled(false)
    }
  }, [searchEntity])

  useEffect(() => {
    setFormData({
      ...formData,
      language: lang
    })
  }, [lang])

  useEffect(() => {
    if (isGenericFormDisabled) {
      setFormData({
        ...formData,
        precision: precision
      })
    } else if (isSpecificFormDisabled) {
      setFormData({
        ...formData,
        precision: '3'
      })
    }
  }, [isGenericFormDisabled, isSpecificFormDisabled])

  useEffect(() => {
    setFormData({
      ...formData,
      subject: props.subject?.denomination
    })
  }, [props.subject])

  const handlerChangeGenericSearchValue = (e) => {
    setGenericSearchValue(e.target.value)
    setSearchEntity({
      ...searchEntity,
      generic: e.target.value
    })
    setSpecificFormDisabled(true)
  }

  const handlerChangeFullName = (e) => {
    setFullName(e.target.value)
    setSearchEntity({
      ...searchEntity,
      wholeName: e.target.value
    })
  }

  const handlerChangeFirstName = (e) => {
    setFirstName(e.target.value)
    setSearchEntity({
      ...searchEntity,
      firstName: e.target.value
    })
  }
  const handlerChangeLastName = (e) => {
    setLastName(e.target.value)
    setSearchEntity({
      ...searchEntity,
      lastName: e.target.value
    })
  }

  const handlerChangeBirthDate = (val) => {
    let formatted = moment(val, 'dd MM Do YYYY, h:mm:ss', true).format('YYYY-MM-DD')
    setBirthDate(val)
    setSearchEntity({
      ...searchEntity,
      birthDate: formatted.toString() !== 'Invalid date' ? formatted.toString() : ''
    })
  }

  const handleChangeBirthLocation = (e) => {
    setBirthLocation(e.target.value)
    setSearchEntity({
      ...searchEntity,
      birthLocation: e.target.value
    })
  }

  const handleChangeCitizienShip = (e) => {
    setCitizenShip(e.target.value)
    setSearchEntity({
      ...searchEntity,
      citizenCountry: e.target.value
    })
  }

  const handleChangePassportNumber = (e) => {
    setPassportNumber(e.target.value)
    setSearchEntity({
      ...searchEntity,
      passportNumber: e.target.value
    })
  }

  const handlePrecisionClick = (e) => {
    setPrecision(e.target.value)
    setFormData({
      ...formData,
      precision: e.target.value
    })
  }

  const handleMassiveSearchClick = () => {
    setShowMassiveSearchModal(true)
  }

  const clearSpecificFormData = () => {
    setGenericSearchValue('')
    setFullName('')
    setFirstName('')
    setLastName('')
    setBirthDate(null)
    setBirthLocation('')
    setCitizenShip('')
    setPassportNumber('')
    setSearchEntity(emptyEntity)
    setPrecision('3')
  }

  const validateSearchForms = () => {
    if (
      !fullName &&
      !firstName &&
      !lastName &&
      !birthDate &&
      !birthLocation &&
      !passportNumber &&
      !citizenship &&
      !genericSearchValue
    ) {
      PopupError({
        text: labels.SEARCHEMPTYFORM,
        title: labels.WARNING,
        request: labels.CONTACTSUPPORT,
        close: labels.CLOSE
      })
      return false
    }
    return true
  }

  const executeSearch = () => {
    if (validateSearchForms()) {
      props.executeSearch({
        ...formData,
        entities: [searchEntity]
      })
    }
  }

  return (
    <div className="col-lg-12 ps-3">
      <div className={`row ${isGenericFormDisabled || isSpecificFormDisabled ? 'hidden' : ''}`}>
        <div className="d-flex justify-content-center">
          <button
            onClick={() => handleMassiveSearchClick()}
            className="btn btn-primary btn-new-rel px-5">
            <ITextFile className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
            &nbsp; {labels.MASSIVE}
          </button>
        </div>
      </div>

      <div className={isGenericFormDisabled ? 'hidden' : undefined}>
        <div
          className={`row mt-4 mb-2 ${(isGenericFormDisabled || isSpecificFormDisabled) && 'hidden'}`}>
          <div className="col-12 text-center">
            <h6 className="line-around">
              <span>{labels.OR}</span>
            </h6>
          </div>
        </div>
        {isSpecificFormDisabled && (
          <div>
            {labels.GENERICTEXT[0]} <b>{labels.GENERICTEXT[1]}</b> {labels.GENERICTEXT[2]}{' '}
            <b>{labels.GENERICTEXT[3]}</b>
          </div>
        )}
        <div className="d-flex">
          <div className="col-12 text-center">
            <BwmInput
              placeholder={labels.GENERICSEARCH}
              name="generic-search"
              label={labels.GENERICSEARCH}
              className="form-control"
              value={genericSearchValue}
              onChange={(e) => handlerChangeGenericSearchValue(e)}
              disabled={isGenericFormDisabled}
            />
          </div>
        </div>
      </div>
      <div className={isSpecificFormDisabled ? 'hidden' : undefined}>
        <div
          className={`row mt-4 mb-2 ${isGenericFormDisabled || isSpecificFormDisabled ? 'hidden' : undefined}`}>
          <div className="col-12 text-center">
            <h6 className="line-around">
              <span>{labels.OR}</span>
            </h6>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-12 text-center ">
            <BwmInput
              name="full-name"
              placeholder={labels.WHOLENAME}
              label={labels.WHOLENAME}
              className="form-control"
              value={fullName}
              onChange={(e) => handlerChangeFullName(e)}
              disabled={isSpecificFormDisabled}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="col-6 pe-2 mt-3 text-center">
            <BwmInput
              name="first-name"
              placeholder={labels.FIRSTNAME}
              label={labels.FIRSTNAME}
              className="form-control"
              value={firstName}
              onChange={(e) => handlerChangeFirstName(e)}
              disabled={isSpecificFormDisabled}
            />
          </div>
          <div className="col-6 mt-3 text-center">
            <BwmInput
              name="last-name"
              placeholder={labels.LASTNAME}
              label={labels.LASTNAME}
              className="form-control"
              value={lastName}
              onChange={(e) => handlerChangeLastName(e)}
              disabled={isSpecificFormDisabled}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="col-6 pe-2 mt-3 text-center date-field-label">
            <DateField
              placeholder={labels.BIRTHDATE}
              label={labels.BIRTHDATE}
              onChange={(date) => handlerChangeBirthDate(date)}
              date={birthDate}
              name={'birth-date'}
              isDisabled={isSpecificFormDisabled}
            />
          </div>
          <div className="col-6 mt-3 text-center">
            <BwmInput
              placeholder={labels.BIRTHPLACE}
              label={labels.BIRTHPLACE}
              className="form-control"
              onChange={(e) => handleChangeBirthLocation(e)}
              date={birthLocation}
              name={'birth-location'}
              disabled={isSpecificFormDisabled}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="col-6 pe-2 mt-3 text-center">
            <BwmInput
              placeholder={labels.PASSPORTNUMBER}
              label={labels.PASSPORTNUMBER}
              className="form-control"
              onChange={(e) => handleChangePassportNumber(e)}
              value={passportNumber}
              disabled={isSpecificFormDisabled}
            />
          </div>
          <div className="col-6 mt-3 text-center">
            <BwmInput
              placeholder={labels.CITIZENCOUNTRY}
              label={labels.CITIZENCOUNTRY}
              className="form-control"
              onChange={(e) => handleChangeCitizienShip(e)}
              value={citizenship}
              disabled={isSpecificFormDisabled}
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-between mt-3">
        <div className={`col-lg-9`}>
          <div className={isSpecificFormDisabled ? 'hidden' : undefined}>
            <div className="col-6 text-start ps-0">
              <h6 className="green-label">{labels.PRECISION}</h6>
            </div>
            <div
              className="btn-group-toggle col-12 d-flex justify-content-start align-items-center gap-2 ps-0"
              data-toggle="buttons">
              <input
                hidden={true}
                type="radio"
                name="verylowprecisionbtn"
                defaultChecked={precision === '0'}
                id="verylowprecisionbtn"
                value="0"
                onClick={handlePrecisionClick}
              />
              <label
                className="btn btn-outline-primary btn-empty col-3 px-3 pe-2"
                htmlFor="verylowprecisionbtn">
                {labels.LOWEST}&nbsp;
                {precision === '0' && (
                  <ICheck className="padding-right-4px svg" width="16" fill="#128186" />
                )}
              </label>

              <input
                hidden={true}
                type="radio"
                name="lowprecision"
                defaultChecked={precision === '1'}
                id="lowprecisionbtn"
                value="1"
                onClick={handlePrecisionClick}
              />
              <label
                className="btn btn-outline-primary btn-empty col-3 px-3"
                htmlFor="lowprecisionbtn">
                {labels.LOW}&nbsp;
                {precision === '1' && (
                  <ICheck className="padding-right-4px svg" width="16" fill="#128186" />
                )}
              </label>

              <input
                hidden={true}
                type="radio"
                name="mediumprecision"
                defaultChecked={precision === '2'}
                id="mediumprecisionbtn"
                value="2"
                onClick={handlePrecisionClick}
              />
              <label
                className="btn btn-outline-primary btn-empty col-3 px-3 ms-2 pe-2"
                htmlFor="mediumprecisionbtn">
                {labels.MEDIUM}&nbsp;
                {precision === '2' && (
                  <ICheck className="padding-right-4px svg" width="16" fill="#128186" />
                )}
              </label>

              <input
                hidden={true}
                type="radio"
                name="highprecision"
                defaultChecked={precision === '3'}
                id="highprecisionbtn"
                value="3"
                onClick={handlePrecisionClick}
              />
              <label
                className="btn btn-outline-primary btn-empty col-3 px-3 me-5"
                htmlFor="highprecisionbtn">
                {labels.HIGH}&nbsp;
                {precision === '3' && (
                  <ICheck className="padding-right-4px svg" width="16" fill="#128186" />
                )}
              </label>

              <button
                className="btn rounded-circle btn-outline-primary btn-empty col-1  ms-3"
                onClick={() => handleModalClick()}>
                ?
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`row mt-4 mb-2 ${(isGenericFormDisabled || isSpecificFormDisabled) && 'hidden'}`}>
        <div className="col-12 text-center">
          <h6 className="line-around"></h6>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3" id="blue-buttons-search">
        <div className="btngroup col-5 pe-4" aria-label="Precisione">
          <button
            className="btn btn-outline-primary btn-empty w-100"
            onClick={() => clearSpecificFormData()}>
            <ITrash className={'padding-right-4px svg'} width="16" fill={` ${'#128186'}`} />{' '}
            {labels.CLEAR}
          </button>
        </div>
        <div className="btngroup col-5" aria-label="Precisione">
          <button
            className="btn btn-primary btn-new-rel px-3 w-100"
            onClick={() => executeSearch()}>
            <ISearch className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />{' '}
            {labels.SEARCH}
          </button>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={showHelpModal}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.HELPTITLE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueHelpModal />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={showMassiveSearchModal}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.MASSIVEPOPUPTITLE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueFileImportModal
            closeModalExt={closeModal}
            subject={props.subject}
            precision={precision}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
