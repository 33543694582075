import { IDelete } from '../../styles/icons'

export function BtnDelete(props) {
  const { handlerOnClick, isDisabled } = props
  return (
    <button
      type="button"
      className="btn btn-empty px-4  btn-sm "
      onClick={handlerOnClick}
      disabled={isDisabled}>
      <IDelete
        className={'padding-right-4px'}
        width="16"
        fill={` ${isDisabled ? '#FFFFFF' : '#128186'}`}
        alt={'Elimina'}
      />{' '}
      Elimina
    </button>
  )
}
