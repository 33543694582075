import { useEffect, useRef, useState } from 'react'
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@table-library/react-table-library/table'
import Sortable from 'sortablejs'

import { useRowSelect, SelectClickTypes } from '@table-library/react-table-library/select'
import { useTheme } from '@table-library/react-table-library/theme'
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui'
import './starTable.css'
import { TableUtils, baseSearchObject, fieldTypes } from './TableUtils'
import NumberFormat from 'react-number-format'
import { StarCell } from './StarCell'
import { getTableStorage } from './TableStorage'
import { DropdownMultipleSelect } from '../form/lists/DropdownMultipleSelect'
import { generateUniqueKey } from '../Utility'
import { StarCheckbox } from '../form/input-boxes/StarCheckbox'
import { ISortDown, ISortUp, ISearch } from '../../../styles/icons'
import { StarTableTop } from './StarTableTop'
import { TableSkeleton } from './StarTableSkeleton'

/**
 * Componente multifunzione per liste di elementi.
 *
 * per forzare un reload della lista(ad es in caso di salvataggi di nuove entità), ricalcolare la key del componente
 * @param {*} param0
 * @returns
 */
export function StarTable({
  headerComponent = undefined,
  headerColums = [], //colonne header componibili tramite TableUtils.composeHeader()
  checkedItems = [],
  language = null,
  rows = [], //righe componibili tramite TableUtils.composeRow(TableUtils.composeCell())
  withCheckBoxes = false, //indica se le righe sono selezionabili
  onClickCheck = (ids) => {}, //callback su click dei checkbox, passa la lista di id selezionati
  loading = false, //indica se mostrare lo spinner
  tableConfigurationKey = '', //chiave localStorage della configurazione della tabella, per determinare ad es. quali colonne mostrare
  searchCallBack, //callback di ricerca
  onExecutedSearch, //funzione eseguita a ricerca completata, fornisce il contenuto della ricerca
  searchObjectPrototype: searchObject = {}, //searchObject di base. Una copia viene conservata al montaggio per riportare la lista allo stato iniziale
  setSearchObject = (searchObjet) => {}, //setter per searchObject
  usePagination = true,
  dialogComponent = null,
  actionsComponent = null,
  perPageOptions = null,
  setId = null,
  onClickRow = null,
  formComponent: DynamicFormComponent,
  formComponentProps = {},
  additionalHeaderProps = {},
  rowClass = (rowData) => '',
  offset = 245
}) {
  const tableConfiguration = tableConfigurationKey ? getTableStorage(tableConfigurationKey) : null
  const [hiddenColumns, setHiddenColumns] = useState(
    tableConfiguration ? tableConfiguration?.getHiddenColumns() : []
  )
  const [columns, setColumns] = useState(headerColums)
  const [isLoading, setIsLoading] = useState(false)
  const [defaultSort, setDefaultSort] = useState('id')
  const [tableKey, setTableKey] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [initialSearchObject, setInitialSearchObject] = useState({}) //oggetto di ricerca iniziale
  const [searchTerms, setSearchTerms] = useState({}) //oggetto che contiene i termini di ricerca tramite header
  const [headerSizes, setHeaderSizes] = useState('')
  const [hideFilters, setHideFilters] = useState(true)

  const timeout = useRef()
  const tableRef = useRef(null)

  const showDetail = (row) => {
    if (setId) setId(row.id)
    if (onClickRow) onClickRow(row)
  }

  //aggiunge righe per raggiungere l'altezza minima della tabella
  const prepareRows = () => {
    let rule = ''
    rows.forEach(() => {
      rule += 'auto '
    })
    const minimunRows = 15

    for (let index = rows.length; index < minimunRows; index++) {
      rule += '1fr '
    }

    return rule
  }

  useEffect(() => {
    // Imposta la variabile CSS iniziale
    handleResize()

    // Aggiunge l'evento resize
    window.addEventListener('resize', handleResize)

    // Rimuove l'evento al termine del ciclo di vita del componente
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    handleResize()
  }, [rows, isLoading, loading, hideFilters])

  useEffect(() => {
    if (hiddenColumns?.length === columns?.length) onClickCheck([])
  }, [hiddenColumns])

  const handleResize = () => {
    const newHeight = window.innerHeight - offset
    document.documentElement.style.setProperty('--star-table-height', `${newHeight}px`)
  }

  const theme = useTheme([
    getTheme({
      ...DEFAULT_OPTIONS,
      striped: true
    }),
    {
      Cell: `height: 32px;
    padding: 5px!important;
    &:not(:last-of-type) {
      border-right: 1px solid #a0a8ae;
    }
    `,
      Row: `height: 32px`,
      Header: `height: 40px;`,
      HeaderCell: `
    max-height:75px;
    padding: 0 !important;
    .table th {padding: 0 5px 0 5px};
    &:not(:last-of-type) {
      border-right: 1px solid #a0a8ae;
    }`,
      Table: `--data-table-library_grid-template-columns: ${headerSizes}; 
    grid-template-rows: ${prepareRows()};
    grid-gap:0px;
    `
    }
  ])

  /**
   * utilizzato alla modifica dei campi in header
   * @param {*} columnName
   * @param {*} query
   */
  const onChangeSearch = (columnName, query) => {
    let newSearchObject = { ...searchObject }
    newSearchObject[columnName] = query
    newSearchObject.page = 0
    clearTimeout(timeout.current)
    setSearchObject(newSearchObject)
    setSearchTerms(TableUtils.getCleanSearchObject(newSearchObject))
    timeout.current = setTimeout(() => {
      executeSearch(newSearchObject)
    }, 500)
  }

  const onChangePage = (newSearchObject) => {
    executeSearch(newSearchObject)
    setSearchObject(newSearchObject)
  }

  useEffect(() => {
    if (language) setColumns(headerColums)
  }, [headerColums])

  //esegue ricerca iniziale, e conserva il searchObject iniziale
  useEffect(() => {
    setInitialSearchObject(searchObject)

    if (tableConfiguration) {
      /*       tableConfiguration.setSearchObjectIfNotPresent(searchObject);
      searchObject = tableConfiguration.getSearchObject() */
      setColumns(TableUtils.updateHeadersOrder(columns, tableConfiguration.getColumnOrder()))
    }
    if (tableConfiguration?.getColumnSizes()) {
      setHeaderSizes(tableConfiguration.getColumnSizes())
    } else {
      setHeaderSizes(
        TableUtils.prepareHeaderSizes(columns, hiddenColumns, withCheckBoxes, tableConfiguration)
      )
    }
    executeSearch(searchObject)
    setSearchTerms(searchObject)
    setDefaultSort(searchObject.sortField)

    setTableKey(generateUniqueKey(tableConfigurationKey))

    //eventi di controllo per funzioni alternative
    const handleKeyDown = (event) => {}
    const handleKeyUp = (event) => {}

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    //cleanup degli eventi
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  //determina se ci sono termini di ricerca da resettare
  useEffect(() => {
    let canBeReset = false

    for (const [key, value] of Object.entries(searchTerms)) {
      if (!canBeReset) {
        const searchObjectKey = initialSearchObject[key]
        canBeReset = searchObjectKey !== value
        //due array vuoti sono sempre diversi, inoltre è necessario verificarne gli elementi
        if (
          canBeReset &&
          Array.isArray(value) &&
          Array.isArray(searchObjectKey) &&
          value.length === searchObjectKey.length
        ) {
          if (value.length === 0) {
            canBeReset = false
          } else {
            canBeReset = !searchObjectKey.every((element, index) => element === value[index])
          }
        }
      }
    }
  }, [searchObject])

  useEffect(() => {
    if (tableConfiguration) {
      tableConfiguration.setHiddenColumns(hiddenColumns)
      tableConfiguration.setColumnOrder(columns)
    }
    setTableKey(generateUniqueKey(tableConfigurationKey))
  }, [columns])

  const executeSearch = (searchObject) => {
    setIsLoading(true)
    searchCallBack &&
      searchCallBack(searchObject).then((res) => {
        /*  if (tableConfiguration) {
        tableConfiguration.setSearchObject(searchObject);
      } */
        onExecutedSearch(res?.content || res?.data, res)
        setSearchObject({
          ...searchObject,
          totalElements: res.totalElements
        })
        if (usePagination) setTotalPages(res?.totalPages)
        setIsLoading(false)
      })
  }

  const data = { nodes: rows }

  const checkableDataRows = { nodes: data?.nodes.filter((node) => node.checkable === true) }
  const select = useRowSelect(
    checkableDataRows,
    {
      state: { ids: checkedItems },
      onChange: (action, state) => {
        onClickCheck(state?.ids)
      }
    },
    { clickType: SelectClickTypes.ButtonClick }
  )

  const resize = { minWidth: 45, resizerHighlight: '#a6a6a6', resizerWidth: 5 }

  const composeCell = (rowId, data, index, columnIndex) => {
    return (
      <StarCell
        key={`starcell-${rowId}-${index} `}
        rowId={rowId}
        data={data}
        index={index}
        headerColums={columns}
        hiddenColumns={hiddenColumns}
        columnIndex={columnIndex}
      />
    )
  }

  const handleLayoutChange = (widths) => {
    if (tableConfiguration) {
      const table = document.getElementById('star-table')
      tableConfiguration.setColumnSizes(
        table.style.getPropertyValue('--data-table-library_grid-template-columns')
      )
    }
  }

  const renderSortButtons = (searchObject, h, defaultSort) => {
    const size = 17
    const orderingName = h.orderingName || h.fieldName
    return (
      <span>
        {searchObject.sortField === orderingName && (
          <>
            {searchObject.sortDirection === 'DESC' ? (
              <ISortDown
                width={size}
                className={'pointer float-end mb-1'}
                onClick={() => sort(searchObject, h, defaultSort)}
              />
            ) : (
              <ISortUp
                width={size}
                className={'pointer float-end mb-1'}
                onClick={() => sort(searchObject, h, defaultSort)}
              />
            )}
          </>
        )}
      </span>
    )
  }

  const sort = (searchObject, h, defaultSort) => {
    const orderingName = h.orderingName || h.fieldName
    // l'utente clicca un campo diverso da quello già ordinato
    if (searchObject.sortField !== orderingName) {
      onChangePage({ ...searchObject, sortField: orderingName, sortDirection: 'ASC' })
    } else {
      if (searchObject.sortDirection === 'DESC') {
        onChangePage({ ...searchObject, sortField: defaultSort, sortDirection: 'DESC' })
      } else {
        onChangePage({ ...searchObject, sortField: orderingName, sortDirection: 'DESC' })
      }
    }
  }

  const sorting = () => {
    const element = tableRef.current.querySelector('thead tr:nth-of-type(1)')
    if (!element) return
    Sortable.create(element, {
      filter: '.checkbox-column',
      onEnd(event) {
        let oldIndex, newIndex
        if (withCheckBoxes) {
          oldIndex = event.oldIndex - 1
          newIndex = event.newIndex - 1
        } else {
          oldIndex = event.oldIndex
          newIndex = event.newIndex
        }
        const newColumns = [...columns]
        const element = newColumns.splice(oldIndex, 1)[0]
        newColumns.splice(newIndex, 0, element)
        setColumns(newColumns)
      },
      onMove: function (evt) {
        if (evt.related.className.includes('checkbox-column')) {
          return false
        }
      }
    })
  }

  return (
    <div className="star-table">
      <div className="d-flex py-1 star-table-top-container">
        <StarTableTop
          actionsComponent={actionsComponent}
          columns={columns}
          hiddenColumns={hiddenColumns}
          searchObject={searchObject}
          totalPages={totalPages}
          tableConfiguration={tableConfiguration}
          tableConfigurationKey={tableConfigurationKey}
          withCheckBoxes={withCheckBoxes}
          perPageOptions={perPageOptions}
          setColumns={setColumns}
          usePagination={usePagination}
          setHiddenColumns={setHiddenColumns}
          setHeaderSizes={setHeaderSizes}
          setTableKey={setTableKey}
          onChangePage={onChangePage}
        />
        {headerComponent}
      </div>
      <div className=" d-flex flex-column m-auto">
        <div className="star-table-container">
          {dialogComponent}

          <Table
            id="star-table"
            key={tableKey}
            theme={theme}
            className="star-table-table"
            data={data}
            select={select}
            ref={tableRef}
            layout={{
              custom: true,
              fixedHeader: true,
              onLayoutChange: handleLayoutChange
            }}>
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    {withCheckBoxes && (
                      <HeaderCell
                        hide={hiddenColumns?.length === columns?.length}
                        resize={resize}
                        pinLeft
                        className="checkbox-column table-header">
                        <StarCheckbox
                          checked={select?.state?.all}
                          indeterminate={!select?.state?.all && !select?.state?.none}
                          onChange={select?.fns?.onToggleAll}
                          ariaLabel={'Seleziona tutti i visibili'}
                        />
                      </HeaderCell>
                    )}
                    {columns.map((h, index) => {
                      const component = h?.component(
                        sort,
                        searchObject,
                        h,
                        defaultSort,
                        additionalHeaderProps
                      )
                      return (
                        <HeaderCell
                          key={`header - ${index} `}
                          className="table-header"
                          resize={resize}
                          hide={TableUtils.isHiddenColumn(hiddenColumns, h)}
                          pinRight={h.pinRight}
                          pinLeft={h.pinLeft}
                          onMouseEnter={sorting}>
                          <div>
                            <div
                              className="d-flex flex-column justify-content-center w-100"
                              style={{ cursor: 'grab' }}>
                              <div className={`star-table-header ${h.additionalClass}`}>
                                <div className="d-flex">
                                  <div className="mr-2">
                                    {h.sortable && renderSortButtons(searchObject, h, defaultSort)}
                                  </div>
                                  {!component ? (
                                    <span
                                      onClick={() =>
                                        h.sortable ? sort(searchObject, h, defaultSort) : {}
                                      }
                                      className={h.sortable ? 'cursor-pointer' : ''}>
                                      {h.displayedName}
                                    </span>
                                  ) : (
                                    component
                                  )}
                                </div>
                                {h.searchable && (
                                  <div className="mx-2 mt-1">
                                    <ISearch
                                      width="17"
                                      className={'pointer float-end mb-1'}
                                      onClick={() => {
                                        setHideFilters(!hideFilters)
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              <div
                                className="star-table-searchfield"
                                style={hideFilters ? { display: 'None' } : {}}>
                                {h.searchable && (
                                  <TableSearchField
                                    type={h.type}
                                    fieldName={h.fieldName}
                                    onChange={(value) => onChangeSearch(h.fieldName, value)}
                                    options={h.options}
                                    value={searchObject[h.fieldName]}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </HeaderCell>
                      )
                    })}
                  </HeaderRow>
                </Header>
                <Body>
                  {(isLoading || loading) && (
                    <TableSkeleton
                      colCount={baseSearchObject.pageSize}
                      rowsCount={
                        withCheckBoxes
                          ? columns.length - hiddenColumns.length + 1
                          : columns.length - hiddenColumns.length
                      }
                    />
                  )}

                  {!isLoading &&
                    !isLoading &&
                    hiddenColumns?.length !== columns?.length &&
                    rows?.length === 0 && (
                      <div className="empty-state">
                        <i
                          className="thx-warning-triangle thx-warning-triangle-grey q-icon"
                          style={{ width: '56px', height: '56px' }}
                        />
                        <h4>Non ci sono risultati da mostrare</h4>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => {
                            executeSearch({
                              ...initialSearchObject,
                              totalElements: searchObject.totalElements,
                              pageSize: searchObject.pageSize
                            })
                          }}>
                          Resetta tutti i filtri
                        </button>
                      </div>
                    )}

                  {!isLoading &&
                    !loading &&
                    columns?.length > 0 &&
                    hiddenColumns?.length === columns?.length && (
                      <div className="empty-state">
                        <i
                          className="thx-table thx-table-grey q-icon"
                          style={{ width: '56px', height: '56px' }}
                        />
                        <h4>Nessuna colonna selezionata</h4>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => {
                            tableConfiguration.cleanSearchObject()
                            window.location.reload(false)
                          }}>
                          Ripristina
                        </button>
                      </div>
                    )}

                  {!isLoading &&
                    !loading &&
                    columns?.length > 0 &&
                    hiddenColumns?.length !== columns?.length &&
                    tableList.map((row, index) => {
                      const rowData = row.cellData
                      const id = row.id
                      return (
                        <Row
                          key={'star-row-' + id}
                          item={rowData}
                          style={isLoading || loading ? { display: 'None' } : {}}
                          className={`${row.additionalClass} ${rowClass(row.rowData)}`}
                          onClick={() => showDetail(row)}>
                          {/*disegno la checkbox solo se prevista*/}
                          {withCheckBoxes && row.checkable && (
                            <Cell pinLeft item={row} className="checkbox-column">
                              <StarCheckbox
                                checked={select.state.ids.includes(id)}
                                onChange={() => {
                                  select.fns.onToggleById(id)
                                }}
                                ariaLabel={'Seleziona elemento'}
                              />
                            </Cell>
                          )}
                          {withCheckBoxes && !row.checkable && (
                            <Cell pinLeft item={row} className="checkbox-column" />
                          )}
                          {
                            //per preservare l'ordine delle celle e delle colonne, viene eseguito un doppio ciclo
                            !isLoading &&
                              !loading &&
                              columns.map((column, columnIndex) => {
                                return Object.entries(rowData).map((e, index) => {
                                  if (e[1]?.fieldName === column.fieldName) {
                                    return composeCell(id, e, index, columnIndex)
                                  }
                                })
                              })
                          }
                        </Row>
                      )
                    })}
                </Body>
              </>
            )}
          </Table>
        </div>
      </div>
      {DynamicFormComponent && (
        <DynamicFormComponent
          {...formComponentProps}
          paginationComponent={
            <StarTableTop
              actionsComponent={actionsComponent}
              columns={columns}
              hiddenColumns={hiddenColumns}
              searchObject={searchObject}
              totalPages={totalPages}
              tableConfiguration={tableConfiguration}
              tableConfigurationKey={tableConfigurationKey}
              withCheckBoxes={withCheckBoxes}
              setColumns={setColumns}
              setHiddenColumns={setHiddenColumns}
              setHeaderSizes={setHeaderSizes}
              setTableKey={setTableKey}
              onChangePage={onChangePage}
            />
          }
        />
      )}
    </div>
  )
}

function TableSearchField({
  type = fieldTypes.TEXT,
  onChange = () => {},
  options = [{ id: null, description: '' }],
  value = ''
} = {}) {
  if ([fieldTypes.DATE, fieldTypes.TEXT, fieldTypes.NUMBER].includes(type)) {
    return (
      <input
        type={
          [fieldTypes.DATE, fieldTypes.TEXT].includes(type) ? fieldTypes.TEXT : fieldTypes.NUMBER
        }
        onChange={(e) => onChange(e.target.value)}
        className="cell-input"
        placeholder="Cerca..."
        value={value || ''}
      />
    )
  } else if (fieldTypes.CURRENCY === type) {
    return (
      <NumberFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={0}
        fixedDecimalScale={true}
        className="cell-input"
        inputMode="numeric"
        onValueChange={(value) => onChange(value.floatValue)}
        value={value}
      />
    )
  } else if (fieldTypes.SELECTION === type) {
    return (
      <DropdownMultipleSelect
        options={options}
        onSelectionChange={(options) => onChange(options.map((o) => o.id))}
        value={options.filter((o) => {
          if (value && value.length > 0) {
            return value.includes(o.id)
          }
          return false
        })}
      />
    )
  }
}
