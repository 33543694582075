import '../../../../styles/arcoTablesModal.css'

export function ArcoProfessionists(props) {
  const renderRows = (professionists) => {
    return professionists.map((professionist) => {
      const idCheckbox = `checkboxProfessionistDetails${professionist.id}`
      return (
        <div
          key={`professionist-${professionist.id}`}
          className={'row text-start row-table'}
          id={`professionist-${professionist.id}`}>
          <div className="col-lg-1 text-center div-td">
            <input
              type="checkbox"
              id={idCheckbox}
              onClick={(e) => props.onCheckBoxClick(e, professionist)}
            />
          </div>
          <div
            className={`col-lg-1 text-center div-td`}
            onClick={(e) => props.onClickCompileUpdate(e, professionist)}>
            {professionist.code}
          </div>
          <div
            className="col-lg-7 text-center div-td text-truncate"
            onClick={(e) => props.onClickCompileUpdate(e, professionist)}>
            {professionist.name}
          </div>
          <div
            className="col-lg-1 text-center div-td text-truncate text-center"
            onClick={(e) => props.onClickCompileUpdate(e, professionist)}>
            {professionist.disabled ? 'SI' : 'NO'}
          </div>
          <div
            className="col-lg-2 text-center div-td text-truncate"
            onClick={(e) => props.onClickCompileUpdate(e, professionist)}>
            {professionist.disabledDate ? professionist.disabledDate.substring(0, 10) : ''}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={'px-2 mx-2'}>
      <div className="row text-center header-table">
        <div className="col-sm-1 text-center div-td">
          <input type="checkbox" id="checkboxAllProfessionistId" onClick={props.onCheckClick} />
        </div>

        <div className={`col-lg-1 text-center div-td`}>
          <strong>Codice</strong>{' '}
        </div>
        <div className="col-lg-7 text-center div-td">
          <strong>Nome&nbsp; e &nbsp;Cognome</strong>
        </div>
        <div className="col-lg-1 text-center div-td">
          <strong>Disabilitato</strong>{' '}
        </div>
        <div className="col-lg-2 text-center div-td">
          <strong>Data Disabilitazione</strong>{' '}
        </div>
      </div>
      {renderRows(props.professionistsData)}
    </div>
  )
}

export default ArcoProfessionists
