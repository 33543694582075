import { ReactComponent as ISave } from '../../../../../styles/images/svgs/regular/save.svg'

export function ConfirmButton(props) {
  return (
    <button onClick={props.onClick} className="btn btn-primary btn-new-rel px-5">
      <ISave className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
      &nbsp; CONFERMA
    </button>
  )
}
