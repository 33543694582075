import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import axiosService from '../../../../services/axios-service'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError } from '../../../shared/PopupError'
import { downloadFile } from '../../../shared/Utility'
import { IExport } from '../../../../styles/icons'

export function YearlyBalanceExportModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const exportCoraYearlyBalances = () => {
    let filename =
      'SALDI_ANNO_' +
      props.selectedYear +
      '_' +
      (props.subject?.companyName ? props.subject.companyName : props.subject?.lastName) +
      '.csv'
    let url = `/api/cora/custom/cora-balances/export-yearly-balances/${props.subjectId}/${props.selectedYear}`
    callDownloadService(url, filename)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    setLoading(true)
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        if (response) {
          closeModal()
          if (response?.data?.size > 0) {
            downloadFile(response.data, file)
            PopupSuccess({ text: 'File scaricato' })
          } else {
            closeModal()
            PopupError({ ...props, text: 'Impossibile generare il file' })
          }
        }
      })
      .catch((error) => {
        closeModal()
        let reader = new FileReader()
        reader.onload = function () {
          PopupError({ ...props, text: reader.result })
        }
        reader.readAsText(error.request.response)
      })
      .then(() => setLoading(false))
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary  btn-empty px-4 btn-sm ${props.disabled ? 'disabled' : ''}`}
        data-tip={"Esporta i saldi per l'anno selezionato"}
        onClick={openModal}>
        <IExport
          className={'padding-right-4px'}
          width="20"
          fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`}
        />
        &nbsp; Esporta Saldi
      </button>
      <Modal
        size="sm"
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>ESPORTA SALDI ANNUALI</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p>
              Attraverso questa funzione è possibile scaricare tutti i saldi riferiti all'anno
              selezionato (<strong>{props.selectedYear}</strong>).
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              onClick={exportCoraYearlyBalances}
              type="button"
              className="btn btn-new-rel px-5">
              ESPORTA
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
