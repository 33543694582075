import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as ICheck } from '../../../styles/images/svg/check.svg'
import * as actions from '../../../actions'

export default function BlueLanguageSelector(props) {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.blueLanguage.language)

  const [selectedLanguage, setSelectedLanguage] = useState(lang)

  const handleLanguageClick = (e) => {
    setSelectedLanguage(e.target.value)
    dispatch(actions.changeBlueLanguage(e.target.value))
  }

  return (
    <>
      <div
        className={`btn-group-toggle text-end ms-auto ${props.class || 'w-10'}`}
        data-toggle="buttons">
        <input
          hidden={true}
          type="radio"
          name={`italianGeneral`}
          defaultChecked={selectedLanguage === 'it'}
          id={`italianGeneral`}
          value={'it'}
          onClick={handleLanguageClick}
        />
        <label
          className={`btn btn-outline-primary me-2 btn-empty ${selectedLanguage === 'it' ? 'border-selected' : ''}`}
          htmlFor={`italianGeneral`}>
          IT&nbsp;
          {selectedLanguage === 'it' && (
            <ICheck className={'padding-right-4px svg'} width="16" fill={'#128186'} />
          )}
        </label>
      </div>
      <div className={`btn-group-toggle text-end ${props.class || 'w-10'}`} data-toggle="buttons">
        <input
          hidden={true}
          type="radio"
          name={`englishGeneral`}
          defaultChecked={selectedLanguage === 'en'}
          id={`englishGeneral`}
          value={'en'}
          onClick={handleLanguageClick}
        />
        <label
          className={`btn btn-outline-primary btn-empty ${selectedLanguage === 'en' ? 'border-selected' : ''}`}
          htmlFor={`englishGeneral`}>
          EN&nbsp;
          {selectedLanguage === 'en' && (
            <ICheck className={'padding-right-4px svg'} width="16" fill={'#128186'} />
          )}
        </label>
      </div>
    </>
  )
}
