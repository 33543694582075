import moment from 'moment'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import 'react-confirm-alert/src/react-confirm-alert.css'
import * as actions from '../../../../actions'
import * as Constants from '../../../../config/Constants'
import '../../../../styles/alertComm.css'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { ReactComponent as IZipFile } from '../../../../styles/images/svg/file-archive.svg'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupError } from '../../../shared/PopupError'
import CorproEmailForm from './CorproEmailForm'
import { Link } from 'react-router-dom'
import { ReactComponent as ILogFile } from '../../../../styles/images/svg/th-list.svg'
import { ReactComponent as IQuestion } from '../../../../styles/images/svgs/regular/question-circle.svg'

class CreateCommunicationModal extends React.Component {
  constructor(props) {
    super(props)
    this.resetInitialState()
  }

  resetInitialState = () => {
    this.setState({
      showModal: true,
      loading: false,
      errorDataFile: { isValid: true, msg: '' },
      modalType: 'IMPORT_CSV',
      dataFile: []
    })
  }

  buttonAssistance = () => {
    const link = document.createElement('a')
    let subjectName = this.props.subject ? ' - Soggetto: ' + this.props.subject.subjectName : ''
    let customerName = this.props.auth?.user?.customer
      ? 'Utente: ' + this.props.auth?.user?.customer?.companyName
      : ''
    link.href = `mailto:assistenza@starinfostudio.it?subject=CORPRO - Richiesta assistenza ${customerName} ${subjectName}`
    document.body.appendChild(link)
    link.click()
  }

  renderRows(communications) {
    return communications.map((communication, index) => {
      return (
        <Row key={index} className="alert-body">
          <React.Fragment>
            <div className="col w-15 div-td">
              {communication.creationDate
                ? moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)
                : ''}
            </div>
            <div className="col w-20 div-td">{communication?.corproSubject?.subjectName}</div>
            <div className="col w-25 div-td">
              {communication?.corproCommunicationType?.code} -{' '}
              {communication?.corproCommunicationType?.description}
            </div>
            <div className="col w-10 div-td">{communication?.referenceYear}</div>
            <div className="col w-10 div-td">{communication?.subscribersNumber}</div>
            <div className="col w-10 div-td">
              {this.props.isCommunicationOK(communication) ? 'Creata' : 'Errori'}
            </div>
            <div className="col w-10 div-td text-center containerPopOver">
              <div>
                {this.props.isCommunicationOK(communication) ? (
                  <button
                    data-tip={'Scarica COMUNICAZIONE ZIP'}
                    onClick={() => this.props.downloadTxt(communication)}>
                    <IZipFile width="20" fill="#128186" />
                  </button>
                ) : (
                  <button
                    data-tip={'Scarica LOG DI REPORT ERRORI'}
                    onClick={() => this.props.downloadTxt(communication)}>
                    <ILogFile width="20" fill="#128186" />
                  </button>
                )}
              </div>
            </div>
          </React.Fragment>
        </Row>
      )
    })
  }

  close = () => {
    this.resetInitialState()
    this.props.closeModalComm()
  }

  render() {
    let sendEnabled =
      this.state?.communications &&
      this.state?.communications?.length > 0 &&
      this.props.isCommunicationOK(this.state?.communications[0])
    return (
      <React.Fragment>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.close}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>IMPORT CSV ISCRITTI</h2>
            </Modal.Title>
          </Modal.Header>
          {this.state.modalType === 'IMPORT_CSV' ? (
            <Modal.Body>
              <div className="d-flex justify-content-between">
                <div className="image-upload text-start">
                  <label htmlFor={`dataFile`}>
                    <div className="certificateFiles">
                      CARICA FILE DATI <IUpload width="25" fill="#000" />
                    </div>
                  </label>
                  <input
                    id={`dataFile`}
                    type="file"
                    name={`dataFile`}
                    accept=".csv, .CSV"
                    ref={(ref) => this.setDataFile(ref)}
                    onChange={(e) => this.handleUploadFile(e)}
                  />
                  <div className="certificatiLoaded text-start">
                    {!this.state.errorDataFile.isValid ? (
                      <div className="certificatiLoaded-error">{this.state.errorDataFile.msg}</div>
                    ) : (
                      <div>
                        {this.state.dataFile &&
                          this.state.dataFile.map((file, index) => {
                            return (
                              <div key={index}>
                                <ICheck width="12" fill="#8e8e8e" />
                                {file.name}
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-content-end mt-5">
                <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.close} />
                <button
                  disabled={!this.state.errorDataFile.isValid || !this.state.dataFile}
                  onClick={this.handleSendDataFile}
                  type="button"
                  className="btn btn-primary rounded-pill btn-new-rel px-5">
                  CONFERMA
                </button>
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <div className="text-start header-table">
                <div className="col w-15 div-td">Data creazione</div>
                <div className="col w-20 div-td">Soggetto</div>
                <div className="col w-25 div-td">Tipo comunicazione</div>
                <div className="col w-10 div-td">Anno</div>
                <div className="col w-10 div-td">Numero totale iscritti</div>
                <div className="col w-10 div-td">Stato</div>
                <div className="col w-10 div-td">Download</div>
              </div>
              {this.renderRows(this.state.communications)}
              {sendEnabled ? (
                <CorproEmailForm
                  {...this.props}
                  closeModal={this.close}
                  sentCommunications={this.state.communications}
                  emailAddress={this.props.user.login}
                  subjectId={this.props.subject?.id}
                />
              ) : (
                <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                  <button
                    className="btn btn-outline-primary btn-empty px-4 btn-sm"
                    onClick={this.close}>
                    ANNULLA
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-new-rel px-5 btn-sm"
                    onClick={this.buttonAssistance}>
                    <IQuestion className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
                    &nbsp; RICHIEDI ASSISTENZA
                  </button>
                </div>
              )}
            </Modal.Body>
          )}
        </Modal>
      </React.Fragment>
    )
  }

  setDataFile = () => {}

  handleUploadFile = (val) => {
    if (this.isEmpty(val)) {
      this.setState({ errorDataFile: { isValid: false, msg: 'Il file è obbligatorio' } })
    }
    let files = []
    for (let i = 0; i < val.target.files.length; i++) {
      if (!this.checkFileName(val.target.files[i].name)) {
        this.setState({ errorDataFile: { isValid: false, msg: 'Estensione o nome file errati' } })
      } else {
        files.push(val.target.files[i])
      }
    }
    if (files.length > 0) this.setState({ dataFile: files })
  }

  checkFileName = (name) => {
    return (
      name.split('.').pop().toLowerCase() === 'csv' || name.split('.').pop().toLowerCase() === 'CSV'
    )
  }

  isEmpty = (val) => {
    return val === '' || val == null
  }

  handleSendDataFile = () => {
    this.setState({ loading: true })
    let formData = new FormData()
    if (this.state.dataFile?.length > 0) {
      for (let i = 0; i < this.state.dataFile.length; i++) {
        formData.append('dataFile', this.state.dataFile[i])
      }
    } else {
      return
    }
    actions.importCorproCsvFile(this.props?.subject?.id, formData).then(
      (res) => {
        this.setState({ loading: false })
        if (res) {
          this.setState({ communications: res, modalType: 'SEND' })
        } else {
          this.onErrorSendDataFile()
        }
      },
      (errors) => {
        this.onErrorSendDataFile()
      }
    )
  }

  onErrorSendDataFile = (errors) => {
    this.close()
    PopupError({
      ...this.props,
      text:
        "Errore durante l'importazione dei dati di CORPRO. <br>Controllare il file e riprovare." +
        (errors ? errors : '')
    })
  }
}
export default CreateCommunicationModal
