import Row from 'react-bootstrap/Row'
/**
 * Ritorna un messaggio di errore dato dall'utente
 * @returns
 */
export default function MessageWrongFile({ communicationError }) {
  let error_status = "Si è verificato un errore durante l'elaborazione del file"
  if (communicationError !== '' && communicationError !== null) {
    error_status = communicationError
  }
  return (
    <div className="popup communication">
      <Row>
        <>{error_status}</>
      </Row>
    </div>
  )
}
