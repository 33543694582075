import React from 'react'
import axiosService from '../../../../services/axios-service'
import PaymentRow from './PaymentRow'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { isNotEmpty } from '../../../shared/Utility'

class PaymentList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progressive: '',
      paymentRows: [],
      paymentType: '',
      paymentTypeCode: '',
      relationshipPaymentTypes: [],
      idSelectedCheckBox: [] //NOTE: questo paramentro contiene gli id selezionati dall'utente nel checkbox
    }
  }

  componentDidMount() {
    this.getRelationshipPaymentTypes()
    this.getCrsPaymentList()
  }

  getCrsPaymentList = () => {
    let relId = this.props.relationship?.id || this.props.relationshipId
    const axiosInstance = axiosService.getInstance()
    if (relId) {
      axiosInstance
        .get(`/api/crs/custom/crs-payments/${this.props.subject?.id}/${relId}`)
        .then((res) => {
          this.setState({ paymentRows: res.data })
          //console.log("Payments:        " + JSON.stringify(this.state.paymentRows))
        })
    }
  }

  /**
   * Questa funzione cambia lo stato idSelectedCheckBox aggiungendo o togliendo elementi
   * a seconda se sono presenti o no nella tabella
   * @param {*} id id del progressivo
   */
  changeSelectedId = (id) => {
    let index = this.state.idSelectedCheckBox.indexOf(id)

    if (index < 0) {
      let out = this.state.idSelectedCheckBox
      out.push(id)
      this.setState({
        ...this.state,
        idSelectedCheckBox: out
      })
    }
    if (index > -1) {
      let out = this.state.idSelectedCheckBox
      out.splice(index, 1)
      this.setState({
        ...this.state,
        idSelectedCheckBox: out
      })
    }
  }

  getRelationshipPaymentTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-payment-types').then((res) => {
      this.setState({ relationshipPaymentTypes: res.data })
    })
  }

  renderRows = () => {
    return [
      this.state.paymentRows.map((payment, index) => {
        let selectCheckBox = this.state.idSelectedCheckBox.indexOf(payment.progressive) >= 0
        return (
          <PaymentRow
            ref={`payment_${index + 1}`}
            checkbox={selectCheckBox}
            onClickCheckBox={() => this.changeSelectedId(payment.progressive)}
            key={'payment' + payment.id}
            {...this.props}
            payment={payment}
            relationshipPaymentTypes={this.state.relationshipPaymentTypes}
          />
        )
      }),
      <PaymentRow
        ref={`payment_0`}
        {...this.props}
        key={'payment_0'}
        relationshipPaymentTypes={this.state.relationshipPaymentTypes}
      />
    ]
  }

  addCrsPayment = () => {
    let relId = this.props.relationship?.id || this.props.relationshipId
    let i
    let payments = this.state.paymentRows.length + 1
    for (i = 0; i < payments; i++) {
      let data = this.refs['payment_' + i].getData()
      if (isNotEmpty(data.amount) && isNotEmpty(data.paymentType)) {
        actions.addCrsPayment(data, relId).then(
          (response) => {
            this.getCrsPaymentList()
          },
          (errors) => {
            PopupError({ ...this.props, text: errors })
          }
        )
      }
    }
    this.refs['payment_0'].resetFields()
    this.getCrsPaymentList()
  }

  deleteElement = () => {
    let relId = this.props.relationship?.id || this.props.relationshipId

    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      data: this.state.idSelectedCheckBox,
      url: `/api/crs/custom/crs-payments/${relId}`,
      method: 'DELETE'
    }).then(
      () => {
        this.getRelationshipPaymentTypes()
        this.getCrsPaymentList()
      },
      (errors) => {
        PopupError({ ...this.props, text: errors })
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className={'margin-top-2'}>
          <div className="text-start header-table">
            <div className="col w-5 div-td"></div>
            <div className="col w-5 div-td">Prog</div>
            <div className="col w-40 div-td">Tipo Pagamento</div>
            <div className="col w-25 div-td">Importo</div>
            <div className="col w-10 div-td">Divisa</div>
          </div>
        </div>
        {this.renderRows()}
        <div className={'row-payment mt-2'}>
          <button
            className="btn btn-primary btn-new-rel px-4 me-4 btn-sm"
            onClick={() => this.deleteElement()}>
            ELIMINA
          </button>
          <button
            className="btn btn-primary btn-new-rel px-5 me-2 btn-sm"
            onClick={() => this.addCrsPayment()}>
            + AGGIUNGI
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default PaymentList
