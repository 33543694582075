import { useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('it', it)

export function ProvinceField(props) {
  const [error, setError] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    if (props.error !== undefined && props.error !== error) {
      setError(props.error)
    }
  }, [props.error])

  const inputWrapperClass = `input-wrapper ${props.touched && error !== undefined ? 'has-error' : ''}`

  return (
    <div className={inputWrapperClass}>
      <FloatingLabel id="floatingLabel" label={props.placeholder}>
        <Form.Control
          type={'text'}
          name={props.name}
          onFocus={props.onFocus}
          onChange={props.onChange}
          value={props.province}
          disabled={props.disabled}
          placeholder={props.placeholder}
          isInvalid={props.touched ? error : undefined}
        />
      </FloatingLabel>
    </div>
  )
}
