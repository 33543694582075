import {
  GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS,
  GET_ADMIN_CUSTOMERS_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {
  customers: {
    data: [],
    errors: []
  },
  customerApplications: {
    data: [],
    errors: []
  }
}

export const customersReducer = (state = INITIAL_STATE.customers, action) => {
  switch (action.type) {
    case GET_ADMIN_CUSTOMERS_SUCCESS:
      return { ...state, data: action.customers, errors: [] }
    default:
      return state
  }
}

export const customerApplicationReducer = (state = INITIAL_STATE.customerApplications, action) => {
  switch (action.type) {
    case GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS:
      return { ...state, data: action.customerApplications, errors: [] }
    default:
      return state
  }
}
