import React from 'react'
import Autosuggest from 'react-autosuggest'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { generateUniqueKey } from '../Utility'

const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const getSuggestions = (value, filtro = []) => {
  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }
  const regex = new RegExp('^.*' + escapedValue + '.*$', 'i')
  const suggestions = filtro.filter((datas) => regex.test(datas.name))

  return suggestions
}

class AutoCompileCustom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      suggestions: [],
      input: '',
      suggestionIndex: -1,
      error: { isValid: true, msg: '' },
      guid: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {}

    if (nextProps.error !== undefined) {
      newState.error = nextProps.error
    }

    if (nextProps.value !== prevState.value) {
      newState.value = nextProps.value || ''
    }

    return Object.keys(newState).length > 0 ? newState : null
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue?.toUpperCase() || '',
      input: method,
      suggestionIndex: event.target.dataset.suggestionIndex
    })

    // Chiama la funzione debounced
    this.props.handleInputAutocompile(newValue, this.props.id)
  }

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (method === 'click') {
      this.setState({
        value: suggestionValue,
        input: method,
        suggestionIndex: event.target.dataset.suggestionIndex
      })
      this.props.handleInputAutocompile(suggestionValue)
    } else {
      event.preventDefault()
    }
  }

  componentDidMount() {
    this.setState({ guid: generateUniqueKey('id') })
    if (document.getElementsByClassName('react-autosuggest__input').length === 1) {
      document.getElementsByClassName('react-autosuggest__input')[0].classList.add('form-control')
    }
  }

  componentDidUpdate() {
    const formFloating = document.querySelector(`#${this.state.guid}`)
    if (formFloating) {
      if (this.state.value) {
        formFloating.classList.add('input-has-value')
      } else {
        formFloating.classList.remove('input-has-value')
      }
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  renderSuggestion = (suggestion) => {
    return suggestion.name
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.filtro)
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  reset = () => {
    this.setState({
      value: '',
      suggestions: [],
      input: '',
      suggestionIndex: -1
    })
  }

  render() {
    const { suggestions, value } = this.state
    const inputProps = {
      placeholder: this.props.placeholder,
      value: value,
      onChange: this.onChange
    }
    const inputWrapperClass = `input-wrapper w-100 ${this.props.touched && this.props.error !== undefined ? 'has-error' : ''}`

    return (
      <div id={this.state.guid} className={inputWrapperClass}>
        <FloatingLabel id="floatingLabel" label={this.props.label}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionSelected={this.onSuggestionSelected}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            disabled={false}
          />
        </FloatingLabel>
        {/* {this.props.touched && this.props.error !== undefined &&
          <div className="text-danger">{this.props.error}</div>
        } */}
      </div>
    )
  }
}

export default AutoCompileCustom
