import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import 'react-datepicker/dist/react-datepicker.css'
import * as Constants from '../../../../config/Constants'
import '../../../../styles/autocomplete.css'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as ISort } from '../../../../styles/images/svgs/regular/sort.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { ReactComponent as ILeft } from '../../../../styles/images/svgs/regular/arrow-left.svg'
import { ReactComponent as IRight } from '../../../../styles/images/svgs/regular/arrow-right.svg'

import { ArcoRegistryModal } from './ArcoRegistryModal'
import imgShowBlu from '../../../../styles/images/show_blu.png'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import '../../../../styles/arcoTablesModal.css'
import { useSelector } from 'react-redux'

import { SearchBar } from './SearchBar'

import { NumberOfResultsSelector } from '../../../shared/searchComponents/NumberOfResultsSelector'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

export function ArcoRegistriesList(props) {
  const [searchObject, setSearchObject] = useState(undefined)
  const [pageNumber, setPageNumber] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const showModalRegistriesList = props.showModalRegistriesList
  const [showRegistryModal, setShowRegistryModal] = useState(false)
  const [modalShow, setModalShow] = useState(props.modalShow != undefined ? props.modalShow : true)
  const [hideClearSearchButton, setHideClearSearchButton] = useState(true)
  const [sortFiscalCode, setSortFiscalCode] = useState(1)
  const [sortSurnameOrDenomination, setSortSurnameOrDenomination] = useState(1)
  const [sortLocation, setSortLocation] = useState(1)
  const [disableSearchBox, setDisableSearchBox] = useState(true)
  const [searchType, setSearchType] = useState('1')
  const [searchPlaceholder, setSearchPlaceholder] = useState('es. Mario Rossi')
  const [searchIsOpen, setSearchIsOpen] = useState()
  const [showDetailItem, setShowDetailItem] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [arcoRegistries, setArcoRegistries] = useState([])
  const [checkedArcoRegistriesValues, setCheckedArcoRegistriesValues] = useState([])
  const [depth, setDepth] = useState(props.depth || 1)
  const [detailId, setDetailId] = useState(props.detailId)
  const arcoSubject = useSelector((state) => state.arcoSubject.data)
  const [searchOptions, setSearchTypeOption] = useState([
    {
      id: 1,
      description: 'Nome, Cognome o Ragione Sociale'
    },
    {
      id: 2,
      description: 'Codice Fiscale'
    },
    {
      id: 3,
      description: 'Località'
    }
  ])

  const handleClose = () => {
    props.onHide()
  }

  useEffect(() => {
    if (props.detailId && !showRegistryModal && !props.fromHolders && !props.fromSignatories) {
      showDetailFromId()
    }
  })

  const setResultData = (data) => {
    setPageNumber(data.pageable.pageNumber)
    setResultsPerPage(data.pageable.pageSize)
    setTotalPages(data.totalPages)
    setArcoRegistries(data.content)
  }

  useEffect(() => {
    actions.getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id).then((res) => {
      setResultData(res)
    })
  }, [])

  useEffect(() => {
    if (arcoRegistries.length > 0) {
      setIsLoading(true)
      actions
        .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, {
          ...searchObject,
          pageNumber: pageNumber,
          pageSize: resultsPerPage
        })
        .then((res) => {
          setResultData(res)
          uncheckAll()
        })
        .then(() => setIsLoading(false))
    }
  }, [pageNumber])

  useEffect(() => {
    if (arcoRegistries.length > 0) {
      setIsLoading(true)
      actions
        .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, {
          ...searchObject,
          pageNumber: 0,
          pageSize: resultsPerPage
        })
        .then((res) => {
          setResultData(res)
          uncheckAll()
        })
        .then(() => setIsLoading(false))
    }
  }, [resultsPerPage])

  const handlerShowNewRegistryModal = () => {
    setDepth(depth + 1)
    setModalShow(true)
    handlerShowRegistryModal()
  }

  const handlerShowRegistryModal = () => {
    setShowRegistryModal(!showRegistryModal)
    setShowDetailItem(null)
  }

  const handlerCloseRegistryModal = (registry) => {
    if (registry && props.onSelectedItem) {
      props.onSelectedItem(registry)
    } else {
      actions
        .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, {
          ...searchObject,
          pageNumber: pageNumber
        })
        .then((res) => {
          setResultData(res)
        })
    }

    setDetailId(null)
    handlerShowRegistryModal()

    setDepth(depth - 1)
  }

  const sortBy = (val) => {
    if (val) {
      switch (val) {
        case 'fiscalCode':
          arcoRegistries.sort((a, b) =>
            a.fiscalCode > b.fiscalCode ? sortFiscalCode : b.fiscalCode > a.fiscalCode ? -1 : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            arcoRegistries.reverse()
          }
          setSortFiscalCode(-sortFiscalCode)
          break
        case 'surnameOrDenomination':
          arcoRegistries.sort((a, b) =>
            getSurnameOrDenomination(a) > getSurnameOrDenomination(b)
              ? sortSurnameOrDenomination
              : getSurnameOrDenomination(b) > getSurnameOrDenomination(a)
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            arcoRegistries.reverse()
          }
          setSortSurnameOrDenomination(-sortSurnameOrDenomination)
          break
        case 'location':
          arcoRegistries.sort((a, b) =>
            a.location > b.location ? sortLocation : b.location > a.location ? -1 : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            arcoRegistries.reverse()
          }
          setSortLocation(-sortLocation)
          break
        default:
          return
      }
    }
  }

  const onCheckClick = (event) => {
    if (event.target.checked) checkAll()
    else uncheckAll()
  }

  const checkAll = () => {
    document.getElementById('checkboxAllId').checked = true
    arcoRegistries.map((arcoRegistry) => {
      const checkboxDetailsId = `checkboxDetails${arcoRegistry.id}`
      const idCheckbox = `row-${arcoRegistry.id}`
      document.getElementById(checkboxDetailsId).checked = true
      document.getElementById(idCheckbox).checked = true
    })
    let rowIds = document.querySelectorAll('[id^="row-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.add('bg-cobalt', 'bold')
      }
    }
    checkallArcoRegistries()
  }
  const checkallArcoRegistries = () => {
    let allArcoRegistrys = arcoRegistries
    setCheckedArcoRegistriesValues(allArcoRegistrys)
  }

  const uncheckAll = () => {
    const checkboxAllId = document.getElementById('checkboxAllId')
    if (checkboxAllId) checkboxAllId.checked = false
    arcoRegistries.map((arcoRegistry) => {
      const checkboxDetailsId = `checkboxDetails${arcoRegistry.id}`
      const idCheckbox = `row-${arcoRegistry.id}`
      if (document.getElementById(checkboxDetailsId)) {
        document.getElementById(checkboxDetailsId).checked = false
      }
      if (document.getElementById(idCheckbox)) {
        document.getElementById(idCheckbox).checked = false
      }
    })
    uncheckallArcoRegistries()
    let rowIds = document.querySelectorAll('[id^="row-"]')
    for (let i = 0; i < rowIds?.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.remove('bg-cobalt', 'bold')
      }
    }
  }
  const uncheckallArcoRegistries = () => {
    setCheckedArcoRegistriesValues([])
  }

  const renderRowHeader = () => {
    return (
      <React.Fragment>
        <div className="text-center header-table">
          <div className="col-lg-1 text-center div-td">
            <input type="checkbox" id="checkboxAllId" onClick={onCheckClick} />
          </div>
          <div className="col-lg-4 text-center div-td">
            <strong>Cognome e Nome o Ragione sociale</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => sortBy('surnameOrDenomination')}
            />
          </div>
          <div className="col-lg-3 text-center div-td">
            <strong>Codice fiscale </strong>
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => sortBy('fiscalCode')}
            />
          </div>
          <div className="col-lg-3 text-center div-td">
            <strong>Localit&agrave; </strong>
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => sortBy('location')}
            />
          </div>
          <div className="col-lg-1 text-center div-td">
            <strong>Dettaglio </strong>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const getSurnameOrDenomination = (item) => {
    return Constants.PF === item.personType
      ? item.lastName + ' ' + item.firstName
      : item.denomination
  }

  const dynamicSearch = (value) => {
    setSearchTerm(value)
  }

  const showDetailFromId = () => {
    for (let i = 0; i < arcoRegistries.length; i++) {
      let element = arcoRegistries[i]
      if (element.id == detailId) {
        showDetail(element)
        break
      }
    }
  }

  const showDetail = (element) => {
    handlerShowRegistryModal()
    setModalShow(true)
    setDepth(depth + 1)
    setShowDetailItem(element)
  }

  const onCheckBoxClick = (event, arcoRegistry) => {
    if (event.target.checked) {
      checkArcoRegistry(arcoRegistry)
      let element = document.getElementById('row-' + arcoRegistry.id)
      if (element) element.classList.add('bg-cobalt', 'bold')
    } else {
      uncheckArcoRegistry(arcoRegistry)
      document.getElementById('checkboxAllId').checked = false
      let element = document.getElementById('row-' + arcoRegistry.id)
      if (element) element.classList.remove('bg-cobalt', 'bold')
    }
  }

  const checkArcoRegistry = (arcoRegistry) => {
    setCheckedArcoRegistriesValues(checkedArcoRegistriesValues.concat(arcoRegistry))
    if (checkedArcoRegistriesValues.length + 1 === arcoRegistries.length) {
      checkAll()
    }
  }

  const uncheckArcoRegistry = (arcoRegistry) => {
    let newList = []
    checkedArcoRegistriesValues.map((current) => {
      if (current.id !== arcoRegistry.id) newList.push(current)
    })
    setCheckedArcoRegistriesValues(newList)
  }

  const deleteArcoArcoRegistrySubject = () => {
    checkedArcoRegistriesValues.map((arcoRegistry) => {
      actions.deleteArcoRegistries(arcoRegistry.id).then(
        () => {
          actions
            .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, {
              ...searchObject,
              pageNumber: pageNumber
            })
            .then((res) => {
              setResultData(res)
            })
          setCheckedArcoRegistriesValues([])
          setHideClearSearchButton(true)
          //chiudo form se contiene l'elemento cancellato
        },
        (errors) => {
          console.error('Error while deleting arco subject: ', errors)
          PopupError({
            ...props,
            text: errors.title || "Errore durante l'eliminazione del soggetto anagrafico"
          })
        }
      )
      document.getElementById(`checkboxDetails${arcoRegistry.id}`).checked = false
      let newList = []
      arcoRegistries.map((current) => {
        if (current.id !== arcoRegistry.id) newList.push(current)
      })
      setCheckedArcoRegistriesValues(newList)
    })
    let rowIds = document.querySelectorAll('[id^="row-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.remove('bg-cobalt', 'bold')
      }
    }
  }

  const exportRowValues = (registry) => {
    if (props.fromHolders || props.fromSignatories) {
      props.onSelectedItem(registry)
      handleClose()
    } else if (props.onSelectedItem) {
      registry.isNew = true
      props.onSelectedItem(registry)
      handleClose()
    }
  }

  const renderRows = () => {
    return arcoRegistries.map((item, index) => {
      const idCheckbox = `checkboxDetails${item.id}`
      return (
        <React.Fragment key={index}>
          <div
            className=" text-start row-table"
            id={`row-${item.id}`}
            key={`row-${item.id}-${depth}`}>
            <div className="col-lg-1 text-center div-td">
              <input type="checkbox" id={idCheckbox} onClick={(e) => onCheckBoxClick(e, item)} />
            </div>
            <div className="col-lg-4 div-td text-truncate" onClick={() => exportRowValues(item)}>
              {getSurnameOrDenomination(item).toUpperCase()}
            </div>
            <div className="col-lg-3 div-td text-truncate" onClick={() => exportRowValues(item)}>
              {item.fiscalCode}
            </div>
            <div className="col-lg-3 div-td text-truncate" onClick={() => exportRowValues(item)}>
              {item.birthLocation.toUpperCase() || item.location.toUpperCase()}
            </div>
            <div className="col-lg-1 div-td text-truncate text-center">
              <img src={imgShowBlu} onClick={() => showDetail(item)} alt="" />
            </div>
          </div>
        </React.Fragment>
      )
    })
  }

  const onChangeResultsPerPage = (value) => {
    if (value) {
      if (parseInt(value) > 99) {
        setResultsPerPage(99)
      } else {
        setResultsPerPage(parseInt(value))
      }
    } else {
      setResultsPerPage(value)
    }
  }

  const searchRegistries = () => {
    let searchTypeInt = parseInt(searchType)

    //closeAllRowDetails();
    let searchObject = {
      pageNumber: 0,
      pageSize: resultsPerPage || 20,
      searchTerm: searchTerm
    }
    switch (searchTypeInt) {
      case 1:
        searchObject.searchField = 'nameOrDenomination'
        break
      case 2:
        searchObject.searchField = 'fiscalCode'
        break
      case 3:
        searchObject.searchField = 'location'
        break
      default:
        break
    }

    actions
      .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, { ...searchObject })
      .then((res) => {
        setResultData(res)
        setHideClearSearchButton(false)
        uncheckAll()
      })
    setSearchObject(searchObject)

    setSearchIsOpen(false)
  }

  const renderSearchBar = () => {
    return (
      <SearchBar
        key={'registries-search-bar'}
        searchIsOpen={true}
        openSearchBox={openSearchBox}
        searchOptions={searchOptions}
        handlerChangeSearch={handlerChangeSearch}
        searchType={searchType}
        searchPlaceholder={searchPlaceholder}
        dynamicSearch={dynamicSearch}
        searchTerm={searchTerm}
        onChangeResultsPerPage={onChangeResultsPerPage}
        resultsPerPage={resultsPerPage}
        placeholder={20}
        doSearch={searchRegistries}
        customClass={'searchBarArcoRegList'}
      />
    )
  }

  const getSubjectName = () => {
    return arcoSubject?.companyName
  }

  const handlerChangeSearch = (e) => {
    let option = e.target.value
    if (undefined !== option && '' !== option) {
      setDisableSearchBox(false)
      setSearchType(option)
      switch (parseInt(option)) {
        case 1:
          // Search by completeName
          setSearchPlaceholder('es. Mario Rossi')
          break
        case 2:
          // Search by fiscalCode
          setSearchPlaceholder('es. VLNBBN05D69L736L')
          break
        case 3:
          // Search by location
          setSearchPlaceholder('es. Verona')
          break
        default:
          setSearchPlaceholder('')
          return
      }
    }
  }

  const openSearchBox = () => {
    setSearchIsOpen(!searchIsOpen)
  }

  const onClearSearch = () => {
    const clearSearchObject = { pageNumber: 0, pageSize: resultsPerPage }
    actions
      .getAllArcoRegistriesBySubjectIdNoCache(arcoSubject.id, clearSearchObject)
      .then((res) => {
        setResultData(res)
      })
    setSearchObject(clearSearchObject)
    setHideClearSearchButton(true)
    uncheckAll()
  }

  const renderHeader = () => {
    return (
      <div className="row header-btn-arcoRegistries d-flex align-items-center pb-1 ps-3 pe-1">
        <div className="col-12">{renderSearchBar()}</div>

        <div className="row  pt-3 align-items-center justify-content-between">
          <div className="col-4">
            <div className="btn-group" role="group" aria-label="action buttons" id={'elimina'}>
              <button
                type="button"
                disabled={`${checkedArcoRegistriesValues.length > 0 ? '' : 'disabled'}`}
                className={`btn btn-outline-primary btn-empty px-4 btn-sm  ${checkedArcoRegistriesValues.length > 0 ? '' : 'disabled'}`}
                onClick={() => deleteArcoArcoRegistrySubject()}>
                <IDelete
                  className={'padding-right-4px'}
                  width="12"
                  fill={` ${checkedArcoRegistriesValues.length > 0 ? '#128186' : '#FFFFFF'}`}
                />
                &nbsp; Elimina
              </button>
            </div>
          </div>
          <div className="col-4 row">
            <div className={`btn-group col-12 align-self-center h-auto`}>
              <button
                type="button"
                disabled={props.disableInsert}
                onClick={handlerShowNewRegistryModal}
                className="btn btn-primary btn-new-rel px-4 btn-sm">
                <IPlus className={'padding-right-4px'} width="14" />
                &nbsp; Aggiungi nuova anagrafica
              </button>
            </div>
          </div>
          <div className="col-4 text-end pe-0">
            <button
              hidden={hideClearSearchButton}
              type="button"
              className={`btn btn-secondary  px-3 `}
              onClick={() => onClearSearch()}>
              PULISCI RICERCA
            </button>
          </div>
        </div>
      </div>
    )
  }

  const title = () => {
    if (props.selectedItem == undefined && !(props.fromHolders || props.fromSignatories)) {
      return '< ' + getSubjectName()
    } else if (props?.fromSignatories?.personType == 'LEGAL') {
      return 'FIRMATARIO DI ' + getSurnameOrDenomination(props.fromSignatories).toUpperCase()
    } else if (props.fromHolders) {
      return 'TITOLARE DI ' + getSurnameOrDenomination(props.fromHolders).toUpperCase()
    } else if (props?.fromSignatories?.personType == 'FISICAL') {
      return 'PROCURATORE DI ' + getSurnameOrDenomination(props.fromSignatories).toUpperCase()
    }
  }

  const updatePageNumber = (pageNumber) => {
    setPageNumber(pageNumber)
  }

  return (
    <React.Fragment>
      {isLoading && <PageSpinner />}
      <Modal
        backdrop="static"
        show={showModalRegistriesList}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="arco-modal-max mt-0 mb-1"
        centered
        scrollable>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{title()}</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-1 overflow-x-hidden">
          {renderHeader()}

          <div className="ps-3 pe-3 ">
            {renderRowHeader()}
            {renderRows()}
          </div>

          {showRegistryModal && modalShow && (
            <div>
              <ArcoRegistryModal
                {...props}
                arcoRegistries={arcoRegistries}
                selectedItem={showDetailItem}
                showModalCheck={showRegistryModal}
                onHide={handlerCloseRegistryModal}
                depth={depth}
                fromRegistry={props.fromRegistry}
                fromHolders={props.fromHolders}
                fromSignatories={props.fromSignatories}
                disableInsert={props.disableInsert}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className=" m-auto pt-0 form-relationship-button justify-content-between align-item-center">
            <div className="col-2 justify-content-center">
              <button
                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                onClick={handleClose}>
                <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
                &nbsp; CHIUDI
              </button>
            </div>
            <div className="col-8 row text-center align-items-center justify-content-center align-self-center">
              <button
                disabled={pageNumber <= 0}
                type="button"
                className={`col-2 btn btn-primary btn-new-rel px-4  w-10 ${pageNumber <= 0 && 'disabled'}`}
                onClick={() => updatePageNumber(pageNumber - 1)}>
                <ILeft className={'padding-right-4px'} width="12" fill={'#FFFFFF'}></ILeft>
              </button>
              <div className="col-3 text-center">
                <strong>
                  Pagina {pageNumber + 1} di {totalPages}
                </strong>
              </div>
              <button
                disabled={totalPages === pageNumber + 1}
                type="button"
                className={`col-2 btn btn-primary btn-new-rel px-4  w-10 ${totalPages === pageNumber + 1 && 'disabled'}`}
                onClick={() => updatePageNumber(pageNumber + 1)}>
                <IRight className={'padding-right-4px'} width="12" fill={'#FFFFFF'}></IRight>
              </button>
            </div>
            <div className="col-2 text-center justify-content-center">
              <NumberOfResultsSelector
                selected={resultsPerPage || 20}
                onChange={(val) => setResultsPerPage(val)}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
