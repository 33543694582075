import React, { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import '../../../../styles/comurei-form.css'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError } from '../../../shared/PopupError'

const CorproEmailForm = (props) => {
  const [formData, updateFormData] = useState()
  const [subjectId, setSubjectId] = useState(props.subjectId)
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [sentCommunications, setSentCommunications] = useState(props.sentCommunications)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    updateFormData({
      ...formData,
      id: subjectId,
      emailAddress: emailAddress
    })
  }, [])

  const confirmCorproCommunicationsEmail = (emailAddress) => {
    sentCommunications.map((communication, index) => {
      actions
        .corproSendCommunicationMail(communication.corproSubject.id, communication.id, emailAddress)
        .then(
          (res) => {
            if (res) {
              PopupSuccess({ text: 'Email inviata' })
            } else {
              PopupError({ ...props, text: "Errore nell'invio della email" })
            }
          },
          (errors) => {
            PopupError({ ...props, text: "Errore nell'invio della email " + errors })
          }
        )
    })
    props.closeModal()
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      emailAddress: e.target.value.toUpperCase()
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setErrorEmailAddress({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event, sendComm) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmCorproCommunicationsEmail(emailAddress)
    }
  }

  return (
    <form onSubmit={(e) => handleFormSubmit(e, false)}>
      <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
        <div className="tab-content col-md-12 col-lg-12">
          <div className="form-row">
            <div className="col-md-8 col-lg-8 subDetailComurei">
              <div className="row">
                <div className="col-md-11 col-lg-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    label="Indirizzo email a cui spedire le comunicazioni"
                    value={emailAddress}
                    maxLength="100"
                    onChange={handlerChangeEmailAddress}
                    error={errorEmailAddress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
        <button
          className="btn btn-outline-primary btn-empty px-4 btn-sm"
          onClick={props.closeModal}>
          ANNULLA
        </button>
        <button type="submit" className="btn btn-primary btn-new-rel px-5">
          CONFERMA
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'CorproEmailForm'
})(CorproEmailForm)
