import { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import { BwmInput } from '../shared/form/BwmInput'
import imgShowBlu from '../../styles/images/show_blu.png'
import { BwmCheckbox } from '../shared/form/BwmCheckbox'
import { Col, Row } from 'react-bootstrap'

const LoginForm = (props) => {
  const { submitCb, errors } = props
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [stayConn, setStayConn] = useState(true)
  const [formData, updateFormData] = useState()
  const [errorUserName, setErrorUserName] = useState({ isValid: true, msg: '' })
  const [errorPassword, setErrorPassword] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    updateFormData({
      ...formData,
      rememberMe: true
    })
  }, [])

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    }
  }

  const handlerEmail = (val) => {
    setUsername(val.toLowerCase())
    updateFormData({ ...formData, username: val.toLowerCase() })
  }

  const handlerPassword = (val) => {
    setPassword(val)
    updateFormData({ ...formData, password: val })
  }

  const handlerStayConn = (e) => {
    setStayConn(e.target.checked)
    updateFormData({ ...formData, rememberMe: e.target.checked })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validation()) submitCb(formData)
  }

  const resetError = () => {
    setErrorUserName({ isValid: true, msg: '' })
    setErrorPassword({ isValid: true, msg: '' })
  }

  const validation = () => {
    resetError()
    let isValid = true
    if (!username) {
      setErrorUserName({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!password) {
      setErrorPassword({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else if (password.length < 8) {
      setErrorPassword({ isValid: false, msg: 'Inserire almeno 8 caratteri' })
      isValid = false
    }
    return isValid
  }

  return (
    <div className="col-md-10 col-lg-10 mt-3">
      <form onSubmit={(e) => handleSubmit(e)}>
        <BwmInput
          name="username"
          type="text"
          label="Email"
          className="form-control form-control-normal"
          value={username}
          onChange={(e) => handlerEmail(e.target.value)}
          error={errorUserName}
        />
        <div className="row-form login mt-3">
          <BwmInput
            id="loginPsw"
            name="password"
            type="password"
            label="Password"
            className="form-control form-control-normal"
            value={password}
            onChange={(e) => handlerPassword(e.target.value)}
            error={errorPassword}
          />
          <div className="align-self-center mt-4 eye cursor-pointer">
            <img
              className="img-info ms-2"
              alt="mostra password"
              src={imgShowBlu}
              onClick={(e) => onClickIconPsw(e, 'loginPsw')}
            />
          </div>
        </div>
        <div className="row-form loginStayConn mt-3">
          <span>Rimani connesso</span>
          <div className="input-group cursor-pointer">
            <BwmCheckbox
              name="stayConnected"
              className="form-control"
              value={stayConn}
              onClick={(e) => handlerStayConn(e)}
            />
          </div>
        </div>
        {errors?.status === 404 && <div className="text-danger ">Email o password errati</div>}
        <Row className="mt-3 pt-3">
          <Col lg={8}>
            <div className="notAccount">
              <span>
                Non hai un account?{' '}
                <a
                  target={'_blank'}
                  rel="noopener noreferrer"
                  href="https://www.starsoluzioni.it/my-account/">
                  <strong>Registrati</strong>
                </a>
              </span>
            </div>
            <div className="lostPassword">
              <span>
                Non ricordi la password?{' '}
                <a
                  target={'_blank'}
                  rel="noopener noreferrer"
                  href="https://www.starsoluzioni.it/my-account/lost-password/">
                  <strong>Recupera password</strong>
                </a>
              </span>
            </div>
          </Col>
          <Col lg={4}>
            <div className="loginSubmit">
              <button className="btn btn-primary btn-lg btn-new-rel btn-star" type="submit">
                Accedi
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'loginForm'
})(LoginForm)
