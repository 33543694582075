import * as actions from '../../../../actions'
import { useState } from 'react'
import { cloneDeep } from 'lodash'
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { ReactComponent as ITextFile } from '../../../../styles/images/svgs/regular/file-alt.svg'
import * as Constants from '../../../../config/Constants'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { checkReceiptSize } from '../../../shared/UploadReceipt'
import { Form } from 'react-bootstrap'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import ModalProcessingOutComeReceipt from './ModalProcessingOutRecepts/ModalProcessingOutComeReceipt'

const CONSISTENCY = 'CONSISTENCY'
const RECEIPT = 'RECEIPT'

function FileRow({ file }) {
  return (
    <div className="d-flex green-label text-truncate align-items-center">
      <ICheck width="12" fill="#128186" />
      {file.name}
    </div>
  )
}

function isAtpec05(fileName = '') {
  return ['ATPEC05'].includes(fileName.toUpperCase().slice(0, 7))
}

export function UploadReceiptModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loadedFiles, setLoadedFiles] = useState([])
  const [errorReceipt, setErrorReceipt] = useState({ isValid: true, msg: '' })
  const [loading, setLoading] = useState(false)
  const [communication, setCommunication] = useState([])
  const [receiptType, setReceiptType] = useState(null)
  const [consistencyStartingYear, setConsistencyStartingYear] = useState('')

  const closeModal = () => {
    setShowModal(false)
    setLoadedFiles([])
    setCommunication([])
  }

  const openModal = () => {
    setShowModal(true)
    setLoadedFiles([])
    setReceiptType(null)
    setConsistencyStartingYear('')
    setCommunication([])
  }

  const determineModalType = (files = []) => {
    let modalType = null
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (isAtpec05(file.name)) {
        if (modalType === RECEIPT) {
          setErrorReceipt({
            isValid: false,
            msg: 'Selezionare un tipo file per volta'
          })
          return null
        } else {
          modalType = CONSISTENCY
        }
      } else {
        if (modalType === CONSISTENCY) {
          setErrorReceipt({
            isValid: false,
            msg: 'Selezionare un tipo file per volta'
          })
          return null
        } else {
          modalType = RECEIPT
        }
      }
    }
    return modalType
  }

  const checkFileName = (file) => {
    let name = file.name
    return Constants.ATPEC_REGEXP.test(name)
  }

  const handleSendReceipt = () => {
    const _loadedFiles = cloneDeep(loadedFiles)
    const _receiptType = cloneDeep(receiptType)
    closeModal()
    setLoading(true)
    const formData = new FormData()
    for (var x = 0; x < _loadedFiles.length; x++) {
      let file = _loadedFiles[x]
      formData.append('receiptFile', file)
    }
    if (_receiptType === RECEIPT) {
      actions
        .createCoraReceipt(formData)
        .then(
          (communication) => {
            if (communication?.length > 0) {
              setCommunication(communication)
            } else {
              PopupError({
                text: "Si è verificato un errore non gestito nell'elaborazione della ricevuta."
              })
            }
          },
          (errors) => {
            PopupError({ text: errors })
          }
        )
        .then(() => setLoading(false))
    } else if (receiptType === CONSISTENCY) {
      if (isNotEmpty(consistencyStartingYear)) {
        formData.append('startingYear', consistencyStartingYear)
      }
      actions
        .consistencyPhotoReceipt(formData)
        .then(
          (communication) => {
            if (communication?.length > 0) {
              props.refreshSubjects()
              setCommunication(communication)
            } else {
              PopupError({
                text: "Si è verificato un errore inatteso nell'elaborazione della fotografia."
              })
            }
          },
          (errors) => {
            PopupError({
              text: "Si è verificato un errore inatteso nell'elaborazione della fotografia."
            })
          }
        )
        .then(() => setLoading(false))
    }
  }

  const renderOptions = () => {
    const currYear = new Date().getFullYear() - 1
    let options = []
    options.push(
      <option key={`year-select-empty`} value={''}>
        Tutti
      </option>
    )
    for (let index = 0; index < 9; index++) {
      const year = currYear - index
      options.push(
        <option key={`year-select-${year}`} value={year}>
          {year}
        </option>
      )
    }
    return options
  }

  const onFileInput = (files) => {
    //valida nome, estensione e dimensioni files
    //popola stato, con oggetti di tipo {file, anno(se atpec05)}, sovrascrivendo duplicati
    let hasInvalidFile = false
    let invalidFileName = ''
    let loadedFiles = []
    let modalType = determineModalType(files)
    if (!modalType) {
      return
    }

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (!checkFileName(file) || !checkReceiptSize(file)) {
        hasInvalidFile = true
        invalidFileName = file.name
      }
    }
    let error = { isValid: true, msg: '' }
    if (hasInvalidFile) {
      error = {
        isValid: false,
        msg: `Il file ${invalidFileName || ''} non è valido. Verificare estensione (.run, .p7m, .enc), nome (senza parentesi), dimensioni (max 5MB) e l'eventuale presenza di caratteri speciali nel nome del file: { } ( ) [ ] | ~ \` $ % & * + - = < > @ # ^ _ \\ / ; : '.`
      }
      modalType = null //azzero il tipo modale
    } else {
      loadedFiles = files
      error = { isValid: true, msg: '' }
    }
    setErrorReceipt(error)
    setLoadedFiles(loadedFiles)
    setReceiptType(modalType)
  }

  return (
    <>
      {loading && <PageSpinner />}

      <button
        type="button"
        className={`btn btn-empty btn-sm ${props.disabled ? 'disabled' : ''}`}
        data-tip={
          'Caricare file RICEVUTA DI TRASMISSIONE (ATPEZC), FOTOGRAFIA (ATPEC05) e ESITI (ATPECXX)'
        }
        onClick={openModal}
        disabled={props.disabled}>
        <ITextFile
          className={'padding-right-4px svg'}
          width="20"
          height="19"
          fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`}
        />
        &nbsp; Elabora file ricevuti
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {receiptType === CONSISTENCY && <h2>ELABORA FOTOGRAFIE DI CONSISTENZA</h2>}
            {receiptType === RECEIPT && <h2>ELABORA RICEVUTE ED ESITI</h2>}
            {isEmpty(receiptType) && <h2>ELABORA FILE RICEVUTI</h2>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-between">
            <div className="image-upload text-start">
              <UploadFileLabel label={'CARICA FILE RICEVUTO'} forLabel={'receiptFile'} />
              <input
                id={`receiptFile`}
                type="file"
                name={`receiptFile`}
                multiple
                accept=".run,.enc,.p7m"
                onChange={(e) => onFileInput(e.target.files)}
              />
              <div className="certificatiLoaded text-start">
                {!errorReceipt.isValid && (
                  <div className="certificatiLoaded-error">{errorReceipt.msg}</div>
                )}

                {Array.from(loadedFiles).map((file, index) => {
                  return (
                    <div key={`file-row-${index}`}>
                      <FileRow file={file} />
                    </div>
                  )
                })}
              </div>
              {receiptType === CONSISTENCY && (
                <>
                  <div className={`form-group d-flex mt-4 pt-4`}>
                    <label>Seleziona l'anno di inizio elaborazione: </label>
                    <Form.Control
                      className="form-control ms-2"
                      style={{ maxWidth: '4rem' }}
                      onChange={(e) => setConsistencyStartingYear(e.target.value)}
                      as="select"
                      defaultValue={consistencyStartingYear}>
                      {renderOptions()}
                    </Form.Control>
                  </div>
                  <div className="form-group">
                    <label>
                      Se non si indica nulla verranno elaborati TUTTI i rapporti presenti nella
                      fotografia
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Per la selezione sarà letta la DATA DI INIZIO dei rapporti</label>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorReceipt.isValid || loadedFiles.length < 1}
              onClick={handleSendReceipt}
              type="button"
              className="btn btn-new-rel px-5">
              CONFERMA
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {communication.map((communication, index) => {
        return (
          <ModalProcessingOutComeReceipt
            communication={communication.coraCommunication}
            key={communication.filename + '-' + index}
            fileName={communication.filename}
            subjectName={communication.coraCommunication?.subject?.completeName}
            receiptType={receiptType}
          />
        )
      })}
    </>
  )
}
