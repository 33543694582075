import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import '../../../../styles/comurei-form.css'
import { ReactComponent as IAnagrafica } from '../../../../styles/images/svg/id-card.svg'
import * as actions from '../../../../actions'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../application-cora/relationships/ProvinceFieldB5'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import { PopupError } from '../../../shared/PopupError'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import '../../../../styles/subject-crs.css'
import { CreateCommunicationModal } from '../communications/CreateCommunicationModal'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svgs/regular/file-alt.svg'
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'
import { cloneDeep } from 'lodash'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

export function CrsForm(props) {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const auth = useSelector((state) => state.auth)

  const createCommunicationRefData = useRef()

  const [subject, setSubject] = useState({})
  const [defaultEmail, setDefaultEmail] = useState('')

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)
  const [errorsSid, setErrorsSid] = useState({})

  const nameApplication = Constants.APPLICATION_CRS
  const id = props.subjectId

  const [showCreateCommunication, setShowCreateCommunication] = useState(true)

  const retrieveSubject = (id) => {
    if (id) {
      actions.getCrsSubject(id).then((response) => {
        setSubject(response)
        setNewInitialValues(response)
      })
    } else {
      setSubject({})
      setNewInitialValues(undefined)
    }
  }

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  const setNewInitialValues = (subjectItem = {}) => {
    if (isNotEmpty(subjectItem?.coraSubjectSid)) {
      setCoraSubjectSid(subjectItem?.coraSubjectSid)
    }
    if (isNotEmpty(auth)) {
      if (isEmpty(coraSubjectSid?.email)) {
        setDefaultEmail(auth?.user?.login)
      }
    }

    setInitialValues({
      id: subjectItem?.id,
      subjectCode: subjectItem?.subjectCode || '0' + props.newSubjectCode,
      fiscalCode: subjectItem?.fiscalCode || '',
      companyName: subjectItem?.companyName || '',
      address: subjectItem?.address || '',
      location: subjectItem?.location || '',
      province: subjectItem?.province || '',
      flagOicrTrust: subjectItem?.flagOicrTrust || false,
      idFiscalOicrTrust: subjectItem?.idFiscalOicrTrust || '',
      countryCode: subjectItem?.countryCode || 'IT',
      country: subjectItem?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      communicationTypeCode: subjectItem?.communicationTypeCode || '1',
      referenceYear: subjectItem?.referenceYear || new Date().getFullYear() - 1,
      lastCommunication: subjectItem?.lastCommunication || '',
      errorFiscalCode: false
    })
  }

  const validationSchema = Yup.object().shape({
    subjectCode: Yup.string().required('Campo obbligatorio'),
    companyName: Yup.string().required('Campo obbligatorio'),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    address: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    flagOicrTrust: Yup.boolean(),
    idFiscalOicrTrust: Yup.string().when('flagOicrTrust', {
      is: true, // When flagOicrTrust is true (checked)
      then: (schema) => schema.required('Campo obbligatorio'), // Make idFiscalOicrTrust required
      otherwise: (schema) => schema.notRequired() // Otherwise, it's optional
    }),
    countryCode: Yup.string().required('Campo obbligatorio'),
    country: Yup.string().required('Campo obbligatorio'),
    communicationTypeCode: Yup.string().required('Campo obbligatorio'),
    referenceYear: Yup.string().required('Campo obbligatorio'),
    lastCommunication: Yup.string(),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'subjectCode',
      errorLabel: 'Codice'
    },
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione Sociale'
    },
    {
      errorKey: 'address',
      errorLabel: 'Indirizzo'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede Legale (Località)'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'idFiscalOicrTrust',
      errorLabel: 'Id Fiscal OICR/TRUST'
    },
    {
      errorKey: 'country',
      errorLabel: 'Paese'
    },
    {
      errorKey: 'countryCode',
      errorLabel: 'Codice Paese'
    },
    {
      errorKey: 'referenceYear',
      errorLabel: 'Anno di riferimento'
    },
    {
      errorKey: 'lastCommunication',
      errorLabel: 'Ultima comunicazione'
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: 'Numero accreditamento SID'
    },
    {
      errorKey: 'errorPassword',
      errorLabel: 'Password di protezione'
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: 'Soggetto'
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: 'Configurazione SID'
    },
    {
      errorKey: 'errorEmail',
      errorLabel: 'Indirizzo email'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  const handleFiscalCode = (fiscalCode, setValues, values) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      actions.getBaseRegistryByFiscalCode(fiscalCode).then(
        (baseRegistry) => {
          if (baseRegistry) {
            form.companyName = baseRegistry?.companyName || ''
            form.location = baseRegistry?.location?.location || ''
            form.province = baseRegistry?.location?.province || ''
            form.lastName = baseRegistry?.lastName || ''
            form.firstName = baseRegistry?.firstName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender
          }
          form.errorFiscalCode = false
          setValues(form)
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
      actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'crs').then(
        (_coraSubjectSid) => {
          if (_coraSubjectSid?.id) {
            setCoraSubjectSid(_coraSubjectSid)
          }
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
    }
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const addCrsSubject = (newSubject, setValues, sendComm, sid) => {
    const shouldVerifyOnComurei =
      isEmpty(newSubject.id) || subject.fiscalCode !== newSubject.fiscalCode
    newSubject.coraSubjectSid = sid
    actions.addCrsSubject(newSubject).then(
      (response) => {
        setSubject(response)
        setValues(response)
        if (shouldVerifyOnComurei) {
          // TODO Check right behave
          actions.verifyCrsSubjectOnComurei(newSubject.fiscalCode).then(
            (res) => {
              if (res === false) {
                ComureiSubjectNotPresentAlert({
                  fiscalCode: newSubject.fiscalCode,
                  denomination: newSubject.companyName
                })
              } else {
                PopupSuccess({ text: 'Soggetto salvato correttamente' })
              }
            },
            (errors) => {
              console.log(errors)
              PopupSuccess({ text: 'Soggetto salvato correttamente' })
            }
          )
        } else if (sendComm) {
          if (document.getElementById('subject-modal')) {
            document.getElementById('subject-modal').style.display = 'none'
          }
          createCommunicationRefData.current.clickButtonSend()
        } else {
          PopupSuccess({ text: 'Soggetto salvato correttamente' })
        }
        props.getSubjects()
        closeModal()
      },
      (errors) => {
        if (errors.status === 511) {
          PopupError({
            text:
              errors.status === 511
                ? 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                : Constants.APPLICATION_GENERIC_ERROR
          })
        } else {
          PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
        }
      }
    )
  }

  const saveCrsSubject = (form, setValues, sendComm) => {
    saveSid(
      certificateFiles?.files,
      coraSubjectSid,
      form?.fiscalCode,
      'crs',
      (res) => addCrsSubject(form, setValues, sendComm, res),
      (errors) => {
        PopupError({
          ...props,
          text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR
        })
      }
    )
  }

  const updateAll = (values, setValues, sendComm) => {
    const form = Object.assign({}, values)
    if (sendComm === true) {
      const lastYear = new Date().getFullYear() - 1
      if (form.referenceYear < lastYear) {
        PopupAlertCommunicationYear(
          values.referenceYear,
          lastYear,
          () => saveCrsSubject(form, setValues, sendComm),
          () => {
            form.referenceYear = lastYear
            setSubject(form)
            setValues(form)
            saveCrsSubject(form, sendComm)
          }
        )
        return
      }
    }
    saveCrsSubject(form, setValues, sendComm)
  }

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const handleFormSubmit = async (values, setValues, validateForm, sendComm) => {
    const errors = await validateForm(values)
    setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0

    setErrorsSid({ ...errorsSid, errorSidNumber, errorEmail })
    if (thereAreErrors) {
      setShowErrorAlert(true)
    } else {
      updateAll(values, setValues, sendComm)
      setShowErrorAlert(false)
    }
  }

  const handleChangeSidValues = (newValues) => {
    setCoraSubjectSid({ ...coraSubjectSid, ...newValues })
  }

  const handleCommunicationTypeClick = (val, setValues, values) => {
    const form = Object.assign({}, values)
    setShowCreateCommunication(false)
    form.communicationTypeCode = val
    setValues(form)
  }

  const handleChangeValue = (val, name, setValues, values, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (val && val !== '' && trim) val = val.trim()
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleInputAutocompile = (newLocation, setValues, values) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  const handleInputAutocompileCountry = (newCountry, setValues, values) => {
    const form = Object.assign({}, values)
    if (!newCountry || newCountry === '') {
      form.countryCode = newCountry
      form.country = ''
      setValues(form)
      return
    }
    newCountry = newCountry ? newCountry.toUpperCase() : ''
    let countriesFilter = props.countries.filter((country) => {
      return country.name === newCountry
    })
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry
      form.country = ''
    }
    setValues(form)
  }

  const configurationSidValidation = () => {
    return validateSid(
      coraSubjectSid?.sidNumber,
      coraSubjectSid?.email,
      errorsSid.errorSidNumber,
      errorsSid.errorEmail
    )
  }

  // TODO ASPETTARE API DI EDOARDO
  const checkSid = (sidNumber) => {
    const allSubjects = props.subjects || []
    for (const subject of allSubjects) {
      const sid = subject.coraSubjectSid?.sidNumber
      if (subject.id != props.subjectId && sid === sidNumber) {
        PopupError({
          text: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
        })
        return false
      }
    }
    return true
  }

  /* Validation onChange */
  const handleChangeSid = (val, errorKey, errorMessage) => {
    let value = val.target.value
    let name = val.target.name
    let newCoraSubjectSid = cloneDeep(coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    setErrorsSid({ ...errorsSid, errorInConfigSid: { isValid: true, msg: '' }, ...error })
    if (name === 'password') {
      newCoraSubjectSid.password = value.toUpperCase().trim()
    }
    if (name === 'sidNumber') {
      if (checkSid(value.toUpperCase().trim())) {
        newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
      } else {
        PopupError({
          text: `Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`
        })
      }
    }
    if (name === 'email') {
      coraSubjectSid.email = value ? value.toUpperCase().trim() : value
    }
    setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleCertificateFilesCustom = (val) => {
    /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
    setIsCertSidOpened(true)
    if (isNotEmpty(val)) setCertificateFiles(val)
  }

  const closeModal = () => {
    setErrorsSid({})
    if (props.closeModal) props.closeModal()
  }

  const getErrors = (errors) => {
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}>
      {({ values, errors, setValues, validateForm }) => (
        <Form>
          {showErrorAlert && (
            <div>
              <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
            </div>
          )}
          <div className="row row-detail bg-gray d-flex">
            <div className="col-6 pe-3" id={`anagrafica-${subject?.id || 0}`}>
              <div className="configurazioneSID big p-3">
                <div id={`anagrafica-${id || 0}`}>
                  <div className="row mb-3">
                    <h5>
                      <IAnagrafica fill="#000" width="25" />
                      Anagrafica
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-md-2 pe-2">
                      <Field name="subjectCode">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="subjectCode"
                            className="form-control"
                            label="Codice"
                            value={values.subjectCode}
                            maxLength="16"
                            disabled={true}
                            error={errors.subjectCode}
                            touched={touched}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-3 mb-2 pe-2">
                      <Field name="fiscalCode">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="fiscalCode"
                            label={
                              !values.flagOicrTrust
                                ? 'Codice Fiscale'
                                : 'C. Fiscale del Comunicante'
                            }
                            className="form-control"
                            value={values.fiscalCode}
                            onChange={(e) =>
                              handleChangeValue(
                                e.target.value,
                                'fiscalCode',
                                setValues,
                                values,
                                true,
                                true
                              )
                            }
                            error={errors.fiscalCode || errors.errorFiscalCode}
                            touched={touched}
                            maxLength="11"
                            onBlur={(e) => handleFiscalCode(e.target.value, setValues, values)}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-md-4 pe-2">
                      <Field name="address">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="address"
                            label="Indirizzo"
                            className="form-control"
                            value={values.address}
                            onChange={(e) =>
                              handleChangeValue(e.target.value, 'address', setValues, values, true)
                            }
                            error={errors.address}
                            touched={touched}
                            maxLength="100"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-3">
                      <Field name="location">
                        {({ field }) => (
                          <AutoCompileCustom
                            {...field}
                            label="Sede Legale (Località)"
                            id="location"
                            handleInputAutocompile={(e) =>
                              handleInputAutocompile(e, setValues, values)
                            }
                            filtro={props.locations}
                            value={values.location}
                            error={errors.location}
                            touched={touched}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-3 pe-2">
                      <Field name="province">
                        {({ field }) => (
                          <ProvinceField
                            {...field}
                            province={values.province || ''}
                            placeholder="Prov. EE per Estero"
                            label="EE per Estero"
                            onChange={(e) =>
                              handleChangeValue(
                                e.target.value,
                                'province',
                                setValues,
                                values,
                                true,
                                true
                              )
                            }
                            error={errors.province}
                            touched={touched}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-9 mb-2">
                      <Field name="countryCode">
                        {({ field }) => (
                          <BwmSelect
                            options={props.countries}
                            name="country"
                            id="country"
                            label="Paese"
                            className="form-control"
                            onChange={(e) => handleInputAutocompileCountry(e, setValues, values)}
                            value={values.countryCode || ''}
                            error={errors.countryCode}
                            touched={touched}
                            showCode={true}
                            nameKey={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-9 pe-2">
                      <Field name="companyName">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="companyName"
                            label={
                              !values.flagOicrTrust
                                ? 'Ragione Sociale'
                                : 'Denominazione OICR o TRUST'
                            }
                            className="form-control"
                            value={values.companyName}
                            onChange={(e) =>
                              handleChangeValue(
                                e.target.value,
                                'companyName',
                                setValues,
                                values,
                                true
                              )
                            }
                            error={errors.companyName}
                            touched={touched}
                            maxLength="70"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-2 mt-3">
                      <Field name="flagOicrTrust">
                        {({ field }) => (
                          <BwmCheckbox
                            name={`flagOicrTrust`}
                            label="OICR o TRUST"
                            className="form-control"
                            onChange={(e) =>
                              handleChangeValue(
                                e.target.checked,
                                'flagOicrTrust',
                                setValues,
                                values
                              )
                            }
                            checked={field.value}
                            error={errors.flagOicrTrust}
                            touched={touched}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="mt-4 mb-1">
                      <h5 className="m-0">
                        <IAnagrafica fill="#000" width="25" />
                        Tipo Comunicazione
                      </h5>
                    </div>
                    <div className="col-12">
                      <div className="input-group flex-column h-100 justify-content-around">
                        {/* Radio Buttons Section */}
                        <div className="row mb-3 mt-3">
                          <div className="col-auto">
                            <div className="inputRadio me-3 pe-2">
                              <input
                                hidden={true}
                                className="form-check-input"
                                type="radio"
                                name={`communicationTypeCode`}
                                checked={values.communicationTypeCode === '1'}
                                id={`ct1`}
                                value={'1'}
                                onChange={(e) =>
                                  handleCommunicationTypeClick(e.target.value, setValues, values)
                                }
                              />
                              <label className="form-check-label" htmlFor={`ct1`}>
                                <img
                                  width="20"
                                  className="pe-2"
                                  src={values.communicationTypeCode === '1' ? radioSel : radioUnsel}
                                  alt=""
                                />
                                NUOVI DATI
                              </label>
                            </div>
                          </div>
                          <div className="col-auto me-3">
                            <div className="inputRadio">
                              <input
                                hidden={true}
                                className="form-check-input"
                                type="radio"
                                name={`communicationTypeCode`}
                                checked={values.communicationTypeCode === '2'}
                                id={`ct2`}
                                value={'2'}
                                disabled={true}
                                onChange={(e) =>
                                  handleCommunicationTypeClick(e.target.value, setValues, values)
                                }
                              />
                              <label className="form-check-label" htmlFor={`ct2`}>
                                <img
                                  width="20"
                                  className="pe-2"
                                  src={values.communicationTypeCode === '2' ? radioSel : radioUnsel}
                                  alt=""
                                />
                                SOSTITUTIVA
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="inputRadio">
                              <input
                                hidden={true}
                                className="form-check-input"
                                type="radio"
                                name={`communicationTypeCode`}
                                checked={values.communicationTypeCode === '3'}
                                id={`ct3`}
                                value={'3'}
                                disabled={true}
                                onChange={(e) =>
                                  handleCommunicationTypeClick(e.target.value, setValues, values)
                                }
                              />
                              <label className="form-check-label" htmlFor={`ct3`}>
                                <img
                                  width="20"
                                  className="pe-2"
                                  src={values.communicationTypeCode === '3' ? radioSel : radioUnsel}
                                  alt=""
                                />
                                ANNULLAMENTO
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Fields Section */}
                        <div className="row">
                          <div className="col mb-3 pe-2">
                            <div className="form-group">
                              <Field name="referenceYear">
                                {({ field }) => (
                                  <BwmInput
                                    {...field}
                                    name="referenceYear"
                                    label="Anno di riferimento"
                                    className="form-control"
                                    value={values.referenceYear}
                                    error={errors.referenceYear}
                                    touched={touched}
                                    maxLength="4"
                                    onChange={(e) =>
                                      handleChangeValue(
                                        e.target.value,
                                        'referenceYear',
                                        setValues,
                                        values
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="col pe-2">
                            <div className="form-group">
                              <Field name="lastCommunication">
                                {({ field }) => (
                                  <BwmInput
                                    {...field}
                                    name="lastCommunication"
                                    label="Ultima comunicazione"
                                    className="form-control"
                                    value={values.lastCommunication}
                                    error={errors.lastCommunication}
                                    touched={touched}
                                    maxLength="100"
                                    disabled={true}
                                    onChange={(e) =>
                                      handleChangeValue(
                                        e.target.value,
                                        'lastCommunication',
                                        setValues,
                                        values
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="col">
                            <div
                              className={`form-group ${values.flagOicrTrust && 'show'}`}
                              data-tip="ID OICR senza p. giuridica rilasciato da Banca d'Italia (5 caratteri) oppure ID Fiscale TRUST (16 caratteri)">
                              <div className="input-group">
                                <Field name="idFiscalOicrTrust">
                                  {({ field }) => (
                                    <BwmInput
                                      {...field}
                                      name="idFiscalOicrTrust"
                                      label={'Id Fiscal OICR/TRUST'}
                                      value={values.idFiscalOicrTrust}
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeValue(
                                          e.target.value,
                                          'idFiscalOicrTrust',
                                          setValues,
                                          values,
                                          true,
                                          true
                                        )
                                      }
                                      error={errors.idFiscalOicrTrust}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </div>
                              {!errors.idFiscalOicrTrust && touched && (
                                <div className="text-danger">{errors.idFiscalOicrTrust}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-6'}>
              <div className="configurazioneSID p-3">
                <SidContactFormContainer
                  id={props.subjectId || 0}
                  errors={errorsSid}
                  nameApplication={nameApplication}
                  subject={subject}
                  defaultEmail={defaultEmail}
                  coraSubjectSid={coraSubjectSid}
                  certificateFiles={certificateFiles}
                  fiscalCode={subject?.fiscalCode}
                  licenseExpired={props.licenseExpired}
                  setCoraSubjectSid={setCoraSubjectSid}
                  setErrorCertificates={(errorCertificates) =>
                    setErrorsSid({ ...errorsSid, errorCertificates })
                  }
                  handleChangeSid={handleChangeSid}
                  handleChangeSidValues={handleChangeSidValues}
                  handleCertificateFilesCustom={handleCertificateFilesCustom}
                />
                {values.id && (
                  <div className="panel-info align-items-end">
                    <h5>Codice soggetto: {subject?.id}</h5>
                    {coraSubjectSid && <h5>Codice SID: {coraSubjectSid?.id}</h5>}
                  </div>
                )}
              </div>
            </div>
            <div className={'col-12 mt-4 d-flex justify-content-between'}>
              <button
                type="button"
                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                onClick={closeModal}>
                <IUndo className={'svg'} width="18" fill={'#128186'} />
                &nbsp; ANNULLA
              </button>
              <CreateCommunicationModal
                {...props}
                ref={createCommunicationRefData}
                subjectId={values?.id}
                emailAddress={coraSubjectSid?.email}
                generateEmpty={true}
                classCustom={'display-none'}
                closeSubjectModal={closeModal}
                subject={subject}
              />
              <button
                type="button"
                onClick={() => handleFormSubmit(values, setValues, validateForm, true)}
                disabled={props.licenseExpired}
                className="btn btn-primary btn-new-rel px-5">
                <ITextFile className={'svg'} width="16" fill={`#FFFFFF`} />
                &nbsp; CREA COMUNICAZIONE VUOTA
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-new-rel px-5"
                onClick={() => handleFormSubmit(values, setValues, validateForm)}>
                <ISave className={'svg'} width="18" fill={'#FFFFFF'} />
                &nbsp; CONFERMA
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
