import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import * as Constants from '../../../../config/Constants'
import 'react-datepicker/dist/react-datepicker.css'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import Tabs from './Tabs'
import BalanceForm from './BalanceForm'
import * as actions from '../../../../actions'
import DelegateForm from './DelegateForm'
import { DateField } from '../../../shared/form/DateFieldB5'
import { PersonTypeField } from './PersonTypeField'
import { ProvinceField } from './ProvinceFieldB5'
import '../../../../styles/autocomplete.css'
import * as Utility from '../../../shared/Utility'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import CodiceFiscale from 'codice-fiscale-js'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { PopupError } from '../../../shared/PopupError'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { RankedDropdownList } from '../../../shared/form/RankedDropdownList'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import { endOfDay } from 'date-fns'
import { Col, Row } from 'react-bootstrap'
import { ICopy, ISave, IUndo, IUnlock } from '../../../../styles/icons'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'
import { Formik, Field, Form } from 'formik'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import * as Yup from 'yup'

registerLocale('it', it)

export default function RelationshipForm(props) {
  const subjectid = props.subjectId
  const [touched, setTouched] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const relationshipTypes = useSelector((state) => state.relationshipTypes.data)
  const rankedrelationshipTypes = useSelector((state) => state.rankedRelationshipTypes.data)

  const [commTypeList, setCommTypeList] = useState(props?.communicationTypes || [])
  const [showCab, setShowCab] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [endDateDisabled, setEndDateDisabled] = useState(false)

  const setNewInitialValues = (data = {}) => {
    setShowCab(Constants.codRelTypeCab.indexOf(data.relationshipType?.code) !== -1)
    setShowDescription(data.relationshipType?.code === '99')
    if (data?.relationshipType?.code === '98') {
      setEndDateDisabled(data?.relationshipType?.code === '98')
      data.endDate = null
    }
    data.relationshipType =
      data.relationshipType || rankedrelationshipTypes[0] || relationshipTypes[0]

    setInitialValues({
      id: data.id,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
      relationshipType: {
        id: data.relationshipType?.id,
        code: data.relationshipType?.code,
        description: data.relationshipType?.description
      },
      coraSubject: { id: subjectid },
      coraRole: { id: '1' },
      cab: Constants.codRelTypeCab.indexOf(data?.relationshipType?.code) !== -1 ? data.cab : '',
      description: data?.relationshipType?.code === '99' ? data.description : '',
      personType: data?.personType || Constants.PNF,
      fiscalCode: data.fiscalCode || '',
      companyName: data.companyName || '',
      location: data.location || '',
      province: data.province || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      gender: data.gender || 'FEMALE',
      birthDate: data.birthDate ? new Date(data.birthDate) : null,
      comment: data.comment || '',
      identifier: data.identifier || '',
      includedInNextCommunication: data.includedInNextCommunication !== false,
      coraCommunicationType: {
        id: data.coraCommunicationType?.id || '1'
      },
      codError: data.codError,
      idResult: data.idResult || null,
      lastCommunicationError: data.lastCommunicationError,
      coraRelationDelegates: data.coraRelationDelegates || [],
      canceledAndCommunicated: data.canceledAndCommunicated,
      showDescription,
      showCab,
      endDateDisabled,
      errorFiscalCode: false
    })
  }

  const retrieveSubject = () => {
    if (props.subjectId && props.id) {
      actions.getCoraRelationshipBySubjectIdAndId(props.subjectId, props.id).then((data) => {
        setNewInitialValues(data)
      })
    } else {
      setNewInitialValues()
    }
  }

  useEffect(() => {
    retrieveSubject()
  }, [props.id])

  const sameMonthYear = (startDate, endDate) => {
    return (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    )
  }

  const canCloseReport = (endDateDisabled, commType) => {
    return !(endDateDisabled && commType === 2)
  }

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required('Campo obbligatorio'),
    endDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'), 'La data fine non può essere minore della data inizio')
      .test(
        'same-month-year',
        'Data di fine non valida per comunicazione nuovo rapporto',
        function (value) {
          const { startDate, coraCommunicationType } = this.parent
          if (!startDate) return false
          if (coraCommunicationType?.id === '1' && value) {
            return sameMonthYear(new Date(startDate), new Date(value))
          }
          return true
        }
      )
      .test('can-close', 'Chiusura non ammessa per rapporti di tipo 98', function (value) {
        const { endDateDisabled, coraCommunicationType } = this.parent
        return canCloseReport(endDateDisabled, coraCommunicationType?.id)
      }),
    cab: Yup.string().when('showCab', (data, schema) => {
      if (data[0]) {
        return schema.required('Campo obbligatorio')
      }
      return
    }),
    description: Yup.string().when('showDescription', (data, schema) => {
      if (data[0]) {
        return schema.required('Campo obbligatorio')
      }
      return
    }),
    personType: Yup.string().required('Selezionare il tipo di persona'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().nullable(),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    relationshipType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio'),
      code: Yup.string(),
      description: Yup.string()
    }),
    coraCommunicationType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio')
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Denominazione'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede legale'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'gender',
      errorLabel: 'Sesso'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'coraCommunicationType.id',
      errorLabel: 'Tipo Comunicazione'
    },
    {
      errorKey: 'cab',
      errorLabel: 'CAB'
    },
    {
      errorKey: 'startDate',
      errorLabel: 'Data inizio'
    },
    {
      errorKey: 'endDate',
      errorLabel: 'Data fine'
    }
  ]

  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  const [disableIdentifierInput, setDisableIdentifierInput] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!relationshipTypes?.length) {
      dispatch(actions.getRelationshipTypes())
    }
    dispatch(actions.getRankedRelationshipTypes(props.subject.id))
  }, [])

  // i rapporti cancellati e comunicati in precedenza devono tornare visibili sulla lista principale in caso di modifica
  useEffect(() => {
    if (initialValues.id) {
      setInitialValues({
        ...initialValues,
        personType: initialValues.personType || Constants.PNF,
        canceledAndCommunicated: false
      })
    }
  }, [
    initialValues.startDate,
    initialValues.endDate,
    initialValues.cab,
    initialValues.description,
    initialValues.personType,
    initialValues.fiscalCode,
    initialValues.companyName,
    initialValues.location,
    initialValues.lastName,
    initialValues.firstName,
    initialValues.gender,
    initialValues.birthDate,
    initialValues.comment,
    initialValues.includedInNextCommunication,
    initialValues.identifier,
    initialValues.relationshipType,
    initialValues.coraCommunicationType
  ])

  const handlePersonTypeClick = (val, setValues, values) => {
    const form = Object.assign({}, values)
    if (val === Constants.PF) {
      form.personType = val
      form.gender = 'FEMALE'
      form.companyName = null
      form.location = null
    } else if (val === Constants.PNF) {
      form.personType = val
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
      form.location = null
    }
    setValues(form)
  }

  const handleInputAutocompile = (location, setValues, values) => {
    const form = Object.assign({}, values)
    if (!location || location === '') {
      form.location = location
      setValues(form)
    }
    if (form.province?.toUpperCase() === 'EE') {
      form.location = location?.toUpperCase() || ''
      form.province = form.province?.toUpperCase() || ''
      return
    }
    actions.getLocation(location?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.province = loc.province
          form.location = location?.toUpperCase() || ''
          setValues(form)
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    form.location = location?.toUpperCase() || ''
    setValues(form)
  }

  const resetTypeDependentFields = (setValues, values) => {
    const form = Object.assign({}, values)
    setShowCab(false)
    form.cab = null
    setShowDescription(false)
    setEndDateDisabled(false)
    setValues(form)
  }

  const handlerChangeRelationshipType = (val, setValues, values) => {
    const form = Object.assign({}, values)
    const relationshipType = relationshipTypes.find((el) => el.id?.toString() === val?.toString())
    const cod = relationshipType?.code
    form.relationshipType = relationshipType
    resetTypeDependentFields(setValues, values)

    if (Constants.codRelTypeCab.indexOf(cod) !== -1) {
      setShowCab(true)
    } else if (cod === '98') {
      form.endDate = null
      setEndDateDisabled(true)
    } else if (cod === '99') {
      setShowDescription(true)
    }
    setValues(form)
  }

  const handlerChange = (val, name, setValues, values) => {
    const form = Object.assign({}, values)
    if (name === 'includedInNextCommunication') {
      form.includedInNextCommunication = val
    } else if (name === 'coraCommunicationType') {
      val = val && val !== '' ? parseInt(val.trim()) : null
      form.coraCommunicationType = { id: val }
      form.includedInNextCommunication = true
    }
    setValues(form)
  }

  const handlerChangeEndDate = (val, setValues, values) => {
    const form = Object.assign({}, values)
    form.endDate = val
    setValues(form)
    handleEndDateLogic(val, setValues, values)
  }

  const handleChangeValue = (val, name, setValues, values, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (val && val !== '' && trim) val = val.trim()
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleEndDateLogic = (endDate, setValues, values) => {
    const form = Object.assign({}, values)
    let today = isNotEmpty(delayedCommunicationDate)
      ? new Date(delayedCommunicationDate)
      : new Date()
    //datemin = inizio mese precedente; datemax:fine mese precedente
    let endDateEndOfDay = endOfDay(endDate) //utilizzo endOfDay di date-fns per prevenire casistiche in cui l'ora di endDate sia successiva all'ra di dateMax
    let dateMin = endOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1))
    let dateMax = endOfDay(new Date(today.getFullYear(), today.getMonth(), 0))
    // Se la data selezionata è tra datemin e datemax-> tipo comunicazione 5-Chiusura nei termini
    if (endDateEndOfDay >= dateMin && endDateEndOfDay <= dateMax) {
      form.coraCommunicationType = { id: '2' }
      form.includedInNextCommunication = true
      setValues(form)
    } else if (endDateEndOfDay < dateMin) {
      //se la data selezionata è precedente al mese precedente -> tipo comunicazione 2=chiusura tardiva
      form.coraCommunicationType = { id: '3' }
      form.includedInNextCommunication = true
    }
    form.endDate = endDate
    setValues(form)
  }

  const handleFiscalCode = (val, setValues, values) => {
    const form = Object.assign({}, values)
    actions
      .getBaseRegistryPersonByFiscalCode(val)
      .then(
        (baseRegistry) => {
          if (isNotEmpty(baseRegistry)) {
            if (baseRegistry.personType === Constants.PNF) {
              form.companyName = baseRegistry?.companyName || form.companyName
              form.location = baseRegistry?.location || form.location
              form.province = baseRegistry?.province || form.province
            } else if (baseRegistry?.personType === Constants.PF) {
              form.lastName = baseRegistry?.lastName || form.lastName
              form.firstName = baseRegistry?.firstName || form.firstName
              form.birthDate = baseRegistry?.birthDate
                ? new Date(baseRegistry?.birthDate)
                : form.birthDate
              form.gender = baseRegistry?.gender || form.gender
              form.location = baseRegistry?.location?.location || form.location
              form.province = baseRegistry?.location?.province || form.province
            }
            form.errorFiscalCode = false
            setValues(form)
          }
        },
        () => {
          if (form.personType === Constants.PF) {
            try {
              const cf = new CodiceFiscale(val).toJSON()
              if (cf) {
                form.birthDate = cf?.birthday ? new Date(cf?.birthday) : null
                form.gender = cf?.gender === 'M' ? 'MALE' : form.gender
                form.location = cf?.birthplace.toUpperCase() || form.location
                form.province = cf?.birthplaceProvincia || form.province
              }
              form.errorFiscalCode = false
            } catch (e) {
              form.errorFiscalCode = true
            }
            setValues(form)
          }
        }
      )
      .then(() => {
        testForSimilarCoraRelationship(values, props.handleCloseModal)
      })
  }

  const testForSimilarCoraRelationship = (values, onConfirm = () => {}, onCancel = () => {}) => {
    return actions.testForSimilarCoraRelationship(values, props.subjectId).then((res) => {
      if (res) {
        PopupConfirm({
          titleColor: titleColors.ALERT,
          title: 'RILEVATO RAPPORTO POTENZIALMENTE DUPLICATO',
          component: () => {
            return (
              <>
                <Row>
                  <Col className="text-center">
                    <strong> Stessa data inizio, stesso soggetto, stesso tipo rapporto</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    Ricordiamo che per chiudere (o aggiornare) rapporti già comunicati basta
                    selezionare il rapporto dall'elenco e indicare SOLO la data di chiusura (non
                    deve essere creato un nuovo rapporto) e impostare poi il tipo comunicazione
                    5=chiusura nei termini o 2=chiusura tardiva o aggiornamento.
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <strong>
                      {' '}
                      Se il rapporto NON è duplicato, premere CONFERMA, altrimenti CHIUDI SENZA
                      SALVARE e il rapporto NON verrà registrato.
                    </strong>
                  </Col>
                </Row>
              </>
            )
          },
          confirm: 'CHIUDI SENZA SALVARE',
          cancel: 'CONFERMA',
          handleClickConfirm: onConfirm,
          onClickCancel: onCancel
        })
      }
    })
  }

  const onTabChange = async (values, validateForm) => {
    const errors = await validateForm(values)
    if (Object.keys(errors).length > 0) {
      const allTouched = Object.keys(values).reduce((acc, key) => {
        acc[key] = true // Imposta ogni campo come "touched"
        return acc
      }, {})

      setTouched(allTouched)
      setShowErrorAlert(true)
      return false
    } else {
      setShowErrorAlert(false)
      if (!values?.id) {
        saveAndSetRelationship(values)
        return true
      }
      return true
    }
  }

  const handleFormSubmit = async (values, validateForm) => {
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      setShowErrorAlert(false)
      if (!values?.id) {
        saveAndSetRelationship(values, props.handleCloseModal)
      } else {
        updateRelationship(values, handlerShowDetail)
      }
    }
  }

  const saveAndSetRelationship = (values, onSuccess = () => {}) => {
    const formData = Object.assign(values, {})
    delete formData.setShowCab
    delete formData.setShowDescription
    delete formData.endDateDisabled
    actions.createCoraRelationship(formData).then(
      (res) => {
        if (res?.constructor === Object) {
          setNewInitialValues(res)
        }
        props.getRelationships(props.subjectId)
        onSuccess()
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
        return false
      }
    )
  }

  const updateRelationship = (formData, onSuccess) => {
    formData.lastCommunicationError = null
    delete formData.endDateDisabled
    delete formData.showDescription
    delete formData.showCab
    delete formData.coraRelationDelegates
    actions.updateCoraRelationship(formData).then(
      () => {
        onSuccess()
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      }
    )
  }

  const handlerShowDetail = () => {
    props.getRelationships(props.subjectId)
    if (props.handleCloseModal) {
      props.handleCloseModal()
    }
  }

  const prepareClonedRelationship = (values) => {
    let cloned = JSON.parse(JSON.stringify(values))
    let cleanDelegates = []
    for (let delegate of values.coraRelationDelegates || []) {
      cleanDelegates.push({
        ...delegate,
        id: null
      })
    }

    cloned.id = null
    actions.createCoraRelationship(cloned).then(
      (res) => {
        if (cleanDelegates.length > 0) {
          let promises = []
          for (let index = 0; index < cleanDelegates.length; index++) {
            const delegate = cleanDelegates[index]
            delegate.coraRelationship = { id: res.id }
            promises.push(actions.createCoraDelegate(delegate))
          }
          Promise.all(promises).then(() => {
            props.getRelationships(props.subjectId)
            PopupSuccess({
              title: 'Rapporto clonato con successo , il rapporto clonato è aperto nel dettaglio'
            })
            props.handleCloseModal()
            props.onClone(res.id)
          })
        } else {
          props.getRelationships(props.subjectId)
          PopupSuccess({
            title: 'Rapporto clonato con successo, il rapporto clonato è aperto nel dettaglio'
          })
          props.handleCloseModal()
          props.onClone(res.id)
        }
      },
      (err) => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = 'Luogo di nascita'
    else formErrorsTabMapping[4].errorLabel = 'Sede legale (Comune)'
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      context={{ showCab, showDescription, endDateDisabled }}
      validationSchema={validationSchema}>
      {({ values, errors, setValues, validateForm }) => (
        <Form>
          {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
          {showErrorAlert &&
            errors &&
            typeof errors === 'object' &&
            Object.keys(errors).length > 0 && (
              <div>
                <ErrorListAlert
                  errors={getErrors(errors, values.personType)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
          <div>
            <input
              defaultValue={values?.identifier || ''}
              className="form-control"
              name="identifier"
              hidden={true}
            />

            <div className="form-row">
              <div className="col col-lg-12 col-xl-6 pe-3 mb-3">
                <div className="panel">
                  <div className="mb-2">
                    <Field name="relationshipType.id">
                      {({ field }) => (
                        <RankedDropdownList
                          {...field}
                          name="relationshipType"
                          label={'Tipo di Rapporto'}
                          list={relationshipTypes}
                          rankedElements={rankedrelationshipTypes}
                          onChange={(e) =>
                            handlerChangeRelationshipType(e.target.value, setValues, values)
                          }
                          error={errors.relationshipType}
                          touched={touched}
                          selectedValue={values.relationshipType?.id}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="d-flex w-100">
                    <div className="col-4 pe-2">
                      <Field name="startDate">
                        {({ field }) => (
                          <DateField
                            {...field}
                            label={'Data Inizio'}
                            date={values.startDate}
                            onChange={(date) =>
                              handleChangeValue(date, 'startDate', setValues, values)
                            }
                            error={errors.startDate}
                            touched={touched}
                          />
                        )}
                      </Field>
                    </div>
                    {!endDateDisabled && (
                      <div className="col-4 pe-2">
                        <Field name="endDate">
                          {({ field }) => (
                            <DateField
                              {...field}
                              label={'Data Fine'}
                              date={values.endDate}
                              onChange={(date) => handlerChangeEndDate(date, setValues, values)}
                              error={errors.endDate}
                              touched={touched}
                              isDisabled={endDateDisabled}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    {showCab && (
                      <div className="small col">
                        <Field name="cab">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="cab"
                              label="CAB"
                              className="form-control"
                              value={values.cab}
                              error={errors.cab}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(e.target.value, 'cab', setValues, values)
                              }
                              maxLength="5"
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    {showDescription && (
                      <div className="small col">
                        <Field name="description">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="description"
                              label="Descrizione"
                              className="form-control"
                              value={values.description}
                              error={errors.description}
                              touched={touched}
                              onChange={(e) =>
                                handleChangeValue(
                                  e.target.value,
                                  'description',
                                  setValues,
                                  values,
                                  true
                                )
                              }
                              maxLength="24"
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="panel col col-lg-6 col-sm-12 d-flex flex-column mb-3">
                <div className="row d-flex">
                  <div className="col-8 pe-2">
                    <Field name="coraCommunicationType.id">
                      {({ field }) => (
                        <BwmSelect
                          {...field}
                          options={commTypeList}
                          name="coraCommunicationType"
                          label="Tipo Comunicazione"
                          className="form-control"
                          value={values.coraCommunicationType?.id}
                          error={errors.coraCommunicationType}
                          touched={touched}
                          onChange={(e) =>
                            handlerChange(
                              e.target.value,
                              'coraCommunicationType',
                              setValues,
                              values
                            )
                          }
                          showCode={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-4 pt-3">
                    <div>
                      <Field name="includedInNextCommunication">
                        {({ field }) => (
                          <BwmCheckbox
                            {...field}
                            name={`includedInNextCommunication_${values?.id}`}
                            label="Incluso nella prossima comunicazione"
                            className="form-control"
                            checked={values.includedInNextCommunication}
                            error={errors.includedInNextCommunication}
                            touched={touched}
                            onChange={(e) =>
                              handlerChange(
                                e.target.checked,
                                'includedInNextCommunication',
                                setValues,
                                values
                              )
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div>
                  {values?.identifier && (
                    <div className="w-100 mt-2">
                      <Field name="identifier">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="identifier"
                            label="Codice Univoco"
                            className="form-control"
                            Icon={() => {
                              if (disableIdentifierInput) {
                                return (
                                  <IUnlock
                                    fill={'#128186'}
                                    className={'padding-bottom-4px svg'}
                                    width="25"
                                  />
                                )
                              } else {
                                return null
                              }
                            }}
                            iconTip={
                              disableIdentifierInput ? 'Clicca per abilitare le modifiche' : ''
                            }
                            onIconClick={() => {
                              disableIdentifierInput && setDisableIdentifierInput(false)
                            }}
                            value={values.identifier}
                            error={errors.identifier}
                            touched={touched}
                            disabled={disableIdentifierInput}
                            onChange={(e) =>
                              handleChangeValue(
                                e.target.value,
                                'identifier',
                                setValues,
                                values,
                                true,
                                true
                              )
                            }
                            maxLength="50"
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </div>
                {values.canceledAndCommunicated && (
                  <div className="justify-content-center pt-2">
                    <Col className="text-danger" style={{ fontSize: '1.1rem' }}>
                      <strong> ULTIMA COMUNICAZIONE CANCELLAZIONE</strong>
                    </Col>
                  </div>
                )}
              </div>

              {/* {values?.id && (
                <div className="panel-info col col-lg-6 col-xl-2 col-sm-12 pt-2">
                  <h6>ID: {values?.id}</h6>
                  {values?.codError && (
                    <h6 className={'text-red'}>COD. ERRORE: {values?.codError}</h6>
                  )}
                  <h5>ID ESITO:</h5>
                  <h6>
                    <input
                      className={'input-esit'}
                      value={values.idResult}
                      type={'text'}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'idResult', setValues, values, true, true)
                      }
                    />
                  </h6>
                  <h5>Data di creazione:</h5>
                  <h6>{formatDateForDisplay(values?.createDate)}</h6>
                  <h5>Data ultima modifica:</h5>
                  <h6>
                    {values.modifyDate ? formatDateForDisplay(values.modifyDate) : '--/--/--'}
                  </h6>
                </div>
              )} */}
            </div>
            <div className="form-row">
              <div className="tab-content relationship col-md-12 col-lg-12">
                <Tabs className="pt-2 bg-white">
                  <div id="tab-rel" label="Rapporto">
                    <div className="form-row p-3 pb-2 pt-2">
                      <div className="w-15">
                        <Field name="personType">
                          {({ field }) => (
                            <PersonTypeField
                              {...field}
                              key={props.index}
                              id={props.index}
                              personType={values.personType}
                              error={errors.personType}
                              touched={touched}
                              onClick={(e) =>
                                handlePersonTypeClick(e.target.value, setValues, values)
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div
                        id="infoPnf"
                        className={
                          'col-7' + (values.personType === Constants.PNF ? '' : ' d-none')
                        }>
                        <div className="row">
                          <div className="col-4 pe-2 mb-2">
                            <Field name="fiscalCode">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="fiscalCode"
                                  label="Codice Fiscale"
                                  className="form-control"
                                  value={values.fiscalCode}
                                  error={errors.fiscalCode || errors.errorFiscalCode}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'fiscalCode',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  maxLength="16"
                                  onBlur={(e) =>
                                    handleFiscalCode(e.target.value, setValues, values)
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-8 mb-2 pe-4">
                            <Field name="companyName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="companyName"
                                  label="Denominazione"
                                  className="form-control"
                                  value={values.companyName}
                                  error={errors.companyName}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'companyName',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  maxLength="60"
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-md-5 col-lg-5 pe-2">
                            <Field name="location">
                              {({ field }) => (
                                <AutoCompileCustom
                                  {...field}
                                  label="Sede Legale (Comune) "
                                  id="location"
                                  value={values.location}
                                  error={errors.location}
                                  touched={touched}
                                  handleInputAutocompile={(e) =>
                                    handleInputAutocompile(e, setValues, values)
                                  }
                                  filtro={props.relationshipLocations}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-md-3 col-lg-3">
                            <Field name="province">
                              {({ field }) => (
                                <ProvinceField
                                  {...field}
                                  placeholder="Prov. EE per Estero"
                                  label="EE per Estero"
                                  province={values.province}
                                  error={errors.province}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'province',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div
                        id="infoPf"
                        className={
                          'col-md-7 col-lg-7' +
                          (values.personType === Constants.PF ? '' : ' d-none')
                        }>
                        <div className="form-row col-md-12 col-lg-12 mb-2">
                          <div className="col-4 pe-2">
                            <Field name="fiscalCode">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="fiscalCode"
                                  label="Codice Fiscale"
                                  className="form-control"
                                  value={values.fiscalCode}
                                  error={errors.fiscalCode || errors.errorFiscalCode}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'fiscalCode',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  maxLength="16"
                                  onBlur={(e) =>
                                    handleFiscalCode(e.target.value, setValues, values)
                                  }
                                />
                              )}
                            </Field>
                          </div>

                          <div className="col-4 pe-2">
                            <Field name="lastName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="lastName"
                                  label="Cognome"
                                  className="form-control"
                                  value={values.lastName}
                                  error={errors.lastName}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'lastName',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  maxLength="26"
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-4 pe-2">
                            <Field name="firstName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="firstName"
                                  label="Nome"
                                  className="form-control"
                                  value={values.firstName}
                                  error={errors.firstName}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'firstName',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  maxLength="25"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="form-row col-md-12 col-lg-12">
                          <div className="col-2 pe-2">
                            <Field name="gender">
                              {({ field }) => (
                                <BwmSelect
                                  {...field}
                                  options={Constants.genderList}
                                  name="gender"
                                  label="Sesso"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'gender',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  value={values.gender}
                                  error={errors.gender}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-2 pe-2">
                            <Field name="birthDate">
                              {({ field }) => (
                                <DateField
                                  {...field}
                                  name={'birthDate'}
                                  label={'Data di Nascita'}
                                  date={values.birthDate}
                                  error={errors.birthDate}
                                  touched={touched}
                                  onChange={(date) =>
                                    handleChangeValue(date, 'birthDate', setValues, values)
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-4 pe-2">
                            <Field name="location">
                              {({ field }) => (
                                <AutoCompileCustom
                                  {...field}
                                  label="Comune di Nascita"
                                  id="location"
                                  handleInputAutocompile={(e) =>
                                    handleInputAutocompile(e, setValues, values)
                                  }
                                  filtro={props.relationshipLocations}
                                  value={values.location}
                                  error={errors.location}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-3">
                            <Field name="province">
                              {({ field }) => (
                                <ProvinceField
                                  {...field}
                                  placeholder="Prov. EE per Estero"
                                  label="EE per Estero"
                                  province={values.province}
                                  error={errors.province}
                                  touched={touched}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'province',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className="col d-flex">
                        <Field name="comment">
                          {({ field }) => (
                            <FloatingLabel className="w-100" label={'Note'}>
                              <textarea
                                {...field}
                                name="comment"
                                className={'form-control custom-text-area'}
                                rows={3}
                                cols={12}
                                value={values.comment}
                                error={errors.comment}
                                touched={touched}
                                maxLength={255}
                                onChange={(e) =>
                                  handleChangeValue(e.target.value, 'comment', setValues, values)
                                }
                              />
                            </FloatingLabel>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div
                    handleCreateRelationship={(e) => onTabChange(values, validateForm)}
                    id="tab-tit"
                    className="d-none"
                    label="Titolare Eff. e Delegato">
                    <DelegateForm
                      key={props.index}
                      relationship={values}
                      startDate={values.startDate}
                      endDate={values.endDate}
                      relationshipProvinces={props.relationshipProvinces}
                      relationshipLocations={props.relationshipLocations}
                      roles={props.roles}
                      subjectId={subjectid}
                      getRelationships={props.getRelationships}
                      updateDelegates={(delegatesArr) => {
                        values.coraRelationDelegates = delegatesArr
                        values.canceledAndCommunicated = false
                        setValues(values)
                      }}
                    />
                  </div>
                  <div
                    handleCreateRelationship={(e) => onTabChange(values, validateForm)}
                    id="tab-saldi"
                    className="d-none"
                    label="Saldi Annuali">
                    <BalanceForm
                      key={props.index}
                      relationship={values}
                      currencies={props.currencies}
                      subjectId={subjectid}
                      onSave={() => {
                        values.canceledAndCommunicated = false
                        setValues(values)
                      }}
                    />
                  </div>
                </Tabs>
              </div>
            </div>
            <div className="form-row mt-4 d-flex justify-content-between">
              <button className="btn btn-empty px-4 btn-sm" onClick={handlerShowDetail}>
                <IUndo className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
                &nbsp; ANNULLA
              </button>
              <button
                className={'btn btn-empty'}
                hidden={!subjectid}
                onClick={() => {
                  prepareClonedRelationship(values)
                }}>
                <ICopy className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
                &nbsp; CLONA RAPPORTO
              </button>
              <button
                onClick={() => handleFormSubmit(values, validateForm)}
                className="btn btn-new-rel px-5">
                <ISave className={'padding-bottom-4px svg'} width="16" fill={'#FFFFFF'} />
                &nbsp; CONFERMA
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
