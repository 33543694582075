import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnFill } from './BtnFill'

export const titleColors = {
  ALERT: 'popup-title-alert',
  SUCCESS: 'popup-title-success',
  ERROR: 'popup-title-delete'
}

export function PopupConfirm({
  title,
  titleColor,
  component,
  innerHtml,
  text,
  onClickCancel,
  showConfirmButton = true,
  showCancelButton = true,
  handleClickConfirm,
  confirm,
  cancel
}) {
  const Component = component || (() => <></>)

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup popup-large modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="close btn btn-outline-primary btn-sm"
                onClick={onClose}>
                <span aria-hidden="true" style={{ fontSize: '14px' }}>
                  X
                </span>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <Row className={`show-grid ${titleColor} text-center`}>
              <Col md={12} lg={12}>
                <h4 className="fw-bold">{title || 'Attenzione'}</h4>
              </Col>
            </Row>
            <Row className={'show-grid text-center'} style={{ fontSize: '15px' }}>
              <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: innerHtml }}></Col>
              <Col md={12} lg={12} style={{ fontSize: '15px' }}>
                {text}
              </Col>
              <Col md={12} lg={12}>
                <Component />
              </Col>
            </Row>
            <div
              className={`pt-5 d-flex align-items-center ${showCancelButton && showConfirmButton ? 'justify-content-between' : 'justify-content-center'}`}>
              {showCancelButton && (
                <button
                  type="button"
                  className="btn btn-outline-primary btn-empty px-4 btn-sm"
                  onClick={() => {
                    if (onClickCancel) onClickCancel()
                    onClose()
                  }}>
                  {cancel || 'ANNULLA'}
                </button>
              )}
              {showConfirmButton && (
                <BtnFill
                  text={confirm || 'CONTINUA'}
                  handlerOnClick={() => {
                    if (handleClickConfirm) handleClickConfirm()
                    onClose()
                  }}
                />
              )}
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
