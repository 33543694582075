import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { ComureiSubjectModal } from './NewComureiSubjectModal'
import { ComureiSubjectList } from './NewComureiSubjectList'
import { CreateComureiCommunicationModal } from '../communications/NewCreateCommunicationModal'
import { APPLICATION_COMUREI, APPLICATIONS } from '../../../../config/Constants'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'
import axiosService from '../../../../services/axios-service'
import '../../../../styles/dac7NewStyles.css'

export function ComureiSubject() {
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  const prevAuthRef = useRef()

  const [historicSubjects, setHistoricSubjects] = useState(0)
  const [customerApplication, setCustomerApplication] = useState()
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [communication, setCommunication] = useState({
    show: false,
    user: null,
    subject: null,
    communication: null
  })

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  const subjectTypes = useSelector((state) => state.comureiSubjectTypes.data)

  const _subjects = useSelector((state) => state.crsSubjects.data)
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    getSubjects()
    getLocations()
    getSubjectTypes()
    getCommunicationTypes()
    getHistoricSubjects()
  }, [])

  useEffect(() => {
    setSubjects(_subjects)
  }, [_subjects])

  useEffect(() => {
    if (prevAuthRef.current !== auth && auth) {
      const application = APPLICATIONS.find((a) => a.description === APPLICATION_COMUREI)
      const customerApp = auth.user?.customer?.customerApplications?.find(
        (ca) => ca?.application?.id === application?.id
      )
      setCustomerApplication(customerApp)
    }
    prevAuthRef.current = auth
  }, [auth])

  const getSubjectSummary = () => {
    CheckRemainingSubjects(APPLICATION_COMUREI, false, (subjectSummary) => {
      setSubjectSummary(subjectSummary)
    })
  }

  const getSubjects = () => {
    getSubjectSummary()
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getSubjectTypes = () => {
    dispatch(actions.getAllComureiSubjectTypes())
  }

  const getCommunicationTypes = () => {
    dispatch(actions.getAllComureiCommunicationTypes())
  }

  const reloadList = () => {
    getSubjectSummary()
    getHistoricSubjects()
  }

  const closeCreateCommunicationModal = () => {
    setCommunication({
      show: false,
      user: null,
      subject: null,
      communication: null
    })
    getSubjects()
  }

  const openCreateCommunicationModal = (subject, communications) => {
    setCommunication({
      show: true,
      user: auth.user,
      subject: subject,
      communication: communications
    })
  }

  const getHistoricSubjects = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: '/api/comurei/custom/comurei-subjects/historic',
      method: 'GET'
    }).then((response) => {
      setHistoricSubjects(response.data)
    })
  }

  return (
    <>
      <div className="table-custom">
        <div className="tab-content tab-rel">
          <div className="d-flex my-2 header-btn-relationship justify-content-between">
            <div className="d-flex align-items-center ps-0">
              <SubjectTotalAndCallToAction
                total={subjectSummary?.total}
                remaining={subjectSummary?.available}
                used={subjectSummary?.used}
                licenseType={customerApplication?.licenseType}
                customerApplication={customerApplication}
              />
              <span
                className="ms-2 font-italic fw-light text-secondary"
                style={{ fontSize: '1rem' }}>
                Soggetti storici (non conteggiati): {historicSubjects}
              </span>
            </div>

            <div>
              <ComureiSubjectModal
                locations={locations}
                subjectTypes={subjectTypes}
                getSubjects={reloadList}
                openCreateCommunicationModal={openCreateCommunicationModal}
              />
            </div>
          </div>
          <div className="row">
            <div className="px-0">
              <ComureiSubjectList
                subjects={subjects}
                subjectTypes={subjectTypes}
                locations={locations}
                getSubjects={getSubjects}
                setSubjects={setSubjects}
                reloadList={reloadList}
                user={auth.user}
                openCreateCommunicationModal={openCreateCommunicationModal}
              />
            </div>
          </div>
        </div>
      </div>

      {communication.show && (
        <CreateComureiCommunicationModal
          user={communication.user}
          subject={communication.subject}
          communications={communication.communication}
          closeModalComm={closeCreateCommunicationModal}
        />
      )}
    </>
  )
}
