import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from './Tabs'
import * as actions from '../../../../actions'
import { RelationshipsList } from './NewRelationshipsList'
import elenco from '../../../../styles/images/elenco.png'
import spedire from '../../../../styles/images/spedire.png'
import storico from '../../../../styles/images/storico.png'
import photo from '../../../../styles/images/camera.png'
import calendariotab from '../../../../styles/images/calendarTab.png'
import arrow_l from '../../../../styles/images/arrow_l.png'
import { MonthlyList } from '../communications/NewMonthlyList'
import { Link } from 'react-router-dom'
import { ConsistencyHistoryList } from '../receipts/NewConsistencyHistoryList'
import * as Constants from '../../../../config/Constants'
import { CommunicationsTab } from '../communications/CommunicationsTab'
import { YearlyBalanceList } from '../balances/NewYearlyBalanceList'
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import { Col } from 'react-bootstrap'
import { cloneDeep } from 'lodash'

//pulisce la riga di errore della fotografia
export function cleanErrorString(string) {
  if (string && string.includes('<<')) {
    let arr = string.split('<')
    arr.shift()
    return arr.join('<<')
  }
  return string
}

export function Relationships(props) {
  const dispatch = useDispatch()

  const [subjectId, setSubjectId] = useState('')
  const [filterParams, setFilterParams] = useState({})
  const [showTotalRelationshipResults, setShowTotalRelationshipResults] = useState(false)
  const [show, setShow] = useState(false)

  const prevCustomerApplicationsRef = useRef()

  const auth = useSelector((state) => state.auth)
  const relationships = useSelector((state) => state.relationships.data)
  const subject = useSelector((state) => state.subject.data)
  const roles = useSelector((state) => state.roles.data)

  const relationshipLocations = useSelector((state) =>
    state.locations.data.map((value, index) => ({
      name: value.location,
      key: index
    }))
  )
  const relationshipProvinces = useSelector((state) =>
    state.locations.data.map((value, index) => ({
      name: value.province,
      key: index
    }))
  )
  const currencies = useSelector((state) =>
    cloneDeep(state.roles.data).map((el) => {
      el.description = `${el.code} - ${el.description}`
      return el
    })
  )
  const communicationTypes = useSelector((state) =>
    state.communicationTypes.data.filter((el) => Constants.exludedCommTypes.indexOf(el.code) === -1)
  )
  const relationshipTypes = useSelector((state) => state.relationshipTypes.data)
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    const currentCustomerApplications = auth?.user?.customer?.customerApplications
    const prevCustomerApplications = prevCustomerApplicationsRef.current

    // Confronta se le customerApplications sono cambiate
    if (prevCustomerApplications !== currentCustomerApplications) {
      // Esegui CheckLicenseAndRedirect solo se c'è stato un cambiamento
      CheckLicenseAndRedirect(
        props.history,
        '/app/cora/dashboard',
        currentCustomerApplications,
        Constants.APPLICATION_CORA
      )
    }

    // Aggiorna la variabile di riferimento con il valore attuale
    prevCustomerApplicationsRef.current = currentCustomerApplications
  }, [auth?.user?.customer?.customerApplications, props.history])

  useEffect(() => {
    setSubjectId(props.match.params.id)
    getSubject(props.match.params.id)
    getRelationshipLocations()
    getAllCurrency()
    getAllRole()
    getCommunicationTypes()
    getRelationshipTypes()
    setShow(true)
  }, [])

  const getRelationshipLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getAllCurrency = () => {
    if (!currencies?.length) {
      dispatch(actions.getAllCurrency())
    }
  }

  const getAllRole = () => {
    dispatch(actions.getAllRole())
  }

  const getSubject = (subjectId) => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const getCommunicationTypes = () => {
    dispatch(actions.getAllCommunicationTypes())
  }

  const getRelationshipTypes = () => {
    dispatch(actions.getRelationshipTypes())
  }

  return (
    <>
      <div className="row title-page justify-content-between">
        <Col lg={7}>
          <Link to="/app/cora/dashboard">
            <img
              src={arrow_l}
              className="ms-2 me-3 mb-2"
              width="10"
              height="19"
              alt="Torna a elenco soggetti"
            />
            {subject?.personType === Constants.PNF
              ? subject?.companyName
              : subject?.lastName + ' ' + subject?.firstName}
          </Link>
        </Col>
        <Col lg={5}>
          {isNotEmpty(delayedCommunicationDate) && (
            <div className="me-auto" style={{ fontSize: '1.5rem' }}>
              <strong className="pulse text-light px-2">
                INVIO DIFFERITO IMPOSTATO AL {formatDateForDisplay(delayedCommunicationDate)}
              </strong>
            </div>
          )}
        </Col>
      </div>
      <div className="table-custom">
        <Tabs className="mt-3">
          <div label="ELENCO RAPPORTI" icon={elenco} id={'relationships'}>
            {show && communicationTypes?.length > 0 && relationshipTypes?.length > 0 && (
              <RelationshipsList
                subjectId={subjectId}
                subject={subject}
                communicationTypes={communicationTypes}
                relationships={relationships}
                relationshipTypes={relationshipTypes}
                relationshipLocations={relationshipLocations}
                relationshipProvinces={relationshipProvinces}
                currencies={currencies}
                roles={roles}
                filterParams={filterParams}
                setFilterParams={(params) => setFilterParams(params)}
                showTotalResults={showTotalRelationshipResults}
                setShowTotalResults={(showTotalResults) => {
                  setShowTotalRelationshipResults(showTotalResults)
                }}
              />
            )}
          </div>
          <div label="RAPPORTI DA SPEDIRE" icon={spedire} id={'toSend'}>
            <MonthlyList subjectId={subjectId} subject={subject} />
          </div>
          <div label="SALDI ANNUALI" icon={calendariotab} id={'calendar'}>
            <YearlyBalanceList subjectId={subjectId} />
          </div>
          <div label="STORICO COMUNICAZIONI" icon={storico} id={'history'}>
            <CommunicationsTab subjectId={subjectId} />
          </div>
          <div label="FOTOGRAFIA/ESITI" icon={photo} id={'photo'}>
            <ConsistencyHistoryList subjectId={subjectId} />
          </div>
        </Tabs>
      </div>
    </>
  )
}
