export function ErrorPage() {
  return (
    <div id="errorPage">
      <div className="text-center mt-5">
        <h1>Il server è momentaneamente in manutenzione</h1>
        <h3>Si prega di riprovare fra cinque minuti</h3>

        <span
          className="text-primary"
          role="button"
          onClick={
            () => window.location.reload(true)
            /**in caso di nuove pubblicazioni, è bene pulire la cache per evitare che venga utilizzata la vecchia versione
             * il reload forza axios a fare una nuova chiamata che, se ha successo, porterà alla pagina di login*/
          }>
          Torna alla pagina principale
        </span>
      </div>
    </div>
  )
}
