//enumerazione status comunicazioni CORA/CRS. Controparte dell'omonima classe su backend, e tabella a DB
export const CommunicationStatus = {
  CREATED: 'CREATED' /* Creata - In attesa di ricevuta */,
  CREATED_WITH_ERROR: 'CREATED_WITH_ERROR' /* Creata con errori */,
  ERROR_SID: 'ERROR_SID',
  RECEIPT_ACCEPTED: 'RECEIPT_ACCEPTED',
  RECEIPT_NOT_ACCEPTED: 'RECEIPT_NOT_ACCEPTED',
  RECEIPT_ACCEPTED_WITH_ERROR: 'RECEIPT_ACCEPTED_WITH_ERROR',
  RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND:
    'RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND',
  RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND:
    'RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND',
  RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND: 'RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND'
}
