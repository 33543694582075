export function BtnEmpty(props) {
  const { text, handlerOnClick, classCustom } = props
  return (
    <div className={classCustom != undefined ? classCustom : ''}>
      <button
        type="button"
        className="btn btn-outline-primary btn-empty px-4 btn-sm"
        onClick={handlerOnClick}>
        {text}
      </button>
    </div>
  )
}
