import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import MessageWrongFile from './MessageWrongFile'
import MessageCorrectFile from './MessageCorrectFile'
/**
 * Questa funzione ha il compito di visualizzare il popup
 * @returns popup singolo con i sati passsati in input
 */
export default function ModalProcessingOutComeReceipt({
  receiptType,
  communication,
  fileName,
  subjectName
}) {
  const [showReceiptModal, setshowReceiptModal] = useState(true)

  const closeReceiptModal = () => {
    setshowReceiptModal(false)
  }

  return (
    <Modal
      backdrop="static"
      show={showReceiptModal}
      onHide={closeReceiptModal}
      dialogClassName="modal-70w"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {subjectName && <h2>Soggetto: {subjectName}</h2>}
          <h2>ESITO ELABORAZIONE FILE "{fileName}"</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {communication?.id ? (
          <MessageCorrectFile
            closeReceiptModal={closeReceiptModal}
            communication={communication}
            receiptType={receiptType}
            fileName={fileName}
          />
        ) : (
          <MessageWrongFile communicationError={communication.communicationError} />
        )}
      </Modal.Body>
    </Modal>
  )
}
