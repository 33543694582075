import React from 'react'
import LoginForm from './LoginForm'
import Signin from './Signin'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as actions from '../../actions'
import logo from '../../styles/images/logo.png'
import '../../styles/login.css'

class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      showLogin: true
    }
  }

  componentDidMount() {}

  showSignUp = () => {
    this.setState({ showLogin: false })
  }
  showLogin = () => {
    this.setState({ showLogin: true })
  }

  loginUser = (userdata) => {
    if (document.getElementById('header'))
      document.getElementById('header').style.visibility = 'visible'

    this.props.dispatch(actions.login(userdata))
  }

  registerUser = (newUser) => {
    this.props.dispatch(actions.registerUser(newUser))
  }

  render() {
    const { isAuth } = this.props.auth

    if (isAuth) {
      return <Redirect to={{ pathname: '/dashboard' }} />
    }

    return (
      <div className="login">
        <div>
          <img src={logo} alt="logo" className="logo-login" />
        </div>
        <div className="content-login">
          {this.state.showLogin ? (
            <div className="body-login small">
              <div className="form-login">
                <p className="benvenuto-inserisci">
                  <strong>
                    Inserisci le tue <span>Credenziali</span>
                  </strong>
                </p>
                <LoginForm submitCb={this.loginUser} errors={this.props.errors} />
              </div>
            </div>
          ) : (
            <div className="body-login big">
              <div className="form-login">
                <p className="benvenuto-inserisci">
                  <strong>
                    Crea il tuo <span>Account</span>
                  </strong>
                </p>
                <Signin
                  submitCb={this.registerUser}
                  errors={this.props.errors}
                  showLogin={this.showLogin}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    errors: state.auth.errors
  }
}

export default connect(mapStateToProps)(Login)
