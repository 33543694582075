import { Col, Modal } from 'react-bootstrap'
import React from 'react'
import { BtnFill } from '../../shared/BtnFill'
import { ReactComponent as IUndo } from '../../../styles/images/svgs/regular/times-circle.svg'

export function ConfirmModal(props) {
  return (
    <Modal
      show={props.showSendModal}
      onHide={() => props.setShowSendModal(false)}
      backdrop="static"
      dialogClassName="w-50 p-2"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100 popup-title-success">
          CONFERMA INVIO DATI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="row text-center d-flex flex-column justify-content-between mt-2">
        <Col>
          <h4>Scarica Pdf con dati indicati:</h4>
          <BtnFill text="SCARICA" handlerOnClick={props.riepilogo}></BtnFill>
        </Col>

        <Col>
          <h4>
            Se i dati sono corretti premi il bottone INVIA e saranno spediti in modo sicuro al
            nostro ufficio assistenza per l'elaborazione.
          </h4>
          <BtnFill text="INVIA" handlerOnClick={props.invia}></BtnFill>
        </Col>
        <Col className="mt-2">
          <h4>
            ATTENZIONE: i vostri dati NON vengono salvati sui nostri server, per vostra sicurezza.
          </h4>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-empty px-4 btn-sm" onClick={() => props.setShowSendModal(false)}>
          <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
          &nbsp; CHIUDI
        </button>
      </Modal.Footer>
    </Modal>
  )
}
