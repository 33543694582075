import React from 'react'
import * as actions from '../../../../actions'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'
import { isEmpty } from '../../../shared/Utility'

export class ImportCrsSubjectDataModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      dataFile: [],
      errorDataFile: { isValid: true, msg: '' },
      loading: false,
      showResultModal: false
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.setState({ dataFile: '' })
  }

  closeResultModal = () => {
    this.setState({ showResultModal: false })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  checkFileName = (name) => {
    return name.split('.').pop().toLowerCase() === 'txt'
  }

  handleUploadFile = (val) => {
    if (isEmpty(val)) {
      this.setState({ errorDataFile: { isValid: false, msg: 'Il file è obbligatorio' } })
    }
    let files = []
    for (let file of val.target.files) {
      if (!this.checkFileName(file.name)) {
        this.setState({ errorDataFile: { isValid: false, msg: 'Estensione o nome file errati' } })
      } else {
        files.push(file)
      }
    }
    if (files.length > 0) this.setState({ dataFile: files })
  }

  handleSendDataFile = () => {
    this.setState({ showModal: false })
    this.setState({ loading: true })
    let formData = new FormData()
    if (this.state.dataFile?.length > 0) {
      for (let datafile of this.state.dataFile) {
        formData.append('dataFile', datafile)
      }
    } else {
      return
    }
    actions
      .importCrsDataFile(formData)
      .then(
        (res) => {
          this.props.getSubjects()
          let results = []
          if (res) {
            for (let result of res) {
              results.push('Importato soggetto: ' + result.denomination)
            }
            PopupSuccess({
              dataList: results
            })
          } else {
            PopupError({
              text: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare."
            })
          }
        },
        (errors) => {
          if (errors?.errorKey === 'licenseCheck') {
            PopupError({
              text: '<p>Hai raggiunto il numero massimo di soggetti acquistati: per importare ulteriori soggetti puoi acquistare soggetti aggiuntivi</p> <button onClick={window.open(\'https://www.starsoluzioni.it/product/crs-comunicazione-common-reporting-standard-ocse/\')}  class="btn btn-primary rounded-pill btn-new-rel px-5">Compra Online</button>'
            })
          } else {
            PopupError({
              text: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare."
            })
          }
        }
      )
      .then(() => {
        this.setState({ dataFile: '', loading: false })
      })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <PageSpinner />}
        <button
          type="button"
          data-tip={'Importa soggetti alla tua lista'}
          className={`btn btn-outline-primary btn-empty btn-sm `}
          disabled={this.props.disabled}
          onClick={this.openModal}>
          <IImport className={'padding-right-4px'} width="20" height="18" fill={`${'#128186'}`} />
          &nbsp; Importa
        </button>

        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>IMPORTA SOGGETTI DA CRS DESKTOP</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <div className="image-upload text-start">
                <label htmlFor={`dataFile`}>
                  <div className="certificateFiles">
                    CARICA FILE DATI <IUpload width="25" fill="#000" />
                  </div>
                </label>
                <input
                  id={`dataFile`}
                  type="file"
                  name={`dataFile`}
                  multiple
                  onChange={(e) => this.handleUploadFile(e)}
                />
                <div className="certificatiLoaded text-start">
                  {!this.state.errorDataFile.isValid ? (
                    <div className="certificatiLoaded-error">{this.state.errorDataFile.msg}</div>
                  ) : (
                    <div>
                      {this.state.dataFile &&
                        this.state.dataFile.map((file, index) => {
                          return (
                            <div key={index}>
                              <ICheck width="12" fill="#8e8e8e" />
                              {file.name}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
              <button
                disabled={!this.state.errorDataFile.isValid || !this.state.dataFile}
                onClick={this.handleSendDataFile}
                type="button"
                className="btn btn-primary btn-new-rel px-5">
                CONFERMA
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}
