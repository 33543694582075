import { useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import '../../../../styles/alertComm.css'
import Modal from 'react-bootstrap/Modal'
import axiosService from '../../../../services/axios-service'
import moment from 'moment'
import * as Constants from '../../../../config/Constants'
import { ComureiEmailForm } from './NewComureiEmailForm'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { downloadFile } from '../../../shared/Utility'

export function CreateComureiCommunicationModal(props) {
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => {
    if (props.closeModalComm) props.closeModalComm()
    setShowModal(false)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        downloadFile(response.data, file)
        PopupSuccess({ text: 'File scaricato' })
      }
    })
  }

  const downloadTxt = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadTxt/${communication.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename)
  }

  const downloadPdf = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadPdf/${communication.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename.replace('.txt', '.pdf'))
  }

  const renderRows = (communications) => {
    return communications.map((communication, index) => {
      let section = ''
      if (communication.registryReason) {
        section = section + 'Indagini Bancarie+'
      }

      if (communication.monitoringReason) {
        section = section + 'Monitoraggio Fiscale+'
      }

      if (communication.factaReason) {
        section = section + 'FATCA/CRS'
      }

      if (section.endsWith('+')) {
        section = section.substring(0, section.length - 1)
      }
      return (
        <Row key={index} className="alert-body d-flex align-items-center">
          <>
            <div className="col w-20 div-td">
              {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
            </div>
            <div className="col w-40 div-td">
              {communication.communicationType.code} - {communication.communicationType.description}
            </div>
            <div className="col w-40 div-td">{section}</div>
            <div className="col w-10 div-td text-center containerPopOver">
              <div className="txt cursor-pointer">
                <ITextFile width="20" fill="#128186" onClick={() => downloadTxt(communication)} />
              </div>
              <div className="pdf cursor-pointer">
                <IPdfFile width="20" fill="#128186" onClick={() => downloadPdf(communication)} />
              </div>
            </div>
          </>
        </Row>
      )
    })
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              COMUNICAZIONE R.E.I. PER{' '}
              <strong>
                {props.subject.personType === Constants.PNF
                  ? props.subject.companyName
                  : props.subject.lastName + ' ' + props.subject.firstName}
              </strong>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="text-start header-table">
              <div className="col w-20 div-td star-table-header">DATA CREAZIONE</div>
              <div className="col w-40 div-td star-table-header">TIPO COMUNICAZIONE</div>
              <div className="col w-40 div-td star-table-header">SEZIONE</div>
              <div className="col w-10 div-td star-table-header">DOWNLOAD</div>
            </div>
          </div>
          {renderRows(props.communications)}
          <ComureiEmailForm
            {...props}
            closeModal={closeModal}
            sentCommunications={props.communications}
            emailAddress={props.user.login}
            subjectId={props.communication?.subjectId}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
