import React from 'react'
import * as Constants from '../../../../config/Constants'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import CodiceFiscale from 'codice-fiscale-js'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import FormCheck from 'react-bootstrap/FormCheck'

class HolderForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.relationship?.crsRelationshipHolder?.id || null,
      personType: this.props.relationship?.crsRelationshipHolder?.personType || Constants.PNF,
      fiscalIdentifier: this.props.relationship?.crsRelationshipHolder?.fiscalIdentifier || '',
      companyName: this.props.relationship?.crsRelationshipHolder?.companyName || '',
      completeAddress: this.props.relationship?.crsRelationshipHolder?.completeAddress || '',
      countryCode: this.props.relationship?.crsRelationshipHolder?.countryCode || 'IT',
      country:
        this.props.relationship?.crsRelationshipHolder?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      holderType: this.props.relationship?.crsRelationshipHolder?.holderType?.id || '',
      holderTypeCode: this.props.relationship?.crsRelationshipHolder?.holderType?.code || '',
      disabledHolderType:
        this.props.relationship?.crsRelationshipHolder?.personType === Constants.PF ? true : false,
      identifier: this.props.relationship?.crsRelationshipHolder?.identifier || '',
      lastName: this.props.relationship?.crsRelationshipHolder?.lastName || '',
      firstName: this.props.relationship?.crsRelationshipHolder?.firstName || '',
      birthDate: this.props.relationship?.crsRelationshipHolder?.birthDate
        ? new Date(this.props.relationship?.crsRelationshipHolder?.birthDate)
        : null,
      birthLocation: this.props.relationship?.crsRelationshipHolder?.birthLocation || '',
      birthProvince: this.props.relationship?.crsRelationshipHolder?.birthProvince || '',
      birthCountry:
        this.props.relationship?.crsRelationshipHolder?.birthCountry ||
        'ITALIA (REPUBBLICA ITALIANA)',
      relationshipHolderTypes: [],
      crsHolderType: {},
      errorFiscalIdentifier: { isValid: true, msg: '' },
      errorCompanyName: { isValid: true, msg: '' },
      errorCompleteAddress: { isValid: true, msg: '' },
      errorCountryCode: { isValid: true, msg: '' },
      errorHolderType: { isValid: true, msg: '' },
      errorCountry: { isValid: true, msg: '' },
      errorIdentifier: { isValid: true, msg: '' },
      errorFirstName: { isValid: true, msg: '' },
      errorLastName: { isValid: true, msg: '' },
      errorBirthDate: { isValid: true, msg: '' },
      errorBirthLocation: { isValid: true, msg: '' },
      errorBirthProvince: { isValid: true, msg: '' },
      errorBirthCountry: { isValid: true, msg: '' },
      errorMessages: [] // Inizializza errorMessages come array vuoto
    }
  }

  componentDidMount() {
    this.getRelationshipHolderTypes()
  }

  handleValidation = () => {
    const errors = [] // Array per raccogliere gli errori

    if (!this.state.fiscalIdentifier) {
      errors.push('Campo obbligatorio: Codice Fiscale')
    }

    if (!this.state.completeAddress) {
      errors.push('Campo obbligatorio: Indirizzo Completo')
    }

    if (!this.state.countryCode) {
      errors.push('Campo obbligatorio: Codice Paese')
    }

    if (!this.state.country) {
      errors.push('Campo obbligatorio: Paese')
    }

    if (this.state.personType === Constants.PNF) {
      if (!this.state.companyName) {
        errors.push('Campo obbligatorio: Nome della Società')
      }
      if (!this.state.holderType) {
        errors.push('Campo obbligatorio: Tipo di Titolare')
      }
    } else {
      if (!this.state.firstName) {
        errors.push('Campo obbligatorio: Nome')
      }
      if (!this.state.lastName) {
        errors.push('Campo obbligatorio: Cognome')
      }
      if (!this.state.birthDate) {
        errors.push('Campo obbligatorio: Data di Nascita')
      }
      if (!this.state.birthLocation) {
        errors.push('Campo obbligatorio: Luogo di Nascita')
      }
      if (!this.state.birthProvince) {
        errors.push('Campo obbligatorio: Provincia di Nascita')
      }
      if (!this.state.birthCountry) {
        errors.push('Campo obbligatorio: Paese di Nascita')
      }
    }

    // Imposta i messaggi di errore nello stato
    this.setState({ errorMessages: errors })

    return errors.length === 0 // Ritorna true se non ci sono errori
  }

  getRelationshipHolderTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-holder-types').then((res) => {
      this.setState({ relationshipHolderTypes: res.data })
    })
  }

  handlePersonTypeClick = (e) => {
    if (e.target.value === Constants.PF) {
      this.setState({
        personType: e.target.value,
        holderType: '',
        crsHolderType: null,
        disabledHolderType: true
      })
      this.props.handlerTabHolder(true)
    } else {
      this.setState({
        personType: e.target.value,
        disabledHolderType: false
      })
    }
  }

  handlerChangeFiscalIdentifiere = (e) => {
    this.setState({
      fiscalIdentifier: e.target.value.toUpperCase(),
      errorFiscalIdentifier: { isValid: true, msg: '' }
    })
    if (isEmpty(e.target.value)) {
      this.setState({
        ...this.state,
        companyName: '',
        lastName: '',
        firstName: '',
        birthDate: null,
        completeAddress: '',
        fiscalIdentifier: ''
      })
      return
    }
    actions.getBaseRegistryByFiscalCode(e.target.value.toUpperCase()).then(
      (baseRegistry) => {
        if (baseRegistry) {
          this.setState({
            ...this.state,
            companyName: baseRegistry?.companyName || '',
            lastName: baseRegistry?.lastName || '',
            firstName: baseRegistry?.firstName || '',
            birthDate: baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null,
            completeAddress:
              baseRegistry?.location?.location + '  (' + baseRegistry?.location?.province + ')' ||
              ''
          })
        }
      },
      (errors) => {
        if (this.state.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(e.target.value.toUpperCase()).toJSON()
            if (cf) {
              this.setState({
                ...this.state,
                birthDate: cf?.birthday ? new Date(cf?.birthday) : null,
                birthLocation: cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace.toUpperCase() : '',
                birthProvince: cf?.birthplaceProvincia || ''
              })
            }
          } catch (e) {
            //console.log("codice fiscale errato");
            /*this.setState({
                            errorFiscalIdentifier: { isValid: false, msg: "Codice fiscale non valido" }
                        });*/
            //NOTE: la cliente ha chiesto di togliere il controllo sul codice fiscale, decommentare queste cose per farlo funzionare
          }
        }
      }
    )
  }

  handlerChangeCompanyName = (e) => {
    this.setState({
      companyName: e.target.value.toUpperCase(),
      errorCompanyName: { isValid: true, msg: '' }
    })
  }

  handlerCompleteAddress = (e) => {
    this.setState({
      completeAddress: e.target.value.toUpperCase(),
      errorCompleteAddress: { isValid: true, msg: '' }
    })
  }

  handleInputAutocompileCountry = (e) => {
    let newCountry = e.target.value
    this.setState({
      countryCode: newCountry.toUpperCase(),
      errorCountryCode: { isValid: true, msg: '' }
    })
    let countriesFilter = this.props.countries.filter((country) => {
      return country.name === newCountry
    })
    if (countriesFilter.length === 1) {
      this.setState({
        countryCode: countriesFilter[0].name,
        country: countriesFilter[0].key
      })
    }
    if (countriesFilter.length === 0) {
      this.setState({
        countryCode: newCountry.toUpperCase(),
        country: ''
      })
    }
  }

  handlerChangeHolderType = (e) => {
    let code = e.target[e.target.selectedIndex].dataset.cod
    this.setState({
      holderType: e.target.value,
      holderTypeCode: code,
      crsHolderType: { id: parseInt(e.target.value) },
      errorHolderType: { isValid: true, msg: '' }
    })

    this.props.handlerTabHolder(e.target.value !== '1')
  }

  handlerChangeIdentifier = (e) => {
    this.setState({ identifier: e.target.value.toUpperCase() })
  }

  handlerChangeLastName = (e) => {
    this.setState({
      lastName: e.target.value.toUpperCase(),
      errorLastName: { isValid: true, msg: '' }
    })
  }

  handlerChangeFirstName = (e) => {
    this.setState({
      firstName: e.target.value.toUpperCase(),
      errorFirstName: { isValid: true, msg: '' }
    })
  }

  handlerChangeBirthDate = (date) => {
    this.setState({
      birthDate: date,
      errorBirthDate: { isValid: true, msg: '' }
    })
  }

  handleInputAutocompileBirthLocation = (newLocation, id, suggestionIndex) => {
    this.setState({
      birthLocation: newLocation.toUpperCase(),
      errorBirthLocation: { isValid: true, msg: '' }
    })
    actions.getLocation(newLocation.toUpperCase()).then((loc) => {
      if (loc) {
        this.setState({
          birthLocation: loc.location,
          birthProvince: loc.province,
          birthCountry: 'ITALIA (REPUBBLICA ITALIANA)',
          errorBirthLocation: { isValid: true, msg: '' },
          errorBirthProvince: { isValid: true, msg: '' },
          errorBirthCountry: { isValid: true, msg: '' }
        })
      } else {
        this.setState({
          birthLocation: newLocation.toUpperCase(),
          errorBirthLocation: { isValid: true, msg: '' }
        })
      }
    })
  }

  handleProvinceChange = (e) => {
    this.setState({
      birthProvince: e.target.value.toUpperCase(),
      errorBirthProvince: { isValid: true, msg: '' }
    })
  }

  getHolderType = () => {
    if (isNotEmpty(this.state.crsHolderType?.id)) {
      return this.state.crsHolderType
    } else {
      return this.props.relationship?.crsRelationshipHolder?.holderType
    }
  }

  getPersonType = () => {
    if (isNotEmpty(this.state.personType)) {
      return this.state.personType
    } else {
      return this.props.relationship?.crsRelationshipHolder?.personType
    }
  }

  getData() {
    if (this.handleValidation()) {
      let ht = null
      if (isNotEmpty(this.state.crsHolderType?.id)) {
        ht = this.state.crsHolderType
      } else {
        ht = this.props.relationship?.crsRelationshipHolder?.holderType
      }

      return {
        id: this.state.id,
        fiscalIdentifier: this.state.fiscalIdentifier,
        companyName: this.state.companyName,
        completeAddress: this.state.completeAddress,
        countryCode: this.state.countryCode,
        country: this.state.country,
        personType: this.state.personType === Constants.PNF ? 'LEGAL' : 'FISICAL',
        identifier: this.state.identifier,
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        birthDate: new Date(this.state.birthDate),
        birthLocation: this.state.birthLocation,
        birthProvince: this.state.birthProvince,
        birthCountry: this.state.birthCountry,
        holderType: ht
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.handleValidation()) {
      // Procedi con la sottomissione del form
    }
  }

  render() {
    const id = this.props.index || '0'

    return (
      <div className="row align-items-center ms-2  ms-4">
        {/* Se ci sono errori, visualizza il banner */}
        {this.state.errorMessages.length > 0 && (
          <div>
            <form onSubmit={this.handleSubmit}>
              {/* campi del form */}
              <button type="submit">Conferma</button>
            </form>
            {this.state.errorMessages.length > 0 && (
              <div className="error-messages">
                {this.state.errorMessages.map((error, index) => (
                  <p key={index} className="error-text">
                    {error}
                  </p>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Switch per Tipo Persona */}
        <div className="col-2 mb-2 me-2">
          <div className="inputSwitcher form-check form-switch">
            <label className="d-flex align-items-center me-2">
              <FormCheck
                className="form-switch switch-custom-comurei"
                type="switch"
                id={`custom-switch-${id}`}
                checked={this.state.personType === Constants.PF}
                onChange={(e) =>
                  this.handlePersonTypeClick({
                    target: {
                      value: this.state.personType === Constants.PF ? Constants.PNF : Constants.PF
                    }
                  })
                }
              />
              {this.state.personType === Constants.PF ? 'Individual' : 'Organization'}
            </label>
          </div>
        </div>

        {/* Campo Identificativo */}
        <div className="col-3 me-2 mb-2">
          <BwmInput
            name="identifier"
            label="Identificativo rapporto"
            className="form-control"
            value={this.state.identifier}
            readOnly={true}
            maxLength="16"
          />
        </div>

        {/* Campo TIN */}
        <div className="col-2 me-2 mb-2">
          <BwmInput
            name="fiscalIdentifier"
            label="TIN (Id Fiscale)"
            className="form-control"
            value={this.state.fiscalIdentifier}
            onChange={(e) => this.handlerChangeFiscalIdentifiere(e)}
            error={this.state.errorFiscalIdentifier}
            maxLength="16"
          />
        </div>

        {/* Campo Tipologia Titolare */}
        <div className="col-4 me-2 mb-2">
          <BwmSelect
            options={this.state.relationshipHolderTypes}
            name="holderType"
            label="Tipologia titolare"
            className="form-control"
            onChange={this.handlerChangeHolderType}
            value={this.state.holderType}
            error={this.state.errorHolderType}
            showCode={true}
            disabled={this.state.disabledHolderType}
          />
        </div>

        {/* Campo Denominazione (se personType è PNF) */}
        {this.state.personType === Constants.PNF && (
          <div className="col-3 me-2 mb-2">
            <BwmInput
              name="companyName"
              label="Denominazione"
              className="form-control"
              value={this.state.companyName}
              onChange={(e) => this.handlerChangeCompanyName(e)}
              error={this.state.errorCompanyName}
              maxLength="60"
            />
          </div>
        )}

        {/* Campi Cognome e Nome (se personType è PF) */}
        {this.state.personType === Constants.PF && (
          <>
            <div className="col-2 me-2 mb-2">
              <BwmInput
                name="lastName"
                label="Cognome"
                className="form-control"
                value={this.state.lastName}
                onChange={(e) => this.handlerChangeLastName(e)}
                error={this.state.errorLastName}
                maxLength="26"
              />
            </div>
            <div className="col-2 me-2 mb-2">
              <BwmInput
                name="firstName"
                label="Nome"
                className="form-control"
                value={this.state.firstName}
                onChange={(e) => this.handlerChangeFirstName(e)}
                error={this.state.errorFirstName}
                maxLength="25"
              />
            </div>
            <div className="col-1 me-2 mb-2">
              <DateField
                label="Data di Nascita"
                placeholder="Data di Nascita"
                date={this.state.birthDate}
                onChange={(date) => this.handlerChangeBirthDate(date)}
                error={this.state.errorBirthDate}
                name={'birthDate'}
              />
            </div>
            <div className="col-2 me-2 mb-2">
              <AutoCompileCustom
                label="Comune di Nascita"
                id="birthLocation"
                handleInputAutocompile={this.handleInputAutocompileBirthLocation}
                filtro={this.props.locations}
                value={this.state.birthLocation}
                error={this.state.errorBirthLocation}
              />
            </div>
            <div className="col-1 me-2 mb-2">
              <input
                type={'text'}
                placeholder="Prov."
                className={'form-control p-2'}
                onChange={(e) => this.handleProvinceChange(e)}
                value={this.state.birthProvince}
              />
            </div>
          </>
        )}

        {/* Campo Indirizzo Completo */}
        <div className="col-2 me-2 mb-2">
          <BwmInput
            name="completeAddress"
            label="Indirizzo (via, numero, cap e città)"
            className="form-control"
            value={this.state.completeAddress}
            onChange={(e) => this.handlerCompleteAddress(e)}
            error={this.state.errorCompleteAddress}
          />
        </div>

        {/* Campo Paese */}
        <div className="col-3 me-2 mb-2">
          <BwmSelect
            options={this.props.countries}
            name="country"
            id="country"
            label="Paese"
            className="form-control"
            onChange={(e) => this.handleInputAutocompileCountry(e)}
            value={this.state.countryCode || ''}
            error={this.state.errorCountry}
            showCode={true}
            nameKey={true}
          />
        </div>
      </div>
    )
  }
}

export default HolderForm
