import * as actions from '../../../../../actions'

export function getRelationshipsCsvTemplate() {
  return actions.getCoraRelationshipsCsvTemplate()
}

export function downloadRelationshipsCsvTemplate() {
  getRelationshipsCsvTemplate().then((res) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'TEMPLATE IMPORT RAPPORTI.csv'
    let blob = new Blob([res], { type: 'text/plain' })
    link.href = window.URL.createObjectURL(blob)
    link.click()
  })
}
