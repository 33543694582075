import React, { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { ArcoRegistriesList } from './ArcoRegistriesList'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { formatDateForDisplay } from '../../../shared/Utility'

registerLocale('it', it)

export function ArcoRegistrySignatories(props) {
  const [showModal, setShowModal] = useState(false)
  const [checkedArcoRegistriesSignatoriesValues, setcheckedArcoRegistriesSignatoriesValues] =
    useState([])

  const [signatories, setSignatories] = useState(props.signatories || [])

  useEffect(() => {
    setSignatories(props.signatories || [])
  }, [props.signatories])

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const onCheckBoxClick = (event, arcoRegistriesSignatories) => {
    if (event.target.checked) {
      checkArcoRegistriesSignatories(arcoRegistriesSignatories)
      let element = document.getElementById('signatories-' + arcoRegistriesSignatories.id)
      if (element) element.classList.add('bg-cobalt', 'bold')
    } else {
      uncheckArcoRegistriesSignatories(arcoRegistriesSignatories)
      document.getElementById('checkboxAllSignatoriesId').checked = false
      let element = document.getElementById('signatories-' + arcoRegistriesSignatories.id)
      if (element) element.classList.remove('bg-cobalt', 'bold')
    }
  }

  const checkArcoRegistriesSignatories = (arcoRegistriesSignatories) => {
    setcheckedArcoRegistriesSignatoriesValues(
      checkedArcoRegistriesSignatoriesValues.concat(arcoRegistriesSignatories)
    )
  }

  const uncheckArcoRegistriesSignatories = (arcoRegistriesSignatories) => {
    let newList = []
    checkedArcoRegistriesSignatoriesValues.map((current) => {
      if (current.id !== arcoRegistriesSignatories.id) newList.push(current)
    })
    setcheckedArcoRegistriesSignatoriesValues(newList)
  }

  const onCheckClick = (event) => {
    if (event.target.checked) checkAll()
    else uncheckAll()
  }

  const addRegistry = (registry) => {
    if (registry) {
      if (Constants.PF === registry.personType) {
        props.addSignatory(registry)
        handleShowModal()
      } else {
        PopupError({ text: 'Non è consentito inserire persone giuridiche in questa lista' })
      }
    }
  }

  const checkAll = () => {
    document.getElementById('checkboxAllSignatoriesId').checked = true
    signatories.map((arcoRegistriesSignatories) => {
      const checkboxDetailsId = `checkboxDetailsSignatories${arcoRegistriesSignatories.id}`
      const idCheckbox = `signatories-${arcoRegistriesSignatories.id}`
      if (document.getElementById(checkboxDetailsId)) {
        document.getElementById(checkboxDetailsId).checked = true
      }
      if (document.getElementById(idCheckbox)) {
        document.getElementById(idCheckbox).checked = true
      }
    })
    let rowIds = document.querySelectorAll('[id^="signatories-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.add('bg-cobalt', 'bold')
      }
    }
    checkallArcoRegistriesSignatories()
  }

  const checkallArcoRegistriesSignatories = () => {
    setcheckedArcoRegistriesSignatoriesValues(signatories)
  }

  const uncheckAll = () => {
    document.getElementById('checkboxAllSignatoriesId').checked = false
    signatories.map((arcoRegistriesSignatories) => {
      const checkboxDetailsId = `checkboxDetailsSignatories${arcoRegistriesSignatories.id}`
      const idCheckbox = `signatories-${arcoRegistriesSignatories.id}`
      if (document.getElementById(checkboxDetailsId)) {
        document.getElementById(checkboxDetailsId).checked = false
      }
      if (document.getElementById(idCheckbox)) {
        document.getElementById(idCheckbox).checked = false
      }
    })
    uncheckallArcoRegistriesSignatories()
    let rowIds = document.querySelectorAll('[id^="signatories-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.remove('bg-cobalt', 'bold')
      }
    }
  }

  const uncheckallArcoRegistriesSignatories = () => {
    setcheckedArcoRegistriesSignatoriesValues([])
  }

  const deleteArcoArcoRegistrySignatoriesSubject = () => {
    let idsToDelete = checkedArcoRegistriesSignatoriesValues.map((e) => e.id)
    if (props?.selectedItem?.id) {
      actions.deleteArcoRegistriesSignatories(props.selectedItem.id, idsToDelete).then(
        () => {
          //chiudo form se contiene l'elemento cancellato
          props.removeSignatory(idsToDelete)
          setcheckedArcoRegistriesSignatoriesValues([])
          if (document.getElementById('checkboxAllSignatoriesId')) {
            document.getElementById('checkboxAllSignatoriesId').checked = false
          }
        },
        (errors) => {
          PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
    } else {
      props.removeSignatory(idsToDelete)
      setcheckedArcoRegistriesSignatoriesValues([])
      uncheckAll()
    }
  }

  const renderRows = () => {
    return signatories.map((item, index) => {
      const idCheckbox = `checkboxDetailsSignatories${item.id}`
      return (
        <React.Fragment key={index}>
          <div className=" text-start row-table" id={`signatories-${item.id}`}>
            <div className="col-lg-1 text-center div-td">
              <input type="checkbox" id={idCheckbox} onClick={(e) => onCheckBoxClick(e, item)} />
            </div>

            <div
              className="col-lg-2 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {item?.firstName.toUpperCase()}
            </div>
            <div
              className="col-lg-2 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {item?.lastName.toUpperCase()}
            </div>
            <div
              className="col-lg-2 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {item?.fiscalCode}
            </div>
            <div
              className="col-lg-2 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {formatDateForDisplay(item?.birthDate)}
            </div>
            <div
              className="col-lg-2 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {item?.location.toUpperCase()}
            </div>
            <div
              className="col-lg-1 div-td text-truncate"
              onClick={() => props.openChildRegistryModal(item)}>
              {item?.province.toUpperCase()}
            </div>
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <div className="col-lg-12">
      <div className="row pb-4 pt-2">
        <div className="col-lg-9">
          <div className="btn-group" role="group" aria-label="action buttons">
            <button
              type="button"
              disabled={checkedArcoRegistriesSignatoriesValues.length === 0 || props.disableInsert}
              className="btn btn-outline-primary btn-empty px-4 btn-sm "
              onClick={() => deleteArcoArcoRegistrySignatoriesSubject()}>
              <IDelete
                className={'padding-right-4px'}
                width="12"
                fill={` ${checkedArcoRegistriesSignatoriesValues.length === 0 ? '#FFFFFF' : '#128186'}`}
              />
              &nbsp; Elimina
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center header-table">
            <div className="col-lg-1">
              <input
                type="checkbox"
                id="checkboxAllSignatoriesId"
                onClick={(e) => onCheckClick(e)}
              />
            </div>
            <div className="col-lg-2">
              <strong>Nome</strong>{' '}
            </div>
            <div className="col-lg-2">
              <strong>Cognome</strong>
            </div>
            <div className="col-lg-2">
              <strong>Codice Fiscale</strong>{' '}
            </div>
            <div className="col-lg-2">
              <strong>Data di nascita</strong>{' '}
            </div>
            <div className="col-lg-2">
              <strong>Comune</strong>{' '}
            </div>
            <div className="col-lg-1">
              <strong>Provincia</strong>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 ps-0 pe-0">{renderRows()}</div>
      <div className="row pt-5">
        <div className="col-4">
          <button
            onClick={handleShowModal}
            disabled={props.disableInsert}
            className="btn btn-primary btn-new-rel px-5">
            <IPlus className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
            &nbsp; Collega anagrafica
          </button>
        </div>
      </div>
      {showModal && (
        <div>
          <ArcoRegistriesList
            {...props}
            modalShow={false}
            showModalRegistriesList={showModal}
            onHide={handleShowModal}
            fromSignatories={props.selectedItem}
            fromRegistry={props.selectedItem}
            onSelectedItem={addRegistry}
          />
        </div>
      )}
    </div>
  )
}
