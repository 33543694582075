import { getSubjectSummary, isApplicationExpired } from '../../../../actions'
import {
  APPLICATIONS,
  CART_LINK,
  getEcommerceProductId,
  getEcommerceSubjectProductId
} from '../../../../config/Constants'
import { LicenseExpiredPopup } from '../../../application/application-cora/LicenseExpiredPopup'
import { PopupSubjectLicense } from '../../PopupSubjectLicense'

/**
 * controlla la licenza di una data pplicazione, e mostra un popup in caso di licenza scaduta
 * @param {*} customerApplications lista di applicazioni
 * @param {*} applicationName nome applicazione(in Constants.js)
 * @returns
 */
export function IsLicenseValid(customerApplications, applicationName) {
  const application = APPLICATIONS.find((a) => a.description === applicationName)
  const customerApplication = customerApplications?.find(
    (ca) => ca?.application?.id === application.id
  )
  let isExpired = isApplicationExpired(customerApplication)
  if (isExpired) {
    getSubjectSummary(applicationName).then(
      (response) => {
        LicenseExpiredPopup({
          link: CART_LINK(
            getEcommerceProductId(applicationName),
            getEcommerceSubjectProductId(applicationName),
            response.used
          )
        })
      },
      (err) => {
        LicenseExpiredPopup()
      }
    )

    return false
  } else {
    return true
  }
}

/**
 *
 * @param {*} applicationName nome applicazione(in Constants.js)
 * @param {*} showPopup booleana che determina se mostrare o no il popup di errore per l'acquisto di nuovi soggetti
 * @param {*} onSuccess callback che prende l'oggetto con i soggetti rimanenti, e una boleana che indica se l'utente può registrare nuovi soggetti
 */
export function CheckRemainingSubjects(
  applicationName,
  showPopup = false,
  onSuccess = (subjectSummary, hasMoreSubjects = false) => undefined
) {
  getSubjectSummary(applicationName).then(
    (response) => {
      const hasMoreSubjects = response?.available > 0
      if (!hasMoreSubjects) {
        if (showPopup) {
          const application = APPLICATIONS.find((a) => a.description === applicationName)
          PopupSubjectLicense({
            total: response.total,
            used: response.used,
            name: application.name,
            available: response.available
          })
        }
      }
      onSuccess(response, hasMoreSubjects)
    },
    (error) => {
      console.log('Si è verificato un errore nella lettura dei soggetti')
    }
  )
}

/**
 * controlla la licenza di una data applicazione e fa il redirect su una data path, se necessario
 * @param {*} history richiamata con this.props.history
 * @param {*} path percorso di reindirizzamento
 * @param {*} customerApplications lista di applicazioni
 * @param {*} applicationName nome applicazione(in Constants.js)
 */
export function CheckLicenseAndRedirect(
  history,
  path = '/dashboard',
  customerApplications,
  applicationName
) {
  const application = APPLICATIONS.find((a) => a.description === applicationName)
  const customerApplication = customerApplications?.find(
    (ca) => ca?.application?.id === application.id
  )
  let isExpired = isApplicationExpired(customerApplication)
  if (isExpired) {
    history.push(path)
  }
}
