import React, { useEffect, useState } from 'react'
import { BwmInput } from '../shared/form/BwmInput'
import imgShowBlu from '../../styles/images/show_blu.png'
import { useSelector } from 'react-redux'
import { sendEncryptedCredentialsToAdmin } from '../../actions'
import { PopupSuccess } from '../shared/PopupSuccess'
import { PopupError } from '../shared/PopupError'
import { Col, Row } from 'react-bootstrap'
import { InfoBox } from '../shared/InfoBox'

export function AssistanceLogin() {
  const auth = useSelector((state) => state.auth)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    }
  }

  const handleSubmit = () => {
    sendEncryptedCredentialsToAdmin(password).then(
      (res) => {
        PopupSuccess()
      },
      (err) => {
        PopupError({ text: "Si è verificato un errore durante l'invio dei dati" })
      }
    )
  }

  const handlerPassword = (value) => {
    setPassword(value)
  }

  useEffect(() => {
    setUsername(auth?.customer?.user?.login)
  }, [auth])

  return (
    <div className="login mt-4">
      <div className="content-login">
        <div className="body-login px-4">
          <div className="form-login">
            <p className="benvenuto-inserisci">
              <strong>Invia le tue credenziali al centro assistenza</strong>
            </p>
            <div className="col d-flex flex-column justify-content-between  mt-3">
              <Row className="">
                <Col sm={6} lg={6}>
                  <Row>
                    <Col>
                      <BwmInput
                        name="username"
                        type="text"
                        label="Email"
                        className="form-control"
                        value={username}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10} lg={10}>
                      <BwmInput
                        id="loginPsw"
                        name="password"
                        type="password"
                        label="Password"
                        className="form-control"
                        value={password}
                        onChange={(e) => handlerPassword(e.target.value)}
                      />
                    </Col>
                    <Col sm={2} lg={2} className="d-flex">
                      <div className="align-self-center mt-2">
                        <img
                          className="img-info "
                          alt="mostra password"
                          src={imgShowBlu}
                          onClick={(e) => onClickIconPsw(e, 'loginPsw')}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6} lg={6} className="d-flex">
                  <InfoBox
                    additionalClasses={'align-self-center'}
                    simplePointsArr={[
                      {
                        title: '',
                        text: 'Tramite questa form è possibile inviare, in maniera sicura, i dati di login al nostro centro assistenza.'
                      }
                    ]}
                  />
                </Col>
              </Row>
              <Row className="justify-content-end ">
                <button className="btn btn-primary btn-lg btn-new-rel " onClick={handleSubmit}>
                  Invia
                </button>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
