import React from 'react'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import axiosService from '../../../../services/axios-service'
import { DateField } from '../../../shared/form/DateFieldB5'
import { registerLocale } from 'react-datepicker'
import { it } from 'date-fns/locale'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { connect } from 'react-redux'
import { isNotEmpty } from '../../../shared/Utility'

registerLocale('it', it)

class ControllingPersonForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fiscalIdentifier: this.props.controllingPerson?.fiscalIdentifier || '',
      controllingPersonType: this.props.controllingPerson?.controllingPersonType?.id || '',
      controllingPersonTypeCode: this.props.controllingPerson?.controllingPersonType?.code || '',
      firstName: this.props.controllingPerson?.firstName || '',
      lastName: this.props.controllingPerson?.lastName || '',
      address: this.props.controllingPerson?.address || '',
      birthDate: this.props.controllingPerson?.birthDate
        ? new Date(this.props.controllingPerson?.birthDate)
        : new Date(),
      birthLocation: this.props.controllingPerson?.birthLocation || '',
      countryCode: this.props.controllingPerson?.countryCode || 'IT',
      country: this.props.controllingPerson?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      controllingPersonTypes: [],
      countries: [],
      errorFiscalIdentifier: { isValid: true, msg: '' },
      errorControllingPersonType: { isValid: true, msg: '' },
      errorFirstName: { isValid: true, msg: '' },
      errorLastName: { isValid: true, msg: '' },
      errorAddress: { isValid: true, msg: '' },
      errorBirthDate: { isValid: true, msg: '' },
      errorBirthLocation: { isValid: true, msg: '' },
      errorCountryCode: { isValid: true, msg: '' },
      errorCountry: { isValid: true, msg: '' }
    }
  }

  getControllingPersonTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-controlling-person-types').then((res) => {
      this.setState({ controllingPersonTypes: res.data })
    })
  }

  getCountries = () => {
    this.props.dispatch(actions.getAllCountries())
  }

  componentDidMount() {
    this.getCountries()
    this.getControllingPersonTypes()
  }

  handlerChangeTIN = (e) => {
    this.setState({
      fiscalIdentifier: e.target.value.toUpperCase(),
      errorFiscalIdentifier: { isValid: true, msg: '' }
    })
    actions.getBaseRegistryByFiscalCode(e.target.value.toUpperCase()).then((baseRegistry) => {
      if (isNotEmpty(baseRegistry)) {
        this.setState({
          ...this.state,
          lastName: baseRegistry?.lastName || '',
          firstName: baseRegistry?.firstName || '',
          birthDate: baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null,
          birthLocation:
            baseRegistry?.location?.location + '  (' + baseRegistry?.location?.province + ')' || ''
        })
      }
    })
  }

  handlerChangeControllingPersonType = (e) => {
    let code = e.target[e.target.selectedIndex].dataset.cod
    this.setState({
      controllingPersonType: e.target.value,
      controllingPersonTypeCode: code,
      errorControllingPersonType: { isValid: true, msg: '' }
    })
  }

  handlerChangeLastName = (e) => {
    this.setState({
      lastName: e.target.value.toUpperCase(),
      errorLastName: { isValid: true, msg: '' }
    })
  }

  handlerChangeFirstName = (e) => {
    this.setState({
      firstName: e.target.value.toUpperCase(),
      errorFirstName: { isValid: true, msg: '' }
    })
  }

  handlerChangeAddress = (e) => {
    this.setState({
      address: e.target.value.toUpperCase(),
      errorAddress: { isValid: true, msg: '' }
    })
  }

  handlerChangeBirthDate = (date) => {
    this.setState({
      birthDate: date,
      errorBirthDate: { isValid: true, msg: '' }
    })
  }

  handlerChangeBirthLocation = (e) => {
    this.setState({
      birthLocation: e.target.value.toUpperCase(),
      errorBirthLocation: { isValid: true, msg: '' }
    })
  }

  handleInputAutocompileCountry = (e) => {
    let newCountry = e.target.value
    this.setState({
      countryCode: newCountry.toUpperCase(),
      errorCode: { isValid: true, msg: '' }
    })
    let countriesFilter = this.props.countries.filter((country) => {
      return country.name === newCountry
    })
    if (countriesFilter.length === 1) {
      this.setState({
        countryCode: countriesFilter[0].name,
        country: countriesFilter[0].key
      })
    }
    if (countriesFilter.length === 0) {
      this.setState({
        countryCode: newCountry.toUpperCase(),
        country: ''
      })
    }
  }

  handleValidation = () => {
    let isValid = true
    if (!this.state.controllingPersonType) {
      this.setState({
        errorControllingPersonType: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.lastName) {
      this.setState({
        errorLastName: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.firstName) {
      this.setState({
        errorFirstName: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.address) {
      this.setState({
        errorAddress: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.birthDate) {
      this.setState({
        errorBirthDate: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.birthLocation) {
      this.setState({
        errorBirthLocation: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    if (!this.state.countryCode) {
      this.setState({
        errorCountryCode: { isValid: false, msg: 'Campo obbligatorio' }
      })
      isValid = false
    }
    return isValid
  }

  getData() {
    let controllingPerson = {
      id: this.props.controllingPerson?.id || null,
      fiscalIdentifier: this.state.fiscalIdentifier,
      controllingPersonType: { id: this.state.controllingPersonType },
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      address: this.state.address,
      birthDate: new Date(this.state.birthDate),
      birthLocation: this.state.birthLocation,
      countryCode: this.state.countryCode,
      country: this.state.country,
      crsRelationship: { id: this.props.relationship?.id || null }
    }
    return controllingPerson
  }

  addCrsControllingPerson = () => {
    if (this.handleValidation()) {
      let relationshipId = ''
      if (this.props.relationship?.id) {
        relationshipId = this.props.relationship?.id
      } else {
        relationshipId = this.props.controllingPerson?.crsRelationship?.id || ''
      }
      let newControllingPerson = this.getData()
      newControllingPerson.crsRelationship = { id: relationshipId }

      if (this.props.controllingPerson?.id) {
        newControllingPerson.controllingPersonType = {
          id: parseFloat(this.state.controllingPersonType)
        }
        actions.updateCrsControllingPeople(newControllingPerson, relationshipId).then(
          (response) => {
            this.props.handlerShowPersonForm()
          },
          (errors) => {
            PopupError({ ...this.props, text: errors })
          }
        )
      } else {
        actions.addCrsControllingPeople(newControllingPerson, relationshipId).then(
          (response) => {
            this.props.handlerShowPersonForm()
          },
          (errors) => {
            PopupError({ ...this.props, text: errors })
          }
        )
      }
    }
  }

  render() {
    let holderName = this.props.relationship?.crsRelationshipHolder?.companyName || ''
    return (
      <React.Fragment>
        <div className="controlPersonForm panel w-all">
          <div className="row">
            <div className="col-7 d-flex ms-2">
              <h5>Titolare: </h5>{' '}
              <h4 className="ms-2">
                <strong>{holderName}</strong>
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-2 me-2 ms-2 mb-2">
              <BwmInput
                name="companyName"
                label="TIN (Id fiscale)"
                className="form-control"
                value={this.state.fiscalIdentifier}
                onChange={(e) => this.handlerChangeTIN(e)}
                error={this.state.errorFiscalIdentifier}
              />
            </div>
            <div className="col-5">
              <BwmSelect
                options={this.state.controllingPersonTypes}
                name="controllingPersonTypes"
                label="Tipo"
                className="form-control"
                onChange={(e) => this.handlerChangeControllingPersonType(e)}
                error={this.state.errorControllingPersonType}
                value={this.state.controllingPersonType}
              />
            </div>
            <div className="col-2 me-2 ms-2">
              <BwmInput
                name="lastName"
                label="Cognome"
                className="form-control"
                value={this.state.lastName}
                onChange={(e) => this.handlerChangeLastName(e)}
                error={this.state.errorLastName}
              />
            </div>
            <div className="col-2 me-2">
              <BwmInput
                name="firstName"
                label="Nome"
                className="form-control"
                value={this.state.firstName}
                onChange={(e) => this.handlerChangeFirstName(e)}
                error={this.state.errorFirstName}
              />
            </div>
            <div className="col-1 ms-2 mb-2 me-2">
              <DateField
                label="Data di nascita"
                date={this.state.birthDate}
                onChange={(date) => this.handlerChangeBirthDate(date)}
                className="form-control text-start p-2"
                error={this.state.errorBirthDate}
                name="birthDate"
                style={{
                  paddingLeft: '0.75rem'
                }}
              />
            </div>
            <div className="col-3 me-2">
              <BwmInput
                name="birthLocation"
                label="Località di nascita"
                className="form-control"
                value={this.state.birthLocation}
                onChange={(e) => this.handlerChangeBirthLocation(e)}
                error={this.state.errorBirthLocation}
              />
            </div>
            <div className="col-3 me-2">
              <BwmInput
                name="address"
                label="Indirizzo (via numero, località)"
                className="form-control"
                value={this.state.address}
                onChange={(e) => this.handlerChangeAddress(e)}
                error={this.state.errorAddress}
              />
            </div>
            <div className="col-3">
              <BwmSelect
                options={this.props.countries}
                name="country"
                id="country"
                label="Paese"
                className="form-control"
                onChange={(e) => this.handleInputAutocompileCountry(e)}
                value={this.state.countryCode || ''}
                error={this.state.errorCountry}
                showCode={true}
                nameKey={true}
              />
            </div>
          </div>
          <div className="row mb-2 me-5 mt-2">
            <div className="col-7 d-flex"></div>
            <div className="col-5 d-flex justify-content-end align-items-end btn-controllingPerson">
              <button
                className="btn btn-outline-primary btn-empty px-4 btn-sm me-3"
                onClick={this.props.handlerShowPersonForm}>
                ANNULLA
              </button>
              <button
                id={'controlling-person-save-button'}
                className="btn btn-primary btn-new-rel px-5 btn-sm"
                onClick={this.addCrsControllingPerson}>
                SALVA
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  let customCountries = state.countries?.data.map((country, index) => {
    return { name: country.code, key: country.description }
  })

  return {
    countries: customCountries
  }
}

export default connect(mapStateToProps)(ControllingPersonForm)
