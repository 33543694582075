import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ReactComponent as ICheck } from '../../../styles/images/svg/check.svg'
import { BtnEmpty } from '../BtnEmpty'
import { isEmpty } from '../Utility'
import { UploadFileLabel } from '../form/UploadFileLabel'

export function ImportModal(props) {
  const [showModal, setShowModal] = useState(props.show)
  const [dataFile, setDataFile] = useState(props.dataFile || [])
  const [errorDataFile, setErrorDataFile] = useState(
    props.errorDataFile || { isValid: true, msg: '' }
  )

  useEffect(() => {
    if (props.dataFile && props.dataFile !== dataFile) {
      setDataFile(props.dataFile)
    }
    if (props.errorDataFile && props.errorDataFile !== errorDataFile) {
      setErrorDataFile(props.errorDataFile)
    }
  })

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: 'Il file è obbligatorio' })
    }
    let files = []
    for (let file of val.target.files) {
      if (!hasCorrectFileType(file.name)) {
        setErrorDataFile({
          isValid: false,
          msg: `Estensione o nome file errati, sono permessi file di tipo ${allowedFileExtensions.join(', ')}`
        })
      } else if (!checkFileDimension(val.target.files)) {
        setErrorDataFile({ isValid: false, msg: 'Il file non può pesare più di 20MB' })
      } else {
        setErrorDataFile({ isValid: true, msg: '' })
        files.push(file)
      }
    }

    if (files.length > 0) setDataFile(files)
  }

  const checkFileDimension = (files) => {
    let sumDimensionFiles = 0

    for (let file of files) {
      sumDimensionFiles += file.size
    }
    return sumDimensionFiles < maximumFileSize
  }

  const hasCorrectFileType = (name) => {
    if (allowedFileExtensions?.length === 0) return true
    return allowedFileExtensions.indexOf(name.split('.').pop().toLowerCase()) !== -1
  }

  //popola una stringa che indica i tipi di file accettati, secondo le specifiche del tag input
  const fillAcceptAttribute = (allowedFileExtensions = []) => {
    let accept = ''
    allowedFileExtensions.forEach((ext) => (accept += `.${ext},`))
    return accept
  }

  const allowedFileExtensions = props.allowedFileExtensions || []
  const maximumFileSize = 20e6
  const onHide = props.onHide || undefined
  const onSend = props.onSend || undefined
  const onUpload = props.onUpload || handleUploadFile
  const title = props.title || 'IMPORTA FILE'
  const ButtonClose =
    props.buttonClose ||
    (() => <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={() => onHide()} />)
  const ButtonLoadFiles =
    props.buttonLoad || (() => <UploadFileLabel forLabel={'dataFile'} label={'CARICA FILE'} />)
  const SecondaryTitle = props.secondaryTitle || (() => <></>)
  const ButtonUpload =
    props.buttonUpload ||
    (() => (
      <button
        disabled={!errorDataFile.isValid || !dataFile}
        onClick={() => onSend(dataFile)}
        type="button"
        className="btn btn-primary btn-new-rel px-5">
        CONFERMA
      </button>
    ))
  const ExtraButtons = props.extraButtons || (() => '')

  return (
    <>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{title}</h2>
            <SecondaryTitle />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-upload text-start ms-4">
            <ButtonLoadFiles />
            <input
              id={`dataFile`}
              type="file"
              name={`dataFile`}
              multiple
              accept={fillAcceptAttribute(allowedFileExtensions)}
              onChange={(e) => onUpload(e)}
            />
            <div className="certificatiLoaded">
              {!errorDataFile.isValid ? (
                <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
              ) : (
                <div>
                  {dataFile.length > 0 &&
                    dataFile.map((file, index) => {
                      return (
                        <div key={'file-' + index} className="green-label text-truncate">
                          <ICheck width="12" fill="#8e8e8e" />
                          {file.name}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-0">
            <ButtonClose />
            <ExtraButtons />
            <ButtonUpload />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
