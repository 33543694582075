import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLatestConsistency, importFromLatestConsistency } from '../../../../actions'
import RowSpinner from '../../../shared/spinner/Spinner'
import * as Utility from '../../../shared/Utility'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'
import { InfoBox } from '../../../shared/InfoBox'
import { BwmInput } from '../../../shared/form/BwmInput'
import { Col, Modal, Row } from 'react-bootstrap'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { BtnFill } from '../../../shared/BtnFill'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError } from '../../../shared/PopupError'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'

export default function ImportConsitencyRelationshipsForm({ show = false, onHide, onSuccess }) {
  const subject = useSelector((state) => state.subject.data)
  const subjectId = subject?.id
  const defaultEmail = subject?.customer?.user?.login
  const [updateExisting, setUpdateExisting] = useState(false)
  const [latestConsistency, setLatestConsistency] = useState(null)
  const [exportEmail, setExportEmail] = useState()
  const [errorExportEmail, setErrorExportEmail] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isElaborating, setIsElaborating] = useState(false)

  useEffect(() => {
    setExportEmail(defaultEmail)
    getLatestConsistency(subjectId)
      .then(
        (res) => {
          if (res) {
            setLatestConsistency(res)
          }
        },
        (err) => {
          console.log(err)
        }
      )
      .then(() => setIsLoading(false))

    return () => {}
  }, [defaultEmail, subjectId])

  const validate = () => {
    if (Utility.isEmpty(exportEmail) || !Utility.testEmail(exportEmail)) {
      setErrorExportEmail({
        isValid: false,
        msg: "Inserire un indirizzo valido per l'esportazione."
      })
      return false
    }
    return true
  }

  const onConfirm = () => {
    if (validate()) {
      setIsElaborating(true)
      importFromLatestConsistency(subjectId, exportEmail, updateExisting)
        .then(
          (res) => {
            Utility.downloadFile(res, `IMPORT - ${latestConsistency?.txtFilename}`)
            PopupSuccess()
            //ricaricare rapporti
            onSuccess()
          },
          (err) => {
            if (err?.errorKey === 'import-error') {
              PopupError({ text: err.title })
            } else {
              PopupError({ text: APPLICATION_GENERIC_ERROR })
            }
          }
        )
        .then(() => setIsElaborating(false))
    }
  }

  const renderInfoBox = () => {
    return (
      <InfoBox
        simplePointsArr={[
          {
            title: 'Info',
            text: "Tramite questa schermata, è possibile importare i dati dei rapporti presenti sull'ultima fotografia elaborata, non presenti su cora web. All'avvio, verrà recapitata una esportazione del soggetto alla mail specificata."
          },
          {
            title: 'Aggiorna rapporti esistenti',
            text: 'Se selezionata, i rapporti su cora web verranno aggiornati a quelli della fotografia, in base al codice univoco.'
          }
        ]}
      />
    )
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        size="lg"
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onHide}
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <h2>IMPORTA RAPPORTI DA ULTIMA FOTOGRAFIA</h2>
        </Modal.Header>
        <Modal.Body className="bg-blue">
          {isLoading && <RowSpinner />}
          {isElaborating && <PageSpinner />}
          <Row>
            {Utility.isNotEmpty(latestConsistency) ? (
              <Col>
                <Row>
                  <h4>Nome file: {latestConsistency.txtFilename}</h4>
                </Row>
                <Row>
                  <h4>
                    Elaborato in data: {Utility.formatDateTimeForDisplay(latestConsistency.creationDate)}
                  </h4>
                </Row>
                <Row>
                  <Col className={'d-flex flex-1 flex-column justify-content-center '} lg={6}>
                    <Row className="pb-3">
                      <BwmCheckbox
                        name={`update-existing`}
                        label="Aggiorna rapporti esistenti"
                        className="form-control"
                        value={updateExisting}
                        onClick={(e) => setUpdateExisting(e.target.checked)}
                      />
                    </Row>
                    <Row>
                      <BwmInput
                        label={"Indirizzo email al quale inviare l'esportazione"}
                        value={exportEmail}
                        type={'email'}
                        className="form-control"
                        onChange={(e) => setExportEmail(e.target.value)}
                        error={errorExportEmail}
                      />
                    </Row>
                  </Col>
                  <Col lg={6}>{renderInfoBox()}</Col>
                </Row>
              </Col>
            ) : (
              <>
                <h4>Nessuna fotografia elaborata con questo soggetto</h4>
                {renderInfoBox()}
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={onHide} />
            {Utility.isNotEmpty(latestConsistency) && (
              <BtnFill
                text="CONFERMA"
                classCustom="float-end text-end"
                handlerOnClick={onConfirm}
              />
            )}
          </Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  )
}
