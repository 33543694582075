import it from 'date-fns/locale/it'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import ArcoTableProfessionistsList from '../subject-arco/ArcoTableProfessionistsList'
import { useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
registerLocale('it', it)

export function ArcoRegistrationProfessionalsModal(props) {
  const { professionistsData } = props
  const dispatch = useDispatch()

  const getAllProfessionistBySubjectId = () => {
    dispatch(actions.getAllProfessionistBySubjectId(props.subject?.id))
  }

  const confirmButton = (selectedItems = []) => {
    return (
      <button
        className="btn btn-primary btn-new-rel px-5"
        disabled={selectedItems.length === 0}
        onClick={() => props.onConfirm(selectedItems)}>
        AGGIUNGI SELEZIONATI
      </button>
    )
  }

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={'arco-modal-max'}>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Elenco Professionisti</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'me-1 ms-1'}>
            <ArcoTableProfessionistsList
              {...props}
              professionists={professionistsData}
              subject={props.subject}
              confirmButton={(selectedItems = []) => confirmButton(selectedItems)}
              getAllProfessionistBySubjectId={getAllProfessionistBySubjectId}
              onClose={props.onHide}
              disableInsert={props.disableInsert}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
