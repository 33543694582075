import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import * as Constants from '../../config/Constants'
import crsPositive from '../../styles/images/app/positive/Crs.png'
import coraPositive from '../../styles/images/app/positive/Cora.png'
import comureiPositive from '../../styles/images/app/positive/Comurei.png'
import corproPositive from '../../styles/images/app/positive/Corpro.png'
import arcoPositive from '../../styles/images/app/positive/Arco.png'
import bluePositive from '../../styles/images/app/positive/Blue.png'
import Modal from 'react-bootstrap/Modal'
import * as actions from '../../actions'
import { PopupError } from '../shared/PopupError'
import { PopupSuccess } from '../shared/PopupSuccess'
import { formatDateForDisplay } from '../shared/Utility'
import { Row } from 'react-bootstrap'

export class ApplicationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDemoModal: false,
      applications: this.props.applications,
      selectedApplicationForModal: ''
    }
  }
  checkExpire(expire) {
    const format = formatDateForDisplay(expire)
    if (moment(expire).add(1, 'days').isSameOrAfter(moment())) {
      return 'fino al ' + format
    } else {
      return 'scaduto il ' + format
    }
  }

  openModal = (applicationName) => {
    this.setState({ showDemoModal: true, selectedApplicationForModal: applicationName })
  }

  closeModal = () => {
    this.setState({ showDemoModal: false })
  }

  activateDemo = (applicationName, application) => {
    actions.activateDemoForApplication(applicationName).then(
      (response) => {
        if (response) {
          this.closeModal()
          this.props.getApplication()
          PopupSuccess({
            text: 'Licenza DEMO attivata',
            handleClickConfirm: () => window.location.reload()
          })
        }
      },
      (errors) => {
        PopupError({ text: 'Errore generico nel salvataggio dei dati' })
      }
    )
  }

  renderApplications() {
    return this.props.applications.map((application, index) => {
      let icon = ''

      let modalFunc = () => () => this.renderStandardModal()

      const applicationName = application.application.name
      switch (applicationName.toLowerCase()) {
        case 'cora':
          icon = coraPositive
          modalFunc = () => this.renderCoraModal()
          break
        case 'comurei':
          icon = comureiPositive
          modalFunc = () => this.renderComureiModal()
          break
        case 'crs':
          icon = crsPositive
          modalFunc = () => this.renderCRSModal()
          break
        case 'corpro':
          icon = corproPositive
          break
        case 'arco':
          icon = arcoPositive
          modalFunc = () => this.renderStandardModal()
          break
        case 'blue':
          modalFunc = () => this.renderStandardModal()
          icon = bluePositive
          break
        case 'dac7':
          // eslint-disable-next-line no-restricted-globals
          // modalFunc = () => this.renderStandardModal()
          icon = '/app/dac7/assets/dac7-logo.png'
          break
        default:
      }
      let licenseString = ''
      switch (application.licenseType) {
        case 'BOUGHT':
          licenseString = 'Abbonamento ' + this.checkExpire(application.expiration)
          break
        case 'DEMO':
          licenseString = 'In DEMO ' + this.checkExpire(application.expiration)
          break
        case 'DEMOAVAILABLE':
          licenseString = 'Demo disponibile per 30 giorni'
          break
      }

      return (
        <div key={applicationName}>
          {modalFunc()}
          {application.licenseType === 'DEMOAVAILABLE' ? (
            <button className="card" onClick={() => this.openModal(applicationName)}>
              <div className={'col'}>
                <div className={'row coraStar-img'}>
                  <img src={icon} alt={`${applicationName}`} className={'image-app'} />
                </div>
                <div className={'row coraStar-subtitle'}>
                  <span>{application.application.description}</span>
                </div>
                <div className={'row coraStar-scadenza'}>
                  {applicationName === 'COMUREI' ? (
                    <span>"Attiva il primo Soggetto gratuitamente"</span>
                  ) : (
                    <span>{licenseString}</span>
                  )}
                </div>
              </div>
            </button>
          ) : (
            <Link className="card" to={`/app/${applicationName.toLowerCase()}/dashboard`}>
              <div className={'col'}>
                <div className={'row coraStar-img'}>
                  <img src={icon} alt={`${applicationName}`} className={'image-app'} />
                </div>
                <div className={'row coraStar-subtitle'}>
                  <span>{application.application.description}</span>
                </div>
                {this.renderLabel(application, licenseString)}
              </div>
            </Link>
          )}
        </div>
      )
    })
  }

  renderCoraModal() {
    return (
      <DemoActivationModal
        show={this.state.showDemoModal && this.state.selectedApplicationForModal === 'CORA'}
        onHide={this.closeModal}
        applicationName={'CORA'}
        BodyComponent={() => {
          return (
            <>
              <Row className="mt-4 justify-content-center">
                {' '}
                Si desidera attivare la versione demo di CORA per 30 giorni?
              </Row>

              <Row className="mt-4 justify-content-center">
                {' '}
                Con la versione DEMO potrete inserire i dati e valutare l'operatività del programma
              </Row>
              <Row className="justify-content-center">
                <strong>La demo di CORA Web non ha limiti e può essere usata per 30 giorni.</strong>
              </Row>
              <Row className="justify-content-center">
                <strong>
                  Potete sottoscrivere l'abbonamento annuale al programma cliccando
                  <a href={Constants.CORA_BUY_LINK} target="blank">
                    {' '}
                    qui
                  </a>
                </strong>
              </Row>
              <Row className="justify-content-center">
                <strong>
                  I dati inseriti nel programma durante il periodo di DEMO verranno conservati
                </strong>
              </Row>
            </>
          )
        }}
        onActivateDemo={() => this.activateDemo(this.state.selectedApplicationForModal)}
      />
    )
  }

  renderStandardModal() {
    return (
      <DemoActivationModal
        show={this.state.showDemoModal}
        onHide={this.closeModal}
        applicationName={this.state.selectedApplicationForModal}
        BodyComponent={() => (
          <>
            Si desidera attivare la versione demo di {this.state.selectedApplicationForModal} per 30
            giorni?
          </>
        )}
        onActivateDemo={() => this.activateDemo(this.state.selectedApplicationForModal)}
      />
    )
  }

  renderCRSModal() {
    return (
      <DemoActivationModal
        show={this.state.showDemoModal && this.state.selectedApplicationForModal === 'CRS'}
        onHide={this.closeModal}
        applicationName={'CRS'}
        BodyComponent={() => {
          return (
            <>
              <Row className="mt-4 justify-content-center">
                {' '}
                Si desidera attivare la versione demo di CRS per 30 giorni?
              </Row>

              <Row className="mt-4 justify-content-center">
                {' '}
                Con la versione DEMO potrete inserire i dati e valutare l'operatività del programma
              </Row>
              <Row className="justify-content-center">
                <strong>
                  La creazione finale della comunicazione (bottone CREA COMUNICAZIONE) richiede il
                  pagamento della licenza che potete acquistare cliccando
                  <a href={Constants.CRS_BUY_LINK} target="blank">
                    {' '}
                    qui
                  </a>
                </strong>
              </Row>
            </>
          )
        }}
        onActivateDemo={() => this.activateDemo(this.state.selectedApplicationForModal)}
      />
    )
  }

  renderComureiModal() {
    return (
      <DemoActivationModal
        show={this.state.showDemoModal && this.state.selectedApplicationForModal === 'COMUREI'}
        onHide={this.closeModal}
        applicationName={'COMUREI'}
        BodyComponent={() => {
          return (
            <>
              <Row className="mt-4 justify-content-center">
                {' '}
                Si desidera attivare COMUREI per il PRIMO SOGGETTO (gratuito)?
              </Row>

              <Row className="justify-content-center mt-4">
                <strong>
                  Potrete acquistare ulteriori soggetti cliccando
                  <a href={Constants.COMUREI_BUY_LINK} target="blank">
                    {' '}
                    qui
                  </a>
                </strong>
              </Row>
            </>
          )
        }}
        buttonText="ATTIVA"
        titleText="ATTIVA COMUREI"
        onActivateDemo={() => this.activateDemo(this.state.selectedApplicationForModal)}
      />
    )
  }

  renderLabel(application, licenseString) {
    if (application.application.name.toLowerCase() === 'comurei') {
      return (
        <div className={'row coraStar-scadenza'}>
          <span>
            Abbonamento SENZA SCADENZA per &nbsp;<b>{application.maxSubjects}&nbsp;</b> soggetto/i
          </span>
        </div>
      )
    } else {
      if (application.licenseType === 'BOUGHT') {
        return (
          <div className={'row coraStar-scadenza'}>
            <span>
              {application.type}
              {licenseString} per&nbsp;<b>{application.maxSubjects}&nbsp;</b> soggetti
            </span>
          </div>
        )
      } else if (application.licenseType === 'DEMO') {
        return (
          <div className={'row coraStar-scadenza'}>
            <span>
              {licenseString} per&nbsp;<b>{application.maxSubjects}</b>&nbsp;soggetti
            </span>
          </div>
        )
      }
    }
  }

  render() {
    return (
      <div className="container-dash">
        <div className={this.props?.applications?.length > 0 ? 'panel-dash' : ''}>
          {this.renderApplications()}
        </div>
      </div>
    )
  }
}

function DemoActivationModal({
  show = false,
  onHide = () => {},
  applicationName = '',
  BodyComponent = () => <></>,
  buttonText = 'Attiva demo',
  titleText = 'Attivazione demo ' + applicationName,
  onActivateDemo = () => {}
}) {
  return (
    <Modal
      key={'modal' + applicationName}
      backdrop="static"
      dialogClassName="modal-40w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}>
      <div className="bar" />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        <div style={{ fontSize: '13px;' }}>
          <BodyComponent />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row d-flex justify-content-around">
          <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={onHide}>
            Chiudi
          </button>
          <button className="btn btn-primary btn-new-rel px-5" onClick={onActivateDemo}>
            {buttonText}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
