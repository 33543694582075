import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { BwmSelect } from '../../../shared/form/BwmSelect'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { BtnFill } from '../../../shared/BtnFill'
import { BwmInput } from '../../../shared/form/BwmInput'
import NumberFormat from 'react-number-format'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import {
  BALANCE_DISABLED,
  getBalanceRulesByRelationshipType,
  getDisabledFields
} from '../BalanceRules'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'

export function ModalCreateBalance(props) {
  const dispatch = useDispatch()
  const { relationship, onHide, handlerSubmitBalance, balance, balances } = props
  const [formDataBalance, updateFormDataBalance] = useState({
    coraRelationship: {
      id: relationship?.id ? relationship?.id : null
    }
  })
  const [yearBalance, setYearBalance] = useState(
    balance?.year ? balance.year : new Date().getFullYear() - 1
  )
  const [errorYearBalance, setErrorYearBalance] = useState({ isValid: true, msg: '' })
  const [amount1Balance, setAmount1Balance] = useState(balance?.amount1 ? balance.amount1 : 0)
  const [errorAmount1Balance, setErrorAmount1Balance] = useState({ isValid: true, msg: '' })
  const [amount2Balance, setAmount2Balance] = useState(balance?.amount2 ? balance.amount2 : 0)
  const [errorAmount2Balance, setErrorAmount2Balance] = useState({ isValid: true, msg: '' })
  const [amount3Balance, setAmount3Balance] = useState(balance?.amount3 ? balance.amount3 : 0)
  const [errorAmount3Balance, setErrorAmount3Balance] = useState({ isValid: true, msg: '' })
  const [amount4Balance, setAmount4Balance] = useState(balance?.amount4 ? balance.amount4 : 0)
  const [errorAmount4Balance, setErrorAmount4Balance] = useState({ isValid: true, msg: '' })
  const [amount5Balance, setAmount5Balance] = useState(balance?.amount5 ? balance.amount5 : 0)
  const [errorAmount5Balance, setErrorAmount5Balance] = useState({ isValid: true, msg: '' })
  const [stockBalance, setStockBalance] = useState(balance?.stock ? balance.stock : 0)
  const [errorStockBalance, setErrorStockBalance] = useState({ isValid: true, msg: '' })
  const [currencyBalance, setCurrencyBalance] = useState(
    balance?.currency?.id ? balance?.currency?.id : 58
  )
  const [errorCurrencyBalance, setErrorCurrencyBalance] = useState({ isValid: true, msg: '' })
  const [currencyType, setCurrencyType] = useState(balance?.currencyType || null)
  const [idResult, setIdResult] = useState(balance?.idResult || '')
  const [relationshipRules] = useState(
    getBalanceRulesByRelationshipType(props.relationship?.relationshipType?.code || '01')
  )

  const [disabledFields] = useState(getDisabledFields(relationshipRules))
  const currencyTypes = useSelector((state) => state.currencyTypes.data)

  useEffect(() => {
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      id: balance?.id || null,
      currency: {
        id: currencyBalance,
        isoCode: 'EUR',
        description: 'EURO UNIONE ECONOMICA MONETARIA'
      },
      amount1: amount1Balance || 0,
      amount2: amount2Balance || 0,
      amount3: amount3Balance || 0,
      amount4: amount4Balance || 0,
      amount5: amount5Balance || 0,
      stock: stockBalance || 0,
      year: yearBalance,
      currencyType: currencyType,
      idResult: idResult
    })
    if (currencyTypes.length === 0) {
      dispatch(actions.getAllCurrencyTypes())
    }
  }, [])

  const handlerYear = (e) => {
    setYearBalance(e.target.value.trim())

    setErrorYearBalance({ isValid: true, msg: '' })
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      year: e.target.value.trim()
    })
  }

  const handlerAmount1 = (val) => {
    if (isEmpty(val)) val = 0

    setAmount1Balance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      amount1: val.floatValue
    })
  }

  const handlerAmount2 = (val) => {
    if (isEmpty(val)) {
      val = 0
    }
    setAmount2Balance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      amount2: val.floatValue
    })
  }

  const handlerAmount3 = (val) => {
    if (isEmpty(val)) {
      val = 0
    }
    setAmount3Balance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      amount3: val.floatValue
    })
  }

  const handlerAmount4 = (val) => {
    if (isEmpty(val)) {
      val = 0
    }
    setAmount4Balance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      amount4: val.floatValue
    })
  }

  const handlerAmount5 = (val) => {
    if (isEmpty(val)) {
      val = 0
    }
    setAmount5Balance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      amount5: val.floatValue
    })
  }

  const handlerStock = (val) => {
    if (isEmpty(val)) {
      val = 0
    }
    setStockBalance(val.floatValue)
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      stock: val.floatValue
    })
  }

  const handlerCurrency = (e) => {
    setCurrencyBalance(e.target.value.trim())
    updateFormDataBalance({
      ...formDataBalance,
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      currency: {
        id: e.target.value.trim(),
        isoCode: e.target.getAttribute('data-cod'),
        description: e.target.options[e.target.value].text
      }
    })
  }

  const handlerCurrencyType = (e) => {
    const value = isNotEmpty(e.target.value) ? { id: e.target.value } : null
    setCurrencyType(value)
    updateFormDataBalance({
      ...formDataBalance,
      currencyType: value
    })
  }

  const handlerIdResult = (e) => {
    const value = e.target.value.toUpperCase().trim()
    setIdResult(value)
    updateFormDataBalance({
      ...formDataBalance,
      idResult: value
    })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (yearBalance == undefined) {
      setErrorYearBalance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }

    if (amount1Balance == undefined && relationshipRules?.amount1 !== BALANCE_DISABLED) {
      setErrorAmount1Balance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount2Balance == undefined && relationshipRules?.amount2 !== BALANCE_DISABLED) {
      setErrorAmount2Balance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount3Balance == undefined && relationshipRules?.amount3 !== BALANCE_DISABLED) {
      setErrorAmount3Balance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount4Balance == undefined && relationshipRules?.amount4 !== BALANCE_DISABLED) {
      setErrorAmount4Balance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount5Balance == undefined && relationshipRules?.amount5 !== BALANCE_DISABLED) {
      setErrorAmount5Balance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (stockBalance == undefined && relationshipRules?.stock !== BALANCE_DISABLED) {
      setErrorStockBalance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (currencyBalance == undefined) {
      setErrorCurrencyBalance({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }

    return isValid
  }

  const resetError = () => {
    setErrorYearBalance({ isValid: true, msg: '' })
    setErrorAmount1Balance({ isValid: true, msg: '' })
    setErrorAmount2Balance({ isValid: true, msg: '' })
    setErrorAmount3Balance({ isValid: true, msg: '' })
    setErrorAmount4Balance({ isValid: true, msg: '' })
    setErrorAmount5Balance({ isValid: true, msg: '' })
    setErrorStockBalance({ isValid: true, msg: '' })
    setErrorCurrencyBalance({ isValid: true, msg: '' })
  }

  const handlerSubmit = () => {
    if (handleValidation()) {
      if (!balance?.id) {
        const balancesList = balances || []
        let id = null
        for (let value of balancesList) {
          if (value.year == yearBalance) {
            id = value.id
          }
        }
        formDataBalance.id = id
      }
      handlerSubmitBalance(formDataBalance)
    }
  }

  const handlerClose = () => {
    resetError()
    onHide()
  }

  const renderAmountTextBox = (handler, tooltip, disabled, value, label, error) => {
    return (
      <StarTooltip text={tooltip}>
        <div className="form-group">
          <label>{label}</label>
          <div className="input-group">
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={0}
              fixedDecimalScale={true}
              className="form-control"
              inputMode="numeric"
              value={!disabled && value}
              readOnly={disabled}
              onValueChange={(val) => handler(val)}
            />
          </div>
          {error && error.isValid === false ? <div className="text-danger">{error.msg}</div> : ''}
        </div>
      </StarTooltip>
    )
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      onHide={handlerClose}
      bsPrefix="modal-create modal"
      backdrop="static">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiungi Saldo
          <h4>
            {relationship?.companyName != null && relationship?.companyName !== ''
              ? relationship.companyName
              : relationship?.lastName + ' ' + relationship?.firstName}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="show-grid">
            <Col md={2} lg={2} className="me-4">
              <BwmInput
                name="year"
                label="Anno"
                className="form-control"
                value={yearBalance}
                onChange={(e) => handlerYear(e)}
                error={errorYearBalance}
                maxLength="4"
              />
            </Col>
            <Col md={2} lg={2} className="me-4">
              <BwmInput
                name="idResult"
                label="Esito"
                className="form-control"
                value={idResult}
                onChange={(e) => handlerIdResult(e)}
              />
            </Col>
          </Row>
          <Row className="show-grid align-items-center">
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerAmount1,
                relationshipRules.amount1,
                disabledFields.amount1Disabled,
                amount1Balance,
                'Importo 1',
                errorAmount1Balance
              )}
            </Col>
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerAmount2,
                relationshipRules.amount2,
                disabledFields.amount2Disabled,
                amount2Balance,
                'Importo 2',
                errorAmount2Balance
              )}
            </Col>
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerAmount3,
                relationshipRules.amount3,
                disabledFields.amount3Disabled,
                amount3Balance,
                'Importo 3',
                errorAmount3Balance
              )}
            </Col>
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerAmount4,
                relationshipRules.amount4,
                disabledFields.amount4Disabled,
                amount4Balance,
                'Importo 4',
                errorAmount4Balance
              )}
            </Col>
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerAmount5,
                relationshipRules.amount5,
                disabledFields.amount5Disabled,
                amount5Balance,
                'Altre Informazioni / Importo 5',
                errorAmount5Balance
              )}
            </Col>
          </Row>
          <Row className="show-grid">
            <Col md={2} lg={2} className="me-4">
              {renderAmountTextBox(
                handlerStock,
                relationshipRules.stock,
                disabledFields.stockDisabled,
                stockBalance,
                'Giacenza media',
                errorStockBalance
              )}
            </Col>
            <Col lg={4}>
              <BwmSelect
                options={props.currencies}
                name="currency"
                label="Valuta"
                className="form-control"
                onChange={(e) => handlerCurrency(e)}
                error={errorCurrencyBalance}
                value={currencyBalance}
                showCode={true}
              />
            </Col>
            <Col lg={4}>
              <BwmSelect
                options={currencyTypes}
                name="currencyType"
                label="Natura"
                className="form-control"
                onChange={(e) => handlerCurrencyType(e)}
                value={currencyType?.id}
                showCode={true}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={handlerClose} />
        <BtnFill text="SALVA" classCustom="float-end text-end" handlerOnClick={handlerSubmit} />
      </Modal.Footer>
    </Modal>
  )
}
