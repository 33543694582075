import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CorproForm from './CorproForm'
import CommunicationsHistoryModal from '../communications/CommunicationsHistoryModal'
import { ReactComponent as ISort } from '../../../../styles/images/svgs/regular/sort.svg'

class CorproSubjectList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      indexShow: 0,
      subjects: undefined,
      sortCorproSubjectType: 1,
      sortCompanyName: 1,
      sortFiscalCode: 1,
      sortCorproCommunicationType: 1,
      sortCorproSubjectResponsible: 1
    }
  }

  onCheckClick = (event) => {
    if (event.target.checked) this.checkAll()
    else this.uncheckAll()
  }

  checkAll = () => {
    document.getElementById('checkboxAllId').checked = true
    this.props.subjects.map((subject) => {
      const checkboxDetailsId = `checkboxDetails${subject.id}`
      const idCheckbox = `checkboxDetails${subject.id}`
      document.getElementById(checkboxDetailsId).checked = true
      document.getElementById(idCheckbox).checked = true
    })
    this.props.checkAllSubjects()
    let rowIds = document.querySelectorAll('[id^="row-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.add('bg-cobalt', 'bold')
      }
    }
  }

  uncheckAll = () => {
    document.getElementById('checkboxAllId').checked = false
    this.props.subjects.map((subject) => {
      const checkboxDetailsId = `checkboxDetails${subject.id}`
      const idCheckbox = `checkboxDetails${subject.id}`
      document.getElementById(checkboxDetailsId).checked = false
      document.getElementById(idCheckbox).checked = false
    })
    this.props.uncheckAllSubjects()
    let rowIds = document.querySelectorAll('[id^="row-"]')
    for (let i = 0; i < rowIds.length; i++) {
      if (rowIds[i].localName === 'div') {
        rowIds[i].classList.remove('bg-cobalt', 'bold')
      }
    }
  }

  onCheckBoxClick = (event, subject) => {
    if (event.target.checked) {
      this.props.checkSubject(subject)
      let element = document.getElementById('row-' + subject.id)
      if (element) element.classList.add('bg-cobalt', 'bold')
    } else {
      this.props.uncheckSubject(subject)
      document.getElementById('checkboxAllId').checked = false
      let element = document.getElementById('row-' + subject.id)
      if (element) element.classList.remove('bg-cobalt', 'bold')
    }
  }

  showDetail = (index) => {
    this.setState({
      show: !this.state.show,
      indexShow: index
    })
  }

  renderRows(subjects) {
    return subjects.map((subject, index) => {
      const idCheckbox = `checkboxDetails${subject.id}`
      return (
        <React.Fragment key={index}>
          <div
            className={
              'text-start row-table' +
              (this.state.show && this.state.indexShow === index ? ' bg-blue bold' : '')
            }
            id={`row-${subject.id}`}>
            <div className="col w-5 text-center div-td">
              <input
                type="checkbox"
                id={idCheckbox}
                onClick={(e) => this.onCheckBoxClick(e, subject)}
              />
            </div>
            <div className="col w-15 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.authorityDescription}
            </div>
            <div className="col w-15 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.subjectName}
            </div>
            <div className="col w-15 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.authorityFiscalCode}
            </div>
            <div className="col w-40 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.corproSubjectType.code} - {subject.corproSubjectType.description}
            </div>
            <div className="col w-15 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.referenceYear}
            </div>
            <div className="col w-20 div-td text-truncate" onClick={() => this.showDetail(index)}>
              {subject.corproCommunicationType.code} - {subject.corproCommunicationType.description}
            </div>
            <div className="col w-8 div-td text-truncate d-flex justify-content-around pe-4 ps-4">
              <CommunicationsHistoryModal
                subjectId={subject.id}
                subjectName={subject.subjectName}
                downloadTxt={this.props.downloadTxt}
              />
            </div>
          </div>
          <TransitionGroup component={null}>
            {this.state.show && this.state.indexShow === index ? (
              <CSSTransition in={this.state.show} timeout={300} classNames="visible" unmountOnExit>
                <div className="row-detail bg-blue">
                  <div className="col-md-12 col-lg-12 text-start p-0">
                    <CorproForm
                      {...this.props}
                      showDetail={this.showDetail}
                      subjectItem={subject}
                      index={index}
                      user={this.props.user}
                      openCreateCommunicationModal={this.props.openCreateCommunicationModal}
                    />
                  </div>
                </div>
              </CSSTransition>
            ) : (
              ''
            )}
          </TransitionGroup>
        </React.Fragment>
      )
    })
  }

  sortBy = (val) => {
    if (val) {
      let subjects =
        this.state.subjects && this.state.subjects.length > 0
          ? this.state.subjects
          : this.props.subjects
      let subjectsOrdered = subjects
      switch (val) {
        case 'corproSubjectType':
          // Sort by corproSubjectType
          subjectsOrdered = subjects.sort((a, b) =>
            a.corproSubjectType?.code > b.corproSubjectType?.code
              ? this.state.sortCorproSubjectType
              : b.corproSubjectType?.code > a.corproSubjectType?.code
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            subjectsOrdered.reverse()
          }
          this.setState({
            subjects: subjectsOrdered,
            sortCorproSubjectType: -this.state.sortCorproSubjectType
          })
          break
        case 'companyName':
          // Sort by companyName
          subjectsOrdered = subjects.sort((a, b) =>
            a.companyName > b.companyName
              ? this.state.sortCompanyName
              : b.companyName > a.companyName
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            subjectsOrdered.reverse()
          }
          this.setState({ subjects: subjectsOrdered, sortCompanyName: -this.state.sortCompanyName })
          break
        case 'fiscalCode':
          // Sort by fiscalCode
          subjectsOrdered = subjects.sort((a, b) =>
            a.fiscalCode > b.fiscalCode
              ? this.state.sortFiscalCode
              : b.fiscalCode > a.fiscalCode
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            subjectsOrdered.reverse()
          }
          this.setState({ subjects: subjectsOrdered, sortFiscalCode: -this.state.sortFiscalCode })
          break
        case 'corproCommunicationType':
          // Sort by corproCommunicationType
          subjectsOrdered = subjects.sort((a, b) =>
            a.corproCommunicationType?.code > b.corproCommunicationType?.code
              ? this.state.sortCorproCommunicationType
              : b.corproCommunicationType?.code > a.corproCommunicationType?.code
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            subjectsOrdered.reverse()
          }
          this.setState({
            subjects: subjectsOrdered,
            sortCorproCommunicationType: -this.state.sortCorproCommunicationType
          })
          break
        case 'corproSubjectResponsible':
          // Sort by corproSubjectResponsible
          subjectsOrdered = subjects.sort((a, b) =>
            a.corproSubjectResponsible?.lastName > b.corproSubjectResponsible?.lastName
              ? this.state.sortCorproSubjectResponsible
              : b.corproSubjectResponsible?.lastName > a.corproSubjectResponsible?.lastName
                ? -1
                : 0
          )
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            subjectsOrdered.reverse()
          }
          this.setState({
            subjects: subjectsOrdered,
            sortCorproSubjectResponsible: -this.state.sortCorproSubjectResponsible
          })
          break
        default:
          return
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="text-start header-table">
          <div className="col w-5 text-center div-td">
            <input type="checkbox" id="checkboxAllId" onClick={this.onCheckClick} />
          </div>
          <div className="col w-15 div-td">
            <strong>Soggetto</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => this.sortBy('corproSubjectResponsible')}
            />
          </div>
          <div className="col w-15 div-td">
            <strong>Comunicato da</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => this.sortBy('companyName')}
            />
          </div>
          <div className="col w-15 div-td">
            <strong>Codice Fiscale</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => this.sortBy('fiscalCode')}
            />
          </div>
          <div className="col w-40 div-td">
            <strong>Tipo Soggetto</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => this.sortBy('corproSubjectType')}
            />
          </div>
          <div className="col w-15 div-td">
            <strong>Anno</strong>
          </div>
          <div className="col w-20 div-td">
            <strong>Tipo Comunicazione</strong>{' '}
            <ISort
              width="10"
              className={'pointer float-end'}
              fill="#128186"
              onClick={() => this.sortBy('corproCommunicationType')}
            />
          </div>
          <div className="col w-8 div-td" style={{ textAlign: 'center' }}>
            <strong>Storico</strong>
          </div>
        </div>
        {this.renderRows(this.props.subjects)}
      </React.Fragment>
    )
  }
}

export default CorproSubjectList
