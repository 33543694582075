import {
  CORPRO_GET_SUBJECT_TYPES_SUCCESS,
  CORPRO_GET_SUBJECTS_SUCCESS,
  GET_ALL_TRANSMISSION_TYPES_SUCCESS,
  GET_ALL_SUPPLIER_TYPES_SUCCESS
} from '../../actions/types'

const INITIAL_STATE = {
  corproSubjectTypes: {
    data: [],
    errors: []
  },

  corproSubjects: {
    data: [],
    errors: []
  },

  corproTransmissionTypes: {
    data: [],
    errors: []
  },

  corproSupplierTypes: {
    data: [],
    errors: []
  }
}

export const corproSubjectTypesReducer = (state = INITIAL_STATE.corproSubjectTypes, action) => {
  switch (action.type) {
    case CORPRO_GET_SUBJECT_TYPES_SUCCESS:
      return { ...state, data: action.corproSubjectTypes }
    default:
      return state
  }
}

export const corproSubjectsReducer = (state = INITIAL_STATE.corproSubjects, action) => {
  switch (action.type) {
    case CORPRO_GET_SUBJECTS_SUCCESS:
      return { ...state, data: action.corproSubjects }
    default:
      return state
  }
}

export const corproTransmissionTypesReducer = (
  state = INITIAL_STATE.corproTransmissionTypes,
  action
) => {
  switch (action.type) {
    case GET_ALL_TRANSMISSION_TYPES_SUCCESS:
      return { ...state, data: action.corproTransmissionTypes }
    default:
      return state
  }
}

export const corproSupplierTypesReducer = (state = INITIAL_STATE.corproSupplierTypes, action) => {
  switch (action.type) {
    case GET_ALL_SUPPLIER_TYPES_SUCCESS:
      return { ...state, data: action.corproSupplierTypes }
    default:
      return state
  }
}
