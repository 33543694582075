import React, { useState, useEffect } from 'react'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../application-cora/relationships/ProvinceFieldB5'
import { ISave, IUndo } from '../../../../styles/icons'
import { ReactComponent as IAnagrafica } from '../../../../styles/images/svg/id-card.svg'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { PopupError } from '../../../shared/PopupError'
import * as Utility from '../../../shared/Utility'
import { Formik, Field, Form } from 'formik'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import { cloneDeep } from 'lodash'
import * as Yup from 'yup'

export const CoraSubjectForm = (props) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const [subject, setSubject] = useState({})

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)
  const [errorsSid, setErrorsSid] = useState({})

  const nameApplication = Constants.APPLICATION_CORA
  const id = props.subjectId

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required('Campo obbligatorio'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const retrieveSubject = (id) => {
    if (id) {
      actions.getCoraSubjectDetail(id).then((response) => {
        setSubject(response.data)
        props.setSubject(response.data)
        setNewInitialValues(response.data)
      })
    } else {
      setSubject({})
      setNewInitialValues(undefined)
    }
  }

  const setNewInitialValues = (subjectItem) => {
    if (subjectItem) setCoraSubjectSid(subjectItem.coraSubjectSid)
    setInitialValues({
      id: subjectItem?.id || null,
      personType: subjectItem?.personType || Constants.PNF,
      fiscalCode: subjectItem?.fiscalCode,
      companyName: subjectItem?.companyName,
      location: subjectItem?.location,
      province: subjectItem?.province,
      firstName: subjectItem?.firstName,
      lastName: subjectItem?.lastName,
      gender: subjectItem?.gender,
      birthDate: subjectItem?.birthDate,
      errorFiscalCode: false
    })
  }

  const addOrUpdateCoraSubject = (sid, values) => {
    let newSubject = { ...values }
    newSubject.createDate = new Date()
    newSubject.modifyDate = new Date()
    newSubject.coraSubjectSid = sid
    props.addOrUpdateCoraSubject(newSubject, values?.fiscalCode)
    closeModal()
  }

  const closeModal = () => {
    setErrorsSid({})
    if (props.closeModal) {
      setNewInitialValues()
      props.closeModal()
    }
  }

  const resetFields = () => {
    setCoraSubjectSid({})
  }

  const updateAll = (values) => {
    saveSid(
      certificateFiles?.files,
      coraSubjectSid,
      values.fiscalCode,
      'cora',
      (res) => addOrUpdateCoraSubject(res, { ...values, errorFiscalCode: undefined }),
      () => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        resetFields()
      }
    )
  }

  const configurationSidValidation = () => {
    return validateSid(
      coraSubjectSid?.sidNumber,
      coraSubjectSid?.email,
      errorsSid.errorSidNumber,
      errorsSid.errorEmail
    )
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const handleFormSubmit = async (values, validateForm) => {
    const errors = await validateForm(values)
    setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0
    setErrorsSid({ ...errorsSid, errorSidNumber, errorEmail })
    if (thereAreErrors) {
      setShowErrorAlert(true)
    } else {
      updateAll(values)
      setShowErrorAlert(false)
    }
  }

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione sociale'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede legale'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'gender',
      errorLabel: 'Sesso'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: 'Numero accreditamento SID'
    },
    {
      errorKey: 'errorPassword',
      errorLabel: 'Password di protezione'
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: 'Soggetto'
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: 'Configurazione SID'
    },
    {
      errorKey: 'errorEmail',
      errorLabel: 'Indirizzo email'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  // TODO ASPETTARE API DI EDOARDO
  const checkSid = (sidNumber) => {
    const allSubjects = props.subjects || []
    for (const subject of allSubjects) {
      const sid = subject.coraSubjectSid?.sidNumber
      if (subject.id != props.subjectId && sid === sidNumber) {
        PopupError({
          text: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
        })
        return false
      }
    }
    return true
  }

  const handleChangeSid = (val, errorKey, errorMessage) => {
    let value = val.target.value
    let name = val.target.name
    let newCoraSubjectSid = cloneDeep(coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    setErrorsSid({ ...errorsSid, errorInConfigSid: '', ...error })
    if (name === 'password') {
      newCoraSubjectSid.password = value.toUpperCase().trim()
    }
    if (name === 'sidNumber') {
      if (checkSid(value.toUpperCase().trim())) {
        newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
      } else {
        PopupError({
          text: `Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`
        })
      }
    }
    if (name === 'email') {
      coraSubjectSid.email = value ? value.toUpperCase().trim() : value
    }
    setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleChangeSidValues = (newValues) => {
    setCoraSubjectSid({ ...coraSubjectSid, ...newValues })
  }

  const handleCertificateFilesCustom = (val) => {
    /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
    setIsCertSidOpened(true)
    if (isNotEmpty(val)) setCertificateFiles(val)
  }

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = 'Luogo di nascita'
    else formErrorsTabMapping[4].errorLabel = 'Sede legale (Comune)'
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  const handlePersonTypeClick = (val, setValues, values) => {
    const form = Object.assign({}, values)
    form.personType = val
    if (val === Constants.PF) {
      form.companyName = null
    } else {
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
    }
    setValues(form)
  }

  const handleChangeValue = (val, name, setValues, values, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (val && val !== '' && trim) val = val.trim()
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleFiscalCode = (fiscalCode, setValues, values) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      actions.getBaseRegistryByFiscalCode(fiscalCode).then(
        (baseRegistry) => {
          if (baseRegistry) {
            form.companyName = baseRegistry?.companyName || ''
            form.location = baseRegistry?.location?.location || ''
            form.province = baseRegistry?.location?.province || ''
            form.lastName = baseRegistry?.lastName || ''
            form.firstName = baseRegistry?.firstName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender
          }
          form.errorFiscalCode = false
          setValues(form)
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
      actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'cora').then(
        (_coraSubjectSid) => {
          if (_coraSubjectSid?.id) {
            setCoraSubjectSid(_coraSubjectSid)
          }
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
    }
  }

  const handleInputAutocompile = (newLocation, setValues, values) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ values, errors, setValues, validateForm }) => (
          <Form>
            {showErrorAlert && (
              <div>
                <ErrorListAlert
                  errors={getErrors(errors, values.personType)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
            <div className="row row-detail bg-gray d-flex">
              <div className={'col-6 pe-3'}>
                <div className="configurazioneSID big p-3">
                  <div id={`anagrafica-${id || 0}`}>
                    <div className="row mb-3">
                      <h5>
                        <IAnagrafica fill="#000" width="25" />
                        Anagrafica
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12 mb-3 ps-0">
                        <div className="input-group">
                          <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
                            <label
                              className="form-check-label d-flex align-items-center"
                              htmlFor={`pType1${initialValues?.id}`}
                              onClick={() =>
                                handlePersonTypeClick(Constants.PNF, setValues, values)
                              }>
                              <input
                                className="form-check-input pe-1 me-2 mt-0"
                                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                type="radio"
                                checked={values.personType === Constants.PNF}
                                onChange={() => {}}
                              />
                              Persona giuridica
                            </label>
                          </div>
                          <div className="inputRadio" style={{ fontSize: '15px' }}>
                            <label
                              className="form-check-label d-flex align-items-center"
                              htmlFor={`pType2${initialValues?.id}`}
                              onClick={() =>
                                handlePersonTypeClick(Constants.PF, setValues, values)
                              }>
                              <input
                                className="form-check-input pe-1 me-2 mt-0"
                                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                type="radio"
                                checked={values.personType === Constants.PF}
                                onChange={() => {}}
                              />
                              Persona fisica
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {values.personType === Constants.PNF ? (
                        <>
                          <div className="col-3 pe-2 mb-2">
                            <Field name="fiscalCode">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="fiscalCode"
                                  label="Codice Fiscale"
                                  className="form-control"
                                  value={values.fiscalCode}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'fiscalCode',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  error={errors.fiscalCode || errors.errorFiscalCode}
                                  touched={touched}
                                  maxLength="16"
                                  onBlur={(e) => {
                                    handleFiscalCode(e.target.value, setValues, values)
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-9 mb-2">
                            <Field name="companyName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label="Ragione Sociale"
                                  className="form-control"
                                  value={values.companyName}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'companyName',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.companyName}
                                  touched={touched}
                                  maxLength="60"
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-6 pe-2 mb-2">
                            {/* LOCALITÀ */}
                            <Field name="location">
                              {({ field }) => (
                                <AutoCompileCustom
                                  {...field}
                                  label={
                                    values.personType === Constants.PNF
                                      ? 'Sede Legale (Località)'
                                      : 'Comune di nascita'
                                  }
                                  id="location"
                                  handleInputAutocompile={(e) =>
                                    handleInputAutocompile(e, setValues, values)
                                  }
                                  filtro={props.locations}
                                  value={values.location}
                                  error={errors.location}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="col-3 pe-2 mb-2">
                            <Field name="province">
                              {({ field }) => (
                                <ProvinceField
                                  {...field}
                                  placeholder="Prov. EE per Estero"
                                  label="EE per Estero"
                                  province={values.province}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'province',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  error={errors.province}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-3 pe-2 mb-2">
                            <Field name="fiscalCode">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="fiscalCode"
                                  label="Codice Fiscale"
                                  className="form-control"
                                  value={values.fiscalCode}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'fiscalCode',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  error={errors.fiscalCode || errors.errorFiscalCode}
                                  touched={touched}
                                  maxLength="16"
                                  onBlur={(e) => {
                                    handleFiscalCode(e.target.value, setValues, values)
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-4 pe-2">
                            <Field name="firstName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  name="firstName"
                                  label="Nome"
                                  type="text"
                                  className="form-control"
                                  value={values.firstName}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'firstName',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.firstName}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-5 mb-2">
                            <Field name="lastName">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label="Cognome"
                                  type="text"
                                  className="form-control"
                                  value={values.lastName}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'lastName',
                                      setValues,
                                      values,
                                      true
                                    )
                                  }
                                  error={errors.lastName}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-3 pe-2 mb-2">
                            <Field name="gender">
                              {({ field }) => (
                                <BwmSelect
                                  {...field}
                                  options={Constants.genderList || []}
                                  name="gender"
                                  label="Sesso"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleChangeValue(e.target.value, 'gender', setValues, values)
                                  }
                                  value={values.gender}
                                  error={errors.gender}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-3 col pe-2 mb-2">
                            <Field name="birthDate">
                              {({ field, form }) => (
                                <DateField
                                  {...field}
                                  label="Data di nascita"
                                  date={values.birthDate}
                                  onChange={(value) => form.setFieldValue(field.name, value)}
                                  error={errors.birthDate}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-4 pe-2 mb-2">
                            {/* LOCALITÀ */}
                            <Field name="location">
                              {({ field }) => (
                                <AutoCompileCustom
                                  {...field}
                                  label={
                                    values.personType === Constants.PNF
                                      ? 'Sede Legale (Località)'
                                      : 'Comune di nascita'
                                  }
                                  id="location"
                                  handleInputAutocompile={(e) =>
                                    handleInputAutocompile(e, setValues, values)
                                  }
                                  filtro={props.locations}
                                  value={values.location}
                                  error={errors.location}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="col-2 mb-2">
                            <Field name="province">
                              {({ field }) => (
                                <ProvinceField
                                  {...field}
                                  placeholder="Prov. EE per Estero"
                                  label="EE per Estero"
                                  province={values.province}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      e.target.value,
                                      'province',
                                      setValues,
                                      values,
                                      true,
                                      true
                                    )
                                  }
                                  error={errors.province}
                                  touched={touched}
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'col-6'}>
                <div className="configurazioneSID p-3">
                  <SidContactFormContainer
                    id={props.subjectId || 0}
                    errors={errorsSid}
                    nameApplication={nameApplication}
                    subject={subject}
                    defaultEmail={props.defaultEmail}
                    coraSubjectSid={coraSubjectSid}
                    certificateFiles={certificateFiles}
                    fiscalCode={values.fiscalCode}
                    licenseExpired={props.licenseExpired}
                    setCoraSubjectSid={setCoraSubjectSid}
                    setErrorCertificates={(errorCertificates) =>
                      setErrorsSid({ ...errorsSid, errorCertificates })
                    }
                    handleChangeSid={handleChangeSid}
                    handleChangeSidValues={handleChangeSidValues}
                    handleCertificateFilesCustom={handleCertificateFilesCustom}
                  />
                </div>
              </div>
              <div className={'col-12 mt-4 d-flex justify-content-between'}>
                <button
                  className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
                  onClick={closeModal}>
                  <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
                  &nbsp; ANNULLA
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-new-rel px-5 ml-2"
                  onClick={() => handleFormSubmit(values, validateForm)}>
                  <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
                  &nbsp; CONFERMA
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
