import { useState, useEffect } from 'react'
import { BwmInput } from '../../shared/form/BwmInputB5'
import * as Constants from '../../../config/Constants'
import AutoCompileCustom from '../../shared/form/AutoCompileCustomB5'
import { ProvinceField } from '../application-cora/relationships/ProvinceFieldB5'
import ErrorListAlert from '../../shared/form/ErrorListAlert'
import { ISave, IUndo } from '../../../styles/icons'
import * as actions from '../../../actions'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupError } from '../../shared/PopupError'
import * as Utility from '../../shared/Utility'
import { useSelector } from 'react-redux'
import getText from './labels'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

export default function BlueSubjectForm(props) {
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)
  const customer = useSelector((state) => state.auth.customer)
  const locations = useSelector((state) => state.locations.customLocations)
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCALCODE
    },
    {
      errorKey: 'denomination',
      errorLabel:
        initialValues.personType === Constants.PNF ? labels.DENOMINATION : labels.FIRSTLASTNAME
    },
    {
      errorKey: 'address',
      errorLabel: labels.ADDRESS
    },
    {
      errorKey: 'location',
      errorLabel: labels.LOCATION
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'postalCode',
      errorLabel: labels.POSTALCODE
    },
    {
      errorKey: 'email',
      errorLabel: labels.EMAILADDRESS
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: labels.FISCALCODE
    }
  ]

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required(labels.SELECTTYPEPERSON),
    denomination: Yup.string().required(labels.REQUIREDFIELD),
    fiscalCode: Yup.string().required(labels.REQUIREDFIELD),
    address: Yup.string().required(labels.REQUIREDFIELD),
    province: Yup.string().required(labels.REQUIREDFIELD),
    location: Yup.string().required(labels.REQUIREDFIELD),
    postalCode: Yup.string().required(labels.REQUIREDFIELD),
    email: Yup.string().email(labels.INVALIDEMAILFORMAT).required(labels.REQUIREDFIELD),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => labels.NOTVALID,
      (value) => !value
    )
  })

  useEffect(() => {
    if (!props.id) {
      setInitialValues({
        personType: Constants.PNF,
        customer: customer,
        errorFiscalCode: false
      })
    } else {
      setInitialValues({ ...props.subject, errorFiscalCode: false })
    }
  }, [])

  const handleFiscalCode = (val, setValues, values) => {
    if (val) {
      actions
        .getBaseRegistryByFiscalCode(val)
        .then((baseRegistry) => {
          if (baseRegistry) {
            let newLocation = values.location || baseRegistry.location?.location || ''
            let newDenomination = values.denomination || baseRegistry.companyName || ''
            let newProvince = values.province || baseRegistry.location?.province || ''
            let newPostalCode = values.postalCode || baseRegistry.location?.postalCode || ''
            setValues({
              ...values,
              denomination: newDenomination,
              location: newLocation,
              province: newProvince,
              postalCode: newPostalCode,
              errorFiscalCode: false
            })
          }
        })
        .catch(() => {
          setValues({
            ...values,
            errorFiscalCode: true
          })
        })
    }
  }

  const handleInputAutocompile = (newLocation, setValues, values) => {
    newLocation = newLocation.toUpperCase()
    const locationObject = locations?.find((e) => {
      return e.name === newLocation
    })
    if (locationObject) {
      setValues({
        ...values,
        location: locationObject.name?.toUpperCase() || '',
        province: locationObject.province?.toUpperCase() || values.province || '',
        postalCode: locationObject.postalCode || values.postalCode || ''
      })
    } else {
      setValues({
        ...values,
        location: newLocation
      })
    }
  }

  const handlePersonTypeClick = (val, setValues, values) => {
    const form = Object.assign({}, values)
    if (val === Constants.PF) {
      form.personType = val
      form.gender = 'FEMALE'
      form.companyName = null
    } else {
      form.personType = val
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
    }
    setValues(form)
  }

  const saveSubject = (subject) => {
    setLoading(true)
    actions
      .saveBlueSubject(subject)
      .then(
        () => {
          props.handleCloseModal()
          props.getSubjects()
        },
        (err) => {
          PopupError({
            text: err.status === 511 ? labels.MAXSUBJECTSREACHED : labels.GENERICERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => {
        setLoading(false)
      })
  }

  const handleFormSubmit = async (values, validateForm) => {
    const errors = await validateForm(values)
    setTouched(true)
    if (Object.keys(errors).length > 0) {
      setShowErrorAlert(true)
    } else {
      saveSubject(values)
      setShowErrorAlert(false)
    }
  }

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[1].errorLabel = labels.FIRSTLASTNAME
    else formErrorsTabMapping[1].errorLabel = labels.DENOMINATION
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <>
      <div className="row-detail bg-grey">
        {loading && <PageSpinner text={labels.SPINNERTITLE} />}
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ handleChange, values, errors, setValues, validateForm }) => (
            <Form>
              {showErrorAlert &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div>
                    <ErrorListAlert
                      label={labels.INCOMPLETEDATA}
                      errors={getErrors(errors, values.personType)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <div className={'col-lg-12'}>
                <div className="big pe-4">
                  <div className="col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-md-12 col-lg-12 mb-3 ps-0">
                        <div className="input-group">
                          <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
                            <label
                              className="form-check-label d-flex align-items-center"
                              htmlFor={`pType1${initialValues?.id}`}
                              onClick={() =>
                                handlePersonTypeClick(Constants.PNF, setValues, values)
                              }>
                              <input
                                className="form-check-input pe-1 me-2 mt-0"
                                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                type="radio"
                                checked={values.personType === Constants.PNF}
                                onChange={() => {}}
                              />
                              {labels.LEGALPERSON}
                            </label>
                          </div>
                          <div className="inputRadio" style={{ fontSize: '15px' }}>
                            <label
                              className="form-check-label d-flex align-items-center"
                              htmlFor={`pType2${initialValues?.id}`}
                              onClick={() =>
                                handlePersonTypeClick(Constants.PF, setValues, values)
                              }>
                              <input
                                className="form-check-input pe-1 me-2 mt-0"
                                style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                                type="radio"
                                checked={values.personType === Constants.PF}
                                onChange={() => {}}
                              />
                              {labels.PHYSICALPERSON}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 pe-2 mb-2">
                        <Field name="fiscalCode">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="fiscalCode"
                              label={labels.FISCALCODE}
                              placeholder={labels.FISCALCODE}
                              className="form-control"
                              value={values.fiscalCode}
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              onBlur={(e) => {
                                handleFiscalCode(e.target.value, setValues, values)
                              }}
                              error={errors.fiscalCode || errors.errorFiscalCode}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-5 pe-2 mb-2">
                        <Field name="denomination">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="denomination"
                              label={
                                values.personType === Constants.PF
                                  ? labels.FIRSTLASTNAME
                                  : labels.DENOMINATION
                              }
                              placeholder={
                                values.personType === Constants.PF
                                  ? labels.FIRSTLASTNAME
                                  : labels.DENOMINATION
                              }
                              className="form-control"
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              value={values.denomination}
                              error={errors.denomination}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-4 pe-2 mb-2">
                        <Field name="address">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="address"
                              label={labels.ADDRESS}
                              placeholder={labels.ADDRESS}
                              className="form-control"
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              value={values.address}
                              error={errors.address}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-2 pe-2 mb-2">
                        <Field name="location">
                          {({ field }) => (
                            <AutoCompileCustom
                              {...field}
                              label={labels.LOCATION}
                              id="location"
                              handleInputAutocompile={(e) =>
                                handleInputAutocompile(e, setValues, values)
                              }
                              filtro={locations}
                              value={values.location}
                              error={errors.location}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-1 pe-2 mb-2">
                        <Field name="province">
                          {({ field }) => (
                            <ProvinceField
                              {...field}
                              placeholder={labels.PROVINCE}
                              hint={labels.OUTSIDEITALY}
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              province={values.province}
                              error={errors.province}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-1 col-lg-1 pe-2 mb-2">
                        <Field name="postalCode">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="postalCode"
                              placeholder={labels.POSTALCODE}
                              label={labels.POSTALCODE}
                              className="form-control"
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              value={values.postalCode}
                              error={errors.postalCode}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-3 pe-2 mb-2">
                        <Field name="email">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              name="email"
                              placeholder={labels.EMAILADDRESS}
                              label={labels.EMAILADDRESS}
                              className="form-control"
                              onChange={(e) => {
                                const { name, value } = e.target
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase()
                                  }
                                })
                              }}
                              value={values.email}
                              error={errors.email}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-12 mt-4 d-flex justify-content-between'}>
                  <button
                    className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
                    onClick={props.handleCloseModal}>
                    <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
                    &nbsp; {labels.CANCEL}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-new-rel px-5 ml-2"
                    onClick={() => handleFormSubmit(values, validateForm)}>
                    <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
                    &nbsp; {labels.CONFIRM}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
