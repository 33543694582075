import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../actions'
import elenco from '../../../../styles/images/elenco.png'
import spedire from '../../../../styles/images/spedire.png'
import arrow_l from '../../../../styles/images/arrow_l.png'
import Tabs from '../../application-cora/relationships/Tabs'
import RelationshipList from './NewRelationshipList'
import CommunicationHistoryList from '../communications/CommunicationsHistoryList'
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker'
import { APPLICATION_CRS } from '../../../../config/Constants'

const CrsRelationship = () => {
  const [subject, setSubject] = useState(undefined)
  const { id: subjectId } = useParams()
  const history = useHistory()

  const auth = useSelector((state) => state.auth)

  const fetchSubject = (subjectId) => {
    actions.getCrsSubject(subjectId).then((res) => {
      setSubject(res)
    })
  }

  useEffect(() => {
    fetchSubject(subjectId)
  }, [subjectId])

  useEffect(() => {
    // CheckLicenseAndRedirect(
    //   history,
    //   '/app/crs/dashboard',
    //   auth?.user?.customer?.customerApplications,
    //   APPLICATION_CRS
    // )
  }, [auth, history])

  return (
    <>
      <div className="row title-page">
        <Link to="/app/crs/dashboard">
          <img src={arrow_l} className="px-4" alt="Torna a elenco soggetti" />
          {subject?.companyName}
        </Link>
      </div>
      <div className="table-custom">
        <Tabs>
          <div label="ELENCO RAPPORTI" icon={elenco}>
            {subjectId && <RelationshipList auth={auth} subjectId={subjectId} subject={subject} />}
          </div>
          <div label="STORICO COMUNICAZIONI" icon={spedire}>
            <CommunicationHistoryList subjectId={subjectId} auth={auth} />
          </div>
        </Tabs>
      </div>
    </>
  )
}

export default CrsRelationship
