import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import Row from 'react-bootstrap/Row'
import axiosService from '../../../../services/axios-service'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupError } from '../../../shared/PopupError'
import { isEmpty } from '../../../shared/Utility'

export class CoraSubjectsExportModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      loading: false,
      responseModal: false,
      responseKo: '',
      responseOk: '',
      emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
      emailModified: false,
      errorEmail: { isValid: true, msg: '' }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checkedSubjects.length > 0) {
      const emailAddress = nextProps.checkedSubjects[0]?.customer?.user?.login
      if (emailAddress !== prevState.emailAddress) {
        return { emailAddress }
      }
    }
    return null
  }

  closeModal = () => {
    this.setState({ showModal: false })
    this.setState({ emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login })
  }

  closeResponseModal = () => {
    this.setState({ responseModal: false })
    this.setState({ emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  handlerEmail = (e) => {
    this.setState({ errorEmail: { isValid: true, msg: '' } })
    this.setState({ emailAddress: e.target.value })
    this.setState({ emailModified: true })
  }

  handlerValidation = () => {
    if (isEmpty(this.state.emailAddress)) {
      this.setState({ errorEmail: { isValid: false, msg: 'Campo obbligatorio' } })
      return false
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.emailAddress
      )
    ) {
      this.setState({ errorEmail: { isValid: false, msg: 'Email non formalmente corretta' } })
      return false
    }
    return true
  }

  exportCoraSubjects = () => {
    if (this.handlerValidation()) {
      this.setState({ loading: true })
      const axiosInstance = axiosService.getInstance()
      let subjectsIds = this.props.checkedSubjects
        ? this.props.checkedSubjects?.map((r) => r.id)
        : this.props.checkedSubjects?.map((r) => r.id)
      axiosInstance({
        data: subjectsIds,
        url: `/api/cora/custom/cora-subjects/export/${this.state.emailAddress}`,
        method: 'POST'
      })
        .then(
          (response) => {
            if (response) {
              if (response?.status === 200) {
                this.closeModal()
                this.setState({ responseOk: 'OK', responseModal: true })
              } else {
                this.closeModal()
                this.setState({
                  responseKo: "Si è verificato un errore durante l'esportazione dei dati."
                })
              }
            }
          },
          (err) => {
            this.closeModal()
            PopupError({ text: "Si è verificato un errore durante l'esportazione dei dati." })
          }
        )
        .then(() => this.setState({ loading: false }))
    }
  }

  render() {
    let email =
      isEmpty(this.state.emailAddress) && !this.state.emailModified
        ? this.props.checkedSubjects[0]?.customer?.user?.login
        : this.state.emailAddress
    return (
      <>
        {this.state.loading ? <PageSpinner /> : ''}
        <button
          type="button"
          disabled={this.props.disabled}
          className={`btn btn-empty btn-sm ${this.props.disabled ? 'disabled' : ''}`}
          data-tip={'Esporta i soggetti selezionati'}
          onClick={this.openModal}>
          <IExport width="16" fill={` ${this.props.disabled ? '#FFFFFF' : '#128186'}`} />
          &nbsp; Esporta
        </button>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>ESPORTA SOGGETTI</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: '16px' }}>
                Attraverso questa funzione è possibile scaricare tutti i dati inerenti ai soggetti
                selezionati.
              </p>
            </div>
            <div className="row-detail comurei bg-blue p-2 mt-2 d-flex radius">
              <div className="tab-content col-md-12 col-lg-12">
                <div className="form-row">
                  <div className="col-md-8 col-lg-8 subDetailComurei">
                    <div className="row">
                      <div className="col-md-11 col-lg-11">
                        <BwmInput
                          id="emailAddress"
                          type="email"
                          className="form-control form-control-normal"
                          label="Indirizzo email a cui inviare il file di export"
                          //name="emailAddress"
                          value={email}
                          maxLength="100"
                          onChange={(e) => this.handlerEmail(e)}
                          error={this.state.errorEmail}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
              <button
                onClick={this.exportCoraSubjects}
                type="button"
                className="btn btn-new-rel px-5">
                ESPORTA
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.responseModal && (
          <Modal
            backdrop="static"
            show={this.state.responseModal}
            onHide={this.closeResponseModal}
            dialogClassName="modal-70w"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="bar"></div>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>INIZIO PROCEDURA ESPORTAZIONE SOGGETTI</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.responseOk ? (
                <>
                  Esportazione dei soggetti avviata: riceverai i file al tuo indirizzo email.
                </>
              ) : (
                <div className="popup communication">
                  <Row>
                    <>
                      <p className={'color-red'}>
                        Errore nell'esportazione dei soggetti {this.state.responseKo}
                      </p>
                    </>
                  </Row>
                </div>
              )}
              <div className="d-flex justify-content-between align-content-end mt-5">
                <BtnEmpty
                  text="ANNULLA"
                  classCustom="float-start"
                  handlerOnClick={this.closeResponseModal}
                />
                <button
                  onClick={() => this.closeResponseModal()}
                  type="button"
                  className="btn btn-new-rel px-5">
                  OK
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  }
}
