import authService from '../services/auth-service'
import axiosService from '../services/axios-service'
import moment from 'moment'

import {
  APPLICATION_SUCCESS,
  COMMUNICATIONS_BALANCE_SUCCESS,
  COMMUNICATIONS_HISTORY_SUCCESS,
  COMMUNICATIONS_LAST_ERROR_SUCCESS,
  COMMUNICATIONS_TO_SEND_SUCCESS,
  COMUREI_COMMUNICATIONS_HISTORY_SUCCESS,
  COMUREI_GET_SUBJECT_TYPES_SUCCESS,
  COMUREI_GET_SUBJECTS_SUCCESS,
  CORPRO_COMMUNICATIONS_HISTORY_SUCCESS,
  CORPRO_GET_SUBJECT_TYPES_SUCCESS,
  CORPRO_GET_SUBJECTS_SUCCESS,
  GET_ALL_SUPPLIER_TYPES_SUCCESS,
  CRS_GET_SUBJECTS_SUCCESS,
  GET_ACCOUNT_SUCCESS,
  GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS,
  GET_ADMIN_CUSTOMERS_SUCCESS,
  GET_ALL_COMMUNICATION_TYPES_SUCCESS,
  GET_ALL_TRANSMISSION_TYPES_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_BALANCES_BY_RELATIONSHIP_SUCCESS,
  GET_CORA_SUBJECT_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_TYPES_SUCCESS,
  GET_LOCATIONS_SUCCESS,
  GET_ROLE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  RELATIONSHIP_SUCCESS,
  RELATIONSHIP_TYPE_SUCCESS,
  SUBJECTS_BY_PERSONTYPE_SUCCESS,
  ARCO_GET_SUBJECTS_SUCCESS,
  ARCO_GET_SUBJECT_SUCCESS,
  ARCO_GET_SUBJECT_TYPES_SUCCESS,
  GET_ALL_COLLABORATORS_SUCCESS,
  GET_ALL_ARCO_DOMAIN_SUCCESS,
  GET_ALL_PROFESSIONISTS_SUCCESS,
  GET_ARCO_TABLE_DOMAIN_SUCCESS,
  GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS,
  ARCO_GET_REGISTRIES_SUCCESS,
  GET_ARCO_OPERATIONS_SUCCESS,
  ARCO_CACHE_SEARCH,
  ARCO_CLEAR_SEARCH,
  ARCO_REGISTRY_CACHE_SEARCH,
  GET_ARCO_CASUAL_OPERATIONS_SUCCESS,
  GET_ARCO_REGISTRY_DOCUMENT_SUCCESS,
  BLUE_GET_SUBJECT_SUCCESS,
  BLUE_LANGUAGE_CHANGE,
  BLUE_GET_EVENTS_SUCCESS,
  RANKED_RELATIONSHIP_TYPE_SUCCESS,
  COMUREI_GET_COMMUNICATION_TYPES_SUCCESS
} from './types'
import { baseSearchObject } from '../components/shared/tables/TableUtils'
import { ApplicationPaths } from '../services/ApplicationPaths'

const axiosInstance = axiosService.getInstance(ApplicationPaths)

//lOCATION ACTIONS------------------------------------------------------------------------------------------------------

export const getAllLocations = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/custom/locations`)
      .then((res) => res.data)
      .then((locations) => {
        dispatch(getAllLocationsSuccess(locations))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllLocationsSuccess = (locations) => {
  return {
    type: GET_LOCATIONS_SUCCESS,
    locations
  }
}

export const getLocation = (city) => {
  return axiosInstance
    .get(`/api/locations/custom/${city}`)
    .then((res) => res.data)
    .catch(({ response }) => {
      return Promise.reject(response?.data?.errors)
    })
}

export const getAllCountries = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/custom/countries`)
      .then((res) => res.data)
      .then((countries) => {
        dispatch(getAllCountriesSuccess(countries))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCountriesSuccess = (countries) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    countries
  }
}

export const getCountry = (countryCode) => {
  return axiosInstance
    .get(`/api/custom/countries/${countryCode}`)
    .then((res) => res.data)
    .catch(({ response }) => {
      Promise.reject(response.data.errors)
    })
}

//BASE-REGISTRYS ACTIONS------------------------------------------------------------------------------------------------

export const getCoraSubjectSidByFiscalCode = (fiscalCode, application) => {
  return axiosInstance
    .get(`/api/cora/cora-subject-sids-fiscal-code/${fiscalCode}/${application}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getBaseRegistryByFiscalCode = (fiscalCode) => {
  return axiosInstance
    .get(`/api/base-registries/custom/${fiscalCode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getBaseRegistryPersonByFiscalCode = (fiscalCode) => {
  return axiosInstance
    .get(`/api/base-registries/custom/person/${fiscalCode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//DELEGATE ACTIONS -----------------------------------------------------------------------------------------------------

const getBalancesByRelationshipIdSuccess = (balances) => {
  return {
    type: GET_BALANCES_BY_RELATIONSHIP_SUCCESS,
    balances
  }
}

export const getDelegatesByRelationshipId = (subjectId, relationshipId) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-delegates/${subjectId}/${relationshipId}`)
    .then((res) => res.data)
    .catch(({ response }) => response.data?.errors)
}

export const getBalancesByRelationshipId = (subjectId, relationshipId) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-balances/${subjectId}/${relationshipId}`)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
}

export const createCoraDelegate = (coraDelegateData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-delegates', coraDelegateData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCoraDelegate = (coraDelegateData) => {
  return axiosInstance
    .put('/api/cora/custom/cora-delegates', coraDelegateData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCoraDelegate = (coraDelegateId) => {
  return axiosInstance
    .delete(`/api/cora/custom/cora-delegates/${coraDelegateId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//USER ACTIONS----------------------------------------------------------------------------------------------------------

export const getCustomerById = (customerId) => {
  //NON utilizzato
  return axiosInstance
    .get(`/api/customers/${customerId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getSubjectSummary = (applicationName) => {
  return axiosInstance
    .get(`/api/custom/customer-applications/getSubjectSummary/${applicationName}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err))
}

export const getAccount = () => {
  return (dispatch) => {
    axiosInstance
      .get('/api/common/users/current')
      .then((res) => res.data)
      .then((account) => {
        dispatch(getAccountSuccess(account.admin, account.username, account.customer, account))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAdminCustomersSuccess = (customers) => {
  return {
    type: GET_ADMIN_CUSTOMERS_SUCCESS,
    customers
  }
}

const getApplicationsByCustomerSuccess = (applications) => {
  return {
    type: APPLICATION_SUCCESS,
    applications
  }
}

const getAccountSuccess = (isAdmin, username, customer, user) => {
  return {
    type: GET_ACCOUNT_SUCCESS,
    isAdmin: isAdmin,
    username: username,
    customer: customer,
    user: user
  }
}

export const getApplicationsByCustomer = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/custom/customer-applications`)
      .then((res) => res.data)
      .then((applications) => {
        dispatch(getApplicationsByCustomerSuccess(applications))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const getApplicationsByCustomerAndNAme = (applicationName) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/custom/customer-applications/${applicationName}`)
      .then((res) => res.data)
      .then((application) => {
        dispatch(getApplicationByCustomerAndNameSuccess(application))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getApplicationByCustomerAndNameSuccess = (application) => {
  return {
    type: GET_APPLICATION_SUCCESS,
    application
  }
}

export const getAdminCustomers = () => {
  return (dispatch) => {
    axiosInstance
      .get('/api/admin/customers')
      .then((res) => res.data)
      .then((customers) => {
        dispatch(getAdminCustomersSuccess(customers))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

/**
 *
 * @param {*} page
 * @param {*} size
 * @param {*} emailCompanyName
 * @returns
 * @deprecated
 */
export const getAdminCustomerApplications = (
  page = 0,
  size = 200,
  emailCompanyName = '',
  filterIncorrect = false
) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/admin/custom/search/customer-applications/`, {
        params: { page, size, emailCompanyName, filterIncorrect }
      })
      .then((res) => res.data)
      .then((customerApplications) => {
        dispatch(getAdminCustomerApplicationsSuccess(customerApplications))
      })
      .catch(({ response }) => response)
  }
}

export const searchAdminCustomerApplications = (seachObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/admin/custom/search/customer-applications/`, seachObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const addCustomerApplication = (customerApplication) => {
  return axiosInstance
    .post(`api/admin/custom/customer-applications/`, customerApplication)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCustomerApplication = (applicationId, customerApplication) => {
  return axiosInstance
    .put(`api/admin/custom/customer-applications/${applicationId}`, customerApplication)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//crea l'applicazione demo
export const activateDemoForApplication = (applicationName) => {
  return axiosInstance
    .post(`/api/custom/customer-applications/${applicationName}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

const getAdminCustomerApplicationsSuccess = (customerApplications) => {
  return {
    type: GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS,
    customerApplications
  }
}

export const searchContactInfos = (seachObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/custom/startel/search`, seachObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveInlineContactInfo = (dto) => {
  return axiosInstance
    .put(`/api/custom/startel/save-inline`, dto)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const getContactInfo = (id = 0) => {
  return axiosInstance
    .get(`/api/custom/startel/${id}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const getContactByLogin = (login = '') => {
  return axiosInstance
    .get(`/api/custom/startel/get-by-login/${login}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveContactInfo = (contactInfo) => {
  return axiosInstance
    .post(`/api/custom/startel`, contactInfo)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const deleteContactInfo = (id) => {
  return axiosInstance
    .delete(`/api/custom/startel/${id}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveContactNumber = (contactNumber) => {
  return axiosInstance
    .post(`/api/custom/startel/contact-number`, contactNumber)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveContactNumbers = (contactNumbers = []) => {
  return axiosInstance
    .post(`/api/custom/startel/contact-numbers`, contactNumbers)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveContactHistory = (contactHistory) => {
  return axiosInstance
    .post(`/api/custom/startel/contact-history`, contactHistory)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const saveContactHistories = (contactHistories = []) => {
  return axiosInstance
    .post(`/api/custom/startel/contact-histories`, contactHistories)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const deleteContactNumber = (id) => {
  return axiosInstance
    .delete(`/api/custom/startel/contact-number/${id}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}
export const deleteContactHistory = (id) => {
  return axiosInstance
    .delete(`/api/custom/startel/contact-history/${id}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

//CORA-SUBJECTS ACTIONS ------------------------------------------------------------------------------------------------
export const getCoraSubjectsByPersonType = (personType) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-subjects/byPersonType/${personType}`)
      .then((res) => res.data)
      .then((subjects) => {
        dispatch(getSubjectsByPersonTypeSucces(subjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const getCoraSubjects = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-subjects/`)
      .then((res) => res.data)
      .then((subjects) => {
        dispatch(getSubjectsByPersonTypeSucces(subjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const searchCoraSubjects = (searchObject = baseSearchObject) => {
  return axiosInstance
    .post('/api/cora/custom/cora-subjects/search', searchObject)
    .then((res) => res?.data)
    .catch(({ response }) => response)
}

const getSubjectsByPersonTypeSucces = (subjects) => {
  return {
    type: SUBJECTS_BY_PERSONTYPE_SUCCESS,
    subjects
  }
}

export const getCoraSubjectDetail = (subjectId) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-subjects/${subjectId}`)
    .then((res) => res)
    .catch(({ response }) => response?.data?.errors)
}

export const getCoraSubject = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-subjects/${subjectId}`)
      .then((res) => res.data)
      .then((subject) => {
        dispatch(getCoraSubjectSuccess(subject))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getCoraSubjectSuccess = (subject) => {
  return {
    type: GET_CORA_SUBJECT_SUCCESS,
    subject
  }
}

export const addCoraSubject = (newCoraSubject) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-subjects`, newCoraSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const updateCoraSubject = (subject) => {
  return axiosInstance
    .put(`/api/cora/custom/cora-subjects`, subject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const adminFindRestorableCoraSubjects = (login = '') => {
  return axiosInstance
    .get(`/api/cora/custom/cora-subjects/admin/${login}/find`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const restoreCoraSubject = (dto) => {
  return axiosInstance
    .put(`/api/cora/custom/cora-subjects/admin/restore`, dto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCoraSubject = (id) => {
  return axiosInstance
    .delete(`/api/cora/custom/cora-subjects/delete/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCoraSubjectSid = (formData) => {
  return axiosInstance
    .post('/api/cora/cora-subject-sids-files', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const verifySidNumber = (sidNumber = '') => {
  return axiosInstance
    .get('/api/cora/validate-sid-number/' + sidNumber)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCoraSubjectSid = (formData) => {
  return axiosInstance
    .put('/api/cora/cora-subject-sids-files', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const postSendFileToVerifySidCertificate = (
  nameApplication,
  files,
  password,
  fiscalCode
) => {
  const url = '/api/cora/verifySidCertificate/'
  const formData = new FormData()
  const filesArr = Array.from(files)
  filesArr.forEach((file) => {
    formData.append('files', file)
  })
  formData.append('password', password)
  formData.append('nameApplication', nameApplication)
  formData.append('fiscalCode', fiscalCode)
  return axiosInstance
    .post(url, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const postSendFileToVerifySidCertificateById = (
  nameApplication,
  id,
  password,
  fiscalCode
) => {
  const url = '/api/cora/verifySidCertificateById/'
  const formData = new FormData()
  formData.append('coraSubjectSidId', id)
  formData.append('password', password)
  formData.append('nameApplication', nameApplication)
  formData.append('fiscalCode', fiscalCode)
  return axiosInstance
    .post(url, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 *
 * @param {number} coraSubjectSidId
 * @returns byte array contenente uno zip con i file
 */
export const exportCertificateFiles = (coraSubjectSidId) => {
  return axiosInstance({
    url: `/api/cora/download-certificates/${coraSubjectSidId}`,
    method: 'GET',
    responseType: 'blob'
  })
    .then((res) => res)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getMailPresets = () => {
  return axiosInstance
    .get('/api/mail-presets')
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {string} inMailHost
 * @param {string} inMailPort
 * @param {string} inMailFolder
 * @param {string} outMailhost
 * @param {string} outMailPort
 * @returns
 */
export const testMailParameters = (
  email,
  password,
  inMailHost,
  inMailPort,
  inMailFolder,
  outMailhost,
  outMailPort
) => {
  const dto = {
    address: email,
    incomingMailHost: inMailHost,
    incomingMailPort: inMailPort,
    incomingMailFolder: inMailFolder,
    outgoingMailHost: outMailhost,
    outgoingMailPort: outMailPort,
    password: password
  }
  return axiosInstance
    .post('/api/custom/mail/test-mail-parameters', dto)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCoraReceipt = (formData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-communications/load/receipt', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const elaborateCommunicationTxt = (formData, subjectId) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/elaborate-communication/${subjectId}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const reElaborateCommunicationTxt = (subjectId, communicationId) => {
  return axiosInstance
    .post(
      `/api/cora/custom/cora-communications/re-elaborate-communication/${subjectId}/${communicationId}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const consistencyPhotoReceipt = (formData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-communications/load/consistency-receipt', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const importFromLatestConsistency = (subjectId = 0, email = '', updateExisting = false) => {
  let formData = new FormData()
  formData.append('subjectId', subjectId)
  formData.append('email', email)
  formData.append('updateExisting', updateExisting)
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/import-from-consistency`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const importCoraDataFile = (formData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-subjects/import', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//RELATIONSHIP ACTIONS ------------------------------------------------------------------------------------------------
const getRelationshipsBySubjectIdSuccess = (data) => {
  return {
    type: RELATIONSHIP_SUCCESS,
    relationships: data?.content,
    total: data?.totalElements
  }
}

export const getRelationshipsBySubjectId = (subjectId, searchObject = baseSearchObject) => {
  searchObject.parentId = subjectId
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/search`, searchObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const searchCoraRelationships = (seachObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/search`, seachObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const getCoraRelationshipBySubjectIdAndId = (subjectId, id) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-relationships/${subjectId}/${id}`)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

export const testForSimilarCoraRelationship = (coraRelationship, subjectId) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/test-similar/${subjectId}`, coraRelationship)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const downloadRelationshipPdf = (
  relationshipsIdList = [],
  subjectId,
  criteria = {
    pageNumber: 0,
    pageSize: 200,
    searchField: 'DEFAULT',
    searchTerm: ''
  }
) => {
  const data = {
    relationshipsIdList: relationshipsIdList,
    criteria: criteria
  }
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/downloadRelationshipPdfList/${subjectId}`, data)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCoraRelationship = (coraRelationship, subjectId) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/delete-by-id-list/${subjectId}`, coraRelationship)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCommunication = (id) => {
  return axiosInstance
    .delete(`/api/cora/custom/cora-communications/delete/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getCommunicationsToSendBySubjectId = (subjectId, delayedCommunicationDate) => {
  return (dispatch) => {
    axiosInstance
      .post(`/api/cora/custom/cora-relationships/toSend/${subjectId}`, {
        delayedCommunicationDate: delayedCommunicationDate
      })
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsToSendBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const createMonthlyCommunications = (subjectId, delayedCommunicationDate) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/create/monthly/${subjectId}`, {
      delayedCommunicationDate: delayedCommunicationDate
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 * Non utilizzato
 * @param {number} subjectId
 * @param {number} year
 * @param {*} formData
 * @returns
 */
export const createYearlyBalanceCommunications = (subjectId, year, formData) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/create/yearly/${subjectId}/${year}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateYearlyBalances = (yearlyBalances) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-relationships/yearlyBalance/update`, yearlyBalances)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCommunicationMail = (subjectId, communicationId, emailAddress, flagPec) => {
  return axiosInstance
    .post(
      `/api/cora/custom/cora-communications/send/mail/${subjectId}/${communicationId}/${emailAddress}/${flagPec}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCoraCommunicationFromOwnPec = (subjectId, communicationIds) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/send/mail/direct/${subjectId}`, communicationIds)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err))
}

export const comureiSendCommunicationMail = (subjectId, communicationId, emailAddress) => {
  return axiosInstance
    .post(
      `/api/comurei/custom/comurei-communications/send/mail/${subjectId}/${communicationId}/${emailAddress}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCrsCommunicationMail = (subjectId, communicationId, emailAddress, flagPec) => {
  return axiosInstance
    .post(
      `/api/crs/custom/crs-communications/send/mail/${subjectId}/${communicationId}/${emailAddress}/${flagPec}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCoraReceiptMail = (subjectId, communicationId, emailAddress, flagPec, type) => {
  return axiosInstance
    .post(
      `/api/cora/custom/cora-communications/send/receipt/mail/${subjectId}/${communicationId}/${emailAddress}/${flagPec}/${type}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getCommunicationsHistoryBySubjectId = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/history/${subjectId}`)
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const getLastCommunicationsErrorBySubjectId = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/history/lastError/${subjectId}`)
      .then((res) => res.data)
      .then((lastErrorCommunication) => {
        dispatch(getLastCommunicationsErrorBySubjectIdSuccess(lastErrorCommunication))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const getCommunicationsHistoryBySubjectIdAndIdentifier = (subjectId, identifier) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/find-by-identifier/${subjectId}`, {
        params: { identifier: identifier }
      })
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const getCommunicationsHistoryBySubjectIdAndDenomination = (subjectId, denomination) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/find-by-denomination/${subjectId}`, {
        params: { denomination: denomination }
      })
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}
export const getCommunicationsHistoryBySubjectIdAndFileName = (subjectId, fileName) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/find-by-file-name/${subjectId}`, {
        params: { fileName: fileName }
      })
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const getCoraRelationshipsCsvTemplate = () => {
  return axiosInstance
    .get('/api/cora/custom/cora-relationships/get-csv-template')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err))
}

/**
 *
 * @param {*} formData oggetto FormData con 'dataFile' binario
 * @returns oggetto contenente righe elaborate, rigettate e messaggi
 */
export const importCoraYearlyBalances = (formData) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-balances/import-yearly-balances`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getConsistencyReceiptHistoryBySubjectId = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-communications/consistency-history/${subjectId}`)
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const downloadCommunicationErrorReport = (communicationId) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-communications/download-report-errori/${communicationId}`)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 *
 * @param {Array} communicationIds
 * @returns byte[] con zip contenente i file. può essere vuoto
 */
export const downloadCommunicationErrorReportBulk = (communicationIds = []) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/download-zip-report-errori`, communicationIds)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 *
 * @param {*} subjectId
 * @returns orggetto di tipo coraCommunication se presente, oppure null
 */
export const getLatestConsistency = (subjectId) => {
  return axiosInstance
    .get(`/api/cora/custom/cora-communications/get-latest-consistency/${subjectId}`)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const BALANCE_DEFAULT_PAGE_SIZE = 1000
/**
 *
 * @param {*} subjectId
 * @param {*} year
 * @param {*} size numero di risultati da restituire. vuoto = tutti
 * @param {*} poage numero di pagina di risultati da restituire. vuoto = prima pagina
 * @returns
 */
export const getCommunicationsBalanceBySubjectIdAndYear = (
  subjectId,
  year,
  page = 0,
  size = BALANCE_DEFAULT_PAGE_SIZE
) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-relationships/yearlyBalance/${subjectId}/${year}/`, {
        params: { page: page, size: size }
      })
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsBalanceBySubjectIdAndYearSuccess(relationships))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

export const updateCoraRelationship = (coraRelationshipData) => {
  return axiosInstance
    .put('/api/cora/custom/cora-relationships', coraRelationshipData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const uploadRelationshipCsvFile = (coraRelationShipImportDTO) => {
  return axiosInstance
    .post(
      '/api/cora/custom/cora-relationships/import-cora-relationships',
      coraRelationShipImportDTO
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCoraRelationshipEndDate = (coraRelationshipData, subjectId = 0) => {
  return axiosInstance
    .put(`/api/cora/custom/cora-relationships/end-date/${subjectId}`, coraRelationshipData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCoraRelationshipCommunicationType = (coraRelationshipData, subjectId = 0) => {
  return axiosInstance
    .put(
      `/api/cora/custom/cora-relationships/communication-type/${subjectId}`,
      coraRelationshipData
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCoraRelationship = (coraRelationshipData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-relationships', coraRelationshipData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

const getRelationshipTypesSuccess = (relationshipTypes) => {
  return {
    type: RELATIONSHIP_TYPE_SUCCESS,
    relationshipTypes
  }
}

export const getRelationshipTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/cora-relationship-types`)
      .then((res) => res.data)
      .then((relationshipTypes) => {
        dispatch(getRelationshipTypesSuccess(relationshipTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getRankedRelationshipTypesSuccess = (rankedRelationshipTypes) => {
  return {
    type: RANKED_RELATIONSHIP_TYPE_SUCCESS,
    rankedRelationshipTypes
  }
}

export const getRankedRelationshipTypes = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/custom/ranked-cora-relationship-types`, { params: { subjectId: subjectId } })
      .then((res) => res.data)
      .then((rankedRelationshipTypes) => {
        dispatch(getRankedRelationshipTypesSuccess(rankedRelationshipTypes))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

//BALANCE ACTIONS ------------------------------------------------------------------------------------------------------
export const createCoraBalance = (coraBalanceData) => {
  return axiosInstance
    .post('/api/cora/custom/cora-balances', coraBalanceData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCoraBalance = (coraBalanceData) => {
  return axiosInstance
    .put('/api/cora/custom/cora-balances', coraBalanceData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCoraBalance = (coraBalanceId) => {
  return axiosInstance
    .delete(`/api/cora/custom/cora-balances/${coraBalanceId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// CURRENCY ACTIONS ----------------------------------------------------------------------------------------------------

export const getAllCurrency = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/custom/currencies`)
      .then((res) => res.data)
      .then((currencies) => {
        dispatch(getAllCurrencySuccess(currencies))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCurrencySuccess = (currencies) => {
  return {
    type: GET_CURRENCY_SUCCESS,
    currencies
  }
}

export const getAllCurrencyTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/currency-types`)
      .then((res) => res.data)
      .then((currencyTypes) => {
        dispatch(getAllCurrencyTypesSuccess(currencyTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCurrencyTypesSuccess = (currencyTypes) => {
  return {
    type: GET_CURRENCY_TYPES_SUCCESS,
    currencyTypes
  }
}

// ROLE ACTIONS

export const getAllRole = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/cora-roles`)
      .then((res) => res.data)
      .then((roles) => {
        dispatch(getAllRoleSuccess(roles))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllRoleSuccess = (roles) => {
  return {
    type: GET_ROLE_SUCCESS,
    roles
  }
}

// COMMUNICATIONS ACTION ------------------------------------------------------------------------------------------

export const getAllCommunicationTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/cora/cora-communication-types`)
      .then((res) => res.data)
      .then((communicationTypes) => {
        dispatch(getAllCommunicationTypesSuccess(communicationTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCommunicationTypesSuccess = (communicationTypes) => {
  return {
    type: GET_ALL_COMMUNICATION_TYPES_SUCCESS,
    communicationTypes
  }
}

const getCommunicationsToSendBySubjectIdSuccess = (communicationsToSend) => {
  return {
    type: COMMUNICATIONS_TO_SEND_SUCCESS,
    communicationsToSend
  }
}

const getCommunicationsHistoryBySubjectIdSuccess = (communicationsHistory) => {
  return {
    type: COMMUNICATIONS_HISTORY_SUCCESS,
    communicationsHistory
  }
}

const getLastCommunicationsErrorBySubjectIdSuccess = (lastErrorCommunication) => {
  return {
    type: COMMUNICATIONS_LAST_ERROR_SUCCESS,
    lastErrorCommunication
  }
}

const getCommunicationsBalanceBySubjectIdAndYearSuccess = (yearlyBalanceRelationships) => {
  return {
    type: COMMUNICATIONS_BALANCE_SUCCESS,
    yearlyBalanceRelationships
  }
}

export const removeNextCommunication = (ids) => {
  return axiosInstance
    .put(`/api/cora/custom/cora-relationships/removeNextCommunication`, ids)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCommunicationAssistanceEmail = (ids = []) => {
  return axiosInstance
    .post(`/api/cora/custom/cora-communications/send-communication-assistance`, ids)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// COMUREI ACTIONS -----------------------------------------------------------------------------------------------------
export const getComureiSubjectDetail = (id) => {
  return axiosInstance
    .get(`/api/comurei/custom/comurei-subjects/by-id/${id}/`)
    .then((res) => res)
    .catch((err) => Promise.reject(err?.response))
}

export const getAllComureiCommunicationTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/comurei/custom/comurei-communication-type/comurei-communication-types`)
      .then((res) => res.data)
      .then((comureiSubjectTypes) => {
        dispatch(getAllComureiCommunicationTypesSuccess(comureiSubjectTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllComureiCommunicationTypesSuccess = (comureiCommunicationTypes) => {
  return {
    type: COMUREI_GET_COMMUNICATION_TYPES_SUCCESS,
    comureiCommunicationTypes
  }
}

export const getAllComureiSubjectTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/comurei/comurei-subject-types`)
      .then((res) => res.data)
      .then((comureiSubjectTypes) => {
        dispatch(getAllComureiSubjectTypesSuccess(comureiSubjectTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllComureiSubjectTypesSuccess = (comureiSubjectTypes) => {
  return {
    type: COMUREI_GET_SUBJECT_TYPES_SUCCESS,
    comureiSubjectTypes
  }
}

export const addComureiSubject = (newComureiSubject) => {
  return axiosInstance
    .post(`/api/comurei/custom/comurei-subjects`, newComureiSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response))
}

export const createComureiCommunication = (newComureiSubject) => {
  return axiosInstance
    .post(`/api/comurei/custom/comurei-subjects/createCommunication`, newComureiSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response))
}

export const verifyCoraSubjectOnComurei = (fiscalCode = '') => {
  return axiosInstance
    .get(`/api/comurei/custom/comurei-subjects/verify-cora-subject/${fiscalCode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const verifyCrsSubjectOnComurei = (fiscalCode = '') => {
  return axiosInstance
    .get(`/api/comurei/custom/comurei-subjects/verify-crs-subject/${fiscalCode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//restituisce un oggetto di tipo ComureiSubjectPec, se presente
export const getPecByFiscalCode = (fiscalCode = '') => {
  return axiosInstance
    .get(`/api/comurei/custom/comurei-subjects/get-pec-by-fiscal-code/${fiscalCode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const searchComureiSubject = (searchObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/comurei/custom/comurei-subjects/search`, searchObject)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
}

export const getAllComureiSubjects = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/comurei/custom/comurei-subjects`)
      .then((res) => res.data)
      .then((comureiSubjects) => {
        dispatch(getAllComureiSubjectsSuccess(comureiSubjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllComureiSubjectsSuccess = (comureiSubjects) => {
  return {
    type: COMUREI_GET_SUBJECTS_SUCCESS,
    comureiSubjects
  }
}

export const getComureiCommunicationsHistoryBySubjectId = (subjectId) => {
  return (dispatch) => {
    dispatch(getComureiCommunicationsHistoryBySubjectIdSuccess([]))
    axiosInstance
      .get(`/api/comurei/custom/comurei-communications/history/${subjectId}`)
      .then((res) => res.data)
      .then((comureiCommunications) => {
        dispatch(getComureiCommunicationsHistoryBySubjectIdSuccess(comureiCommunications))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

const getComureiCommunicationsHistoryBySubjectIdSuccess = (comureiCommunications) => {
  return {
    type: COMUREI_COMMUNICATIONS_HISTORY_SUCCESS,
    comureiCommunications
  }
}

// CRS

export const getAllCrsSubjects = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/crs/custom/crs-subjects`)
      .then((res) => res.data)
      .then((crsSubjects) => {
        dispatch(getAllCrsSubjectsSuccess(crsSubjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const searchCrsSubjects = (seachObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-subjects/search/`, seachObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

const getAllCrsSubjectsSuccess = (crsSubjects) => {
  return {
    type: CRS_GET_SUBJECTS_SUCCESS,
    crsSubjects
  }
}

export const getCrsSubject = (subjectId) => {
  return axiosInstance
    .get(`/api/crs/custom/crs-subjects-id/${subjectId}`)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
}

export const importCrsDataFile = (formData) => {
  return axiosInstance
    .post('/api/crs/custom/crs-subjects/import', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const addCrsSubject = (newCrsSubject) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-subjects`, newCrsSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const addCrsRelationship = (newRelationship) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-relationships`, newRelationship)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const addCrsRelationshipHolder = (newRelationshipHolder) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-relationship-holders`, newRelationshipHolder)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const addCrsControllingPeople = (newControllingPerson, relationshipId) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-controlling-people/${relationshipId}`, newControllingPerson)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateCrsControllingPeople = (newControllingPerson, relationshipId) => {
  return axiosInstance
    .put(`/api/crs/custom/crs-controlling-people/${relationshipId}`, newControllingPerson)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteControllingPeople = (subjectId, relationshipId, ids = []) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-controlling-people/delete/${subjectId}/${relationshipId}`, ids)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const addCrsPayment = (payment, relationshipId) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-payments/${relationshipId}`, payment)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
/**
 * tolgo elemento dalla tabella crs_payment
 */
export const deleteCrsPayment = (payment, relationshipId) => {
  return axiosInstance
    .delete(`/api/crs/custom/crs-payments/${relationshipId}`, payment)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getAllCrsControllingPeople = (subjectId, relationshipId) => {
  return axiosInstance
    .get(`/api/crs/custom/crs-controlling-people/${subjectId}/${relationshipId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getCrsRelationshipsBySubjectId = (subjectId, searchObject = baseSearchObject) => {
  searchObject.parentId = subjectId
  return axiosInstance
    .post(`/api/crs/custom/crs-relationships/search/`, searchObject)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
    .catch(({ response }) => response?.data?.errors)
}

export const deleteCrsSubject = (subjectId) => {
  return axiosInstance
    .delete(`/api/crs/custom/crs-subjects/${subjectId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteCrsRelationship = (crsRelationship) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-relationships/delete`, crsRelationship)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCrsCommunication = (subjectId, relationshipIds = []) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/create/${subjectId}`, relationshipIds)
    .then((res) => res)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCrsCommunicationEmpty = (subjectId) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/create-empty/${subjectId}`)
    .then((res) => res)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const createCrsCommunicationEmptyMultiple = (subjectIds = []) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/create-empty-multiple`, subjectIds)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getCrsCommunicationsHistoryBySubjectId = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/crs/custom/crs-communications/history/${subjectId}`)
      .then((res) => res.data)
      .then((relationships) => {
        dispatch(getCommunicationsHistoryBySubjectIdSuccess(relationships))
      })
  }
}

export const createCrsReceipt = (formData) => {
  return axiosInstance
    .post('/api/crs/custom/crs-communications/load/receipt/', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendCrsReceiptMail = (subjectId, communicationId, emailAddress, flagPec) => {
  return axiosInstance
    .post(
      `/api/crs/custom/crs-communications/send/receipt/mail/${subjectId}/${communicationId}/${emailAddress}/${flagPec}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 *
 * @param {number} subjectId
 * @param {array[number]} communicationIds
 * @returns
 */
export const deleteCrsCommunications = (subjectId, communicationIds) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/delete/${subjectId}`, communicationIds)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response))
}

export const sendCrsCommunicationFromOwnPec = (subjectId, communicationIds) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/send/mail/direct/${subjectId}`, communicationIds)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err))
}

/**
 * Questo file importa i file per rielaborarli e ritornar eun txt elaborato.
 * @param {*} formData i file elaborati
 * @returns la risposta del backend
 */
export const importCrsDocumentsXmlToElaborateTheFile = (formData) => {
  return axiosInstance
    .post(`/api/crs/custom/crs-communications/xml/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// ARCO

export const getAllArcoSubjects = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-subjects`)
      .then((res) => res.data)
      .then((arcoSubjects) => {
        dispatch(getAllArcoSubjectsSuccess(arcoSubjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const searchArcoSubjects = (seachObject = baseSearchObject) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-subjects/search`, seachObject)
    .then((res) => res.data)
    .catch(({ response }) => response)
}

const getAllArcoSubjectsSuccess = (arcoSubjects) => {
  return {
    type: ARCO_GET_SUBJECTS_SUCCESS,
    arcoSubjects
  }
}

export const getArcoSubject = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-subjects/${subjectId}`)
      .then((res) => res.data)
      .then((arcoSubject) => {
        dispatch(getArcoSubjectSuccess(arcoSubject))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getArcoSubjectSuccess = (arcoSubject) => {
  return {
    type: ARCO_GET_SUBJECT_SUCCESS,
    arcoSubject
  }
}

export const deleteArcoSubject = (deadSubject) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-subjects/${deadSubject}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getAllArcoSubjectTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/arco-subject-types`)
      .then((res) => res.data)
      .then((arcoSubjectTypes) => {
        dispatch(getAllArcoSubjectTypesSuccess(arcoSubjectTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllArcoSubjectTypesSuccess = (arcoSubjectTypes) => {
  return {
    type: ARCO_GET_SUBJECT_TYPES_SUCCESS,
    arcoSubjectTypes
  }
}

export const addArcoSubject = (newArcoSubject) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-subjects`, newArcoSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateArcoSubject = (arcoSubject) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-subjects`, arcoSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const saveArcoSubject = (arcoSubject) => {
  if (arcoSubject?.id) {
    return updateArcoSubject(arcoSubject)
  } else {
    return addArcoSubject(arcoSubject)
  }
}

export const getAllArcoRegistriesBySubjectId = (
  subjectId,
  searchObject = { pageNumber: 0, pageSize: 20, searchField: '', searchTerm: '' }
) => {
  return (dispatch) => {
    axiosInstance
      .post(`/api/arco/custom/get-arco-registries-by-subject-id/${subjectId}`, searchObject)
      .then((res) => res.data)
      .then((res) => {
        dispatch(getAllArcoRegistriesSuccess(res))
        dispatch(
          cacheRegistrySearch({
            ...searchObject,
            pageNumber: res.pageable.pageNumber,
            pageSize: res.pageable.pageSize,
            last: res.last,
            totalPages: res.totalPages
          })
        )
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const getAllArcoRegistriesBySubjectIdNoCache = (
  subjectId,
  searchObject = { pageNumber: 0, pageSize: 20, searchField: '', searchTerm: '' }
) => {
  return axiosInstance
    .post(`/api/arco/custom/get-arco-registries-by-subject-id/${subjectId}`, searchObject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

const getAllArcoRegistriesSuccess = (arcoRegistries) => {
  return {
    type: ARCO_GET_REGISTRIES_SUCCESS,
    arcoRegistries
  }
}

export const cacheRegistrySearch = (searchObject) => {
  return (dispatch) => {
    dispatch({ type: ARCO_REGISTRY_CACHE_SEARCH, searchObject })
  }
}

export const downloadSAR = (arcoSubjectId = 0) => {
  return axiosInstance
    .get('/api/arco/custom/arco-subjects/download-sar/' + arcoSubjectId)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// AUTH ACTIONS --------------------------------------------------------------------------------------------------------
const loginSuccess = (isAdmin, token, user, customer) => {
  return {
    type: LOGIN_SUCCESS,
    isAdmin: isAdmin,
    token: token,
    user: user,
    customer: customer
  }
}

const loginFailure = (errors) => {
  return {
    type: LOGIN_FAILURE,
    errors
  }
}

export const registerUser = (userData) => {
  return (dispatch) => {
    return axiosInstance
      .post('/api/custom/register', userData)
      .then((res) => res.data)
      .then((user) => {
        localStorage.setItem('jwt-token', user.jwtToken)
        dispatch(loginSuccess(user.admin, user.jwtToken, user, user.customer))
      })
      .catch((err) => {
        dispatch(loginFailure(err.response))
      })
  }
}

export const login = (userData) => {
  return (dispatch) => {
    return axiosInstance
      .post('/api/common/authenticate', userData)
      .then((res) => res.data)
      .then((user) => {
        authService.saveToken(user.jwtToken)
        dispatch(loginSuccess(user.admin, user.jwtToken, user, user.customer))
      })
      .catch((err) => {
        dispatch(loginFailure(err?.response?.data))
      })
  }
}

export const logout = () => {
  authService.invalidateUser()
  return {
    type: LOGOUT
  }
}

// CORPRO ACTIONS -----------------------------------------------------------------------------------------------------
export const getAllCorproSubjectTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro-subject-types`)
      .then((res) => res.data)
      .then((corproSubjectTypes) => {
        dispatch(getAllCorproSubjectTypesSuccess(corproSubjectTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCorproSubjectTypesSuccess = (corproSubjectTypes) => {
  return {
    type: CORPRO_GET_SUBJECT_TYPES_SUCCESS,
    corproSubjectTypes
  }
}

export const saveCorproSubject = (newCorproSubject) => {
  if (newCorproSubject.id) {
    return updateCorproSubject(newCorproSubject)
  } else {
    return addCorproSubject(newCorproSubject)
  }
}

export const addCorproSubject = (newCorproSubject) => {
  return axiosInstance
    .post(`/api/corpro/custom/corpro-subjects`, newCorproSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const updateCorproSubject = (newCorproSubject) => {
  return axiosInstance
    .put(`/api/corpro/custom/corpro-subjects`, newCorproSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const createCorproCommunication = (newCorproSubject) => {
  return axiosInstance
    .post(`/api/corpro/custom/corpro-subjects/createCommunication`, newCorproSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getAllCorproSubjects = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro/custom/corpro-subjects`)
      .then((res) => res.data)
      .then((corproSubjects) => {
        dispatch(getAllCorproSubjectsSuccess(corproSubjects))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCorproSubjectsSuccess = (corproSubjects) => {
  return {
    type: CORPRO_GET_SUBJECTS_SUCCESS,
    corproSubjects
  }
}

export const deleteCorproSubject = (subjectId) => {
  return axiosInstance
    .delete(`/api/corpro/custom/corpro-subjects/${subjectId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getCorproCommunicationsHistoryBySubjectId = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro/custom/corpro-communications/history/${subjectId}`)
      .then((res) => res.data)
      .then((corproCommunications) => {
        dispatch(getCorproCommunicationsHistoryBySubjectIdSuccess(corproCommunications))
      })
      .catch((err) => Promise.reject(err?.response?.data))
  }
}

const getCorproCommunicationsHistoryBySubjectIdSuccess = (corproCommunications) => {
  return {
    type: CORPRO_COMMUNICATIONS_HISTORY_SUCCESS,
    corproCommunications
  }
}

export const corproSendCommunicationMail = (subjectId, communicationId, emailAddress) => {
  return axiosInstance
    .post(
      `/api/corpro/custom/corpro-communications/send/mail/${subjectId}/${communicationId}/${emailAddress}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getAllCorproCommunicationTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro/corpro-communication-types`)
      .then((res) => res.data)
      .then((communicationTypes) => {
        dispatch(getAllCommunicationTypesSuccess(communicationTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const getAllCorproTransmissionTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro/corpro-transmission-types`)
      .then((res) => res.data)
      .then((corproTransmissionTypes) => {
        dispatch(getAllTransmissionTypesSuccess(corproTransmissionTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const getAllCorproSupplierTypes = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/corpro/corpro-supplier-types`)
      .then((res) => res.data)
      .then((corproTransmissionTypes) => {
        dispatch(getAllSupplierTypesSuccess(corproTransmissionTypes))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllSupplierTypesSuccess = (corproSupplierTypes) => {
  return {
    type: GET_ALL_SUPPLIER_TYPES_SUCCESS,
    corproSupplierTypes
  }
}

const getAllTransmissionTypesSuccess = (corproTransmissionTypes) => {
  return {
    type: GET_ALL_TRANSMISSION_TYPES_SUCCESS,
    corproTransmissionTypes
  }
}

export const importCorproCsvFile = (corproSubjectId, formData) => {
  return axiosInstance
    .post(`/api/corpro/custom/corpro-communication/import-csv/${corproSubjectId}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// ARCO

export const getAllCollaboratorBySubjectId = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-collaborators-by-arco-subject-id/${id}`)
      .then((res) => res.data)
      .then((arcoCollaborators) => {
        dispatch(getAllCollaboratorBySubjectIdSuccess(arcoCollaborators))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllCollaboratorBySubjectIdSuccess = (arcoCollaborators) => {
  return {
    type: GET_ALL_COLLABORATORS_SUCCESS,
    arcoCollaborators
  }
}

export const saveArcoCollaborator = (collaborator) => {
  if (collaborator?.id) {
    return updateArcoCollaborator(collaborator)
  } else {
    return addArcoCollaborator(collaborator)
  }
}

export const addArcoCollaborator = (newCollaborator) => {
  return axiosInstance
    .post(`/api/arco/custom/collaborator`, newCollaborator)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const updateArcoCollaborator = (collaborator) => {
  return axiosInstance
    .put(`/api/arco/custom/collaborator`, collaborator)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoCollaborator = (collaboratorId) => {
  return axiosInstance
    .delete(`/api/arco/custom/collaborator/${collaboratorId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getAllArcoDomains = (arcoSubjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-domains/${arcoSubjectId}`)
      .then((res) => res.data)
      .then((arcoDomains) => {
        dispatch(getAllArcoDomainsSuccess(arcoDomains))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllArcoDomainsSuccess = (arcoDomains) => {
  return {
    type: GET_ALL_ARCO_DOMAIN_SUCCESS,
    arcoDomains
  }
}

export const updateCrArcoDomains = (arcoSubjectId, arcoDomainsList) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-domains/cr/${arcoSubjectId}`, arcoDomainsList)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//ARCO PROFESSIONISTS
export const getAllProfessionistBySubjectId = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-professionals-by-arco-subject-id/${id}`)
      .then((res) => res.data)
      .then((arcoProfessionists) => {
        dispatch(getAllProfessionistBySubjectIdSuccess(arcoProfessionists))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllProfessionistBySubjectIdSuccess = (arcoProfessionists) => {
  return {
    type: GET_ALL_PROFESSIONISTS_SUCCESS,
    arcoProfessionists
  }
}

export const saveArcoProfessionist = (professionist) => {
  if (professionist?.id) {
    return updateArcoProfessionist(professionist)
  } else {
    return addArcoProfessionist(professionist)
  }
}

export const addArcoProfessionist = (newProfessionist) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-professionals`, newProfessionist)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const updateArcoProfessionist = (professionist) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-professionals`, professionist)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoProfessionist = (professionistId) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-professionals/${professionistId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//ARCO RISK VALUE

export const saveArcoRiskValue = (riskValue) => {
  if (riskValue?.id) {
    return updateArcoRiskValue(riskValue.arcoSubject.id, riskValue)
  } else {
    return addArcoRiskValue(riskValue)
  }
}

export const addArcoRiskValue = (newRiskValue) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-domains`, newRiskValue)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const updateArcoRiskValue = (arcoSubjectId, riskValue) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-domains/${arcoSubjectId}`, riskValue)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoRiskValue = (arcoSubjectId, id) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-domains/${arcoSubjectId}/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

//ARCO TABLE DOMAINS
export const getArcoTableDomains = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-table-domains`)
      .then((res) => res.data)
      .then((arcoTableDomains) => {
        dispatch(getArcoTableDomainsSuccess(arcoTableDomains))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getArcoTableDomainsSuccess = (arcoTableDomains) => {
  return {
    type: GET_ARCO_TABLE_DOMAIN_SUCCESS,
    arcoTableDomains
  }
}

//ARCO REGISTRIES
//Document
export const getAllDocumentType = () => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco-document-types`)
      .then((res) => res.data)
      .then((arcoRelationshipDocuments) => {
        dispatch(getArcoRelationshipDocumentsSuccess(arcoRelationshipDocuments))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getArcoRelationshipDocumentsSuccess = (arcoRelationshipDocuments) => {
  return {
    type: GET_ARCO_RELATIONSHIP_DOCUMENT_SUCCESS,
    arcoRelationshipDocuments
  }
}

export const saveArcoDocuments = (arcoDocument) => {
  if (arcoDocument?.id) {
    return updateArcoDocuments(arcoDocument)
  } else {
    return addArcoDocuments(arcoDocument)
  }
}

export const addArcoDocuments = (arcoDocument) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-documents`, arcoDocument)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const updateArcoDocuments = (arcoDocument) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-documents`, arcoDocument)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const addArcoRegistry = (arcoRegistry) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-registries`, arcoRegistry)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const updateArcoRegistry = (arcoRegistry) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-registries`, arcoRegistry)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const deleteArcoRegistries = (id) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-registries/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const saveArcoRegistry = (arcoRegistry) => {
  if (arcoRegistry?.id) {
    return updateArcoRegistry(arcoRegistry)
  } else {
    return addArcoRegistry(arcoRegistry)
  }
}

//ARCO OPERATION

export const getAllArcoOperations = (
  id,
  searchObject = { pageNumber: 0, pageSize: 20, searchField: '', searchTerm: '' }
) => {
  return (dispatch) => {
    axiosInstance
      .post(`/api/arco/custom/arco-operations-by-subject-id/${id}`, searchObject)
      .then((res) => res.data)
      .then((res) => {
        dispatch(getAllArcoOperationsSuccess(res))
        dispatch(
          cacheOperationSearch({
            ...searchObject,
            pageNumber: res.pageable.pageNumber,
            pageSize: res.pageable.pageSize,
            last: res.last,
            totalPages: res.totalPages
          })
        )
      })
      .catch(({ response }) => response)
  }
}

export const cacheOperationSearch = (searchObject) => {
  return (dispatch) => {
    dispatch({ type: ARCO_CACHE_SEARCH, searchObject })
  }
}

export const clearCacheOperationSearch = () => {
  return (dispatch) => {
    dispatch({ type: ARCO_CLEAR_SEARCH })
  }
}

const getAllArcoOperationsSuccess = (arcoRegistriesOperations) => {
  return {
    type: GET_ARCO_OPERATIONS_SUCCESS,
    arcoRegistriesOperations
  }
}

export const deleteArcoRegistriesSignatories = (arcoRegistryId, id) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-registries/signatories/${arcoRegistryId}/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoRegistriesHolders = (arcoRegistryId, Id) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-registries/holders/${arcoRegistryId}/${Id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

// ARCO CONTRACTS

export const getAllArcoContractsBySubejctId = (subjectId) => {
  return axiosInstance
    .get(`/api/arco/custom/arco-contracts-by-subject-id/${subjectId}`)
    .then((res) => res.data?.content)
    .catch(({ response }) => response?.data?.errors)
}

export const getAllArcoContracts = (arcoContractsSearchObject = {}) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-contracts/search`, arcoContractsSearchObject)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
}

export const getAllArcoCasualOperations = (subjectType) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-causal-operations/${subjectType}`)
      .then((res) => res.data)
      .then((arcoCasualOperations) => {
        dispatch(getAllArcoCasualOperationsSuccess(arcoCasualOperations))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getAllArcoCasualOperationsSuccess = (arcoCasualOperations) => {
  return {
    type: GET_ARCO_CASUAL_OPERATIONS_SUCCESS,
    arcoCasualOperations
  }
}

export const saveArcoOperation = (arcoOperation) => {
  if (arcoOperation?.id) {
    return updateArcoOperation(arcoOperation)
  } else {
    return addArcoOperation(arcoOperation)
  }
}

export const addArcoOperation = (arcoOperation) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations`, arcoOperation)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const updateArcoOperation = (arcoOperation) => {
  return axiosInstance
    .put(`/api/arco/custom/arco-operations`, arcoOperation)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoRelationsOfOperation = (subjectId, id, type, relationId) => {
  return axiosInstance
    .delete(
      `/api/arco/custom/arco-operations/relationship/${subjectId}/${id}/${type}/${relationId}`
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoOperation = (id) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-operations/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const downloadAntiTerrorism = (antiterrorism) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-registries-antiterrorism`, antiterrorism)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const printArcoOperationDocument = (arcoOperationDocument) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations-download-documents`, arcoOperationDocument)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
export const downloadArcoOperationZip = (arcoOperationZip) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations-download-documents-zip`, arcoOperationZip)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const printArcoOperationDocumentClip = (arcoOperationDocument) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations-document`, arcoOperationDocument)
    .then((res) => res)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getArcoRegistryDocuments = (id) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/arco/custom/arco-operations-list-documents/${id}`)
      .then((res) => res.data)
      .then((arcoRegistryDocument) => {
        dispatch(getArcoRegistryDocumentsSuccess(arcoRegistryDocument))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getArcoRegistryDocumentsSuccess = (arcoRegistryDocument) => {
  return {
    type: GET_ARCO_REGISTRY_DOCUMENT_SUCCESS,
    arcoRegistryDocument
  }
}

export const importDocumentFile = (id, formData) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations-document/${id}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteArcoRegistriesclips = (arcoOperationDocument) => {
  return axiosInstance
    .delete(`/api/arco/custom/arco-operations-document`, arcoOperationDocument)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const importARIWFile = (formData) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-subject-import`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const importArcoDocumentsZip = (formData, subjectId) => {
  return axiosInstance
    .post(`/api/arco/custom/arco-operations-upload-documents/${subjectId}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**
 * restituisce una lista di anagrafiche ARCO, cercando per codice fiscale. Il codice fiscale potrebbe non essere univoco.
 * @param {number} subjectId
 * @param {string} fiscalCode
 *
 * @returns arcoRegistries
 */
export const getArcoRegistryByFiscalCode = (subjectId, fiscalCode) => {
  return axiosInstance
    .get(`/api/arco/custom/arco-registries/get-by-fiscal-code/${subjectId}/${fiscalCode}`)
    .then((res) => res.data)
}

export const getBlobFromBase64 = (base64) => {
  let byteString = atob(base64)

  // Convert that text into a byte array.
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], {
    type: 'application/pdf'
  })
}

/*BLUE*/
export const getAllBlueSubjects = () => {
  return axiosInstance
    .get(`/api/blue/custom/blue-subjects`)
    .then((res) => res.data)
    .catch(({ response }) => response?.data?.errors)
}

export const getBlueSubject = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/blue/custom/blue-subjects/${subjectId}`)
      .then((res) => res.data)
      .then((blueSubject) => {
        dispatch(getBlueSubjectSuccess(blueSubject))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

const getBlueSubjectSuccess = (blueSubject) => {
  return {
    type: BLUE_GET_SUBJECT_SUCCESS,
    blueSubject
  }
}

export const getBlueSearchEvents = (subjectId) => {
  return (dispatch) => {
    axiosInstance
      .get(`/api/blue/custom/blue-Search-events/${subjectId}`)
      .then((res) => res.data)
      .then((events) => {
        dispatch(getBlueSearchEventsSuccess(events))
      })
      .catch(({ response }) => response?.data?.errors)
  }
}

export const clearBlueSearchEvents = () => {
  return (dispatch) => {
    dispatch(getBlueSearchEventsSuccess([]))
  }
}

const getBlueSearchEventsSuccess = (blueSearchEvents) => {
  return {
    type: BLUE_GET_EVENTS_SUCCESS,
    blueSearchEvents
  }
}

export const addBlueSubject = (newBLueSubject) => {
  return axiosInstance
    .post(`/api/blue/custom/blue-subjects`, newBLueSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const updateBlueSubject = (blueSubject) => {
  return axiosInstance
    .put(`/api/blue/custom/blue-subjects`, blueSubject)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const saveBlueSubject = (blueSubject) => {
  if (blueSubject?.id) {
    return updateBlueSubject(blueSubject)
  } else {
    return addBlueSubject(blueSubject)
  }
}

export const deleteBlueSubject = (blueSubject) => {
  return axiosInstance
    .delete(`/api/blue/custom/blue-subjects/${blueSubject}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const deleteBlueSubjects = (ids) => {
  return axiosInstance
    .delete(`/api/blue/custom/blue-subjects-multiple/${ids}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const checkBlueAntiTerrorism = (subjectId, blueRequest) => {
  return axiosInstance
    .post(`/api/blue/custom/antiterrorism-check/${subjectId}`, blueRequest)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const importBLUEFile = (subjectId, formData) => {
  return axiosInstance
    .post(`/api/blue/custom/massive-file-import/${subjectId}`, formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const blueDownloadAntiTerrorism = (subjectId, eventId, entityId, language = 'it') => {
  return axiosInstance
    .post(`/api/blue/custom/download-blue-search-event-file/${subjectId}/${eventId}`, {
      entityId: entityId,
      language: language
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response))
}

export const changeBlueLanguage = (language) => {
  return (dispatch) => {
    dispatch(getBlueLanguageChange(language))
  }
}

const getBlueLanguageChange = (language) => {
  return {
    type: BLUE_LANGUAGE_CHANGE,
    language
  }
}

export const isApplicationExpired = (customerApplication) => {
  if (customerApplication) {
    return moment(customerApplication.expiration).add(1, 'days').isSameOrAfter(moment())
      ? false
      : true
  } else {
    return true
  }
}

//FACCIAMONOI
export const inviaAccreditamentoSid = (accreditamentoSidDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/invia-richiesta-accreditamento', accreditamentoSidDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const ottieniRiepilogoAccreditamento = (accreditamentoSidDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/riepilogo-accreditamento', accreditamentoSidDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const inviaCertificatiSid = (accreditamentoSidDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/invia-richiesta-certificati', accreditamentoSidDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const ottieniRiepilogoCertificati = (accreditamentoSidDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/riepilogo-certificati', accreditamentoSidDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const inviaEmailRapporti = (csvRapportiDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/cora/send-email', csvRapportiDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const inviaRispostaEmailFacciamoNoiTutto = (rapportiDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/cora/notify-admin', rapportiDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const invioMailManuale = (rapportiDto) => {
  return axiosInstance
    .post('/api/facciamo-noi/cora/admin/send-email', rapportiDto)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const getFacciamoNoiSubjectTypes = () => {
  return axiosInstance
    .get('/api/facciamo-noi/comurei-subject-types')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

/**ASSISTENZA */
export const sendAssistanceRequest = (image, errors, applicationName = '') => {
  const formData = new FormData()
  formData.append('image', image)
  formData.append('errors', errors)
  formData.append('applicationName', applicationName)
  return axiosInstance
    .post('/api/custom/assistance/send-request', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}

export const sendEncryptedCredentialsToAdmin = (password) => {
  const formData = new FormData()
  formData.append('password', password)
  return axiosInstance
    .post('/api/custom/assistance/send-credentials', formData)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err?.response?.data))
}
