import { memo, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import moment from 'moment'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'
import * as Constants from '../../../../config/Constants'
import { BwmSelect } from '../../../shared/form/BwmSelect'
import NumberFormat from 'react-number-format'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IQuestion } from '../../../../styles/images/svgs/regular/question-circle.svg'
import { YearlyBalanceExportModal } from './NewYearlyBalanceExportModal'
import { YearlyBalanceImportModal } from './NewYearlyBalanceImportModal'
import { ReactComponent as IExclude } from '../../../../styles/images/svg/do-not-enter.svg'
import { formatDateForDisplay, isEmpty, isNotEmpty } from '../../../shared/Utility'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupError } from '../../../shared/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'
import { VirtualList } from '../../../shared/form/lists/VirtualList'
import { getBalanceRulesByRelationshipType, getDisabledFields } from '../BalanceRules'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { CreateCommunicationModal } from '../communications/NewCreateCommunicationModal'
import { ISortDown, ISortUp } from '../../../../styles/icons'
import { cloneDeep } from 'lodash'

//memoizzo la cella per impedire rerender non necessari. Potrebbe valere la pena memoizzare l'intera riga
const NumberCell = memo(function NumberCell({
  value,
  valueName,
  relationship,
  className,
  disabled,
  rules,
  handler
}) {
  return (
    <StarTooltip text={rules?.[valueName]}>
      <div className={className}>
        <NumberFormat
          key={`${valueName}_${relationship.id}`}
          name={`${valueName}_${relationship.id}`}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
          fixedDecimalScale={true}
          className="form-control form-control-cell"
          inputMode="numeric"
          value={disabled ? '-' : value}
          readOnly={disabled}
          onValueChange={(e) => handler(relationship, valueName, e)}
        />
      </div>
    </StarTooltip>
  )
})

export function YearlyBalanceList(props) {
  const dispatch = useDispatch()

  const [allCheck, setAllCheck] = useState(false)
  const [checkedItems, setCheckedItems] = useState(new Map())
  const [isDisabled, setIsDisabled] = useState(true)
  const [dropdownYears, setDropdownYears] = useState([])
  const [sort, setSort] = useState({ order: null, activeKey: null })
  const [selectedYear, setSelectedYear] = useState(
    new Date().getMonth() > 4 ? new Date().getFullYear() : new Date().getFullYear() - 1
  )
  const [relationships, setRelationships] = useState([])
  const [excludedItems, setExcludedItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)

  const subject = useSelector((state) => state.subject.data)
  const yearlyBalanceRelationships = useSelector((state) => state.yearlyBalanceRelationships.data)
  const currencies = useSelector((state) => state.currencies.data)
  const currencyTypes = useSelector((state) => state.currencyTypes.data)

  const prevYearlyBalanceRelationshipsRef = useRef()

  useEffect(() => {
    setRelationships(props.yearlyBalanceRelationships)
    _setDropdownYears()
    getYearlyBalance(props.subjectId, selectedYear)
    getAllCurrency()
  }, [])

  useEffect(() => {
    if (prevYearlyBalanceRelationshipsRef.current !== yearlyBalanceRelationships) {
      setRelationships(yearlyBalanceRelationships)
      setLoading(false)
    }
    prevYearlyBalanceRelationshipsRef.current = yearlyBalanceRelationships
  }, [yearlyBalanceRelationships])

  /**
   *
   * @param {*} subjectId
   * @param {*} year
   * @param {*} page
   * @param {*} size
   */
  const getYearlyBalance = (subjectId, year, page, size) => {
    setLoading(true)
    dispatch(actions.getCommunicationsBalanceBySubjectIdAndYear(subjectId, year, page, size))
  }

  const _showAll = () => {
    if (showAll) {
      setShowAll(false)
      getYearlyBalance(props.subjectId, selectedYear)
    } else {
      setShowAll(true)
      getYearlyBalance(props.subjectId, selectedYear, 0, 0)
    }
  }

  const getAllCurrency = () => {
    if (!currencies?.length) {
      dispatch(actions.getAllCurrency())
    }
    if (!currencyTypes?.length) {
      dispatch(actions.getAllCurrencyTypes())
    }
  }

  const openGuideBalances = () => {
    window.open('https://www.starsoluzioni.it/media/TABELLA-RAPPORTI.pdf')
    PopupSuccess({ text: 'File scaricato' })
  }

  const saveBalances = () => {
    const yearlyBalances = {
      subjectId: parseInt(props.subjectId),
      year: String(selectedYear),
      relationships: relationships
    }

    return actions
      .updateYearlyBalances(yearlyBalances)
      .then(() => {
        PopupSuccess({ text: 'I dati sono stati salvati con successo' })

        // Se showAll è true, richiama getYearlyBalance con parametri extra
        if (showAll) {
          getYearlyBalance(props.subjectId, selectedYear, 0, 0)
        } else {
          getYearlyBalance(props.subjectId, selectedYear)
        }
      })
      .catch((errors) => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      })
  }

  const _setDropdownYears = () => {
    const initialYear = 2011 // anno di inizio comunicazione saldi come da normativa
    let currentYear = new Date().getFullYear()
    //vengono mostrati tutti gli anni dal 2011 fino a quello corrente
    let years = []
    for (let i = currentYear; i >= initialYear; i--) {
      years.push({ id: i, description: `Anno: ${i}` })
    }
    setDropdownYears(years)
  }

  const renderRows = (relationships) => {
    if (loading) return <RowSpinner />

    const header = (
      <div className="text-start header-table virtual">{renderRowHeader(relationships)}</div>
    )

    let rows = relationships.map((relationship, index) => renderBalanceRows(relationship, index))

    rows = [header, ...rows]

    if (rows.length === 1) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey q-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">Nessun Saldo trovato</h3>
        </div>
      )
    }
    return <VirtualList rows={rows} />
  }

  const handlerYearChange = (value) => {
    setSelectedYear(parseInt(value))
    getYearlyBalance(props.subjectId, parseInt(value))
    setShowAll(false)
  }

  const getRelationshipToUpdate = (relationships = [], relationship) => {
    let index = relationships.findIndex((rel) => rel.id === relationship?.id)
    if (index === -1) {
      relationships.push(relationship)
      index = relationships.findIndex((rel) => rel.id === relationship?.id)
    }

    if (relationships[index].coraRelationBalances === undefined) {
      relationships[index].coraRelationBalances = []
    }

    if (relationships[index].coraRelationBalances[0] === undefined) {
      relationships[index].coraRelationBalances[0] = {}
    }
    return index
  }

  //prende un valore dal NumberFormat. contiene valore in formato float, valore formattato in stringa e valore raw
  const handlerAmount = (relationship, field, value) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    newRelationships[index].coraRelationBalances[0][field] = value.floatValue
    setRelationships(newRelationships)
  }

  const handlerCurrency = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    newRelationships[index].coraRelationBalances[0].currency = {
      id: e.target.value.trim(),
      isoCode: e.target.options[e.target.value].getAttribute('data-cod'),
      description: e.target.options[e.target.value].text
    }
    setRelationships(newRelationships)
  }

  const handlerCurrencyType = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    if (isNotEmpty(e.target.value)) {
      newRelationships[index].coraRelationBalances[0].currencyType = {
        id: e.target.value.trim()
      }
    } else {
      newRelationships[index].coraRelationBalances[0].currencyType = null
    }
    setRelationships(newRelationships)
  }

  const handlerIdResult = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(relationships, relationship)
    newRelationships[index].coraRelationBalances[0].idResult = e.target.value.toUpperCase().trim()
    setRelationships(newRelationships)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      if (checkedItems.size > 0) {
        checkedItems.clear()
      }
      let items = new Map()
      let newRelationships = cloneDeep(relationships)
      newRelationships.map((relatioship) => items.set(relatioship.id, true))
      setCheckedItems(items)
      setIsDisabled(false)
    } else {
      setCheckedItems(new Map())
      setIsDisabled(true)
    }
  }

  const handleCheckChange = (event) => {
    if (event !== undefined) {
      checkedItems.set(parseInt(event.target.value), event.target.checked)
      let count = 0

      for (const checkedItem of checkedItems) {
        if (checkedItem[1]) {
          setIsDisabled(false)
          return
        }
        if (!checkedItem[1]) {
          count++
        }
      }
      if (count === checkedItems.size) {
        setIsDisabled(true)
      }
    }
  }

  const handlerDontSendCommunication = () => {
    setLoading(true)
    let selectedToExclude = cloneDeep(excludedItems)
    let newRelationships = cloneDeep(relationships)
    for (const checkedItem of checkedItems) {
      if (checkedItem[1]) {
        let idToRemove = parseInt(checkedItem[0])
        selectedToExclude.push(idToRemove)
        let index = newRelationships.map((item) => item.id).indexOf(idToRemove)
        newRelationships.splice(index, 1)
      }
    }
    setExcludedItems(selectedToExclude)
    setRelationships(newRelationships)
    setLoading(false)
    resetCheckBox()
  }

  const resetCheckBox = () => {
    setCheckedItems(new Map())
    setAllCheck(false)
    setIsDisabled(true)
    document.getElementsByName('relationshipCheck').forEach((el) => {
      if (el.checked) {
        el.checked = false
      }
    })
  }

  const getSort = (key) => {
    if (sort.activeKey === key && sort.order) {
      if (sort.order === 1) return { activeKey: key, order: -1 }
      else if (sort.order === -1) return { activeKey: key, order: null }
      else return { activeKey: key, order: 1 }
    } else {
      return { activeKey: key, order: 1 }
    }
  }

  const sortBy = (val) => {
    if (val) {
      let newRelationships = cloneDeep(relationships)
      let relationshipsOrdered = cloneDeep(yearlyBalanceRelationships)
      const newSort = getSort(val)
      if (newSort.order) {
        switch (val) {
          case 'completeName':
            // Sort by completeName
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              return (
                compare * ((a.completeName > b.completeName) - (b.completeName > a.completeName))
              )
            })
            break
          case 'relType':
            // Sort by relationship type
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = a.relationshipType?.code > b.relationshipType?.code

              if (condition) {
                return compare * 1
              } else if (b.relationshipType?.code > a.relationshipType?.code) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
          case 'startDate':
            // Sort by startDate
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = moment(a.startDate).isAfter(moment(b.startDate))

              if (condition) {
                return compare * 1
              } else if (moment(b.startDate).isAfter(moment(a.startDate))) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
          case 'endDate':
            // Sort by endDate
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = moment(a.endDate || new Date()).isAfter(
                moment(b.endDate || new Date())
              )

              if (condition) {
                return compare * 1
              } else if (moment(b.endDate || new Date()).isAfter(moment(a.endDate || new Date()))) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
        }
      }
      setRelationships(relationshipsOrdered)
      setSort(newSort)
    }
  }

  const getSortArrow = (key) => {
    if (key === sort.activeKey && sort.order === -1) {
      return <ISortDown width="15" className={'pointer float-end me-2'} />
    } else if (key === sort.activeKey && sort.order === 1) {
      return <ISortUp width="15" className={'pointer float-end me-2'} />
    }
  }

  const renderRowHeader = () => {
    let smallcolclass = 'col w-7'
    return (
      <>
        <div className="col w-3 text-center div-td justify-content-center">
          <input type="checkbox" checked={allCheck} onChange={(e) => handleAllCheckChange(e)} />
        </div>
        <div className="col w-20 div-td d-flex pointer" onClick={() => sortBy('completeName')}>
          {getSortArrow('completeName')}
          ANAGRAFICA{' '}
        </div>
        <div className="col w-10 div-td d-flex pointer" onClick={() => sortBy('relType')}>
          {getSortArrow('relType')}
          TIPO RAPPORTO{' '}
        </div>
        <div
          className={`${smallcolclass} div-td d-flex pointer`}
          onClick={() => sortBy('startDate')}>
          {getSortArrow('startDate')}
          DATA INIZIO{' '}
        </div>
        <div className={`${smallcolclass} div-td d-flex pointer`} onClick={() => sortBy('endDate')}>
          {getSortArrow('endDate')}
          DATA FINE{' '}
        </div>
        <div className={`${smallcolclass} div-td`}>IMPORTO 1</div>
        <div className={`${smallcolclass} div-td`}>IMPORTO 2</div>
        <div className={`${smallcolclass} div-td`}>IMPORTO 3</div>
        <div className={`${smallcolclass} div-td`}>IMPORTO 4</div>
        <div className={`${smallcolclass} div-td`}>ALTRE INFO/IMPORTO 5</div>
        <div className={`${smallcolclass} div-td`}>GIAC. MEDIA</div>
        <div className={`${smallcolclass} div-td`}>VALUTA</div>
        <div className={`${smallcolclass} div-td`}>NATURA</div>
        <div className={`${smallcolclass} div-td`}>ESITO</div>
      </>
    )
  }

  const getDisplayedAmount = (balance, amount) => {
    if (isEmpty(balance?.id) && isEmpty(amount)) {
      return ''
    } else if (isNotEmpty(balance?.id) && isEmpty(amount)) {
      return '0'
    } else {
      return amount
    }
  }

  const renderBalanceRows = (relationship, index) => {
    let balance = relationship?.coraRelationBalances[0]

    let amount1Balance = getDisplayedAmount(balance, balance?.amount1)
    let amount2Balance = getDisplayedAmount(balance, balance?.amount2)
    let amount3Balance = getDisplayedAmount(balance, balance?.amount3)
    let amount4Balance = getDisplayedAmount(balance, balance?.amount4)
    let amount5Balance = getDisplayedAmount(balance, balance?.amount5)
    let stockBalance = getDisplayedAmount(balance, balance?.stock)
    const rules = getBalanceRulesByRelationshipType(relationship?.relationshipType?.code)
    const disabledFields = getDisabledFields(rules)

    let checked = checkedItems.get(relationship.id)
    let smallcolclass = 'col w-7 px-2 py-1 text-truncate'
    let smallcolClassNumber = `${smallcolclass} text-end border-right`
    return (
      <>
        <div
          className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-hover`}
          id={`row-${relationship.id}`}>
          <div className="col w-3 text-center div-td">
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              id={`row-${relationship.id}`}
              name="relationshipCheck"
              value={relationship.id}
              checked={checked}
              onChange={(e) => handleCheckChange(e)}
            />
          </div>
          <div className="col w-20 div-td text-truncate">{relationship.completeName}</div>
          <div className="col w-10 div-td text-truncate">
            {relationship.relationshipType?.code +
              ' - ' +
              relationship.relationshipType?.description}
          </div>
          <div className="col w-7 div-td text-truncate">
            {formatDateForDisplay(relationship.startDate)}
          </div>
          <div className="col w-7 div-td text-truncate">
            {relationship.endDate && formatDateForDisplay(relationship.endDate)}
          </div>
          {renderNumberCell(
            amount1Balance,
            'amount1',
            relationship,
            smallcolClassNumber,
            disabledFields.amount1Disabled,
            rules,
            handlerAmount
          )}
          {renderNumberCell(
            amount2Balance,
            'amount2',
            relationship,
            smallcolClassNumber,
            disabledFields.amount2Disabled,
            rules,
            handlerAmount
          )}
          {renderNumberCell(
            amount3Balance,
            'amount3',
            relationship,
            smallcolClassNumber,
            disabledFields.amount3Disabled,
            rules,
            handlerAmount
          )}
          {renderNumberCell(
            amount4Balance,
            'amount4',
            relationship,
            smallcolClassNumber,
            disabledFields.amount4Disabled,
            rules,
            handlerAmount
          )}
          {renderNumberCell(
            amount5Balance,
            'amount5',
            relationship,
            smallcolClassNumber,
            disabledFields.amount5Disabled,
            rules,
            handlerAmount
          )}
          {renderNumberCell(
            stockBalance,
            'stock',
            relationship,
            smallcolClassNumber,
            disabledFields.stockDisabled,
            rules,
            handlerAmount
          )}
          <div className={`${smallcolclass} border-right`}>
            <BwmSelect
              options={currencies}
              showCode={true}
              name={`currency_${relationship.id}`}
              className="form-control form-control-cell"
              nameKey={'currencies'}
              onChange={(e) => handlerCurrency(relationship, e)}
              value={balance?.currency?.id || 58}
            />
          </div>
          <div className={`${smallcolclass} border-right`}>
            <BwmSelect
              options={currencyTypes}
              showCode={true}
              name={`currencyType_${relationship.id}`}
              className="form-control form-control-cell"
              onChange={(e) => handlerCurrencyType(relationship, e)}
              value={balance?.currencyType?.id || null}
            />
          </div>
          <div className={`${smallcolclass}`}>
            <BwmInput
              name={'idResult'}
              className={'form-control form-control-cell'}
              value={balance?.idResult || ''}
              onChange={(e) => handlerIdResult(relationship, e)}
            />
          </div>
        </div>
      </>
    )
  }

  /**
   *
   * @param {*} value
   * @param {*} valueName
   * @param {*} relationship
   * @param {*} className
   * @param {*} disabled
   * @param {*} rules
   * @param {*} handler
   * @returns
   */
  const renderNumberCell = (
    value,
    valueName,
    relationship,
    className,
    disabled,
    rules,
    handler
  ) => {
    return (
      <NumberCell
        value={value}
        valueName={valueName}
        relationship={relationship}
        className={className}
        disabled={disabled}
        rules={rules}
        handler={handler}
      />
    )
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <div className="col-7 d-flex" role="group" aria-label="action buttons">
              <div className="pe-2">
                <Dropdown onSelect={(eventKey) => handlerYearChange(eventKey)}>
                  <DropdownButton
                    title={`Anno: ${selectedYear}`}
                    bsPrefix={'dropdown-menu-green btn-fill m-0 me-1 pe-2'}>
                    {dropdownYears &&
                      dropdownYears.map((el, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            eventKey={el.id}
                            active={el.id === selectedYear}>
                            {el.description}
                          </Dropdown.Item>
                        )
                      })}
                  </DropdownButton>
                </Dropdown>
              </div>
              <div className="pe-2">
                <button className="btn btn-empty px-4 btn-sm" onClick={() => openGuideBalances()}>
                  <IQuestion className="padding-right-4px svg" width="20" fill="#128186" />
                  &nbsp;ISTRUZIONI SALDI
                </button>
              </div>
              <div className="pe-2">
                <button className="btn btn-empty px-4 btn-sm" onClick={() => _showAll()}>
                  {showAll ? `Mostra primi ${actions.BALANCE_DEFAULT_PAGE_SIZE}` : 'Mostra tutti'}
                </button>
              </div>
              <div className="pe-2">
                <YearlyBalanceExportModal
                  disabled={false}
                  selectedYear={selectedYear}
                  subjectId={props.subjectId}
                />
              </div>
              <div className="pe-2">
                <YearlyBalanceImportModal
                  subjectId={props.subjectId}
                  selectedYear={selectedYear}
                  getYearlyBalance={() => getYearlyBalance(props.subjectId, selectedYear, 0, 0)}
                />
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-empty px-4 btn-sm ${isDisabled && 'disabled'}`}
                  disabled={checkedItems.size === 0}
                  onClick={handlerDontSendCommunication}>
                  <IExclude
                    className="padding-right-4px"
                    fill={isDisabled ? '#FFFFFF' : '#128186'}
                    width="18"
                  />
                  &nbsp;Escludi dalla comunicazione
                </button>
              </div>
            </div>
            <div
              className="col-5 d-flex justify-content-end"
              role="group"
              aria-label="action buttons">
              <button className="btn btn-empty px-5 btn-sm" onClick={saveBalances}>
                <ISave className="padding-right-4px svg" width="22" height="16" fill="#128186" />
                &nbsp;SALVA
              </button>
              <span className="ms-2">
                <CreateCommunicationModal
                  type="YEARLY"
                  subjectId={props.subjectId}
                  subject={subject}
                  selectedYear={selectedYear}
                  excludedRelationships={excludedItems}
                  isDisabled={relationships.length === 0}
                  onClickCreateCommunicationBefore={saveBalances}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {renderRows(relationships)}
    </>
  )
}
