import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnFill } from './BtnFill'

export function PopupAlert(props) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup">
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">×</span>
              <span className="visually-hidden">Close</span>
            </button>
            <Row className={'show-grid popup-title-alert text-center'}>
              <Col md={12} lg={12}>
                <img alt={''} src="" />
              </Col>
              <Col md={12} lg={12} className="py-3">
                <h4>{props?.title || 'Attenzione'}</h4>
              </Col>
            </Row>
            <Row className={'show-grid text-center'}>
              <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.innerHtml }}></Col>
              <Col md={12} lg={12}>
                {props.text}
              </Col>
            </Row>
            <div className="my-2 py-5 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                onClick={() => {
                  if (props.onClickCancel) props.onClickCancel()
                  onClose()
                }}>
                {props.cancel || 'ANNULLA'}
              </button>
              <BtnFill
                text={props.confirm || 'CONTINUA'}
                handlerOnClick={() => {
                  if (props.handleClickConfirm) props.handleClickConfirm()
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
