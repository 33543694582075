import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { inviaRispostaEmailFacciamoNoiTutto } from '../../../../actions'

export function FacciamoNoiTuttoCoraThankYou() {
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)

    const email = queryParameters.get('email')
    const denominazione = queryParameters.get('denominazione')
    const partitaIva = queryParameters.get('partitaIva')

    if (email && denominazione && partitaIva) {
      let dto = {
        nomeCliente: denominazione,
        codiceFiscaleCliente: partitaIva,
        emailCliente: email,
        coraRelationships: []
      }

      inviaRispostaEmailFacciamoNoiTutto(dto)
    }
  })

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col className="text-center">
          <h3>Perfetto! La tua collaborazione è necessaria al buon funzionamento del servizio.</h3>
          <h4>Ci aggiorniamo il mese prossimo, buon lavoro.</h4>
        </Col>
      </Row>
    </React.Fragment>
  )
}
