import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { PopupError, closePopupError } from '../../../shared/PopupError'
import { PopupDelete } from '../../../shared/PopupDelete'
import { CrsSubjectModal } from './NewCrsSubjectModal'
import { CrsSubjectList } from './NewCrsSubjectList'
import { UploadReceiptModal } from '../../application-crs/receipts/UploadReceiptModal'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ImportCrsSubjectDataModal } from '../import/ImportCrsSubjectDataModal'
import { CrsSubjectsExportModal } from './CrsSubjectsExportModal'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import * as Constants from '../../../../config/Constants'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { CrsCommunicationModal } from '../communications/CrsCommunicationModal'
import { isNotEmpty } from '../../../shared/Utility'
import { BtnFill } from '../../../shared/BtnFill'
import { handleCreateCommunicationErrorsText } from '../communications/CreateCommunicationModal'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'

export const CrsSubject = () => {
  const [subject, setSubject] = useState()
  const [searchObject, setSearchObject] = useState({})
  const [checkedSubjects, setCheckedSubjects] = useState([])
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [isLicenseValid, setIsLicenseValid] = useState(false)
  const previousYear = new Date().getFullYear() - 1
  const [isLoading, setIsLoading] = useState(false)
  const [elaboratedCommunicationModals, setElaboratedCommunicationModals] = useState([])
  const [currentModalIndex, setCurrentModalIndex] = useState(null)
  const [customerApplication, setCustomerApplication] = useState(null)

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  const _subjects = useSelector((state) => state.crsSubjects.data)
  const [subjects, setSubjects] = useState([])

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => ({
      name: country.code,
      key: country.description
    }))
  )

  const prevAuthRef = useRef()
  const prevSubjectSummaryRef = useRef()

  useEffect(() => {
    if (auth?.user?.customer?.customerApplications) {
      checkExpire()
    }
    getLocations()
    getCountries()
  }, [])

  useEffect(() => {
    if (
      prevAuthRef.current?.user?.customer?.customerApplications !==
      auth?.user?.customer?.customerApplications
    ) {
      checkExpire()
    }
    prevAuthRef.current = auth
  }, [auth])

  // Effect to check changes in subjectSummary
  useEffect(() => {
    if (prevSubjectSummaryRef.current !== subjectSummary) {
      if (subjectSummary?.available < 0) {
        PopupSubjectLicense({
          total: subjectSummary.total,
          used: subjectSummary.used,
          name: Constants.APPLICATION_CRS,
          available: subjectSummary.available
        })
        setIsLicenseValid(false)
      }
    }
    prevSubjectSummaryRef.current = subjectSummary
  }, [subjectSummary])

  useEffect(() => {
    setIsLoading(false)
    setSubjects(_subjects)
  }, [_subjects])

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const getSubjectSummary = (showPopup) => {
    CheckRemainingSubjects(Constants.APPLICATION_CRS, showPopup, (subjectSummary) => {
      setSubjectSummary(subjectSummary)
      setDisableNewSubjectButtons(subjectSummary?.available < 1)
    })
  }

  const checkExpire = () => {
    const applications = auth?.user?.customer?.customerApplications
    if (applications?.length > 0) {
      const isLicenseValid = IsLicenseValid(applications, Constants.APPLICATION_CRS)

      const application = Constants.APPLICATIONS.find(
        (a) => a.description === Constants.APPLICATION_CRS
      )
      const customerApplication = applications?.find((ca) => ca?.application?.id === application.id)

      setCustomerApplication(customerApplication)
      setIsLicenseValid(isLicenseValid)
      getSubjectSummary(false)
    }
  }

  const getSubjects = () => {
    actions.searchCrsSubjects(searchObject).then((res) => {
      setSubjects(res.content)
    })
  }

  const onClickCheck = (newList = []) => {
    setCheckedSubjects(newList)
  }

  const setData = (subjects, searchObject) => {
    setSubjects(subjects)
    setSearchObject(searchObject)
  }

  const deleteCrsSubject = () => {
    checkedSubjects.map((subject) => {
      actions.deleteCrsSubject(subject).then(
        () => {
          setCheckedSubjects([])
          getSubjects()
          getSubjectSummary(false)
          PopupSuccess()
        },
        (errors) => {
          PopupError({ text: errors })
        }
      )
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Soggetti Selezionati',
      handleClickConfirm: deleteCrsSubject,
      text: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    })
  }

  const onClickCreateEmptyCommunications = () => {
    setIsLoading(true)
    setElaboratedCommunicationModals([])
    let elaboratedCommunications = []
    actions
      .createCrsCommunicationEmptyMultiple(checkedSubjects)
      .then(
        (res) => {
          res.forEach((element, index) => {
            if (element.communication) {
              elaboratedCommunications.push(() => (
                <CrsCommunicationModal
                  key={'communication-modal-' + element.communication.id}
                  sentCommunications={[element.communication]}
                  showModal={true}
                  subject={element.communication.subject}
                  emailAddress={element.communication.subject?.coraSubjectSid?.email}
                  closeModal={() => {
                    setCurrentModalIndex(
                      elaboratedCommunications[currentModalIndex + 1] ? currentModalIndex + 1 : null
                    )
                  }}
                />
              ))
            } else if (element.error) {
              elaboratedCommunications.push(() => (
                <div key={'communication-error-modal-' + index}>
                  {PopupError({
                    text: element.error,
                    buttonClose: () => (
                      <BtnFill
                        text={'CHIUDI'}
                        handlerOnClick={() => {
                          closePopupError()
                          setCurrentModalIndex(
                            elaboratedCommunications[currentModalIndex + 1]
                              ? currentModalIndex + 1
                              : null
                          )
                        }}
                      />
                    )
                  })}
                </div>
              ))
            }
          })
        },
        (err) => {
          PopupError({
            text: handleCreateCommunicationErrorsText(err)
          })
        }
      )
      .then(() => {
        setIsLoading(false)
        setElaboratedCommunicationModals(elaboratedCommunications)
        setCurrentModalIndex(elaboratedCommunications.length !== 0 ? 0 : null)
        getSubjects()
      })
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <div className="table-custom">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <SubjectTotalAndCallToAction
            total={subjectSummary?.total}
            remaining={subjectSummary?.available}
            used={subjectSummary?.used}
            baseEcommerceProductId={Constants.getEcommerceProductId(Constants.APPLICATION_CRS)}
            subjectsProductId={Constants.getEcommerceSubjectProductId(Constants.APPLICATION_CRS)}
            licenseType={customerApplication?.licenseType}
            customerApplication={customerApplication}
            buyLink={Constants.CRS_BUY_LINK}
          />
          <div className="d-flex">
            <div className="me-2">
              <UploadReceiptModal disabled={!isLicenseValid} />
            </div>
            <div>
              <CrsSubjectModal
                subjectSummary={subjectSummary}
                licenseExpired={!isLicenseValid}
                locations={locations || []}
                countries={countries || []}
                getSubjects={getSubjects}
                disabled={!isLicenseValid}
                closeModal={() => setSubject(null)}
              />
            </div>
          </div>
        </div>
        <CrsSubjectList
          subjects={subjects}
          checkedItems={checkedSubjects}
          licenseExpired={!isLicenseValid}
          setData={setData}
          onClickCheck={onClickCheck}
          openDetail={(subject) => setSubject(subject)}
          onRowClick={() => {
            if (subjectSummary?.available < 0) {
              PopupSubjectLicense({
                total: subjectSummary?.total,
                used: subjectSummary.used,
                name: Constants.APPLICATION_CRS
              })
            }
          }}
          actionsComponent={
            <div className="col-12 ps-0">
              <div className="btn-group" role="group" aria-label="action buttons">
                <button
                  type="button"
                  className={`btn btn-outline-primary btn-empty  btn-sm  ${checkedSubjects?.length === 0 && 'disabled'}`}
                  onClick={openPopupDelete}>
                  <IDelete
                    className={'padding-right-4px'}
                    width="20"
                    height="15"
                    fill={` ${checkedSubjects.length === 0 ? '#FFFFFF' : '#128186'}`}
                  />
                  &nbsp; Elimina
                </button>
                <CrsSubjectsExportModal
                  disabled={checkedSubjects?.length === 0}
                  checkedSubjects={checkedSubjects}
                />
                <ImportCrsSubjectDataModal
                  disabled={disableNewSubjectButtons || !isLicenseValid}
                  getSubjects={getSubjects}
                />

                <StarTooltip
                  text={
                    checkedSubjects?.length === 0
                      ? "Selezionare dall'elenco i soggetti per cui creare la comunicazione"
                      : 'Crea comunicazioni vuote per i soggetti selezionati'
                  }>
                  <button
                    type="button"
                    className={`btn   btn-new-rel  btn-sm  ${checkedSubjects?.length === 0 && 'disabled'}`}
                    onClick={() => {
                      /**
                       * -salva soggetti con anno
                       * -apri modale per ogni soggetto
                       */
                      onClickCreateEmptyCommunications()
                    }}
                    disabled={checkedSubjects?.length === 0 || !isLicenseValid}>
                    <ITextFile className={'padding-right-4px svg'} width="16" />
                    &nbsp; CREA COMUNICAZIONI VUOTE {previousYear}
                  </button>
                </StarTooltip>
              </div>
            </div>
          }
        />
        {subject?.id && (
          <CrsSubjectModal
            id={subject.id}
            hideButton={true}
            subject={subject}
            showModal={!!subject.id}
            licenseExpired={!isLicenseValid}
            subjectSummary={subjectSummary}
            locations={locations || []}
            countries={countries || []}
            getSubjects={getSubjects}
            disabled={!isLicenseValid}
            closeModal={() => setSubject(null)}
          />
        )}
      </div>
      {isNotEmpty(currentModalIndex) &&
        isNotEmpty(elaboratedCommunicationModals[currentModalIndex]) &&
        elaboratedCommunicationModals[currentModalIndex]()}
    </>
  )
}
