import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Constants from '../../config/Constants'
import * as actions from '../../actions'

class AppBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availableSubjects: undefined
    }
  }

  checkExpire(application) {
    if (!actions.isApplicationExpired(application)) {
      return 'fino al ' + new Date(application.expiration).toLocaleDateString('en-GB')
    } else {
      return 'scaduto il ' + new Date(application.expiration).toLocaleDateString('en-GB')
    }
  }
  componentDidMount() {
    let actualAppName = this.props.location.pathname.split('/')[2].toUpperCase()
    let application = this.props.auth?.customer?.customerApplications?.find(
      (ap) => ap.application.name === actualAppName
    )
    if (application) {
      actions.getSubjectSummary(application.application.name).then((res) => {
        if (res) {
          this.setState({ availableSubjects: res.available })
        }
      })
    }
  }

  render() {
    let actualAppName = this.props.location.pathname.split('/')[2].toUpperCase()
    let application = this.props.auth?.customer?.customerApplications?.find(
      (ap) => ap.application.name === actualAppName
    )
    if (application) {
      let applicationName = application.application.name
      let buyURL = Constants.APPLICATIONS.find((a) => a.description == applicationName).buyLink
      let remainingSubjects = this.state.availableSubjects
      let licenseString =
        application.licenseType === 'BOUGHT'
          ? 'Abbonamento ' + this.checkExpire(application)
          : 'In DEMO ' + this.checkExpire(application)
      return (
        <div
          id="appBox"
          aria-labelledby="navbarDropdown"
          onMouseLeave={() => this.props.mouseLeave()}>
          <div className="p-4">
            <h4>{application.application.name}</h4>
            {application.application.description}
            <br />
            {application.licenseType === 'BOUGHT' ? (
              <>
                <div>
                  {application.type}
                  {licenseString}
                </div>
                <div>
                  <b>{remainingSubjects}</b> soggetti rimanenti su&nbsp;
                  <b>{application.maxSubjects}</b> acquistati
                </div>
              </>
            ) : application.application.name.toLowerCase() === 'comurei' ? (
              <div>ATTIVO per 1 soggetto</div>
            ) : (
              <div className={'row coraStar-scadenza'}>
                {licenseString} per&nbsp;<b>{application.maxSubjects}</b>&nbsp;soggetti
              </div>
            )}
          </div>
          <div className="bg-dark-blue">
            <ul className="nav flex-column p-4">
              <li>
                <Link
                  onClick={() => window.open(application.application.videoUrl)}
                  id="video"
                  className="nav-link">
                  VIDEO TUTORIAL
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.open(application.application.manualsUrl)}
                  id="manuals"
                  className="nav-link">
                  MANUALI E NORMATIVA
                </Link>
              </li>

              <li>
                <Link onClick={() => window.open(buyURL)} id="buy" className="nav-link">
                  Compra OnLine
                </Link>
              </li>

              <li>
                <Link
                  onClick={() =>
                    window.open(
                      'https://lnx.starinfostudio.it/wp-content/files_mf/Assistenza-TVQS.exe'
                    )
                  }
                  id="manuals"
                  className="nav-link">
                  Team Viewer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    window.open('https://lnx.starinfostudio.it/downloads/exe/SupremoP.exe')
                  }
                  id="manuals"
                  className="nav-link">
                  Supremo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    application: state.application.data
  }
}

export default withRouter(connect(mapStateToProps)(AppBox))
