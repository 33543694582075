import SidForm from './SidForm'
import ContactForm from './ContactForm'
function SidContactFormContainer(props) {
  return (
    <div key={`certificati-${props.subject?.id || 0}`}>
      <SidForm {...props} />
      <ContactForm {...props} />
    </div>
  )
}

export default SidContactFormContainer
