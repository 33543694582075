import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'

import * as Constants from '../../../../config/Constants'
import '../../../../styles/alertComm.css'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { ImportModal } from '../../../shared/popups/ImportModal'
import { CrsCommunicationModal } from './CrsCommunicationModal'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'

export class CreateCommunicationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showModal: false,
      sentCommunications: [],
      subjectId: '',
      showImportModal: false
    }
  }
  buttonAssistance = () => {
    const link = document.createElement('a')
    let subjectName = this.props.subject ? ' - Soggetto: ' + this.props.subject.companyName : ''
    let customerName = this.props.auth?.user?.customer
      ? 'Utente: ' + this.props.auth?.user?.customer?.companyName
      : ''
    link.href = `mailto:assistenza@starinfostudio.it?subject=CRSWEB - Richiesta assistenza ${customerName} ${subjectName}`
    document.body.appendChild(link)
    link.click()
  }
  closeModal = () => {
    const { subjectId } = this.props
    if (this.props.reloadRelationships) this.props.reloadRelationships(subjectId)
    if (this.props.closeSubjectModal) this.props.closeSubjectModal()
    this.setState({ showModal: false })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  clickButtonSend = () => {
    this.confirmCommunications()
  }

  confirmCommunications = () => {
    const { subjectId } = this.props

    if (this.props.subject?.coraSubjectSid) {
      if (this.props.generateEmpty) {
        this.setState({
          loading: true
        })
        actions
          .createCrsCommunicationEmpty(subjectId)
          .then(
            (res) => {
              if (res?.data?.length > 0) {
                this.setState({ sentCommunications: res.data })
                this.openModal()
              } else {
                this.handleCreateCommunicationErrors()
              }
            },
            (errors) => {
              this.handleCreateCommunicationErrors(errors)
            }
          )
          .then(() => this.setState({ loading: false }))
      } else {
        if (this.props.selectedRelationship?.length > 0) {
          PopupConfirm({
            titleColor: titleColors.ALERT,
            title: 'Creazione comunicazione',
            text: `Si desidera creare una comunicazione con SOLO I RAPPORTI SELEZIONATI? (${this.props.selectedRelationship?.length})`,
            handleClickConfirm: () =>
              this.createCrsCommunicationWithRelationships(
                subjectId,
                this.props.selectedRelationship
              )
          })
        } else {
          this.createCrsCommunicationWithRelationships(subjectId)
        }
      }
    } else {
      // SID non inserito
      PopupError({
        ...this.props,
        text: 'Verificare la correttezza dei certificati e dati SID inseriti nel soggetto'
      })
    }
  }

  handleCreateCommunicationErrors = (errors) => {
    PopupError({ text: handleCreateCommunicationErrorsText(errors) })
  }

  createCrsCommunicationWithRelationships = (subjectId, relationshipIds = []) => {
    this.setState({
      loading: true
    })
    actions
      .createCrsCommunication(subjectId, relationshipIds)
      .then(
        (res) => {
          if (res?.data?.length > 0) {
            this.setState({ sentCommunications: res.data })
            this.openModal()
          } else {
            this.handleCreateCommunicationErrors()
          }
        },
        (errors) => {
          this.handleCreateCommunicationErrors(errors)
        }
      )
      .then(() => this.setState({ loading: false }))
  }

  /**
   * valore booleano per capire se visualizzare il popup
   * @param {boolean} value
   */
  openImportModal(value) {
    this.setState({
      showImportModal: value
    })
  }

  /**
   *  Invia i file caricati al backend per essere rielaborati
   * @param {*} dataFile i file caricati poi saranno inviati
   */
  uploadXMLFile(dataFile, subjectId) {
    const json = JSON.stringify(subjectId)
    const blob = new Blob([json], {
      type: 'application/json'
    })
    let formData = new FormData()
    if (dataFile?.length > 0) {
      this.setState({ loading: true })

      formData.append('file', dataFile[0])
      formData.append('subjectId', blob)
      actions
        .importCrsDocumentsXmlToElaborateTheFile(formData)
        .then(
          (response) => {
            this.setState({
              sentCommunications: response
            })
            if (this.props.getHistory) {
              this.props.getHistory(subjectId)
            }

            this.openModal()
          },
          (err) => {
            this.handleCreateCommunicationErrors(err)
          }
        )
        .then(() =>
          this.setState({
            loading: false
          })
        )
    }
    this.openImportModal(false)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <PageSpinner />}
        {!this.props.upload && (
          <button
            id={'btn-fill'}
            onClick={(e) => this.confirmCommunications()}
            className={
              this.props.classCustom
                ? this.props.classCustom
                : ' btn btn-primary btn-new-rel px-5 btn-sm'
            }
            disabled={this.props.isDisabled}>
            <ITextFile className={'padding-right-4px svg'} width="16" fill={`#FFFFFF`} />
            &nbsp; {this.props.label ? this.props.label : 'CREA COMUNICAZIONE'}
          </button>
        )}
        {this.props.upload && (
          <button
            type="button"
            className={`btn btn-outline-primary btn-empty px-4 btn-sm`}
            data-tip={'Elabora comunicazione XML'}
            aria-label={'Elabora comunicazione XML'}
            onClick={() => this.openImportModal(true)}>
            <ITextFile className={'padding-right-4px svg'} width="16" fill={`#FFFFFF`} />
            &nbsp; Elabora comunicazione XML
          </button>
        )}
        {this.state.showModal && (
          <CrsCommunicationModal
            sentCommunications={this.state.sentCommunications}
            showModal={this.state.showModal}
            closeModal={this.closeModal}
            subject={this.props.subject}
            emailAddress={this.props.emailAddress}
          />
        )}

        {this.state.showImportModal && (
          <ImportModal
            key={'import-xms-' + this.props.subjectId}
            show={true}
            allowedFileExtensions={['xml']}
            title={'SELEZIONA FILE XML DA ELABORARE'}
            onHide={() => {
              this.openImportModal(false)
            }}
            onSend={(dataFile) => this.uploadXMLFile(dataFile, this.props.subjectId)}
          />
        )}
      </React.Fragment>
    )
  }
}

export function handleCreateCommunicationErrorsText(errors) {
  let text = errors?.title
  const buyLink = Constants.APPLICATIONS.find((app) => app.id === 3).buyLink
  if (errors?.errorKey === 'invalid-license-demo') {
    text = `</br>Per creare comunicazioni CRS è necessario l'acquisto di una licenza.
</br><button class="btn btn-primary rounded-pill btn-new-rel mt-5" onclick="location.href='${buyLink}'" type="button">Acquista licenza</button>`
  } else if (errors?.errorKey === 'invalid-license') {
    text = `</br>Licenza CRS scaduta, acquistare o rinnovare la licenza.
</br><button class="btn btn-primary rounded-pill btn-new-rel mt-5" onclick="location.href='${buyLink}'" type="button">Acquista licenza</button>`
  }
  return text || Constants.APPLICATION_GENERIC_ERROR
}
