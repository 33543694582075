import { useState, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import Modal from 'react-bootstrap/Modal'

import it from 'date-fns/locale/it'

import RelationshipForm from './RelationshipForm'
import { PopupDelete } from '../../../shared/PopupDelete'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import '../../../../styles/relationship-form.css'
import {
  baseSearchObject,
  fieldTypes,
  TableUtils as tableUtils
} from '../../../shared/tables/TableUtils'
import { RelationshipRow } from './RelationshipRow'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { CreateCommunicationModal } from '../communications/CreateCommunicationModal'
import * as Constants from '../../../../config/Constants'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import moment from 'moment'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { ReactComponent as ISort } from '../../../../styles/images/svgs/regular/sort.svg'
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay } from '../../../shared/Utility'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import { StarTable } from '../../../shared/tables/StarTable'
import { authReducer } from '../../../../reducers/auth-reducer'

registerLocale('it', it)

const TableActions = (props) => {
  const { isDisabled, openDeletePopup } = props
  return (
    <div className="col-12 ps-0">
      <div className="btn-group" role="group" aria-label="action buttons">
        <button
          type="button"
          className={`btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px ${isDisabled ? 'disabled' : ''}`}
          onClick={openDeletePopup}
          disabled={isDisabled}>
          <IDelete
            className={'padding-bottom-4px'}
            width="12"
            fill={` ${isDisabled ? '#FFFFFF' : '#128186'}`}
          />
          &nbsp; Elimina
        </button>
      </div>
    </div>
  )
}

const filterTypes = {
  DEFAULT: {
    searchField: 'DEFAULT',
    label: 'Elenco iniziale'
  }
  // OPEN_ONLY: {
  //   searchField: 'OPEN_ONLY',
  //   label: 'Rapporti solo aperti'
  // },
  // CLOSED_ONLY: {
  //   searchField: 'CLOSED_ONLY',
  //   label: 'Rapporti chiusi'
  // },
  // CANCELED_AND_COMMUNICATED: {
  //   searchField: 'CANCELED_AND_COMMUNICATED',
  //   label: 'Rapporti cancellati'
  // },
  // INCLUDED_IN_NEXT_COMMUNICATION: {
  //   searchField: 'INCLUDED_IN_NEXT_COMMUNICATION',
  //   label: 'Rapporti da comunicare'
  // },
  // BY_MISSING_IN_CONSISTENCY: {
  //   searchField: 'BY_MISSING_IN_CONSISTENCY',
  //   label: 'Assenti in fotografia'
  // }
}

const RelationshipList = (props) => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [actionsDisabled, setActionsDisabled] = useState(true)

  const [relationships, setRelationships] = useState([])
  const [currRelationship, setCurrRelationship] = useState(null)

  const [filterLabel, setFilterLabel] = useState('Elenco iniziale')

  const [checkedItems, setCheckedItems] = useState([])

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject
  })

  const tableName = 'crs-relationships-subject'

  const handleDeleteRelationship = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        ids.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions
      .deleteCrsRelationship(ids, props.subjectId)
      .then(
        () => {
          PopupSuccess()
        },
        (errors) => PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
      )
      .then(() => reloadRelationships())
      .then(() => {
        resetCheckBox()
        setLoading(false)
      })
  }

  const openDeletePopup = () => {
    PopupDelete({
      operazione: 'Rapporto',
      handleClickConfirm: handleDeleteRelationship
    })
  }

  const searchRelationships = (newSearchObject) => {
    const criteria = Object.values(filterTypes).find((e) => e.label === filterLabel)
    newSearchObject = {
      ...newSearchObject,
      criteria: newSearchObject.criteria ? newSearchObject.criteria : criteria?.searchField
    }
    return actions.getCrsRelationshipsBySubjectId(props.subjectId, newSearchObject)
  }

  const reloadRelationships = () => {
    searchRelationships(searchObject).then((data) => {
      updateRelationships(data.content, data)
    })
  }

  const updateRelationships = (content, res) => {
    setRelationships(content)
    setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
  }

  const handleFilterChange = (filter) => {
    setFilterLabel(filter.label)
    setLoading(true)
    const newSearchObject = {
      ...baseSearchObject,
      pageSize: searchObject.pageSize,
      criteria: filter.searchField
    }
    setSearchObject(newSearchObject)
    searchRelationships(newSearchObject).then((data) => {
      updateRelationships(data.content, data)
      setLoading(false)
    })
  }

  const mapCheckedRelationShips = (items, relationships) => {
    relationships.map((relationship) => items.set(relationship.id, true))
    return items
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Rapporto',
      handleClickConfirm: handleDelete
    })
  }

  const handleDelete = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        ids.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions
      .deleteCrsRelationship(ids, props.subjectId)
      .then(
        () => {
          PopupSuccess()
        },
        (errors) => PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
      )
      .then(() => reloadRelationships())
      .then(() => {
        resetCheckBox()
        setLoading(false)
      })
  }

  const resetCheckBox = () => {
    setCheckedItems([])
    setActionsDisabled(true)
  }

  const updateCheckedItems = (checkedItems = []) => {
    setActionsDisabled(!checkedItems.length)
    setCheckedItems(checkedItems)
  }

  const setDropdownCommType = () => {
    return props.communicationTypes.map((option, index) => (
      <Dropdown.Item key={index} eventKey={option.id}>
        {option.code + '-' + option.description}
      </Dropdown.Item>
    ))
  }

  const onDetailHide = () => {
    setCurrRelationship(undefined)
    reloadRelationships()
  }

  const onClickRow = (row) => {
    setCurrRelationship(row)
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'sequentialCode',
      orderingName: 'progressive',
      displayedName: 'Progressivo'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalIdentifier',
      orderingName: 'fiscalIdentifier',
      displayedName: 'ID Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'completeName',
      orderingName: 'completeName',
      displayedName: 'Titolare'
    }),
    tableUtils.composeHeader({
      fieldName: 'relType',
      orderingName: 'relType',
      displayedName: 'Numero'
    }),
    tableUtils.composeHeader({
      fieldName: 'balance',
      sortable: false,
      searchable: false,
      displayedName: 'Saldo'
    })
  ]

  const prepareRows = (fromRelationships = []) => {
    return fromRelationships.map((relationship) => {
      return tableUtils.composeRow({
        id: relationship.id,
        rowData: relationship,
        cellData: {
          sequentialCode: tableUtils.composeCell({
            fieldName: 'sequentialCode',
            fieldValue: relationship.progressive
          }),
          fiscalIdentifier: tableUtils.composeCell({
            fieldName: 'fiscalIdentifier',
            fieldValue: relationship.crsRelationshipHolder?.fiscalIdentifier
          }),
          completeName: tableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: relationship.crsRelationshipHolder?.completeName
          }),
          relType: tableUtils.composeCell({
            fieldName: 'relType',
            fieldValue: relationship.crsRelationshipNumberType?.code
          }),
          balance: tableUtils.composeCell({
            fieldName: 'balance',
            fieldValue: relationship.balance
          })
        }
      })
    })
  }

  useEffect(() => {
    setRows(prepareRows(relationships))
  }, [relationships])

  return (
    <>
      <StarTable
        headerColums={header}
        checkedItems={checkedItems}
        rows={rows}
        loading={loading}
        offset="300"
        perPageOptions={[10, 20, 50, 100, 250, 500]}
        tableConfigurationKey={tableName} // TODO
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchRelationships(searchObject)}
        onExecutedSearch={(content, res) => updateRelationships(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={updateCheckedItems}
        actionsComponent={
          <TableActions isDisabled={actionsDisabled} openDeletePopup={openDeletePopup} />
        }
        onClickRow={onClickRow}
      />
    </>
  )
}

export default RelationshipList
