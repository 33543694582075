import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { BwmInput } from '../shared/form/BwmInput'
import imgShowBlu from '../../styles/images/show_blu.png'
import { BwmCheckbox } from '../shared/form/BwmCheckbox'

const Signin = (props) => {
  const { submitCb, showLogin } = props
  const [companyName, setCompanyName] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [formData, updateFormData] = useState()
  const [errorCompanyName, setErrorCompanyName] = useState({ isValid: true, msg: '' })
  const [errorLastName, setErrorLastName] = useState({ isValid: true, msg: '' })
  const [errorFirstName, setErrorFirstName] = useState({ isValid: true, msg: '' })
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })
  const [errorPassword, setErrorPassword] = useState({ isValid: true, msg: '' })
  const [errorPasswordConf, setErrorPasswordConf] = useState({ isValid: true, msg: '' })
  const [privacy, setPrivacy] = useState(true)

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    }
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    if (validation()) {
      submitCb(formData)
    }
  }

  const resetError = () => {
    setErrorCompanyName({ isValid: true, msg: '' })
    setErrorLastName({ isValid: true, msg: '' })
    setErrorFirstName({ isValid: true, msg: '' })
    setErrorEmail({ isValid: true, msg: '' })
    setErrorPassword({ isValid: true, msg: '' })
    setErrorPasswordConf({ isValid: true, msg: '' })
  }

  const validation = () => {
    resetError()
    let isValid = true
    if (!companyName) {
      setErrorCompanyName({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    }
    if (!lastName) {
      setErrorLastName({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    }
    if (!firstName) {
      setErrorFirstName({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    }
    if (!email) {
      setErrorEmail({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    } else {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setErrorEmail({ isValid: false, msg: 'Formato email non valido' })
        isValid = false
      }
    }
    if (!password) {
      setErrorPassword({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    }
    if (!passwordConf) {
      setErrorPasswordConf({ isValid: false, msg: 'Campo Obbligatorio' })
      isValid = false
    } else if (passwordConf !== password) {
      setErrorPasswordConf({ isValid: false, msg: 'La password non coincide' })
      isValid = false
    }
    return isValid
  }

  const handlerCompanyName = (val) => {
    setCompanyName(val.toUpperCase())
    updateFormData({
      ...formData,
      companyName: val.toUpperCase()
    })
  }

  const handlerLastName = (val) => {
    setLastName(val.toUpperCase())
    updateFormData({
      ...formData,
      lastName: val.toUpperCase()
    })
  }

  const handlerFirstName = (val) => {
    setFirstName(val.toUpperCase())
    updateFormData({ ...formData, firstName: val.toUpperCase() })
  }

  const handlerEmail = (val) => {
    setEmail(val.toUpperCase())
    updateFormData({
      ...formData,
      email: val,
      login: val
    })
  }

  const handlerPassword = (val) => {
    setPassword(val)
    updateFormData({ ...formData, password: val })
  }

  const handlerPasswordConf = (val) => {
    setPasswordConf(val)
    updateFormData({ ...formData, passwordConf: val })
  }

  const handlerStayConn = (e) => {
    setPrivacy(e.target.checked)
  }

  return (
    <div className="col-md-10 col-lg-10 mt-3">
      <form onSubmit={(e) => handleConfirm(e)}>
        <div className="row-form big">
          <BwmInput
            name="companyName"
            type="text"
            label="Ragione Sociale"
            className="form-control uppercase"
            onChange={(e) => handlerCompanyName(e.target.value)}
            error={errorCompanyName}
          />
        </div>
        <div className="row-form">
          <BwmInput
            name="lastName"
            type="text"
            label="Cognome"
            className="form-control uppercase"
            onChange={(e) => handlerLastName(e.target.value)}
            error={errorLastName}
          />
          <BwmInput
            name="firstName"
            type="text"
            label="Nome"
            className="form-control uppercase"
            onChange={(e) => handlerFirstName(e.target.value)}
            error={errorFirstName}
          />
        </div>
        <div className="row-form big">
          <BwmInput
            name="email"
            type="text"
            label="Email"
            className="form-control"
            onChange={(e) => handlerEmail(e.target.value)}
            error={errorEmail}
          />
        </div>
        {props.errors?.status === 512 && (
          <div className="row-form big text-danger errorSignin">Email già utilizzata</div>
        )}
        <div className="row-form">
          <BwmInput
            id="siginPsw"
            name="password"
            type="password"
            label="Password"
            className="form-control"
            onChange={(e) => handlerPassword(e.target.value)}
            error={errorPassword}
          />
          <div className="align-self-center mt-2 eye">
            <img
              alt={'eye-blu'}
              className="img-info ms-2"
              src={imgShowBlu}
              onClick={(e) => onClickIconPsw(e, 'siginPsw')}
            />
          </div>
          <BwmInput
            id="siginPswConf"
            name="passwordConf"
            type="password"
            label="Conferma Password"
            className="form-control"
            onChange={(e) => handlerPasswordConf(e.target.value)}
            error={errorPasswordConf}
          />
          <div className="align-self-center mt-2 eye">
            <img
              alt={'eye-blu'}
              className="img-info ms-2"
              src={imgShowBlu}
              onClick={(e) => onClickIconPsw(e, 'siginPswConf')}
            />
          </div>
        </div>
        <div className="row-form loginPrivacy">
          <span>Consenso al trattamento dei dati</span>
          <div className="input-group">
            <BwmCheckbox
              name="stayConnected"
              className="form-control"
              value={privacy}
              onClick={(e) => handlerStayConn(e)}
            />
          </div>
        </div>
        <div className="notAccount">
          <span>
            Torna alla <strong onClick={showLogin}>Login</strong>
          </span>
        </div>
        <div className="loginSubmit">
          <button className="btn btn-primary btn-lg btn-new-rel btn-star" type="submit">
            Registrati
          </button>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'Signin'
})(Signin)
