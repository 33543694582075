import React, { useState } from 'react'
import * as actions from '../../../../actions'
import axiosService from '../../../../services/axios-service'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import Row from 'react-bootstrap/Row'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupError } from '../../../shared/PopupError'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import { testEmail } from '../../../shared/Utility'

registerLocale('it', it)
export function ArcoSubjectExportModal(props) {
  const emailAddressToProps = props.emailCustomer

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseModal, setResponseModal] = useState(false)
  const [responseKo, setResponseKo] = useState('')
  const [responseOk, setResponseOk] = useState('')
  const [responseBody, setResponseBody] = useState('')
  const [emailAddress, setEmailAddress] = useState(emailAddressToProps || '')
  const [emailModified, setEmailModified] = useState(false)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  const closeModal = () => {
    setShowModal(false)
    setEmailAddress(props.emailCustomer)
  }

  const closeResponseModal = () => {
    setResponseModal(false)
    setEmailAddress(props.emailCustomer)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handlerEmail = (e) => {
    setErrorEmail({ isValid: true, msg: '' })
    setEmailAddress(e.target.value)
    setEmailModified(true)
  }

  const handlerValidation = () => {
    if (emailModified && !emailAddress) {
      setErrorEmail({ isValid: false, msg: 'Campo obbligatorio' })
      return false
    }
    const emailToTest = emailModified ? emailAddress : props.emailCustomer
    if (!testEmail(emailToTest)) {
      setErrorEmail({ isValid: false, msg: 'Email non formalmente corretta' })
      return false
    }
    return true
  }

  const exportArcoSubjects = () => {
    if (handlerValidation()) {
      setLoading(true)
      const axiosInstance = axiosService.getInstance()
      let subjectsIds = props.checkedSubjects.map((r) => r.id)
      axiosInstance({
        data: subjectsIds,
        url: `/api/arco/custom/arco-subjects/export/${emailModified ? emailAddress : props.emailCustomer}`,
        method: 'POST'
      })
        .then(
          (response) => {
            setResponseOk('OK')
            setResponseModal(true)
            setResponseBody(response.data)
          },
          (err) => {
            setResponseKo("Errore durante l'esportazione")
            PopupError({ ...props, text: "Errore durante l'invio dei documenti" })
          }
        )
        .then(() => {
          closeModal()
          setLoading(false)
        })
    }
  }

  let email = !emailModified ? props.emailCustomer : emailAddress
  return (
    <React.Fragment>
      {loading && <PageSpinner />}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary btn-empty btn-sm ${props.disabled && 'disabled'}`}
        data-tip={'Esporta i soggetti selezionati'}
        onClick={openModal}>
        <IExport
          className={'padding-right-4px'}
          width="16"
          fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`}
        />
        &nbsp; Esporta
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>ESPORTA SOGGETTI</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p>
              Attraverso questa funzione è possibile scaricare tutti i dati inerenti ai soggetti
              selezionati.
            </p>
          </div>
          <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
            <div className="tab-content col-md-12 col-lg-12">
              <div className="form-row">
                <div className="col-md-8 col-lg-8 subDetailComurei">
                  <div className="row">
                    <div className="col-md-11 col-lg-11">
                      <BwmInput
                        id="emailAddress"
                        type="email"
                        className="form-control"
                        label="Indirizzo email a cui inviare il file di export"
                        value={email}
                        maxLength="100"
                        onChange={(e) => handlerEmail(e)}
                        error={errorEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              onClick={exportArcoSubjects}
              type="button"
              className="btn btn-primary btn-new-rel px-5">
              ESPORTA
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {responseModal && (
        <Modal
          backdrop="static"
          show={responseModal}
          onHide={closeResponseModal}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>INIZIO PROCEDURA ESPORTAZIONE SOGGETTI</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseOk ? (
              <React.Fragment>
                {responseBody ||
                  'Esportazione dei soggetti avviata: riceverai i file al tuo indirizzo email.'}
              </React.Fragment>
            ) : (
              <div className="popup communication">
                <Row>
                  <React.Fragment>
                    <p className={'color-red'}>
                      Errore nell'esportazione dei soggetti {responseKo}
                    </p>
                  </React.Fragment>
                </Row>
              </div>
            )}
            <div className="d-flex justify-content-between align-content-end mt-5">
              <button
                onClick={() => closeResponseModal()}
                type="button"
                className="btn btn-primary btn-new-rel px-5">
                OK, CHIUDI
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}
