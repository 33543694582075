import { CommunicationStatus } from '../../../../../config/CommunicationStatus'
/**
 * Genera un messaggio che identifica lo stato del file analizzato
 * @param {*} props
 * @returns
 */
export default function GenerateStatus(props) {
  const dispatchType = props.dispatchType
  const communicationStatus = props.communicationStatus
  const communicationError = props.communicationError
  const isConsistencyReceipt = dispatchType === 'CONSISTENCY_RECEIPT'
  let status = ''
  let color = ''

  if (communicationStatus === CommunicationStatus.CREATED) {
    status = 'Creata - In attesa di ricevuta'
  } else if (communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
    status = 'Creata con errori'
  } else if (communicationStatus === CommunicationStatus.ERROR_SID) {
    status = 'Ricevuta non elaborata: ' + communicationError
    color = 'red'
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
    if (isConsistencyReceipt) {
      status = (
        <>
          {"Fotografia elaborata: cliccare sull'icona per leggere il contenuto"} <br></br>{' '}
          {communicationError}
        </>
      )
    } else {
      status =
        "Ricevuta elaborata: comunicazione ACCOLTA - cliccare sull'icona per leggere il contenuto"
    }
  } else if (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
    if (isConsistencyReceipt) {
      status = communicationError
      color = 'red'
    } else {
      status = 'Ricevuta elaborata: comunicazione SCARTATA'
    }
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
    if (isConsistencyReceipt) {
      status = (
        <>
          {'Fotografia elaborata: discordanza tra gli archivi da verificare'}
          <br></br> {communicationError}
        </>
      )
    } else {
      status = "Ricevuta elaborata con errori: cliccare sull'icona per leggere il contenuto"
    }
  }
  return <strong style={{ color: color }}>{status}</strong>
}
