import { useState } from 'react'
import { ReactComponent as IComunicazioni } from '../../../../styles/images/svg/file-archive.svg'
import { PopupError } from '../../PopupError'
import { PopupSuccess } from '../../PopupSuccess'
import { saveSid } from '../../SidCertsHandler'
import { isEmpty, isNotEmpty } from '../../Utility'
import { BwmCheckbox } from '../BwmCheckbox'
import { BwmInput } from '../BwmInputB5'
import * as Constants from '../../../../config/Constants'
import { ParametriPecModal } from './ParametriPecModal'
import { PopupConfirm, titleColors } from '../../PopupConfirm'

function ContactForm(props) {
  const [isPecModalOpen, setPecModalOpen] = useState(false)
  let email = props.coraSubjectSid?.email ? props.coraSubjectSid?.email : props.defaultEmail

  const closePecModal = () => {
    setPecModalOpen(false)
  }

  const onSaveMailParameters = (subjectSid) => {
    props.handleChangeSidValues(subjectSid)

    if (isNotEmpty(subjectSid?.id)) {
      saveSid(
        undefined,
        subjectSid,
        subjectSid.fiscalCode,
        subjectSid.application,
        () => {
          PopupSuccess()
          closePecModal()
        },
        (errors) =>
          PopupError({ text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR })
      )
    } else {
      closePecModal()
    }
  }

  return (
    <div>
      <div className="row pt-3">
        <div className="col">
          <h5>
            <IComunicazioni fill="#000" width="18" height="17" />
            Comunicazioni
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 mt-2 pe-3">
          <div className="form-group" data-tip={'Indirizzo email del soggetto'}>
            <BwmInput
              className={'form-control'}
              label={`Indirizzo email a cui ricevere quanto generato da ${props.nameApplication ? props.nameApplication.toUpperCase() : 'CORA'}`}
              name="email"
              error={props.errors?.errorEmail?.msg}
              touched={props.errors?.errorEmail?.isValid === false}
              value={email}
              onChange={(e) =>
                props.handleChangeSid(e, 'errorEmail', 'Indirizzo email non corretto')
              }
            />
            <p className={'text-x-small'}>
              Da questo indirizzo si farà l'inoltro all'Agenzia (Sid1) dalla PEC del soggetto
            </p>
          </div>
        </div>
        <div className="col-md-5 mt-4">
          <BwmCheckbox
            name={`flagPec_${props.coraSubjectSid?.id}`}
            label="Selezionare in caso di email PEC"
            className="form-control"
            value={props.coraSubjectSid?.flagPec ? props.coraSubjectSid?.flagPec : false}
            onClick={(e) => props.handleChangeSidValues({ flagPec: e.target.checked })}
          />
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-left align-self-center">
          <button
            className={`btn btn-primary btn-new-rel px-3`}
            onClick={() => {
              if (isEmpty(props.coraSubjectSid?.id)) {
                PopupConfirm({
                  titleColor: titleColors.ALERT,
                  text: 'Per configurare la gestione automatica della pec devono essere correttamente caricati i certificati, la relativa password e il numero SID',
                  showCancelButton: false
                })
              } else {
                setPecModalOpen(true)
              }
            }}>
            Configura gestione automatica PEC
          </button>
        </div>
      </div>

      <ParametriPecModal
        coraSubjectSid={props.coraSubjectSid}
        show={isPecModalOpen}
        onClose={closePecModal}
        afterSave={(subjectSid) => onSaveMailParameters(subjectSid)}
      />
    </div>
  )
}

export default ContactForm
