import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, GET_ACCOUNT_SUCCESS } from '../actions/types'

const INITIAL_STATE = {
  isAuth: false,
  isAdmin: false,
  token: '',
  user: {},
  customer: {},
  errors: []
}

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isAuth: true,
        errors: [],
        isAdmin: action.isAdmin,
        token: action.token,
        user: action.user,
        customer: action.customer
      })
    case GET_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isAuth: true,
        errors: [],
        isAdmin: action.isAdmin,
        username: action.username,
        customer: action.customer,
        user: action.user
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, { errors: action.errors })
    case LOGOUT:
      return Object.assign({}, state, {
        isAuth: false,
        isAdmin: false,
        token: '',
        username: '',
        customer: {}
      })
    default:
      return state
  }
}
