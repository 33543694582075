import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { inviaCertificatiSid, ottieniRiepilogoCertificati } from '../../../actions'
import { BtnFill } from '../../shared/BtnFill'
import { FloatLabelTextBox } from '../../shared/form/input-boxes/FloatLabelTextBox'
import { PopupError } from '../../shared/PopupError'
import { PopupSuccess } from '../../shared/PopupSuccess'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { base64ToArrayBuffer, downloadFile } from '../../shared/Utility'
import { ConfirmModal } from './ConfirmModal'
import { PasswordSection } from './PasswordSection'
import { Tips } from './Tips'
import { SubjectCredentialsSection } from './SubjectCredentialsSection'
import { AdministratorSection } from './AdministratorSection'

export function FormGestioneCertificati(props) {
  const [isLoading, setIsLoading] = useState(false)

  const [nomeCliente, setNomeCliente] = useState('')

  const [comunicanteRagioneSociale, setComunicanteRagioneSociale] = useState('')
  const [comunicanteCodiceFiscale, setComunicanteCodiceFiscale] = useState('')

  const [pinFiscoOnline, setPinFiscoOnline] = useState('')
  const [pinRevoca, setPinRevoca] = useState('')

  const [amministratoreNomeCognome, setAmministratoreNomeCognome] = useState('')
  const [amministratoreCodiceFiscale, setAmministratoreCodiceFiscale] = useState('')
  const [amministratorePassword, setAmministratorePassword] = useState('')
  const [amministratorePin, setAmministratorePin] = useState('')
  const [amministratoreProgressivoSede, setAmministratoreProgressivoSede] = useState('')

  const [password, setPassword] = useState('')
  const [passwordRevoca, setPasswordRevoca] = useState('')
  const [progressivoSede, setProgressivoSede] = useState('')
  const [numeroRichiestaRegistrazione, setNumeroRichiestaRegistrazione] = useState('')

  const [showSendModal, setShowSendModal] = useState(false)
  const [dto, setDto] = useState()

  const sectionTitleStyle = 'fw-bold mt-5'

  const riepilogo = () => {
    setIsLoading(true)
    ottieniRiepilogoCertificati(dto)
      .then(
        (res) => {
          if (res) {
            downloadFile(base64ToArrayBuffer(res), `FNCERT_${comunicanteRagioneSociale}.pdf`)
            PopupSuccess({ text: 'File scaricato' })
          }
        },
        (err) => {
          PopupError({ text: "Si è verificato un errore durante l'invio dei dati" })
        }
      )
      .then(() => setIsLoading(false))
  }

  const invia = () => {
    setIsLoading(true)
    inviaCertificatiSid(dto)
      .then(
        (res) => {
          PopupSuccess({ text: 'Dati inviati correttamente' })
        },
        (err) => {
          PopupError({ text: "Si è verificato un errore durante l'invio dei dati" })
        }
      )
      .then(() => setIsLoading(false))
  }

  const renderConfirmModal = () => {
    return (
      <ConfirmModal
        showSendModal={showSendModal}
        setShowSendModal={setShowSendModal}
        riepilogo={riepilogo}
        invia={invia}
      />
    )
  }

  const submitForm = () => {
    const accreditamentoSidDto = {
      nomeCliente: nomeCliente,
      comunicanteRagioneSociale: comunicanteRagioneSociale,
      comunicanteCodiceFiscale: comunicanteCodiceFiscale,
      pinFiscoOnline: pinFiscoOnline,
      pinRevoca: pinRevoca,
      progressivoSede: progressivoSede,
      numeroRichiestaRegistrazione: numeroRichiestaRegistrazione,
      amministratoreNomeCognome: amministratoreNomeCognome,
      amministratoreCodiceFiscale: amministratoreCodiceFiscale,
      amministratorePassword: amministratorePassword,
      amministratorePin: amministratorePin,
      amministratoreProgressivoSede: amministratoreProgressivoSede,
      password: password,
      passwordRevoca: passwordRevoca
    }
    setDto(accreditamentoSidDto)
    setShowSendModal(true)
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <div className="text-center">
        <h1>Servizio FacciamoNoi</h1>
        <h1>GESTIONE CERTIFICATI</h1>
      </div>
      <div className="w-75 m-auto bg-blue px-2">
        <div>
          Il servizio comprende:
          <ol>
            <li>Eventuale Revoca di Ambiente scaduto/obsoleto</li>
            <li>Generazione nuovo ambiente di Sicurezza</li>
          </ol>
          Compilare i dati richiesti:
        </div>
        <section className="ms-3">
          <Row>
            <Col lg={8}>
              <FloatLabelTextBox
                value={nomeCliente}
                label={'Nome Cliente'}
                onChange={(e) => setNomeCliente(e.target.value)}
              />
            </Col>
          </Row>
          <div className={sectionTitleStyle}>Soggetto Obbligato</div>
          <Row>
            <Col lg={6}>
              <FloatLabelTextBox
                value={comunicanteRagioneSociale}
                label={'Ragione Sociale'}
                onChange={(e) => setComunicanteRagioneSociale(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <FloatLabelTextBox
                value={comunicanteCodiceFiscale}
                label={'Codice Fiscale'}
                onChange={(e) => setComunicanteCodiceFiscale(e.target.value)}
              />
            </Col>
          </Row>
          <SubjectCredentialsSection
            sectionTitleStyle={sectionTitleStyle}
            pinFiscoOnline={pinFiscoOnline}
            pinRevoca={pinRevoca}
            progressivoSede={progressivoSede}
            numeroRichiestaRegistrazione={numeroRichiestaRegistrazione}
            setPinFiscoOnline={setPinFiscoOnline}
            setPinRevoca={setPinRevoca}
            setProgressivoSede={setProgressivoSede}
            setNumeroRichiestaRegistrazione={setNumeroRichiestaRegistrazione}
          />

          <AdministratorSection
            sectionTitleStyle={sectionTitleStyle}
            amministratoreNomeCognome={amministratoreNomeCognome}
            setAmministratoreNomeCognome={setAmministratoreNomeCognome}
            amministratoreCodiceFiscale={amministratoreCodiceFiscale}
            setAmministratoreCodiceFiscale={setAmministratoreCodiceFiscale}
            amministratorePassword={amministratorePassword}
            setAmministratorePassword={setAmministratorePassword}
            amministratorePin={amministratorePin}
            setAmministratorePin={setAmministratorePin}
            amministratoreProgressivoSede={amministratoreProgressivoSede}
            setAmministratoreProgressivoSede={setAmministratoreProgressivoSede}
          />
          <PasswordSection
            sectionTitleStyle={sectionTitleStyle}
            password={password}
            passwordRevoca={passwordRevoca}
            setPassword={setPassword}
            setPasswordRevoca={setPasswordRevoca}
          />
        </section>
        <Row className="row justify-content-end mt-3 pb-3 pe-3">
          <BtnFill
            classCustom="col text-end"
            handlerOnClick={submitForm}
            text={'CONFERMA'}></BtnFill>
        </Row>
        <Tips />
      </div>
      {renderConfirmModal()}
    </>
  )
}
