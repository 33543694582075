import React, { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import '../../../../styles/comurei-form.css'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustom'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmSelect } from '../../../shared/form/BwmSelect'
import { PopupError } from '../../../shared/PopupError'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ProvinceField } from '../../application-cora/relationships/ProvinceField'

const CorproForm = (props) => {
  const [sub, setSub] = useState(props.subjectItem || null)
  const [subjectTypes, setSubjectTypes] = useState(props.subjectTypes || [])
  const [transmissionTypes, setTransmissionTypes] = useState(props.corproTransmissionTypes || [])
  const [formData, updateFormData] = useState()
  const [subjectId, setSubjectId] = useState(sub?.id || null)
  const [subjectType, setSubjectType] = useState(sub?.corproSubjectType?.id || '')
  const [subjectTypeCode, setSubjectTypeCode] = useState(sub?.corproSubjectType?.code || '')
  const [communicationTypes, setCommunicationTypes] = useState(props.communicationTypes || [])
  const [communicationType, setCommunicationType] = useState(
    sub?.corproCommunicationType?.id || '1'
  )
  const [communicationTypeCode, setCommunicationTypeCode] = useState(
    sub?.corproCommunicationType?.code || 0
  )

  const [supplierTypes, setSupplierTypes] = useState(props.corproSupplierTypes || [])
  const [supplierType, setSupplierType] = useState(sub?.corproSupplierType?.id || 1)
  const [supplierTypeCode, setSupplierTypeCode] = useState(sub?.corproSupplierType?.code || '01')

  const [commType, setCommType] = useState(sub?.corproCommunicationType.id.toString() || '1')
  const [referenceYear, setReferenceYear] = useState(sub?.referenceYear || 2020)
  const [emailAddress, setEmailAddress] = useState(sub?.email || '')

  const [authorityFiscalCode, setAuthorityFiscalCode] = useState(sub?.authorityFiscalCode || '')
  const [authorityDescription, setAuthorityDescription] = useState(sub?.authorityDescription || '')
  const [authorityProvince, setAuthorityProvince] = useState(sub?.authorityProvince || '')

  const [registrationCompatible, setRegistrationCompatible] = useState(
    sub?.registrationCompatible || false
  )
  const [registrationIncompatible, setRegistrationIncompatible] = useState(
    sub?.registrationIncompatible || false
  )
  const [specialSections, setSpecialSections] = useState(sub?.specialSections || false)
  const [errorRegistrationCompatible, setErrorRegistrationCompatible] = useState({
    isValid: true,
    msg: ''
  })
  const [errorRegistrationIncompatible, setErrorRegistrationIncompatible] = useState({
    isValid: true,
    msg: ''
  })
  const [errorSpecialSections, setErrorSpecialSections] = useState({ isValid: true, msg: '' })

  const [errorAuthorityFiscalCode, setErrorAuthorityFiscalCode] = useState({
    isValid: true,
    msg: ''
  })
  const [errorAuthorityDescription, setErrorAuthorityDescription] = useState({
    isValid: true,
    msg: ''
  })
  const [errorAuthorityProvince, setErrorAuthorityProvince] = useState({ isValid: true, msg: '' })

  const [subjectName, setSubjectName] = useState(sub?.subjectName || '')
  const [subjectFiscalCode, setSubjectFiscalCode] = useState(sub?.subjectFiscalCode || '')
  const [location, setLocation] = useState(sub?.location || '')
  const [province, setProvince] = useState(sub?.province || '')
  const [errorSubjectName, setErrorSubjectName] = useState({ isValid: true, msg: '' })
  const [errorLocation, setErrorLocation] = useState({ isValid: true, msg: '' })
  const [errorProvince, setErrorProvince] = useState({ isValid: true, msg: '' })

  const [protocolNumber, setProtocolNumber] = useState(sub?.protocolNumber || '')
  const [protocolProgressive, setProtocolProgressive] = useState(sub?.protocolProgressive || '')
  const [errorProtocolNumber, setErrorProtocolNumber] = useState({ isValid: true, msg: '' })
  const [errorProtocolProgressive, setErrorProtocolProgressive] = useState({
    isValid: true,
    msg: ''
  })

  const [corproTrasmissionFiscalCode, setCorproTrasmissionFiscalCode] = useState(
    sub?.corproTrasmissionFiscalCode || ''
  )
  const [transmissionType, setTransmissionType] = useState(
    sub?.corproTransmissionType?.id.toString() || (supplierType == 1 ? 1 : null)
  )

  const [errorSubjectType, setErrorSubjectType] = useState({ isValid: true, msg: '' })
  const [errorCommunicationType, setErrorCommunicationType] = useState({ isValid: true, msg: '' })
  const [errorSupplierType, setErrorSupplierType] = useState({ isValid: true, msg: '' })

  const [errorReferenceYear, setErrorReferenceYear] = useState({ isValid: true, msg: '' })
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })

  const [errorCorproTrasmissionFiscalCode, setErrorCorproTrasmissionFiscalCode] = useState({
    isValid: true,
    msg: ''
  })
  const [errorTransmissionType, setErrorTransmissionType] = useState({ isValid: true, msg: '' })
  const [tabError, setTabError] = useState({ subject: true })
  const [errorSubjectFiscalCode, setErrorSubjectFiscalCode] = useState({ isValid: true, msg: '' })

  const [enableCancelParams, setEnableCancelParams] = useState(
    parseInt(communicationTypeCode) === 0 ? false : true
  )

  const [enableSubjectForms, setEnableSubjectForm] = useState(supplierType == 2)

  const [enableTransmissionParams, setEnableTransmissionParams] = useState(supplierType == 2)

  useEffect(() => {
    if (!tabError.subject) {
      if (!document.getElementById('tab-sub').classList.contains('active')) {
        document.getElementById('tab-sub').click()
      }
    }
    let corproTransmissionTypeValue = null
    if (transmissionType) {
      corproTransmissionTypeValue = { id: transmissionType }
    }

    updateFormData({
      ...formData,
      id: subjectId,
      authorityFiscalCode: authorityFiscalCode,
      authorityDescription: authorityDescription,
      authorityProvince: authorityProvince,
      subjectName: subjectName,
      location: location,
      province: province,
      referenceYear: referenceYear,
      corproCommunicationType: { id: commType },
      corproSubjectType: {
        id: subjectType,
        code: subjectTypeCode
      },
      corproSupplierType: {
        id: supplierType,
        code: supplierTypeCode
      },
      protocolNumber: protocolNumber,
      protocolProgressive: protocolProgressive,
      corproTransmissionType: corproTransmissionTypeValue,
      corproTrasmissionFiscalCode: corproTrasmissionFiscalCode,
      email: emailAddress,
      registrationCompatible: registrationCompatible,
      registrationIncompatible: registrationIncompatible,
      specialSections: specialSections,
      subjectFiscalCode: subjectFiscalCode
    })
  }, [tabError])

  const addCorproSubject = (newSubject, sendComm) => {
    actions.saveCorproSubject(newSubject).then(
      (response) => {
        if (sendComm) {
          props.openCreateCommunicationModal(response, null)
        } else {
          PopupSuccess({
            text: "CLICCA SUL SOGGETTO NELL'ELENCO PER CREARE LA COMUNICAZIONE"
          })
        }
        props.getSubjects()
      },
      (errors) => {
        PopupError({
          text:
            errors.status === 511
              ? 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
              : 'Errore generico nel salvataggio dei dati'
        })
      }
    )

    handleClose()
  }

  const handleClose = () => {
    if (props.closeModal) {
      props.closeModal()
    } else {
      props.showDetail(props.index)
    }
  }

  const handlerChangeSubjectType = (e) => {
    setSubjectType(e.target.value)
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setSubjectTypeCode(cod)
    updateFormData({
      ...formData,
      corproSubjectType: {
        id: parseInt(e.target.value),
        code: cod
      }
    })
  }

  const handlerChangeSupplierType = (e) => {
    setSupplierType(e.target.value)
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setSupplierTypeCode(cod)
    setEnableSubjectForm(cod === '10')
    setEnableTransmissionParams(cod === '10')
    let subjectFiscalCodeUpdate = subjectFiscalCode
    if (cod === '01') {
      setSubjectFiscalCode(authorityFiscalCode)
      subjectFiscalCodeUpdate = authorityFiscalCode
    }
    updateFormData({
      ...formData,
      corproSupplierType: {
        id: parseInt(e.target.value),
        code: cod
      },
      subjectFiscalCode: subjectFiscalCodeUpdate
    })
  }

  const handlerChangeCommunicationType = (e) => {
    setCommunicationType(e.target.value)
    const cod = e.target[e.target.selectedIndex].dataset.cod
    if (parseInt(cod) !== 0) setEnableCancelParams(true)
    else setEnableCancelParams(false)

    setCommunicationTypeCode(cod)
    updateFormData({
      ...formData,
      corproCommunicationType: {
        id: parseInt(e.target.value),
        code: cod
      }
    })
  }

  const handlerChangeTrasmissionType = (e) => {
    setTransmissionType(e.target.value)
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setCommunicationTypeCode(cod)
    updateFormData({
      ...formData,
      corproTransmissionType: {
        id: parseInt(e.target.value),
        code: cod
      }
    })
  }

  const handlerChangeAuthorityFiscalCode = (e) => {
    setAuthorityFiscalCode(e.target.value.toUpperCase())

    if (supplierType == 1) {
      setSubjectFiscalCode(e.target.value.toUpperCase())
      updateFormData({
        ...formData,
        authorityFiscalCode: e.target.value.toUpperCase(),
        subjectFiscalCode: e.target.value.toUpperCase()
      })
    } else {
      updateFormData({
        ...formData,
        authorityFiscalCode: e.target.value.toUpperCase()
      })
    }
  }

  const handlerChangeSubjectFiscalCode = (e) => {
    setSubjectFiscalCode(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      subjectFiscalCode: e.target.value.toUpperCase()
    })
  }

  const handlerChangeAuthorityDescription = (e) => {
    setAuthorityDescription(e.target.value.toUpperCase())

    updateFormData({
      ...formData,
      authorityDescription: e.target.value.toUpperCase()
    })
  }

  const handleAuthorityProvinceChange = (e) => {
    setAuthorityProvince(e.target.value.toUpperCase())
    if (supplierType == 1) {
      setProvince(e.target.value.toUpperCase())
      updateFormData({
        ...formData,
        province: e.target.value.toUpperCase(),
        authorityProvince: e.target.value.toUpperCase()
      })
    } else {
      updateFormData({
        ...formData,
        authorityProvince: e.target.value.toUpperCase()
      })
    }
  }

  const handleFiscalCode = (val) => {
    actions.getBaseRegistryByFiscalCode(val).then(
      (baseRegistry) => {
        if (baseRegistry) {
          setSubjectName(baseRegistry?.companyName || '')
          setLocation(baseRegistry?.location?.location || '')
          setProvince(baseRegistry?.location?.province || '')
          updateFormData({
            ...formData,
            subjectName: subjectName || '',
            location: location || '',
            province: province || ''
          })
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
  }

  const handlerChangeSubjectName = (e) => {
    setSubjectName(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      subjectName: e.target.value.toUpperCase()
    })
  }

  const handlerChangeProtocolNumber = (e) => {
    setProtocolNumber(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      protocolNumber: e.target.value.toUpperCase()
    })
  }

  const handlerChangeProtocolProgressive = (e) => {
    setProtocolProgressive(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      protocolProgressive: e.target.value.toUpperCase()
    })
  }

  const handleInputAutocompile = (newLocation, id, suggestionIndex) => {
    setLocation(newLocation.toUpperCase())
    actions.getLocation(newLocation.toUpperCase()).then(
      (loc) => {
        if (loc) {
          setLocation(loc.location)
          setProvince(loc.province)
          updateFormData({
            ...formData,
            location: newLocation.toUpperCase(),
            province: loc.province
          })
        } else {
          updateFormData({
            ...formData,
            location: newLocation.toUpperCase()
          })
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
  }

  const handleProvinceChange = (e) => {
    setProvince(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      province: e.target.value.toUpperCase()
    })
  }

  const handlerChangeReferenceYear = (e) => {
    setReferenceYear(e.target.value)
    updateFormData({
      ...formData,
      referenceYear: e.target.value
    })
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      email: e.target.value.toUpperCase()
    })
  }

  const handlerChangeTransmissionFiscalCode = (e) => {
    setCorproTrasmissionFiscalCode(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      corproTrasmissionFiscalCode: e.target.value.toUpperCase()
    })
  }

  const handleTransmissionTypeClick = (event) => {
    setTransmissionType(event.target.value)
    updateFormData({
      ...formData,
      corproTransmissionType: { id: event.target.value }
    })
  }

  const resetError = () => {
    setCommunicationType({ isValid: true, msg: '' })
    setErrorReferenceYear({ isValid: true, msg: '' })
    setErrorSubjectType({ isValid: true, msg: '' })
    setErrorAuthorityFiscalCode({ isValid: true, msg: '' })
    setErrorSubjectName({ isValid: true, msg: '' })
    setErrorLocation({ isValid: true, msg: '' })
    setErrorProvince({ isValid: true, msg: '' })
    setErrorEmailAddress({ isValid: true, msg: '' })
    setErrorProtocolNumber({ isValid: true, msg: '' })
    setErrorProtocolProgressive({ isValid: true, msg: '' })
    setErrorAuthorityDescription({ isValid: true, msg: '' })
    setErrorAuthorityProvince({ isValid: true, msg: '' })
    setErrorCorproTrasmissionFiscalCode({ isValid: true, msg: '' })
    setErrorTransmissionType({ isValid: true, msg: '' })
    setErrorSupplierType({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    const MIN_YEAR = 2020
    // VALIDATION SUBJECT
    if (!referenceYear) {
      setErrorReferenceYear({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else if (referenceYear < MIN_YEAR) {
      setErrorReferenceYear({
        isValid: false,
        msg: "L'Anno deve essere " + MIN_YEAR + ' o successivo'
      })
      isValid = false
    }
    if (!authorityFiscalCode) {
      setErrorAuthorityFiscalCode({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!authorityDescription) {
      setErrorAuthorityDescription({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!authorityProvince) {
      setErrorAuthorityProvince({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!subjectName) {
      setErrorSubjectName({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!subjectType) {
      setErrorSubjectType({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!supplierType) {
      setErrorSupplierType({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!location) {
      setErrorLocation({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!province) {
      setErrorProvince({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!subjectFiscalCode) {
      setErrorSubjectFiscalCode({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!communicationType) {
      setErrorCommunicationType({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else {
      if (enableCancelParams) {
        if (!protocolNumber) {
          setErrorProtocolNumber({ isValid: false, msg: 'Campo obbligatorio' })
          isValid = false
        }
        if (!protocolProgressive) {
          setErrorProtocolProgressive({ isValid: false, msg: 'Campo obbligatorio' })
          isValid = false
        }
      }
    }
    // VALIDATION EMAIL
    if (!emailAddress) {
      setErrorEmailAddress({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
        setErrorEmailAddress({ isValid: false, msg: 'Formato email non valido' })
        isValid = false
      }
    }
    if (enableTransmissionParams) {
      if (!transmissionType) {
        setErrorTransmissionType({ isValid: false, msg: 'Campo obbligatorio' })
        isValid = false
      }

      if (!corproTrasmissionFiscalCode) {
        setErrorCorproTrasmissionFiscalCode({ isValid: false, msg: 'Campo obbligatorio' })
        isValid = false
      }
    }

    return isValid
  }

  const handleFormSubmit = (event, sendComm) => {
    event.preventDefault()
    if (handleValidation()) {
      addCorproSubject(formData, sendComm)
    }
  }

  const handlerChangeRegistrationCompatible = (e) => {
    setRegistrationCompatible(e.target.checked)
    updateFormData({
      ...formData,
      registrationCompatible: e.target.checked
    })
  }

  const handlerChangeRegistrationIncompatible = (e) => {
    setRegistrationIncompatible(e.target.checked)
    updateFormData({
      ...formData,
      registrationIncompatible: e.target.checked
    })
  }

  const handlerChangeSpecialSections = (e) => {
    setSpecialSections(e.target.checked)
    updateFormData({
      ...formData,
      specialSections: e.target.checked
    })
  }

  return (
    <React.Fragment>
      <form className="corpro" onSubmit={(e) => handleFormSubmit(e, false)}>
        <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 d-flex">
          <div className="form-row">
            <div className="corproGroup panelSub">
              <div className="form-row">
                <div className="col-md-12 col-lg-12 subDetailComurei">
                  <h6>DATI DELL'ALBO/ORDINE A CUI SI RIFERISCONO GLI ISCRITTI</h6>
                  <div className="row">
                    <div className="col-md-8 col-lg-8">
                      <BwmSelect
                        options={subjectTypes}
                        name="subjectType"
                        label="Natura"
                        className="form-control"
                        onChange={handlerChangeSubjectType}
                        value={subjectType}
                        error={errorSubjectType}
                        showCode={true}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4 ps-0">
                      <BwmInput
                        name="authorityFiscalCode"
                        label="Codice Fiscale"
                        className="form-control"
                        value={authorityFiscalCode}
                        onChange={(e) => handlerChangeAuthorityFiscalCode(e)}
                        error={errorAuthorityFiscalCode}
                        maxLength="16"
                        onBlur={(e) => handleFiscalCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 col-lg-8">
                      <BwmInput
                        name="authorityDescription"
                        label="Denominazione"
                        className="form-control"
                        value={authorityDescription}
                        onChange={(e) => handlerChangeAuthorityDescription(e)}
                        error={errorAuthorityDescription}
                        maxLength="60"
                      />
                    </div>
                    <div className="col-md-1 col-lg-1 pe-0">
                      <div className="row">
                        <ProvinceField
                          province={authorityProvince}
                          label={'Provincia'}
                          onChange={(e) => handleAuthorityProvinceChange(e)}
                          error={errorAuthorityProvince}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10 col-lg-10">
                      <BwmSelect
                        options={supplierTypes}
                        name="supplierType"
                        label="Tipo Fornitore"
                        className="form-control"
                        onChange={handlerChangeSupplierType}
                        value={supplierType}
                        error={errorSupplierType}
                        showCode={true}
                      />
                    </div>
                  </div>
                  <div className={'form-row ps-0 pb-10'}>
                    <div className="col-md-10 col-lg-10 form-check">
                      <label>CARATTERISTICHE DELL'ALBO</label>
                    </div>
                  </div>
                  <div className={'form-row ps-0 pb-10'}>
                    <div className="col-md-4 col-lg-4 form-check d-flex">
                      <div className="input-group  mt-2 align-self-start">
                        <BwmCheckbox
                          name={`registrationCompatible_${subjectId}`}
                          label="L'ordine o albo prevede iscrizione senza esercizio dell'attività"
                          className="form-control"
                          value={registrationCompatible}
                          error={errorRegistrationCompatible}
                          onClick={(e) => handlerChangeRegistrationCompatible(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 form-check d-flex">
                      <label></label>
                      <div className="input-group me-2 mt-2 align-self-start">
                        <BwmCheckbox
                          name={`registrationIncompatible_${subjectId}`}
                          label="L'albo prevede attività incompatibili all'iscrizione"
                          className="form-control"
                          value={registrationIncompatible}
                          error={errorRegistrationIncompatible}
                          onClick={(e) => handlerChangeRegistrationIncompatible(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 form-check d-flex">
                      <label> </label>
                      <div className="input-group  mt-2 align-self-start">
                        <BwmCheckbox
                          name={`specialSections_${subjectId}`}
                          label="L'albo prevede sezioni speciali"
                          className="form-control"
                          value={specialSections}
                          error={errorSpecialSections}
                          onClick={(e) => handlerChangeSpecialSections(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="corproGroup2 panelSub">
              <div className="col-md-12 col-lg-12 commDetailComurei">
                <h6>ESTREMI DELLA COMUNICAZIONE</h6>
                <div className="form-row ps-0">
                  <div className="col-md-6 col-lg-6">
                    <BwmSelect
                      options={communicationTypes}
                      name="communicationType"
                      label="Tipo Comunicazione"
                      className="form-control"
                      onChange={handlerChangeCommunicationType}
                      value={communicationType}
                      error={errorCommunicationType}
                      showCode={true}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <BwmInput
                      name="referenceYear"
                      label="Anno riferimento"
                      className="form-control"
                      value={referenceYear}
                      onChange={(e) => handlerChangeReferenceYear(e)}
                      error={errorReferenceYear}
                      maxLength="4"
                      onBlur={(e) => handlerChangeReferenceYear(e)}
                    />
                  </div>
                </div>
                {enableCancelParams ? (
                  <div className="form-row">
                    <div className="col-md-6 col-lg-6">
                      <BwmInput
                        name="protocolNumber"
                        label="Protocollo N."
                        className="form-control"
                        value={protocolNumber}
                        onChange={(e) => handlerChangeProtocolNumber(e)}
                        error={errorProtocolNumber}
                        maxLength="20"
                        onBlur={(e) => handlerChangeProtocolNumber(e)}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <BwmInput
                        name="protocolProgressive"
                        label="Protocollo Progressivo"
                        className="form-control"
                        value={protocolProgressive}
                        onChange={(e) => handlerChangeProtocolProgressive(e)}
                        error={errorProtocolProgressive}
                        maxLength="20"
                        onBlur={(e) => handlerChangeProtocolProgressive(e)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="form-row"></div>
                )}
              </div>
            </div>
            <div className="corproGroup panelSub">
              <div className="form-row">
                <div className="col-md-12 col-lg-12 subDetailComurei">
                  <h6>DATI ORDINE/CONSIGLIO NAZIONALE O Albo Territoriale</h6>
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <BwmInput
                        name="subjectFiscalCode"
                        label="Codice Fiscale"
                        className={'form-control'}
                        value={subjectFiscalCode}
                        error={errorSubjectFiscalCode}
                        maxLength="11"
                        onChange={(e) => handlerChangeSubjectFiscalCode(e)}
                        disabled={!enableSubjectForms}
                      />
                    </div>
                    <div className="col-md-7 col-lg-7">
                      <BwmInput
                        name="subjectName"
                        label="Denominazione"
                        className="form-control"
                        value={subjectName}
                        onChange={(e) => handlerChangeSubjectName(e)}
                        error={errorSubjectName}
                        maxLength="60"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <AutoCompileCustom
                        label="Comune domicilio fiscale"
                        id="location"
                        handleInputAutocompile={handleInputAutocompile}
                        filtro={props.locations}
                        value={location}
                        error={errorLocation}
                      />
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <ProvinceField
                        province={province}
                        onChange={(e) => handleProvinceChange(e)}
                        error={errorProvince}
                        disabled={!enableSubjectForms}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <BwmInput
                        name="emailAddress"
                        label="Email valido per eventuali contatti"
                        className="form-control"
                        value={emailAddress}
                        onChange={(e) => handlerChangeEmailAddress(e)}
                        error={errorEmailAddress}
                        maxLength="100"
                        onBlur={(e) => handlerChangeEmailAddress(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {enableTransmissionParams ? (
              <div className="corproGroup2 panelSub">
                <div className="col-md-12 col-lg-12 d-flex flex-column">
                  <h6>SOGGETTO CHE ASSUME L'IMPEGNO ALLA TRASMISSIONE TELEMATICA</h6>
                  <div className="form-row ps-0">
                    <div className="col-md-12 col-lg-12">
                      <BwmSelect
                        options={transmissionTypes}
                        name="transmissionType"
                        label="Impegno Trasmissione"
                        className="form-control"
                        onChange={(e) => handlerChangeTrasmissionType(e)}
                        value={transmissionType}
                        error={errorTransmissionType}
                        showCode={true}
                      />
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <BwmInput
                        name="transmissionFiscalCode"
                        label="Codice Fiscale"
                        className="form-control"
                        value={corproTrasmissionFiscalCode}
                        onChange={(e) => handlerChangeTransmissionFiscalCode(e)}
                        error={errorCorproTrasmissionFiscalCode}
                        maxLength="16"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
          <div className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={handleClose}>
            <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
            &nbsp; ANNULLA
          </div>
          <button
            className="btn btn-primary btn-new-rel px-5"
            onClick={(e) => handleFormSubmit(e, true)}
            disabled={props.licenseExpired}>
            <ITextFile className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
            &nbsp; IMPORTA ARCHIVIO ISCRITTI E CREA COMUNICAZIONE
          </button>
          <button type="submit" className="btn btn-primary btn-new-rel px-5">
            <ISave className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
            &nbsp; CONFERMA
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default reduxForm({
  form: 'CorproForm'
})(CorproForm)
