import React, { useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import * as actions from '../../../../actions'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { isEmpty } from '../../../shared/Utility'

registerLocale('it', it)

export function ArcoRegistryImportDocumentModal(props) {
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })

  const closeModal = () => {
    props.onHide()
  }

  const checkFileName = (name) => {
    let allowed = ['pdf', 'jpg', 'png', 'jpeg', 'docx', 'txt']
    return allowed.indexOf(name.split('.').pop().toLowerCase()) !== -1
  }

  const checkFileDimension = (file) => {
    let sumDimensionFiles = 0
    if (file.length === 1) {
      sumDimensionFiles = file[0].size
    } else {
      for (let i = 0; i < file.length; i++) {
        sumDimensionFiles += file[i].size
      }
    }
    if (sumDimensionFiles > 20e6) {
      return false
    } else {
      return true
    }
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: 'Il file è obbligatorio' })
    }
    let files = []
    if (checkFileDimension(val.target.files)) {
      setErrorDataFile({ isValid: true, msg: '' })
      for (let i = 0; i < val.target.files.length; i++) {
        if (!checkFileName(val.target.files[i].name)) {
          setErrorDataFile({ isValid: false, msg: 'Estensione o nome file errati' })
        } else {
          setErrorDataFile({ isValid: true, msg: '' })
          files.push(val.target.files[i])
        }
      }
    } else {
      setErrorDataFile({ isValid: false, msg: 'Dimensione file maggiore di 20MB' })
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append('files', dataFile[i])
      }
    } else {
      return
    }

    actions.importDocumentFile(props.id, formData).then(
      (res) => {
        let results = []
        if (!res.error) {
          PopupSuccess({
            dataList: results
          })
          closeModal()
          props.uncheckAll()
          props.getAllArcoDocumentsByOperationId()
        } else {
          setDataFile([])
        }
      },
      (err) => {
        if (err.errorKey) {
          PopupError({ ...props, text: err.title })
        } else {
          PopupError({ text: 'Errore importazione file' })
        }
      }
    )
  }

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>IMPORTA FILE</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-upload text-start">
            <label htmlFor={`dataFile`}>
              <div className="certificateFiles ps-4 ms-2">
                CARICA FILE <IUpload width="25" fill="#000" />
              </div>
            </label>
            <input
              id={`dataFile`}
              type="file"
              name={`dataFile`}
              multiple
              onChange={(e) => handleUploadFile(e)}
            />
            <div className="certificatiLoaded ps-4 ms-2 text-start">
              {!errorDataFile.isValid ? (
                <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
              ) : (
                <div>
                  {dataFile.length > 0 &&
                    dataFile.map((file, index) => {
                      return (
                        <div key={index}>
                          <ICheck width="12" fill="#8e8e8e" />
                          {file.name}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-0">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || !dataFile}
              onClick={handleSendDataFile}
              type="button"
              className="btn btn-primary btn-new-rel px-5">
              CONFERMA
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
